import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { IImageFragment } from "@/graphql/fragments/__generated__/image.types";
import {
  GetImageSignedPolicyUrlDocument,
  IGetImageSignedPolicyUrlQuery,
  IGetImageSignedPolicyUrlQueryVariables
} from "@/graphql/queries/__generated__/getImageSignedPolicyURL.types";
import { IImage_Type_Enum } from "@/graphql/__generated__/graphql.types";
import { useApolloClient } from "@apollo/client";
import {
  CropParams, parseCropParams,
  PixelCrop
} from "@gannettdigital/community-hub-components";

export interface IKVPair {
  key: string;
  value: any;
}

export interface IUseImageFunctions {
  error: string | undefined;
  createImages: (obitId: string, images: IImageFragment[]) => Promise<string[]>;
  deleteImage: (image_id?: string) => Promise<void>;
}

function parseXml(xmlStr: string): Document {
  return new window.DOMParser().parseFromString(xmlStr, "text/xml");
}

const GCS_ERRORS: { [key: string]: string } = {
  EntityTooLarge: "uploadEntityTooLarge",
  UnknownError: "uploadUnknownError"
};

const supportedMimeTypes = {
  "image/jpeg": "jpeg",
  "image/png": "png"
};
async function getErrorCode(response: Response) {
  const xml = await response.text();

  const errorCode = parseXml(xml)
    .getElementsByTagName("Code")
    .item(0)?.textContent;

  return GCS_ERRORS[errorCode ?? "UnknownError"];
}

const _createImage = (url: string, fields: IKVPair[], file: File) => {
  const formdata = new FormData();

  fields.forEach(field => {
    formdata.append(field.key, field.value);
  });

  // file MUST be the last form field
  formdata.append("file", file);

  return fetch(url, {
    method: "POST",
    body: formdata
  });
};

const useImageFunctions = (): IUseImageFunctions => {
  const [error, setError] = useState<string | undefined>();
  const apolloClient = useApolloClient();

  const getSignedPolicy = (
    variables: IGetImageSignedPolicyUrlQueryVariables
  ) => {
    return apolloClient.query<IGetImageSignedPolicyUrlQuery>({
      query: GetImageSignedPolicyUrlDocument,
      variables
    });
  };

  const createImages = async (obitId: string, images: IImageFragment[]) => {
    setError("");

    const imageIds = [];
    for (const image of images) {
      const uuidName = uuidv4();
      const blob = await fetch(image.uri).then(response => response.blob());
      const file = new File([blob], uuidName, { type: blob.type });
      const extension = supportedMimeTypes[blob.type as keyof typeof supportedMimeTypes];
      if (!extension) {
        throw new Error(`Unsupported mime type encountered: ${blob.type}`);
      }

      let variables: IGetImageSignedPolicyUrlQueryVariables = {
        obitId,
        name: `images/${obitId}/obituary/${uuidName}.${extension}`,
        contentType: blob.type,
        imageType: IImage_Type_Enum.Obituary,
        containerHeight: "",
        containerWidth: "",
        cropHeight: "",
        cropWidth: "",
        cropX: "",
        cropY: "",
        imgRectX1: "",
        imgRectX2: "",
        imgRectY1: "",
        imgRectY2: "",
        naturalImageHeight: "",
        naturalImageWidth: "",
        rotate: "",
        scale: ""
      };

      try {
        try {
          const cropParams = parseCropParams(image.params);
          if (!cropParams) {
            throw new Error("crop params could not be parsed");
          }

          variables = {
            ...variables,
            containerHeight: cropParams.container.height.toString(),
            containerWidth: cropParams.container.width.toString(),
            cropHeight: cropParams.crop.height.toString(),
            cropWidth: cropParams.crop.width.toString(),
            cropX: cropParams.crop.x.toString(),
            cropY: cropParams.crop.y.toString(),
            imgRectX1: cropParams.imgRect.x1.toString(),
            imgRectX2: cropParams.imgRect.x2.toString(),
            imgRectY1: cropParams.imgRect.y1.toString(),
            imgRectY2: cropParams.imgRect.y2.toString(),
            naturalImageHeight: cropParams.naturalImage.height.toString(),
            naturalImageWidth: cropParams.naturalImage.width.toString(),
            rotate: cropParams.rotate.toString(),
            scale: cropParams.scale.toString()
          };
        } catch (error) {
          console.error("could not parse crop params", variables);
        }

        const result = await getSignedPolicy(variables);
        const policy = result.data.get_image_signed_policy_url;

        if (!policy || !policy.url || !policy.fields || !policy.name) {
          console.error("received unvalid signed policy");
          console.error(policy);
          return imageIds;
        }

        const resp = await _createImage(
          policy.url,
          policy.fields as IKVPair[],
          file
        );

        if (resp.status >= 400) {
          const code = await getErrorCode(resp);
          setError(code);
          return imageIds;
        }

        imageIds.push(policy.url + policy.name);
      } catch (error: any) {
        setError(error.message);
        console.error(error);
      }
    }

    return imageIds;
  };

  const deleteImage = (image_id?: string) => {
    alert("Not implemented!");
    return Promise.resolve();
  };

  return {
    error,
    createImages,
    deleteImage
  };
};

// formats a react-image-crop data to an object we can save to db
export const formatToCropParams = (
  img: HTMLImageElement,
  crop: PixelCrop,
  scale: number,
  rotate: number
): CropParams | undefined => {
  // take transform into account;
  const parent = img.parentElement;
  const imgBoundingRect = img.getBoundingClientRect();

  if (!parent) {
    console.error("Could not find parent element");
    return;
  }

  const container = {
    width: parent.clientWidth,
    height: parent.clientHeight
  };

  const naturalImage = {
    width: img.naturalWidth,
    height: img.naturalHeight
  };

  const imgRectX1 = (container.width - imgBoundingRect.width) / 2;
  const imgRectY1 = (container.height - imgBoundingRect.height) / 2;

  // img rect can be outside of parent if zoomed in
  const imgRect = {
    x1: imgRectX1,
    y1: imgRectY1,
    x2: imgRectX1 + imgBoundingRect.width,
    y2: imgRectY1 + imgBoundingRect.height
  };

  const cropParams = {
    container,
    naturalImage,
    imgRect,
    crop,
    scale,
    rotate
  };

  return cropParams;
};

export default useImageFunctions;
