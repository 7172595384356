// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-schema"

/* eslint-disable */

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: any;
  _text: any;
  _uuid: any;
  _varchar: any;
  bigint: any;
  date: any;
  funeral_home_status: any;
  jsonb: any;
  numeric: any;
  obit_publish_status: any;
  obituary_type: any;
  timestamp: any;
  timestamptz: any;
  tsvector: any;
  uuid: any;
};


/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type IBoolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type IDatasource = {
  __typename?: 'Datasource';
  status?: Maybe<Scalars['String']>;
};

export type IField = {
  __typename?: 'Field';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IInt_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type ILocalDirectoriesDatasourceInput = {
  date?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type IMutation = {
  __typename?: 'Mutation';
  ds_local_directories_process_pdf: Scalars['String'];
  ds_local_directories_run?: Maybe<IDatasource>;
};


export type IMutationDs_Local_Directories_Process_PdfArgs = {
  file: Scalars['Upload'];
};


export type IMutationDs_Local_Directories_RunArgs = {
  datasource?: Maybe<ILocalDirectoriesDatasourceInput>;
};

export type IPolicy = {
  __typename?: 'Policy';
  fields?: Maybe<Array<Maybe<IField>>>;
  image_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type IQuery = {
  __typename?: 'Query';
  dummy?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type IString_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};



/** Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'. */
export type I_Text_Comparison_Exp = {
  _eq?: Maybe<Scalars['_text']>;
  _gt?: Maybe<Scalars['_text']>;
  _gte?: Maybe<Scalars['_text']>;
  _in?: Maybe<Array<Scalars['_text']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['_text']>;
  _lte?: Maybe<Scalars['_text']>;
  _neq?: Maybe<Scalars['_text']>;
  _nin?: Maybe<Array<Scalars['_text']>>;
};


/** Boolean expression to compare columns of type "_uuid". All fields are combined with logical 'AND'. */
export type I_Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['_uuid']>;
  _gt?: Maybe<Scalars['_uuid']>;
  _gte?: Maybe<Scalars['_uuid']>;
  _in?: Maybe<Array<Scalars['_uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['_uuid']>;
  _lte?: Maybe<Scalars['_uuid']>;
  _neq?: Maybe<Scalars['_uuid']>;
  _nin?: Maybe<Array<Scalars['_uuid']>>;
};


/** Boolean expression to compare columns of type "_varchar". All fields are combined with logical 'AND'. */
export type I_Varchar_Comparison_Exp = {
  _eq?: Maybe<Scalars['_varchar']>;
  _gt?: Maybe<Scalars['_varchar']>;
  _gte?: Maybe<Scalars['_varchar']>;
  _in?: Maybe<Array<Scalars['_varchar']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['_varchar']>;
  _lte?: Maybe<Scalars['_varchar']>;
  _neq?: Maybe<Scalars['_varchar']>;
  _nin?: Maybe<Array<Scalars['_varchar']>>;
};

/** archived obituaries */
export type IArchived_Obituary = {
  __typename?: 'archived_obituary';
  birth_date: Scalars['String'];
  body: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  death_date: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['uuid'];
  image_url: Scalars['_text'];
  last_name: Scalars['String'];
  legacy_id: Scalars['Int'];
  maiden_name: Scalars['String'];
  middle_name: Scalars['String'];
  original_link: Scalars['String'];
  original_publication: Scalars['String'];
  print_end: Scalars['timestamp'];
  print_start: Scalars['timestamp'];
  site_code: Scalars['String'];
  slug: Scalars['String'];
  state: Scalars['String'];
  suffix: Scalars['String'];
};

/** aggregated selection of "archived_obituary" */
export type IArchived_Obituary_Aggregate = {
  __typename?: 'archived_obituary_aggregate';
  aggregate?: Maybe<IArchived_Obituary_Aggregate_Fields>;
  nodes: Array<IArchived_Obituary>;
};

/** aggregate fields of "archived_obituary" */
export type IArchived_Obituary_Aggregate_Fields = {
  __typename?: 'archived_obituary_aggregate_fields';
  avg?: Maybe<IArchived_Obituary_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IArchived_Obituary_Max_Fields>;
  min?: Maybe<IArchived_Obituary_Min_Fields>;
  stddev?: Maybe<IArchived_Obituary_Stddev_Fields>;
  stddev_pop?: Maybe<IArchived_Obituary_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IArchived_Obituary_Stddev_Samp_Fields>;
  sum?: Maybe<IArchived_Obituary_Sum_Fields>;
  var_pop?: Maybe<IArchived_Obituary_Var_Pop_Fields>;
  var_samp?: Maybe<IArchived_Obituary_Var_Samp_Fields>;
  variance?: Maybe<IArchived_Obituary_Variance_Fields>;
};


/** aggregate fields of "archived_obituary" */
export type IArchived_Obituary_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IArchived_Obituary_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type IArchived_Obituary_Avg_Fields = {
  __typename?: 'archived_obituary_avg_fields';
  legacy_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "archived_obituary". All fields are combined with a logical 'AND'. */
export type IArchived_Obituary_Bool_Exp = {
  _and?: Maybe<Array<IArchived_Obituary_Bool_Exp>>;
  _not?: Maybe<IArchived_Obituary_Bool_Exp>;
  _or?: Maybe<Array<IArchived_Obituary_Bool_Exp>>;
  birth_date?: Maybe<IString_Comparison_Exp>;
  body?: Maybe<IString_Comparison_Exp>;
  city?: Maybe<IString_Comparison_Exp>;
  country?: Maybe<IString_Comparison_Exp>;
  death_date?: Maybe<IString_Comparison_Exp>;
  first_name?: Maybe<IString_Comparison_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  image_url?: Maybe<I_Text_Comparison_Exp>;
  last_name?: Maybe<IString_Comparison_Exp>;
  legacy_id?: Maybe<IInt_Comparison_Exp>;
  maiden_name?: Maybe<IString_Comparison_Exp>;
  middle_name?: Maybe<IString_Comparison_Exp>;
  original_link?: Maybe<IString_Comparison_Exp>;
  original_publication?: Maybe<IString_Comparison_Exp>;
  print_end?: Maybe<ITimestamp_Comparison_Exp>;
  print_start?: Maybe<ITimestamp_Comparison_Exp>;
  site_code?: Maybe<IString_Comparison_Exp>;
  slug?: Maybe<IString_Comparison_Exp>;
  state?: Maybe<IString_Comparison_Exp>;
  suffix?: Maybe<IString_Comparison_Exp>;
};

/** unique or primary key constraints on table "archived_obituary" */
export enum IArchived_Obituary_Constraint {
  /** unique or primary key constraint on columns "id" */
  ArchivedObituaryPkey = 'archived_obituary_pkey',
  /** unique or primary key constraint on columns "slug" */
  ArchivedObituarySlugKey = 'archived_obituary_slug_key'
}

/** input type for incrementing numeric columns in table "archived_obituary" */
export type IArchived_Obituary_Inc_Input = {
  legacy_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "archived_obituary" */
export type IArchived_Obituary_Insert_Input = {
  birth_date?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  death_date?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image_url?: Maybe<Scalars['_text']>;
  last_name?: Maybe<Scalars['String']>;
  legacy_id?: Maybe<Scalars['Int']>;
  maiden_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  original_link?: Maybe<Scalars['String']>;
  original_publication?: Maybe<Scalars['String']>;
  print_end?: Maybe<Scalars['timestamp']>;
  print_start?: Maybe<Scalars['timestamp']>;
  site_code?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type IArchived_Obituary_Max_Fields = {
  __typename?: 'archived_obituary_max_fields';
  birth_date?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  death_date?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  legacy_id?: Maybe<Scalars['Int']>;
  maiden_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  original_link?: Maybe<Scalars['String']>;
  original_publication?: Maybe<Scalars['String']>;
  print_end?: Maybe<Scalars['timestamp']>;
  print_start?: Maybe<Scalars['timestamp']>;
  site_code?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type IArchived_Obituary_Min_Fields = {
  __typename?: 'archived_obituary_min_fields';
  birth_date?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  death_date?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  legacy_id?: Maybe<Scalars['Int']>;
  maiden_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  original_link?: Maybe<Scalars['String']>;
  original_publication?: Maybe<Scalars['String']>;
  print_end?: Maybe<Scalars['timestamp']>;
  print_start?: Maybe<Scalars['timestamp']>;
  site_code?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "archived_obituary" */
export type IArchived_Obituary_Mutation_Response = {
  __typename?: 'archived_obituary_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IArchived_Obituary>;
};

/** on_conflict condition type for table "archived_obituary" */
export type IArchived_Obituary_On_Conflict = {
  constraint: IArchived_Obituary_Constraint;
  update_columns?: Array<IArchived_Obituary_Update_Column>;
  where?: Maybe<IArchived_Obituary_Bool_Exp>;
};

/** Ordering options when selecting data from "archived_obituary". */
export type IArchived_Obituary_Order_By = {
  birth_date?: Maybe<IOrder_By>;
  body?: Maybe<IOrder_By>;
  city?: Maybe<IOrder_By>;
  country?: Maybe<IOrder_By>;
  death_date?: Maybe<IOrder_By>;
  first_name?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  image_url?: Maybe<IOrder_By>;
  last_name?: Maybe<IOrder_By>;
  legacy_id?: Maybe<IOrder_By>;
  maiden_name?: Maybe<IOrder_By>;
  middle_name?: Maybe<IOrder_By>;
  original_link?: Maybe<IOrder_By>;
  original_publication?: Maybe<IOrder_By>;
  print_end?: Maybe<IOrder_By>;
  print_start?: Maybe<IOrder_By>;
  site_code?: Maybe<IOrder_By>;
  slug?: Maybe<IOrder_By>;
  state?: Maybe<IOrder_By>;
  suffix?: Maybe<IOrder_By>;
};

/** primary key columns input for table: archived_obituary */
export type IArchived_Obituary_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "archived_obituary" */
export enum IArchived_Obituary_Select_Column {
  /** column name */
  BirthDate = 'birth_date',
  /** column name */
  Body = 'body',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  DeathDate = 'death_date',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  MaidenName = 'maiden_name',
  /** column name */
  MiddleName = 'middle_name',
  /** column name */
  OriginalLink = 'original_link',
  /** column name */
  OriginalPublication = 'original_publication',
  /** column name */
  PrintEnd = 'print_end',
  /** column name */
  PrintStart = 'print_start',
  /** column name */
  SiteCode = 'site_code',
  /** column name */
  Slug = 'slug',
  /** column name */
  State = 'state',
  /** column name */
  Suffix = 'suffix'
}

/** input type for updating data in table "archived_obituary" */
export type IArchived_Obituary_Set_Input = {
  birth_date?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  death_date?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image_url?: Maybe<Scalars['_text']>;
  last_name?: Maybe<Scalars['String']>;
  legacy_id?: Maybe<Scalars['Int']>;
  maiden_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  original_link?: Maybe<Scalars['String']>;
  original_publication?: Maybe<Scalars['String']>;
  print_end?: Maybe<Scalars['timestamp']>;
  print_start?: Maybe<Scalars['timestamp']>;
  site_code?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type IArchived_Obituary_Stddev_Fields = {
  __typename?: 'archived_obituary_stddev_fields';
  legacy_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type IArchived_Obituary_Stddev_Pop_Fields = {
  __typename?: 'archived_obituary_stddev_pop_fields';
  legacy_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type IArchived_Obituary_Stddev_Samp_Fields = {
  __typename?: 'archived_obituary_stddev_samp_fields';
  legacy_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type IArchived_Obituary_Sum_Fields = {
  __typename?: 'archived_obituary_sum_fields';
  legacy_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "archived_obituary" */
export enum IArchived_Obituary_Update_Column {
  /** column name */
  BirthDate = 'birth_date',
  /** column name */
  Body = 'body',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  DeathDate = 'death_date',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  MaidenName = 'maiden_name',
  /** column name */
  MiddleName = 'middle_name',
  /** column name */
  OriginalLink = 'original_link',
  /** column name */
  OriginalPublication = 'original_publication',
  /** column name */
  PrintEnd = 'print_end',
  /** column name */
  PrintStart = 'print_start',
  /** column name */
  SiteCode = 'site_code',
  /** column name */
  Slug = 'slug',
  /** column name */
  State = 'state',
  /** column name */
  Suffix = 'suffix'
}

/** aggregate var_pop on columns */
export type IArchived_Obituary_Var_Pop_Fields = {
  __typename?: 'archived_obituary_var_pop_fields';
  legacy_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type IArchived_Obituary_Var_Samp_Fields = {
  __typename?: 'archived_obituary_var_samp_fields';
  legacy_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type IArchived_Obituary_Variance_Fields = {
  __typename?: 'archived_obituary_variance_fields';
  legacy_id?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type IBigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "capi_config" */
export type ICapi_Config = {
  __typename?: 'capi_config';
  created_at: Scalars['timestamp'];
  id: Scalars['uuid'];
  /** An object relationship */
  publication: IPublication;
  publication_id: Scalars['uuid'];
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "capi_config" */
export type ICapi_Config_Aggregate = {
  __typename?: 'capi_config_aggregate';
  aggregate?: Maybe<ICapi_Config_Aggregate_Fields>;
  nodes: Array<ICapi_Config>;
};

/** aggregate fields of "capi_config" */
export type ICapi_Config_Aggregate_Fields = {
  __typename?: 'capi_config_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ICapi_Config_Max_Fields>;
  min?: Maybe<ICapi_Config_Min_Fields>;
};


/** aggregate fields of "capi_config" */
export type ICapi_Config_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ICapi_Config_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "capi_config" */
export type ICapi_Config_Aggregate_Order_By = {
  count?: Maybe<IOrder_By>;
  max?: Maybe<ICapi_Config_Max_Order_By>;
  min?: Maybe<ICapi_Config_Min_Order_By>;
};

/** input type for inserting array relation for remote table "capi_config" */
export type ICapi_Config_Arr_Rel_Insert_Input = {
  data: Array<ICapi_Config_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<ICapi_Config_On_Conflict>;
};

/** Boolean expression to filter rows from the table "capi_config". All fields are combined with a logical 'AND'. */
export type ICapi_Config_Bool_Exp = {
  _and?: Maybe<Array<ICapi_Config_Bool_Exp>>;
  _not?: Maybe<ICapi_Config_Bool_Exp>;
  _or?: Maybe<Array<ICapi_Config_Bool_Exp>>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  publication?: Maybe<IPublication_Bool_Exp>;
  publication_id?: Maybe<IUuid_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "capi_config" */
export enum ICapi_Config_Constraint {
  /** unique or primary key constraint on columns "id" */
  CapiConfigPkey = 'capi_config_pkey'
}

/** input type for inserting data into table "capi_config" */
export type ICapi_Config_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  publication?: Maybe<IPublication_Obj_Rel_Insert_Input>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type ICapi_Config_Max_Fields = {
  __typename?: 'capi_config_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "capi_config" */
export type ICapi_Config_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  publication_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type ICapi_Config_Min_Fields = {
  __typename?: 'capi_config_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "capi_config" */
export type ICapi_Config_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  publication_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "capi_config" */
export type ICapi_Config_Mutation_Response = {
  __typename?: 'capi_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ICapi_Config>;
};

/** on_conflict condition type for table "capi_config" */
export type ICapi_Config_On_Conflict = {
  constraint: ICapi_Config_Constraint;
  update_columns?: Array<ICapi_Config_Update_Column>;
  where?: Maybe<ICapi_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "capi_config". */
export type ICapi_Config_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  publication?: Maybe<IPublication_Order_By>;
  publication_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** primary key columns input for table: capi_config */
export type ICapi_Config_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "capi_config" */
export enum ICapi_Config_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PublicationId = 'publication_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "capi_config" */
export type ICapi_Config_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "capi_config" */
export enum ICapi_Config_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PublicationId = 'publication_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "categories" */
export type ICategories = {
  __typename?: 'categories';
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  featured?: Maybe<Scalars['Boolean']>;
  hierarchy?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  /** An array relationship */
  image_urls: Array<ICategory_Image_Urls>;
  /** An aggregated array relationship */
  image_urls_aggregate: ICategory_Image_Urls_Aggregate;
  /** An array relationship */
  keywords: Array<ICategory_Keywords>;
  /** An aggregated array relationship */
  keywords_aggregate: ICategory_Keywords_Aggregate;
  /** An array relationship */
  locations: Array<ICategories_Locations>;
  /** An aggregated array relationship */
  locations_aggregate: ICategories_Locations_Aggregate;
  lock_version: Scalars['bigint'];
  name?: Maybe<Scalars['String']>;
  rl_sub_category_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['bigint']>;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  verticals: Array<ICategories_Verticals>;
  /** An aggregated array relationship */
  verticals_aggregate: ICategories_Verticals_Aggregate;
};


/** columns and relationships of "categories" */
export type ICategoriesImage_UrlsArgs = {
  distinct_on?: Maybe<Array<ICategory_Image_Urls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategory_Image_Urls_Order_By>>;
  where?: Maybe<ICategory_Image_Urls_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type ICategoriesImage_Urls_AggregateArgs = {
  distinct_on?: Maybe<Array<ICategory_Image_Urls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategory_Image_Urls_Order_By>>;
  where?: Maybe<ICategory_Image_Urls_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type ICategoriesKeywordsArgs = {
  distinct_on?: Maybe<Array<ICategory_Keywords_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategory_Keywords_Order_By>>;
  where?: Maybe<ICategory_Keywords_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type ICategoriesKeywords_AggregateArgs = {
  distinct_on?: Maybe<Array<ICategory_Keywords_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategory_Keywords_Order_By>>;
  where?: Maybe<ICategory_Keywords_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type ICategoriesLocationsArgs = {
  distinct_on?: Maybe<Array<ICategories_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategories_Locations_Order_By>>;
  where?: Maybe<ICategories_Locations_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type ICategoriesLocations_AggregateArgs = {
  distinct_on?: Maybe<Array<ICategories_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategories_Locations_Order_By>>;
  where?: Maybe<ICategories_Locations_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type ICategoriesVerticalsArgs = {
  distinct_on?: Maybe<Array<ICategories_Verticals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategories_Verticals_Order_By>>;
  where?: Maybe<ICategories_Verticals_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type ICategoriesVerticals_AggregateArgs = {
  distinct_on?: Maybe<Array<ICategories_Verticals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategories_Verticals_Order_By>>;
  where?: Maybe<ICategories_Verticals_Bool_Exp>;
};

/** aggregated selection of "categories" */
export type ICategories_Aggregate = {
  __typename?: 'categories_aggregate';
  aggregate?: Maybe<ICategories_Aggregate_Fields>;
  nodes: Array<ICategories>;
};

/** aggregate fields of "categories" */
export type ICategories_Aggregate_Fields = {
  __typename?: 'categories_aggregate_fields';
  avg?: Maybe<ICategories_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ICategories_Max_Fields>;
  min?: Maybe<ICategories_Min_Fields>;
  stddev?: Maybe<ICategories_Stddev_Fields>;
  stddev_pop?: Maybe<ICategories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ICategories_Stddev_Samp_Fields>;
  sum?: Maybe<ICategories_Sum_Fields>;
  var_pop?: Maybe<ICategories_Var_Pop_Fields>;
  var_samp?: Maybe<ICategories_Var_Samp_Fields>;
  variance?: Maybe<ICategories_Variance_Fields>;
};


/** aggregate fields of "categories" */
export type ICategories_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ICategories_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "categories" */
export type ICategories_Aggregate_Order_By = {
  avg?: Maybe<ICategories_Avg_Order_By>;
  count?: Maybe<IOrder_By>;
  max?: Maybe<ICategories_Max_Order_By>;
  min?: Maybe<ICategories_Min_Order_By>;
  stddev?: Maybe<ICategories_Stddev_Order_By>;
  stddev_pop?: Maybe<ICategories_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ICategories_Stddev_Samp_Order_By>;
  sum?: Maybe<ICategories_Sum_Order_By>;
  var_pop?: Maybe<ICategories_Var_Pop_Order_By>;
  var_samp?: Maybe<ICategories_Var_Samp_Order_By>;
  variance?: Maybe<ICategories_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "categories" */
export type ICategories_Arr_Rel_Insert_Input = {
  data: Array<ICategories_Insert_Input>;
  on_conflict?: Maybe<ICategories_On_Conflict>;
};

/** aggregate avg on columns */
export type ICategories_Avg_Fields = {
  __typename?: 'categories_avg_fields';
  hierarchy?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lock_version?: Maybe<Scalars['Float']>;
  rl_sub_category_id?: Maybe<Scalars['Float']>;
  source_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "categories" */
export type ICategories_Avg_Order_By = {
  hierarchy?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  rl_sub_category_id?: Maybe<IOrder_By>;
  source_id?: Maybe<IOrder_By>;
};

/** Boolean expression to filter rows from the table "categories". All fields are combined with a logical 'AND'. */
export type ICategories_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ICategories_Bool_Exp>>>;
  _not?: Maybe<ICategories_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ICategories_Bool_Exp>>>;
  created_at?: Maybe<ITimestamptz_Comparison_Exp>;
  description?: Maybe<IString_Comparison_Exp>;
  discarded_at?: Maybe<ITimestamptz_Comparison_Exp>;
  featured?: Maybe<IBoolean_Comparison_Exp>;
  hierarchy?: Maybe<IBigint_Comparison_Exp>;
  id?: Maybe<IBigint_Comparison_Exp>;
  image_urls?: Maybe<ICategory_Image_Urls_Bool_Exp>;
  keywords?: Maybe<ICategory_Keywords_Bool_Exp>;
  locations?: Maybe<ICategories_Locations_Bool_Exp>;
  lock_version?: Maybe<IBigint_Comparison_Exp>;
  name?: Maybe<IString_Comparison_Exp>;
  rl_sub_category_id?: Maybe<IBigint_Comparison_Exp>;
  slug?: Maybe<IString_Comparison_Exp>;
  source_id?: Maybe<IBigint_Comparison_Exp>;
  updated_at?: Maybe<ITimestamptz_Comparison_Exp>;
  verticals?: Maybe<ICategories_Verticals_Bool_Exp>;
};

/** unique or primary key constraints on table "categories" */
export enum ICategories_Constraint {
  /** unique or primary key constraint */
  Idx_16963IndexCategoriesOnSlug = 'idx_16963_index_categories_on_slug',
  /** unique or primary key constraint */
  Idx_16963Primary = 'idx_16963_primary'
}

/** input type for incrementing integer column in table "categories" */
export type ICategories_Inc_Input = {
  hierarchy?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  lock_version?: Maybe<Scalars['bigint']>;
  rl_sub_category_id?: Maybe<Scalars['bigint']>;
  source_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "categories" */
export type ICategories_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  featured?: Maybe<Scalars['Boolean']>;
  hierarchy?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  image_urls?: Maybe<ICategory_Image_Urls_Arr_Rel_Insert_Input>;
  keywords?: Maybe<ICategory_Keywords_Arr_Rel_Insert_Input>;
  locations?: Maybe<ICategories_Locations_Arr_Rel_Insert_Input>;
  lock_version?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  rl_sub_category_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verticals?: Maybe<ICategories_Verticals_Arr_Rel_Insert_Input>;
};

/** columns and relationships of "categories_locations" */
export type ICategories_Locations = {
  __typename?: 'categories_locations';
  /** An object relationship */
  category: ICategories;
  category_id: Scalars['bigint'];
  id: Scalars['bigint'];
  /** An object relationship */
  location: ILocations;
  location_id: Scalars['bigint'];
};

/** aggregated selection of "categories_locations" */
export type ICategories_Locations_Aggregate = {
  __typename?: 'categories_locations_aggregate';
  aggregate?: Maybe<ICategories_Locations_Aggregate_Fields>;
  nodes: Array<ICategories_Locations>;
};

/** aggregate fields of "categories_locations" */
export type ICategories_Locations_Aggregate_Fields = {
  __typename?: 'categories_locations_aggregate_fields';
  avg?: Maybe<ICategories_Locations_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ICategories_Locations_Max_Fields>;
  min?: Maybe<ICategories_Locations_Min_Fields>;
  stddev?: Maybe<ICategories_Locations_Stddev_Fields>;
  stddev_pop?: Maybe<ICategories_Locations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ICategories_Locations_Stddev_Samp_Fields>;
  sum?: Maybe<ICategories_Locations_Sum_Fields>;
  var_pop?: Maybe<ICategories_Locations_Var_Pop_Fields>;
  var_samp?: Maybe<ICategories_Locations_Var_Samp_Fields>;
  variance?: Maybe<ICategories_Locations_Variance_Fields>;
};


/** aggregate fields of "categories_locations" */
export type ICategories_Locations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ICategories_Locations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "categories_locations" */
export type ICategories_Locations_Aggregate_Order_By = {
  avg?: Maybe<ICategories_Locations_Avg_Order_By>;
  count?: Maybe<IOrder_By>;
  max?: Maybe<ICategories_Locations_Max_Order_By>;
  min?: Maybe<ICategories_Locations_Min_Order_By>;
  stddev?: Maybe<ICategories_Locations_Stddev_Order_By>;
  stddev_pop?: Maybe<ICategories_Locations_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ICategories_Locations_Stddev_Samp_Order_By>;
  sum?: Maybe<ICategories_Locations_Sum_Order_By>;
  var_pop?: Maybe<ICategories_Locations_Var_Pop_Order_By>;
  var_samp?: Maybe<ICategories_Locations_Var_Samp_Order_By>;
  variance?: Maybe<ICategories_Locations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "categories_locations" */
export type ICategories_Locations_Arr_Rel_Insert_Input = {
  data: Array<ICategories_Locations_Insert_Input>;
  on_conflict?: Maybe<ICategories_Locations_On_Conflict>;
};

/** aggregate avg on columns */
export type ICategories_Locations_Avg_Fields = {
  __typename?: 'categories_locations_avg_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "categories_locations" */
export type ICategories_Locations_Avg_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** Boolean expression to filter rows from the table "categories_locations". All fields are combined with a logical 'AND'. */
export type ICategories_Locations_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ICategories_Locations_Bool_Exp>>>;
  _not?: Maybe<ICategories_Locations_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ICategories_Locations_Bool_Exp>>>;
  category?: Maybe<ICategories_Bool_Exp>;
  category_id?: Maybe<IBigint_Comparison_Exp>;
  id?: Maybe<IBigint_Comparison_Exp>;
  location?: Maybe<ILocations_Bool_Exp>;
  location_id?: Maybe<IBigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "categories_locations" */
export enum ICategories_Locations_Constraint {
  /** unique or primary key constraint */
  CategoriesLocationsLocationIdCategoryIdKey = 'categories_locations_location_id_category_id_key',
  /** unique or primary key constraint */
  Idx_16974Primary = 'idx_16974_primary'
}

/** input type for incrementing integer column in table "categories_locations" */
export type ICategories_Locations_Inc_Input = {
  category_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "categories_locations" */
export type ICategories_Locations_Insert_Input = {
  category?: Maybe<ICategories_Obj_Rel_Insert_Input>;
  category_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  location?: Maybe<ILocations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type ICategories_Locations_Max_Fields = {
  __typename?: 'categories_locations_max_fields';
  category_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "categories_locations" */
export type ICategories_Locations_Max_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type ICategories_Locations_Min_Fields = {
  __typename?: 'categories_locations_min_fields';
  category_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "categories_locations" */
export type ICategories_Locations_Min_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "categories_locations" */
export type ICategories_Locations_Mutation_Response = {
  __typename?: 'categories_locations_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ICategories_Locations>;
};

/** input type for inserting object relation for remote table "categories_locations" */
export type ICategories_Locations_Obj_Rel_Insert_Input = {
  data: ICategories_Locations_Insert_Input;
  on_conflict?: Maybe<ICategories_Locations_On_Conflict>;
};

/** on conflict condition type for table "categories_locations" */
export type ICategories_Locations_On_Conflict = {
  constraint: ICategories_Locations_Constraint;
  update_columns: Array<ICategories_Locations_Update_Column>;
  where?: Maybe<ICategories_Locations_Bool_Exp>;
};

/** ordering options when selecting data from "categories_locations" */
export type ICategories_Locations_Order_By = {
  category?: Maybe<ICategories_Order_By>;
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location?: Maybe<ILocations_Order_By>;
  location_id?: Maybe<IOrder_By>;
};

/** select columns of table "categories_locations" */
export enum ICategories_Locations_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id'
}

/** input type for updating data in table "categories_locations" */
export type ICategories_Locations_Set_Input = {
  category_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type ICategories_Locations_Stddev_Fields = {
  __typename?: 'categories_locations_stddev_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "categories_locations" */
export type ICategories_Locations_Stddev_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_pop on columns */
export type ICategories_Locations_Stddev_Pop_Fields = {
  __typename?: 'categories_locations_stddev_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "categories_locations" */
export type ICategories_Locations_Stddev_Pop_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_samp on columns */
export type ICategories_Locations_Stddev_Samp_Fields = {
  __typename?: 'categories_locations_stddev_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "categories_locations" */
export type ICategories_Locations_Stddev_Samp_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate sum on columns */
export type ICategories_Locations_Sum_Fields = {
  __typename?: 'categories_locations_sum_fields';
  category_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "categories_locations" */
export type ICategories_Locations_Sum_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** update columns of table "categories_locations" */
export enum ICategories_Locations_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id'
}

/** aggregate var_pop on columns */
export type ICategories_Locations_Var_Pop_Fields = {
  __typename?: 'categories_locations_var_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "categories_locations" */
export type ICategories_Locations_Var_Pop_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate var_samp on columns */
export type ICategories_Locations_Var_Samp_Fields = {
  __typename?: 'categories_locations_var_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "categories_locations" */
export type ICategories_Locations_Var_Samp_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate variance on columns */
export type ICategories_Locations_Variance_Fields = {
  __typename?: 'categories_locations_variance_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "categories_locations" */
export type ICategories_Locations_Variance_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate max on columns */
export type ICategories_Max_Fields = {
  __typename?: 'categories_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  hierarchy?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  lock_version?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  rl_sub_category_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "categories" */
export type ICategories_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  description?: Maybe<IOrder_By>;
  discarded_at?: Maybe<IOrder_By>;
  hierarchy?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  rl_sub_category_id?: Maybe<IOrder_By>;
  slug?: Maybe<IOrder_By>;
  source_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type ICategories_Min_Fields = {
  __typename?: 'categories_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  hierarchy?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  lock_version?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  rl_sub_category_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "categories" */
export type ICategories_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  description?: Maybe<IOrder_By>;
  discarded_at?: Maybe<IOrder_By>;
  hierarchy?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  rl_sub_category_id?: Maybe<IOrder_By>;
  slug?: Maybe<IOrder_By>;
  source_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "categories" */
export type ICategories_Mutation_Response = {
  __typename?: 'categories_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ICategories>;
};

/** input type for inserting object relation for remote table "categories" */
export type ICategories_Obj_Rel_Insert_Input = {
  data: ICategories_Insert_Input;
  on_conflict?: Maybe<ICategories_On_Conflict>;
};

/** on conflict condition type for table "categories" */
export type ICategories_On_Conflict = {
  constraint: ICategories_Constraint;
  update_columns: Array<ICategories_Update_Column>;
  where?: Maybe<ICategories_Bool_Exp>;
};

/** ordering options when selecting data from "categories" */
export type ICategories_Order_By = {
  created_at?: Maybe<IOrder_By>;
  description?: Maybe<IOrder_By>;
  discarded_at?: Maybe<IOrder_By>;
  featured?: Maybe<IOrder_By>;
  hierarchy?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  image_urls_aggregate?: Maybe<ICategory_Image_Urls_Aggregate_Order_By>;
  keywords_aggregate?: Maybe<ICategory_Keywords_Aggregate_Order_By>;
  locations_aggregate?: Maybe<ICategories_Locations_Aggregate_Order_By>;
  lock_version?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  rl_sub_category_id?: Maybe<IOrder_By>;
  slug?: Maybe<IOrder_By>;
  source_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  verticals_aggregate?: Maybe<ICategories_Verticals_Aggregate_Order_By>;
};

/** select columns of table "categories" */
export enum ICategories_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DiscardedAt = 'discarded_at',
  /** column name */
  Featured = 'featured',
  /** column name */
  Hierarchy = 'hierarchy',
  /** column name */
  Id = 'id',
  /** column name */
  LockVersion = 'lock_version',
  /** column name */
  Name = 'name',
  /** column name */
  RlSubCategoryId = 'rl_sub_category_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "categories" */
export type ICategories_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  featured?: Maybe<Scalars['Boolean']>;
  hierarchy?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  lock_version?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  rl_sub_category_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ICategories_Stddev_Fields = {
  __typename?: 'categories_stddev_fields';
  hierarchy?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lock_version?: Maybe<Scalars['Float']>;
  rl_sub_category_id?: Maybe<Scalars['Float']>;
  source_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "categories" */
export type ICategories_Stddev_Order_By = {
  hierarchy?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  rl_sub_category_id?: Maybe<IOrder_By>;
  source_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_pop on columns */
export type ICategories_Stddev_Pop_Fields = {
  __typename?: 'categories_stddev_pop_fields';
  hierarchy?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lock_version?: Maybe<Scalars['Float']>;
  rl_sub_category_id?: Maybe<Scalars['Float']>;
  source_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "categories" */
export type ICategories_Stddev_Pop_Order_By = {
  hierarchy?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  rl_sub_category_id?: Maybe<IOrder_By>;
  source_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_samp on columns */
export type ICategories_Stddev_Samp_Fields = {
  __typename?: 'categories_stddev_samp_fields';
  hierarchy?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lock_version?: Maybe<Scalars['Float']>;
  rl_sub_category_id?: Maybe<Scalars['Float']>;
  source_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "categories" */
export type ICategories_Stddev_Samp_Order_By = {
  hierarchy?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  rl_sub_category_id?: Maybe<IOrder_By>;
  source_id?: Maybe<IOrder_By>;
};

/** aggregate sum on columns */
export type ICategories_Sum_Fields = {
  __typename?: 'categories_sum_fields';
  hierarchy?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  lock_version?: Maybe<Scalars['bigint']>;
  rl_sub_category_id?: Maybe<Scalars['bigint']>;
  source_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "categories" */
export type ICategories_Sum_Order_By = {
  hierarchy?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  rl_sub_category_id?: Maybe<IOrder_By>;
  source_id?: Maybe<IOrder_By>;
};

/** update columns of table "categories" */
export enum ICategories_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DiscardedAt = 'discarded_at',
  /** column name */
  Featured = 'featured',
  /** column name */
  Hierarchy = 'hierarchy',
  /** column name */
  Id = 'id',
  /** column name */
  LockVersion = 'lock_version',
  /** column name */
  Name = 'name',
  /** column name */
  RlSubCategoryId = 'rl_sub_category_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type ICategories_Var_Pop_Fields = {
  __typename?: 'categories_var_pop_fields';
  hierarchy?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lock_version?: Maybe<Scalars['Float']>;
  rl_sub_category_id?: Maybe<Scalars['Float']>;
  source_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "categories" */
export type ICategories_Var_Pop_Order_By = {
  hierarchy?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  rl_sub_category_id?: Maybe<IOrder_By>;
  source_id?: Maybe<IOrder_By>;
};

/** aggregate var_samp on columns */
export type ICategories_Var_Samp_Fields = {
  __typename?: 'categories_var_samp_fields';
  hierarchy?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lock_version?: Maybe<Scalars['Float']>;
  rl_sub_category_id?: Maybe<Scalars['Float']>;
  source_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "categories" */
export type ICategories_Var_Samp_Order_By = {
  hierarchy?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  rl_sub_category_id?: Maybe<IOrder_By>;
  source_id?: Maybe<IOrder_By>;
};

/** aggregate variance on columns */
export type ICategories_Variance_Fields = {
  __typename?: 'categories_variance_fields';
  hierarchy?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lock_version?: Maybe<Scalars['Float']>;
  rl_sub_category_id?: Maybe<Scalars['Float']>;
  source_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "categories" */
export type ICategories_Variance_Order_By = {
  hierarchy?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  rl_sub_category_id?: Maybe<IOrder_By>;
  source_id?: Maybe<IOrder_By>;
};

/** columns and relationships of "categories_verticals" */
export type ICategories_Verticals = {
  __typename?: 'categories_verticals';
  /** An object relationship */
  category: ICategories;
  category_id: Scalars['bigint'];
  id: Scalars['bigint'];
  /** An object relationship */
  vertical: IVerticals;
  vertical_id: Scalars['bigint'];
};

/** aggregated selection of "categories_verticals" */
export type ICategories_Verticals_Aggregate = {
  __typename?: 'categories_verticals_aggregate';
  aggregate?: Maybe<ICategories_Verticals_Aggregate_Fields>;
  nodes: Array<ICategories_Verticals>;
};

/** aggregate fields of "categories_verticals" */
export type ICategories_Verticals_Aggregate_Fields = {
  __typename?: 'categories_verticals_aggregate_fields';
  avg?: Maybe<ICategories_Verticals_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ICategories_Verticals_Max_Fields>;
  min?: Maybe<ICategories_Verticals_Min_Fields>;
  stddev?: Maybe<ICategories_Verticals_Stddev_Fields>;
  stddev_pop?: Maybe<ICategories_Verticals_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ICategories_Verticals_Stddev_Samp_Fields>;
  sum?: Maybe<ICategories_Verticals_Sum_Fields>;
  var_pop?: Maybe<ICategories_Verticals_Var_Pop_Fields>;
  var_samp?: Maybe<ICategories_Verticals_Var_Samp_Fields>;
  variance?: Maybe<ICategories_Verticals_Variance_Fields>;
};


/** aggregate fields of "categories_verticals" */
export type ICategories_Verticals_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ICategories_Verticals_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "categories_verticals" */
export type ICategories_Verticals_Aggregate_Order_By = {
  avg?: Maybe<ICategories_Verticals_Avg_Order_By>;
  count?: Maybe<IOrder_By>;
  max?: Maybe<ICategories_Verticals_Max_Order_By>;
  min?: Maybe<ICategories_Verticals_Min_Order_By>;
  stddev?: Maybe<ICategories_Verticals_Stddev_Order_By>;
  stddev_pop?: Maybe<ICategories_Verticals_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ICategories_Verticals_Stddev_Samp_Order_By>;
  sum?: Maybe<ICategories_Verticals_Sum_Order_By>;
  var_pop?: Maybe<ICategories_Verticals_Var_Pop_Order_By>;
  var_samp?: Maybe<ICategories_Verticals_Var_Samp_Order_By>;
  variance?: Maybe<ICategories_Verticals_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "categories_verticals" */
export type ICategories_Verticals_Arr_Rel_Insert_Input = {
  data: Array<ICategories_Verticals_Insert_Input>;
  on_conflict?: Maybe<ICategories_Verticals_On_Conflict>;
};

/** aggregate avg on columns */
export type ICategories_Verticals_Avg_Fields = {
  __typename?: 'categories_verticals_avg_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  vertical_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "categories_verticals" */
export type ICategories_Verticals_Avg_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  vertical_id?: Maybe<IOrder_By>;
};

/** Boolean expression to filter rows from the table "categories_verticals". All fields are combined with a logical 'AND'. */
export type ICategories_Verticals_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ICategories_Verticals_Bool_Exp>>>;
  _not?: Maybe<ICategories_Verticals_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ICategories_Verticals_Bool_Exp>>>;
  category?: Maybe<ICategories_Bool_Exp>;
  category_id?: Maybe<IBigint_Comparison_Exp>;
  id?: Maybe<IBigint_Comparison_Exp>;
  vertical?: Maybe<IVerticals_Bool_Exp>;
  vertical_id?: Maybe<IBigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "categories_verticals" */
export enum ICategories_Verticals_Constraint {
  /** unique or primary key constraint */
  Idx_16980Primary = 'idx_16980_primary'
}

/** input type for incrementing integer column in table "categories_verticals" */
export type ICategories_Verticals_Inc_Input = {
  category_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  vertical_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "categories_verticals" */
export type ICategories_Verticals_Insert_Input = {
  category?: Maybe<ICategories_Obj_Rel_Insert_Input>;
  category_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  vertical?: Maybe<IVerticals_Obj_Rel_Insert_Input>;
  vertical_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type ICategories_Verticals_Max_Fields = {
  __typename?: 'categories_verticals_max_fields';
  category_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  vertical_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "categories_verticals" */
export type ICategories_Verticals_Max_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  vertical_id?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type ICategories_Verticals_Min_Fields = {
  __typename?: 'categories_verticals_min_fields';
  category_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  vertical_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "categories_verticals" */
export type ICategories_Verticals_Min_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  vertical_id?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "categories_verticals" */
export type ICategories_Verticals_Mutation_Response = {
  __typename?: 'categories_verticals_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ICategories_Verticals>;
};

/** input type for inserting object relation for remote table "categories_verticals" */
export type ICategories_Verticals_Obj_Rel_Insert_Input = {
  data: ICategories_Verticals_Insert_Input;
  on_conflict?: Maybe<ICategories_Verticals_On_Conflict>;
};

/** on conflict condition type for table "categories_verticals" */
export type ICategories_Verticals_On_Conflict = {
  constraint: ICategories_Verticals_Constraint;
  update_columns: Array<ICategories_Verticals_Update_Column>;
  where?: Maybe<ICategories_Verticals_Bool_Exp>;
};

/** ordering options when selecting data from "categories_verticals" */
export type ICategories_Verticals_Order_By = {
  category?: Maybe<ICategories_Order_By>;
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  vertical?: Maybe<IVerticals_Order_By>;
  vertical_id?: Maybe<IOrder_By>;
};

/** select columns of table "categories_verticals" */
export enum ICategories_Verticals_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Id = 'id',
  /** column name */
  VerticalId = 'vertical_id'
}

/** input type for updating data in table "categories_verticals" */
export type ICategories_Verticals_Set_Input = {
  category_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  vertical_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type ICategories_Verticals_Stddev_Fields = {
  __typename?: 'categories_verticals_stddev_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  vertical_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "categories_verticals" */
export type ICategories_Verticals_Stddev_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  vertical_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_pop on columns */
export type ICategories_Verticals_Stddev_Pop_Fields = {
  __typename?: 'categories_verticals_stddev_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  vertical_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "categories_verticals" */
export type ICategories_Verticals_Stddev_Pop_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  vertical_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_samp on columns */
export type ICategories_Verticals_Stddev_Samp_Fields = {
  __typename?: 'categories_verticals_stddev_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  vertical_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "categories_verticals" */
export type ICategories_Verticals_Stddev_Samp_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  vertical_id?: Maybe<IOrder_By>;
};

/** aggregate sum on columns */
export type ICategories_Verticals_Sum_Fields = {
  __typename?: 'categories_verticals_sum_fields';
  category_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  vertical_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "categories_verticals" */
export type ICategories_Verticals_Sum_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  vertical_id?: Maybe<IOrder_By>;
};

/** update columns of table "categories_verticals" */
export enum ICategories_Verticals_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Id = 'id',
  /** column name */
  VerticalId = 'vertical_id'
}

/** aggregate var_pop on columns */
export type ICategories_Verticals_Var_Pop_Fields = {
  __typename?: 'categories_verticals_var_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  vertical_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "categories_verticals" */
export type ICategories_Verticals_Var_Pop_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  vertical_id?: Maybe<IOrder_By>;
};

/** aggregate var_samp on columns */
export type ICategories_Verticals_Var_Samp_Fields = {
  __typename?: 'categories_verticals_var_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  vertical_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "categories_verticals" */
export type ICategories_Verticals_Var_Samp_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  vertical_id?: Maybe<IOrder_By>;
};

/** aggregate variance on columns */
export type ICategories_Verticals_Variance_Fields = {
  __typename?: 'categories_verticals_variance_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  vertical_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "categories_verticals" */
export type ICategories_Verticals_Variance_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  vertical_id?: Maybe<IOrder_By>;
};

/** columns and relationships of "category_image_urls" */
export type ICategory_Image_Urls = {
  __typename?: 'category_image_urls';
  /** An object relationship */
  category?: Maybe<ICategories>;
  category_id?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  url?: Maybe<Scalars['String']>;
};

/** aggregated selection of "category_image_urls" */
export type ICategory_Image_Urls_Aggregate = {
  __typename?: 'category_image_urls_aggregate';
  aggregate?: Maybe<ICategory_Image_Urls_Aggregate_Fields>;
  nodes: Array<ICategory_Image_Urls>;
};

/** aggregate fields of "category_image_urls" */
export type ICategory_Image_Urls_Aggregate_Fields = {
  __typename?: 'category_image_urls_aggregate_fields';
  avg?: Maybe<ICategory_Image_Urls_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ICategory_Image_Urls_Max_Fields>;
  min?: Maybe<ICategory_Image_Urls_Min_Fields>;
  stddev?: Maybe<ICategory_Image_Urls_Stddev_Fields>;
  stddev_pop?: Maybe<ICategory_Image_Urls_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ICategory_Image_Urls_Stddev_Samp_Fields>;
  sum?: Maybe<ICategory_Image_Urls_Sum_Fields>;
  var_pop?: Maybe<ICategory_Image_Urls_Var_Pop_Fields>;
  var_samp?: Maybe<ICategory_Image_Urls_Var_Samp_Fields>;
  variance?: Maybe<ICategory_Image_Urls_Variance_Fields>;
};


/** aggregate fields of "category_image_urls" */
export type ICategory_Image_Urls_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ICategory_Image_Urls_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "category_image_urls" */
export type ICategory_Image_Urls_Aggregate_Order_By = {
  avg?: Maybe<ICategory_Image_Urls_Avg_Order_By>;
  count?: Maybe<IOrder_By>;
  max?: Maybe<ICategory_Image_Urls_Max_Order_By>;
  min?: Maybe<ICategory_Image_Urls_Min_Order_By>;
  stddev?: Maybe<ICategory_Image_Urls_Stddev_Order_By>;
  stddev_pop?: Maybe<ICategory_Image_Urls_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ICategory_Image_Urls_Stddev_Samp_Order_By>;
  sum?: Maybe<ICategory_Image_Urls_Sum_Order_By>;
  var_pop?: Maybe<ICategory_Image_Urls_Var_Pop_Order_By>;
  var_samp?: Maybe<ICategory_Image_Urls_Var_Samp_Order_By>;
  variance?: Maybe<ICategory_Image_Urls_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "category_image_urls" */
export type ICategory_Image_Urls_Arr_Rel_Insert_Input = {
  data: Array<ICategory_Image_Urls_Insert_Input>;
  on_conflict?: Maybe<ICategory_Image_Urls_On_Conflict>;
};

/** aggregate avg on columns */
export type ICategory_Image_Urls_Avg_Fields = {
  __typename?: 'category_image_urls_avg_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "category_image_urls" */
export type ICategory_Image_Urls_Avg_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
};

/** Boolean expression to filter rows from the table "category_image_urls". All fields are combined with a logical 'AND'. */
export type ICategory_Image_Urls_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ICategory_Image_Urls_Bool_Exp>>>;
  _not?: Maybe<ICategory_Image_Urls_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ICategory_Image_Urls_Bool_Exp>>>;
  category?: Maybe<ICategories_Bool_Exp>;
  category_id?: Maybe<IBigint_Comparison_Exp>;
  id?: Maybe<IBigint_Comparison_Exp>;
  url?: Maybe<IString_Comparison_Exp>;
};

/** unique or primary key constraints on table "category_image_urls" */
export enum ICategory_Image_Urls_Constraint {
  /** unique or primary key constraint */
  Idx_16986Primary = 'idx_16986_primary'
}

/** input type for incrementing integer column in table "category_image_urls" */
export type ICategory_Image_Urls_Inc_Input = {
  category_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "category_image_urls" */
export type ICategory_Image_Urls_Insert_Input = {
  category?: Maybe<ICategories_Obj_Rel_Insert_Input>;
  category_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ICategory_Image_Urls_Max_Fields = {
  __typename?: 'category_image_urls_max_fields';
  category_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "category_image_urls" */
export type ICategory_Image_Urls_Max_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  url?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type ICategory_Image_Urls_Min_Fields = {
  __typename?: 'category_image_urls_min_fields';
  category_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "category_image_urls" */
export type ICategory_Image_Urls_Min_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  url?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "category_image_urls" */
export type ICategory_Image_Urls_Mutation_Response = {
  __typename?: 'category_image_urls_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ICategory_Image_Urls>;
};

/** input type for inserting object relation for remote table "category_image_urls" */
export type ICategory_Image_Urls_Obj_Rel_Insert_Input = {
  data: ICategory_Image_Urls_Insert_Input;
  on_conflict?: Maybe<ICategory_Image_Urls_On_Conflict>;
};

/** on conflict condition type for table "category_image_urls" */
export type ICategory_Image_Urls_On_Conflict = {
  constraint: ICategory_Image_Urls_Constraint;
  update_columns: Array<ICategory_Image_Urls_Update_Column>;
  where?: Maybe<ICategory_Image_Urls_Bool_Exp>;
};

/** ordering options when selecting data from "category_image_urls" */
export type ICategory_Image_Urls_Order_By = {
  category?: Maybe<ICategories_Order_By>;
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  url?: Maybe<IOrder_By>;
};

/** select columns of table "category_image_urls" */
export enum ICategory_Image_Urls_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Id = 'id',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "category_image_urls" */
export type ICategory_Image_Urls_Set_Input = {
  category_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ICategory_Image_Urls_Stddev_Fields = {
  __typename?: 'category_image_urls_stddev_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "category_image_urls" */
export type ICategory_Image_Urls_Stddev_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
};

/** aggregate stddev_pop on columns */
export type ICategory_Image_Urls_Stddev_Pop_Fields = {
  __typename?: 'category_image_urls_stddev_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "category_image_urls" */
export type ICategory_Image_Urls_Stddev_Pop_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
};

/** aggregate stddev_samp on columns */
export type ICategory_Image_Urls_Stddev_Samp_Fields = {
  __typename?: 'category_image_urls_stddev_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "category_image_urls" */
export type ICategory_Image_Urls_Stddev_Samp_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
};

/** aggregate sum on columns */
export type ICategory_Image_Urls_Sum_Fields = {
  __typename?: 'category_image_urls_sum_fields';
  category_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "category_image_urls" */
export type ICategory_Image_Urls_Sum_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
};

/** update columns of table "category_image_urls" */
export enum ICategory_Image_Urls_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Id = 'id',
  /** column name */
  Url = 'url'
}

/** aggregate var_pop on columns */
export type ICategory_Image_Urls_Var_Pop_Fields = {
  __typename?: 'category_image_urls_var_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "category_image_urls" */
export type ICategory_Image_Urls_Var_Pop_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
};

/** aggregate var_samp on columns */
export type ICategory_Image_Urls_Var_Samp_Fields = {
  __typename?: 'category_image_urls_var_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "category_image_urls" */
export type ICategory_Image_Urls_Var_Samp_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
};

/** aggregate variance on columns */
export type ICategory_Image_Urls_Variance_Fields = {
  __typename?: 'category_image_urls_variance_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "category_image_urls" */
export type ICategory_Image_Urls_Variance_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
};

/** columns and relationships of "category_keywords" */
export type ICategory_Keywords = {
  __typename?: 'category_keywords';
  /** An object relationship */
  category?: Maybe<ICategories>;
  category_id?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "category_keywords" */
export type ICategory_Keywords_Aggregate = {
  __typename?: 'category_keywords_aggregate';
  aggregate?: Maybe<ICategory_Keywords_Aggregate_Fields>;
  nodes: Array<ICategory_Keywords>;
};

/** aggregate fields of "category_keywords" */
export type ICategory_Keywords_Aggregate_Fields = {
  __typename?: 'category_keywords_aggregate_fields';
  avg?: Maybe<ICategory_Keywords_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ICategory_Keywords_Max_Fields>;
  min?: Maybe<ICategory_Keywords_Min_Fields>;
  stddev?: Maybe<ICategory_Keywords_Stddev_Fields>;
  stddev_pop?: Maybe<ICategory_Keywords_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ICategory_Keywords_Stddev_Samp_Fields>;
  sum?: Maybe<ICategory_Keywords_Sum_Fields>;
  var_pop?: Maybe<ICategory_Keywords_Var_Pop_Fields>;
  var_samp?: Maybe<ICategory_Keywords_Var_Samp_Fields>;
  variance?: Maybe<ICategory_Keywords_Variance_Fields>;
};


/** aggregate fields of "category_keywords" */
export type ICategory_Keywords_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ICategory_Keywords_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "category_keywords" */
export type ICategory_Keywords_Aggregate_Order_By = {
  avg?: Maybe<ICategory_Keywords_Avg_Order_By>;
  count?: Maybe<IOrder_By>;
  max?: Maybe<ICategory_Keywords_Max_Order_By>;
  min?: Maybe<ICategory_Keywords_Min_Order_By>;
  stddev?: Maybe<ICategory_Keywords_Stddev_Order_By>;
  stddev_pop?: Maybe<ICategory_Keywords_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ICategory_Keywords_Stddev_Samp_Order_By>;
  sum?: Maybe<ICategory_Keywords_Sum_Order_By>;
  var_pop?: Maybe<ICategory_Keywords_Var_Pop_Order_By>;
  var_samp?: Maybe<ICategory_Keywords_Var_Samp_Order_By>;
  variance?: Maybe<ICategory_Keywords_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "category_keywords" */
export type ICategory_Keywords_Arr_Rel_Insert_Input = {
  data: Array<ICategory_Keywords_Insert_Input>;
  on_conflict?: Maybe<ICategory_Keywords_On_Conflict>;
};

/** aggregate avg on columns */
export type ICategory_Keywords_Avg_Fields = {
  __typename?: 'category_keywords_avg_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "category_keywords" */
export type ICategory_Keywords_Avg_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
};

/** Boolean expression to filter rows from the table "category_keywords". All fields are combined with a logical 'AND'. */
export type ICategory_Keywords_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ICategory_Keywords_Bool_Exp>>>;
  _not?: Maybe<ICategory_Keywords_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ICategory_Keywords_Bool_Exp>>>;
  category?: Maybe<ICategories_Bool_Exp>;
  category_id?: Maybe<IBigint_Comparison_Exp>;
  id?: Maybe<IBigint_Comparison_Exp>;
  name?: Maybe<IString_Comparison_Exp>;
};

/** unique or primary key constraints on table "category_keywords" */
export enum ICategory_Keywords_Constraint {
  /** unique or primary key constraint */
  Idx_16995Primary = 'idx_16995_primary'
}

/** input type for incrementing integer column in table "category_keywords" */
export type ICategory_Keywords_Inc_Input = {
  category_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "category_keywords" */
export type ICategory_Keywords_Insert_Input = {
  category?: Maybe<ICategories_Obj_Rel_Insert_Input>;
  category_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ICategory_Keywords_Max_Fields = {
  __typename?: 'category_keywords_max_fields';
  category_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "category_keywords" */
export type ICategory_Keywords_Max_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type ICategory_Keywords_Min_Fields = {
  __typename?: 'category_keywords_min_fields';
  category_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "category_keywords" */
export type ICategory_Keywords_Min_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "category_keywords" */
export type ICategory_Keywords_Mutation_Response = {
  __typename?: 'category_keywords_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ICategory_Keywords>;
};

/** input type for inserting object relation for remote table "category_keywords" */
export type ICategory_Keywords_Obj_Rel_Insert_Input = {
  data: ICategory_Keywords_Insert_Input;
  on_conflict?: Maybe<ICategory_Keywords_On_Conflict>;
};

/** on conflict condition type for table "category_keywords" */
export type ICategory_Keywords_On_Conflict = {
  constraint: ICategory_Keywords_Constraint;
  update_columns: Array<ICategory_Keywords_Update_Column>;
  where?: Maybe<ICategory_Keywords_Bool_Exp>;
};

/** ordering options when selecting data from "category_keywords" */
export type ICategory_Keywords_Order_By = {
  category?: Maybe<ICategories_Order_By>;
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
};

/** select columns of table "category_keywords" */
export enum ICategory_Keywords_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "category_keywords" */
export type ICategory_Keywords_Set_Input = {
  category_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ICategory_Keywords_Stddev_Fields = {
  __typename?: 'category_keywords_stddev_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "category_keywords" */
export type ICategory_Keywords_Stddev_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
};

/** aggregate stddev_pop on columns */
export type ICategory_Keywords_Stddev_Pop_Fields = {
  __typename?: 'category_keywords_stddev_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "category_keywords" */
export type ICategory_Keywords_Stddev_Pop_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
};

/** aggregate stddev_samp on columns */
export type ICategory_Keywords_Stddev_Samp_Fields = {
  __typename?: 'category_keywords_stddev_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "category_keywords" */
export type ICategory_Keywords_Stddev_Samp_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
};

/** aggregate sum on columns */
export type ICategory_Keywords_Sum_Fields = {
  __typename?: 'category_keywords_sum_fields';
  category_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "category_keywords" */
export type ICategory_Keywords_Sum_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
};

/** update columns of table "category_keywords" */
export enum ICategory_Keywords_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type ICategory_Keywords_Var_Pop_Fields = {
  __typename?: 'category_keywords_var_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "category_keywords" */
export type ICategory_Keywords_Var_Pop_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
};

/** aggregate var_samp on columns */
export type ICategory_Keywords_Var_Samp_Fields = {
  __typename?: 'category_keywords_var_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "category_keywords" */
export type ICategory_Keywords_Var_Samp_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
};

/** aggregate variance on columns */
export type ICategory_Keywords_Variance_Fields = {
  __typename?: 'category_keywords_variance_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "category_keywords" */
export type ICategory_Keywords_Variance_Order_By = {
  category_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
};

/** columns and relationships of "coupon_adas" */
export type ICoupon_Adas = {
  __typename?: 'coupon_adas';
  account_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  conditions?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  file_name?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  is_active?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  location?: Maybe<ILocations>;
  location_id?: Maybe<Scalars['bigint']>;
  material_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  original_publisher?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  /** An object relationship */
  publisher?: Maybe<IPublishers>;
  rep_contact_name?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "coupon_adas" */
export type ICoupon_Adas_Aggregate = {
  __typename?: 'coupon_adas_aggregate';
  aggregate?: Maybe<ICoupon_Adas_Aggregate_Fields>;
  nodes: Array<ICoupon_Adas>;
};

/** aggregate fields of "coupon_adas" */
export type ICoupon_Adas_Aggregate_Fields = {
  __typename?: 'coupon_adas_aggregate_fields';
  avg?: Maybe<ICoupon_Adas_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ICoupon_Adas_Max_Fields>;
  min?: Maybe<ICoupon_Adas_Min_Fields>;
  stddev?: Maybe<ICoupon_Adas_Stddev_Fields>;
  stddev_pop?: Maybe<ICoupon_Adas_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ICoupon_Adas_Stddev_Samp_Fields>;
  sum?: Maybe<ICoupon_Adas_Sum_Fields>;
  var_pop?: Maybe<ICoupon_Adas_Var_Pop_Fields>;
  var_samp?: Maybe<ICoupon_Adas_Var_Samp_Fields>;
  variance?: Maybe<ICoupon_Adas_Variance_Fields>;
};


/** aggregate fields of "coupon_adas" */
export type ICoupon_Adas_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ICoupon_Adas_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coupon_adas" */
export type ICoupon_Adas_Aggregate_Order_By = {
  avg?: Maybe<ICoupon_Adas_Avg_Order_By>;
  count?: Maybe<IOrder_By>;
  max?: Maybe<ICoupon_Adas_Max_Order_By>;
  min?: Maybe<ICoupon_Adas_Min_Order_By>;
  stddev?: Maybe<ICoupon_Adas_Stddev_Order_By>;
  stddev_pop?: Maybe<ICoupon_Adas_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ICoupon_Adas_Stddev_Samp_Order_By>;
  sum?: Maybe<ICoupon_Adas_Sum_Order_By>;
  var_pop?: Maybe<ICoupon_Adas_Var_Pop_Order_By>;
  var_samp?: Maybe<ICoupon_Adas_Var_Samp_Order_By>;
  variance?: Maybe<ICoupon_Adas_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "coupon_adas" */
export type ICoupon_Adas_Arr_Rel_Insert_Input = {
  data: Array<ICoupon_Adas_Insert_Input>;
  on_conflict?: Maybe<ICoupon_Adas_On_Conflict>;
};

/** aggregate avg on columns */
export type ICoupon_Adas_Avg_Fields = {
  __typename?: 'coupon_adas_avg_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "coupon_adas" */
export type ICoupon_Adas_Avg_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** Boolean expression to filter rows from the table "coupon_adas". All fields are combined with a logical 'AND'. */
export type ICoupon_Adas_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ICoupon_Adas_Bool_Exp>>>;
  _not?: Maybe<ICoupon_Adas_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ICoupon_Adas_Bool_Exp>>>;
  account_id?: Maybe<IString_Comparison_Exp>;
  code?: Maybe<IString_Comparison_Exp>;
  conditions?: Maybe<IString_Comparison_Exp>;
  created_at?: Maybe<ITimestamptz_Comparison_Exp>;
  description?: Maybe<IString_Comparison_Exp>;
  end_date?: Maybe<ITimestamptz_Comparison_Exp>;
  file_name?: Maybe<IString_Comparison_Exp>;
  id?: Maybe<IBigint_Comparison_Exp>;
  is_active?: Maybe<IBoolean_Comparison_Exp>;
  location?: Maybe<ILocations_Bool_Exp>;
  location_id?: Maybe<IBigint_Comparison_Exp>;
  material_id?: Maybe<IString_Comparison_Exp>;
  name?: Maybe<IString_Comparison_Exp>;
  original_publisher?: Maybe<IString_Comparison_Exp>;
  photo_url?: Maybe<IString_Comparison_Exp>;
  publisher?: Maybe<IPublishers_Bool_Exp>;
  rep_contact_name?: Maybe<IString_Comparison_Exp>;
  start_date?: Maybe<ITimestamptz_Comparison_Exp>;
  updated_at?: Maybe<ITimestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "coupon_adas" */
export enum ICoupon_Adas_Constraint {
  /** unique or primary key constraint */
  CouponAdasCodePhotoUrlStartDateKey = 'coupon_adas_code_photo_url_start_date_key',
  /** unique or primary key constraint */
  Idx_17001Primary = 'idx_17001_primary'
}

/** input type for incrementing integer column in table "coupon_adas" */
export type ICoupon_Adas_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "coupon_adas" */
export type ICoupon_Adas_Insert_Input = {
  account_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  conditions?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  file_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  is_active?: Maybe<Scalars['Boolean']>;
  location?: Maybe<ILocations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['bigint']>;
  material_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  original_publisher?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  publisher?: Maybe<IPublishers_Obj_Rel_Insert_Input>;
  rep_contact_name?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ICoupon_Adas_Max_Fields = {
  __typename?: 'coupon_adas_max_fields';
  account_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  conditions?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  file_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  material_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  original_publisher?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  rep_contact_name?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "coupon_adas" */
export type ICoupon_Adas_Max_Order_By = {
  account_id?: Maybe<IOrder_By>;
  code?: Maybe<IOrder_By>;
  conditions?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  description?: Maybe<IOrder_By>;
  end_date?: Maybe<IOrder_By>;
  file_name?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  material_id?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  original_publisher?: Maybe<IOrder_By>;
  photo_url?: Maybe<IOrder_By>;
  rep_contact_name?: Maybe<IOrder_By>;
  start_date?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type ICoupon_Adas_Min_Fields = {
  __typename?: 'coupon_adas_min_fields';
  account_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  conditions?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  file_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  material_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  original_publisher?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  rep_contact_name?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "coupon_adas" */
export type ICoupon_Adas_Min_Order_By = {
  account_id?: Maybe<IOrder_By>;
  code?: Maybe<IOrder_By>;
  conditions?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  description?: Maybe<IOrder_By>;
  end_date?: Maybe<IOrder_By>;
  file_name?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  material_id?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  original_publisher?: Maybe<IOrder_By>;
  photo_url?: Maybe<IOrder_By>;
  rep_contact_name?: Maybe<IOrder_By>;
  start_date?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "coupon_adas" */
export type ICoupon_Adas_Mutation_Response = {
  __typename?: 'coupon_adas_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ICoupon_Adas>;
};

/** input type for inserting object relation for remote table "coupon_adas" */
export type ICoupon_Adas_Obj_Rel_Insert_Input = {
  data: ICoupon_Adas_Insert_Input;
  on_conflict?: Maybe<ICoupon_Adas_On_Conflict>;
};

/** on conflict condition type for table "coupon_adas" */
export type ICoupon_Adas_On_Conflict = {
  constraint: ICoupon_Adas_Constraint;
  update_columns: Array<ICoupon_Adas_Update_Column>;
  where?: Maybe<ICoupon_Adas_Bool_Exp>;
};

/** ordering options when selecting data from "coupon_adas" */
export type ICoupon_Adas_Order_By = {
  account_id?: Maybe<IOrder_By>;
  code?: Maybe<IOrder_By>;
  conditions?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  description?: Maybe<IOrder_By>;
  end_date?: Maybe<IOrder_By>;
  file_name?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  is_active?: Maybe<IOrder_By>;
  location?: Maybe<ILocations_Order_By>;
  location_id?: Maybe<IOrder_By>;
  material_id?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  original_publisher?: Maybe<IOrder_By>;
  photo_url?: Maybe<IOrder_By>;
  publisher?: Maybe<IPublishers_Order_By>;
  rep_contact_name?: Maybe<IOrder_By>;
  start_date?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** select columns of table "coupon_adas" */
export enum ICoupon_Adas_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Code = 'code',
  /** column name */
  Conditions = 'conditions',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  FileName = 'file_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  MaterialId = 'material_id',
  /** column name */
  Name = 'name',
  /** column name */
  OriginalPublisher = 'original_publisher',
  /** column name */
  PhotoUrl = 'photo_url',
  /** column name */
  RepContactName = 'rep_contact_name',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "coupon_adas" */
export type ICoupon_Adas_Set_Input = {
  account_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  conditions?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  file_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  is_active?: Maybe<Scalars['Boolean']>;
  location_id?: Maybe<Scalars['bigint']>;
  material_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  original_publisher?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  rep_contact_name?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ICoupon_Adas_Stddev_Fields = {
  __typename?: 'coupon_adas_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "coupon_adas" */
export type ICoupon_Adas_Stddev_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_pop on columns */
export type ICoupon_Adas_Stddev_Pop_Fields = {
  __typename?: 'coupon_adas_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "coupon_adas" */
export type ICoupon_Adas_Stddev_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_samp on columns */
export type ICoupon_Adas_Stddev_Samp_Fields = {
  __typename?: 'coupon_adas_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "coupon_adas" */
export type ICoupon_Adas_Stddev_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate sum on columns */
export type ICoupon_Adas_Sum_Fields = {
  __typename?: 'coupon_adas_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "coupon_adas" */
export type ICoupon_Adas_Sum_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** update columns of table "coupon_adas" */
export enum ICoupon_Adas_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Code = 'code',
  /** column name */
  Conditions = 'conditions',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  FileName = 'file_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  MaterialId = 'material_id',
  /** column name */
  Name = 'name',
  /** column name */
  OriginalPublisher = 'original_publisher',
  /** column name */
  PhotoUrl = 'photo_url',
  /** column name */
  RepContactName = 'rep_contact_name',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type ICoupon_Adas_Var_Pop_Fields = {
  __typename?: 'coupon_adas_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "coupon_adas" */
export type ICoupon_Adas_Var_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate var_samp on columns */
export type ICoupon_Adas_Var_Samp_Fields = {
  __typename?: 'coupon_adas_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "coupon_adas" */
export type ICoupon_Adas_Var_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate variance on columns */
export type ICoupon_Adas_Variance_Fields = {
  __typename?: 'coupon_adas_variance_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "coupon_adas" */
export type ICoupon_Adas_Variance_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** ordering argument of a cursor */
export enum ICursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "daily_locations_counts" */
export type IDaily_Locations_Counts = {
  __typename?: 'daily_locations_counts';
  count?: Maybe<Scalars['bigint']>;
  count_type?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  date?: Maybe<Scalars['date']>;
  id: Scalars['bigint'];
  parent_id?: Maybe<Scalars['bigint']>;
  parent_name?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "daily_locations_counts" */
export type IDaily_Locations_Counts_Aggregate = {
  __typename?: 'daily_locations_counts_aggregate';
  aggregate?: Maybe<IDaily_Locations_Counts_Aggregate_Fields>;
  nodes: Array<IDaily_Locations_Counts>;
};

/** aggregate fields of "daily_locations_counts" */
export type IDaily_Locations_Counts_Aggregate_Fields = {
  __typename?: 'daily_locations_counts_aggregate_fields';
  avg?: Maybe<IDaily_Locations_Counts_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<IDaily_Locations_Counts_Max_Fields>;
  min?: Maybe<IDaily_Locations_Counts_Min_Fields>;
  stddev?: Maybe<IDaily_Locations_Counts_Stddev_Fields>;
  stddev_pop?: Maybe<IDaily_Locations_Counts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IDaily_Locations_Counts_Stddev_Samp_Fields>;
  sum?: Maybe<IDaily_Locations_Counts_Sum_Fields>;
  var_pop?: Maybe<IDaily_Locations_Counts_Var_Pop_Fields>;
  var_samp?: Maybe<IDaily_Locations_Counts_Var_Samp_Fields>;
  variance?: Maybe<IDaily_Locations_Counts_Variance_Fields>;
};


/** aggregate fields of "daily_locations_counts" */
export type IDaily_Locations_Counts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IDaily_Locations_Counts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "daily_locations_counts" */
export type IDaily_Locations_Counts_Aggregate_Order_By = {
  avg?: Maybe<IDaily_Locations_Counts_Avg_Order_By>;
  count?: Maybe<IOrder_By>;
  max?: Maybe<IDaily_Locations_Counts_Max_Order_By>;
  min?: Maybe<IDaily_Locations_Counts_Min_Order_By>;
  stddev?: Maybe<IDaily_Locations_Counts_Stddev_Order_By>;
  stddev_pop?: Maybe<IDaily_Locations_Counts_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IDaily_Locations_Counts_Stddev_Samp_Order_By>;
  sum?: Maybe<IDaily_Locations_Counts_Sum_Order_By>;
  var_pop?: Maybe<IDaily_Locations_Counts_Var_Pop_Order_By>;
  var_samp?: Maybe<IDaily_Locations_Counts_Var_Samp_Order_By>;
  variance?: Maybe<IDaily_Locations_Counts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "daily_locations_counts" */
export type IDaily_Locations_Counts_Arr_Rel_Insert_Input = {
  data: Array<IDaily_Locations_Counts_Insert_Input>;
  on_conflict?: Maybe<IDaily_Locations_Counts_On_Conflict>;
};

/** aggregate avg on columns */
export type IDaily_Locations_Counts_Avg_Fields = {
  __typename?: 'daily_locations_counts_avg_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "daily_locations_counts" */
export type IDaily_Locations_Counts_Avg_Order_By = {
  count?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  parent_id?: Maybe<IOrder_By>;
};

/** Boolean expression to filter rows from the table "daily_locations_counts". All fields are combined with a logical 'AND'. */
export type IDaily_Locations_Counts_Bool_Exp = {
  _and?: Maybe<Array<Maybe<IDaily_Locations_Counts_Bool_Exp>>>;
  _not?: Maybe<IDaily_Locations_Counts_Bool_Exp>;
  _or?: Maybe<Array<Maybe<IDaily_Locations_Counts_Bool_Exp>>>;
  count?: Maybe<IBigint_Comparison_Exp>;
  count_type?: Maybe<IString_Comparison_Exp>;
  created_at?: Maybe<ITimestamptz_Comparison_Exp>;
  date?: Maybe<IDate_Comparison_Exp>;
  id?: Maybe<IBigint_Comparison_Exp>;
  parent_id?: Maybe<IBigint_Comparison_Exp>;
  parent_name?: Maybe<IString_Comparison_Exp>;
  updated_at?: Maybe<ITimestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "daily_locations_counts" */
export enum IDaily_Locations_Counts_Constraint {
  /** unique or primary key constraint */
  Idx_17010Primary = 'idx_17010_primary'
}

/** input type for incrementing integer column in table "daily_locations_counts" */
export type IDaily_Locations_Counts_Inc_Input = {
  count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  parent_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "daily_locations_counts" */
export type IDaily_Locations_Counts_Insert_Input = {
  count?: Maybe<Scalars['bigint']>;
  count_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  parent_id?: Maybe<Scalars['bigint']>;
  parent_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type IDaily_Locations_Counts_Max_Fields = {
  __typename?: 'daily_locations_counts_max_fields';
  count?: Maybe<Scalars['bigint']>;
  count_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  parent_id?: Maybe<Scalars['bigint']>;
  parent_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "daily_locations_counts" */
export type IDaily_Locations_Counts_Max_Order_By = {
  count?: Maybe<IOrder_By>;
  count_type?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  date?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  parent_id?: Maybe<IOrder_By>;
  parent_name?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IDaily_Locations_Counts_Min_Fields = {
  __typename?: 'daily_locations_counts_min_fields';
  count?: Maybe<Scalars['bigint']>;
  count_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  parent_id?: Maybe<Scalars['bigint']>;
  parent_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "daily_locations_counts" */
export type IDaily_Locations_Counts_Min_Order_By = {
  count?: Maybe<IOrder_By>;
  count_type?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  date?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  parent_id?: Maybe<IOrder_By>;
  parent_name?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "daily_locations_counts" */
export type IDaily_Locations_Counts_Mutation_Response = {
  __typename?: 'daily_locations_counts_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<IDaily_Locations_Counts>;
};

/** input type for inserting object relation for remote table "daily_locations_counts" */
export type IDaily_Locations_Counts_Obj_Rel_Insert_Input = {
  data: IDaily_Locations_Counts_Insert_Input;
  on_conflict?: Maybe<IDaily_Locations_Counts_On_Conflict>;
};

/** on conflict condition type for table "daily_locations_counts" */
export type IDaily_Locations_Counts_On_Conflict = {
  constraint: IDaily_Locations_Counts_Constraint;
  update_columns: Array<IDaily_Locations_Counts_Update_Column>;
  where?: Maybe<IDaily_Locations_Counts_Bool_Exp>;
};

/** ordering options when selecting data from "daily_locations_counts" */
export type IDaily_Locations_Counts_Order_By = {
  count?: Maybe<IOrder_By>;
  count_type?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  date?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  parent_id?: Maybe<IOrder_By>;
  parent_name?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** select columns of table "daily_locations_counts" */
export enum IDaily_Locations_Counts_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  CountType = 'count_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  ParentName = 'parent_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "daily_locations_counts" */
export type IDaily_Locations_Counts_Set_Input = {
  count?: Maybe<Scalars['bigint']>;
  count_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  parent_id?: Maybe<Scalars['bigint']>;
  parent_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type IDaily_Locations_Counts_Stddev_Fields = {
  __typename?: 'daily_locations_counts_stddev_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "daily_locations_counts" */
export type IDaily_Locations_Counts_Stddev_Order_By = {
  count?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  parent_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IDaily_Locations_Counts_Stddev_Pop_Fields = {
  __typename?: 'daily_locations_counts_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "daily_locations_counts" */
export type IDaily_Locations_Counts_Stddev_Pop_Order_By = {
  count?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  parent_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IDaily_Locations_Counts_Stddev_Samp_Fields = {
  __typename?: 'daily_locations_counts_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "daily_locations_counts" */
export type IDaily_Locations_Counts_Stddev_Samp_Order_By = {
  count?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  parent_id?: Maybe<IOrder_By>;
};

/** aggregate sum on columns */
export type IDaily_Locations_Counts_Sum_Fields = {
  __typename?: 'daily_locations_counts_sum_fields';
  count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  parent_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "daily_locations_counts" */
export type IDaily_Locations_Counts_Sum_Order_By = {
  count?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  parent_id?: Maybe<IOrder_By>;
};

/** update columns of table "daily_locations_counts" */
export enum IDaily_Locations_Counts_Update_Column {
  /** column name */
  Count = 'count',
  /** column name */
  CountType = 'count_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  ParentName = 'parent_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type IDaily_Locations_Counts_Var_Pop_Fields = {
  __typename?: 'daily_locations_counts_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "daily_locations_counts" */
export type IDaily_Locations_Counts_Var_Pop_Order_By = {
  count?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  parent_id?: Maybe<IOrder_By>;
};

/** aggregate var_samp on columns */
export type IDaily_Locations_Counts_Var_Samp_Fields = {
  __typename?: 'daily_locations_counts_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "daily_locations_counts" */
export type IDaily_Locations_Counts_Var_Samp_Order_By = {
  count?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  parent_id?: Maybe<IOrder_By>;
};

/** aggregate variance on columns */
export type IDaily_Locations_Counts_Variance_Fields = {
  __typename?: 'daily_locations_counts_variance_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "daily_locations_counts" */
export type IDaily_Locations_Counts_Variance_Order_By = {
  count?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  parent_id?: Maybe<IOrder_By>;
};


/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type IDate_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** columns and relationships of "display_obituary" */
export type IDisplay_Obituary = {
  __typename?: 'display_obituary';
  age?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamp'];
  date_born?: Maybe<Scalars['String']>;
  date_deceased?: Maybe<Scalars['String']>;
  /** An array relationship */
  display_obituary_publications: Array<IDisplay_Obituary_Publication>;
  /** An aggregate relationship */
  display_obituary_publications_aggregate: IDisplay_Obituary_Publication_Aggregate;
  /** An array relationship */
  display_obituary_tags: Array<IDisplay_Obituary_Tag>;
  /** An aggregate relationship */
  display_obituary_tags_aggregate: IDisplay_Obituary_Tag_Aggregate;
  first_name: Scalars['String'];
  full_name: Scalars['String'];
  funeral_home?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  last_name: Scalars['String'];
  middle_name: Scalars['String'];
  obituary_type: Scalars['obituary_type'];
  photo_urls?: Maybe<Scalars['_varchar']>;
  source_id: Scalars['uuid'];
  source_published_at: Scalars['timestamp'];
  source_slug: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
};


/** columns and relationships of "display_obituary" */
export type IDisplay_ObituaryDisplay_Obituary_PublicationsArgs = {
  distinct_on?: Maybe<Array<IDisplay_Obituary_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDisplay_Obituary_Publication_Order_By>>;
  where?: Maybe<IDisplay_Obituary_Publication_Bool_Exp>;
};


/** columns and relationships of "display_obituary" */
export type IDisplay_ObituaryDisplay_Obituary_Publications_AggregateArgs = {
  distinct_on?: Maybe<Array<IDisplay_Obituary_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDisplay_Obituary_Publication_Order_By>>;
  where?: Maybe<IDisplay_Obituary_Publication_Bool_Exp>;
};


/** columns and relationships of "display_obituary" */
export type IDisplay_ObituaryDisplay_Obituary_TagsArgs = {
  distinct_on?: Maybe<Array<IDisplay_Obituary_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDisplay_Obituary_Tag_Order_By>>;
  where?: Maybe<IDisplay_Obituary_Tag_Bool_Exp>;
};


/** columns and relationships of "display_obituary" */
export type IDisplay_ObituaryDisplay_Obituary_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<IDisplay_Obituary_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDisplay_Obituary_Tag_Order_By>>;
  where?: Maybe<IDisplay_Obituary_Tag_Bool_Exp>;
};

/** aggregated selection of "display_obituary" */
export type IDisplay_Obituary_Aggregate = {
  __typename?: 'display_obituary_aggregate';
  aggregate?: Maybe<IDisplay_Obituary_Aggregate_Fields>;
  nodes: Array<IDisplay_Obituary>;
};

/** aggregate fields of "display_obituary" */
export type IDisplay_Obituary_Aggregate_Fields = {
  __typename?: 'display_obituary_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IDisplay_Obituary_Max_Fields>;
  min?: Maybe<IDisplay_Obituary_Min_Fields>;
};


/** aggregate fields of "display_obituary" */
export type IDisplay_Obituary_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IDisplay_Obituary_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "display_obituary". All fields are combined with a logical 'AND'. */
export type IDisplay_Obituary_Bool_Exp = {
  _and?: Maybe<Array<IDisplay_Obituary_Bool_Exp>>;
  _not?: Maybe<IDisplay_Obituary_Bool_Exp>;
  _or?: Maybe<Array<IDisplay_Obituary_Bool_Exp>>;
  age?: Maybe<IString_Comparison_Exp>;
  body?: Maybe<IString_Comparison_Exp>;
  city?: Maybe<IString_Comparison_Exp>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  date_born?: Maybe<IString_Comparison_Exp>;
  date_deceased?: Maybe<IString_Comparison_Exp>;
  display_obituary_publications?: Maybe<IDisplay_Obituary_Publication_Bool_Exp>;
  display_obituary_tags?: Maybe<IDisplay_Obituary_Tag_Bool_Exp>;
  first_name?: Maybe<IString_Comparison_Exp>;
  full_name?: Maybe<IString_Comparison_Exp>;
  funeral_home?: Maybe<IString_Comparison_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  last_name?: Maybe<IString_Comparison_Exp>;
  middle_name?: Maybe<IString_Comparison_Exp>;
  obituary_type?: Maybe<IObituary_Type_Comparison_Exp>;
  photo_urls?: Maybe<I_Varchar_Comparison_Exp>;
  source_id?: Maybe<IUuid_Comparison_Exp>;
  source_published_at?: Maybe<ITimestamp_Comparison_Exp>;
  source_slug?: Maybe<IString_Comparison_Exp>;
  state?: Maybe<IString_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "display_obituary" */
export enum IDisplay_Obituary_Constraint {
  /** unique or primary key constraint on columns "id" */
  DisplayObituaryPkey = 'display_obituary_pkey',
  /** unique or primary key constraint on columns "source_id" */
  DisplayObituarySourceIdKey = 'display_obituary_source_id_key',
  /** unique or primary key constraint on columns "source_slug" */
  DisplayObituarySourceSlugKey = 'display_obituary_source_slug_key',
  /** unique or primary key constraint on columns "source_id" */
  SourceIdIdx = 'source_id_idx'
}

/** input type for inserting data into table "display_obituary" */
export type IDisplay_Obituary_Insert_Input = {
  age?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  date_born?: Maybe<Scalars['String']>;
  date_deceased?: Maybe<Scalars['String']>;
  display_obituary_publications?: Maybe<IDisplay_Obituary_Publication_Arr_Rel_Insert_Input>;
  display_obituary_tags?: Maybe<IDisplay_Obituary_Tag_Arr_Rel_Insert_Input>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  funeral_home?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  obituary_type?: Maybe<Scalars['obituary_type']>;
  photo_urls?: Maybe<Scalars['_varchar']>;
  source_id?: Maybe<Scalars['uuid']>;
  source_published_at?: Maybe<Scalars['timestamp']>;
  source_slug?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type IDisplay_Obituary_Max_Fields = {
  __typename?: 'display_obituary_max_fields';
  age?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  date_born?: Maybe<Scalars['String']>;
  date_deceased?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  funeral_home?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  obituary_type?: Maybe<Scalars['obituary_type']>;
  source_id?: Maybe<Scalars['uuid']>;
  source_published_at?: Maybe<Scalars['timestamp']>;
  source_slug?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type IDisplay_Obituary_Min_Fields = {
  __typename?: 'display_obituary_min_fields';
  age?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  date_born?: Maybe<Scalars['String']>;
  date_deceased?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  funeral_home?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  obituary_type?: Maybe<Scalars['obituary_type']>;
  source_id?: Maybe<Scalars['uuid']>;
  source_published_at?: Maybe<Scalars['timestamp']>;
  source_slug?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "display_obituary" */
export type IDisplay_Obituary_Mutation_Response = {
  __typename?: 'display_obituary_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IDisplay_Obituary>;
};

/** input type for inserting object relation for remote table "display_obituary" */
export type IDisplay_Obituary_Obj_Rel_Insert_Input = {
  data: IDisplay_Obituary_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<IDisplay_Obituary_On_Conflict>;
};

/** on_conflict condition type for table "display_obituary" */
export type IDisplay_Obituary_On_Conflict = {
  constraint: IDisplay_Obituary_Constraint;
  update_columns?: Array<IDisplay_Obituary_Update_Column>;
  where?: Maybe<IDisplay_Obituary_Bool_Exp>;
};

/** Ordering options when selecting data from "display_obituary". */
export type IDisplay_Obituary_Order_By = {
  age?: Maybe<IOrder_By>;
  body?: Maybe<IOrder_By>;
  city?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  date_born?: Maybe<IOrder_By>;
  date_deceased?: Maybe<IOrder_By>;
  display_obituary_publications_aggregate?: Maybe<IDisplay_Obituary_Publication_Aggregate_Order_By>;
  display_obituary_tags_aggregate?: Maybe<IDisplay_Obituary_Tag_Aggregate_Order_By>;
  first_name?: Maybe<IOrder_By>;
  full_name?: Maybe<IOrder_By>;
  funeral_home?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  last_name?: Maybe<IOrder_By>;
  middle_name?: Maybe<IOrder_By>;
  obituary_type?: Maybe<IOrder_By>;
  photo_urls?: Maybe<IOrder_By>;
  source_id?: Maybe<IOrder_By>;
  source_published_at?: Maybe<IOrder_By>;
  source_slug?: Maybe<IOrder_By>;
  state?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** primary key columns input for table: display_obituary */
export type IDisplay_Obituary_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "display_obituary_publication" */
export type IDisplay_Obituary_Publication = {
  __typename?: 'display_obituary_publication';
  created_at: Scalars['timestamp'];
  /** An object relationship */
  display_obituary: IDisplay_Obituary;
  display_obituary_id: Scalars['uuid'];
  /** An object relationship */
  publication: IPublication;
  publication_id: Scalars['uuid'];
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "display_obituary_publication" */
export type IDisplay_Obituary_Publication_Aggregate = {
  __typename?: 'display_obituary_publication_aggregate';
  aggregate?: Maybe<IDisplay_Obituary_Publication_Aggregate_Fields>;
  nodes: Array<IDisplay_Obituary_Publication>;
};

/** aggregate fields of "display_obituary_publication" */
export type IDisplay_Obituary_Publication_Aggregate_Fields = {
  __typename?: 'display_obituary_publication_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IDisplay_Obituary_Publication_Max_Fields>;
  min?: Maybe<IDisplay_Obituary_Publication_Min_Fields>;
};


/** aggregate fields of "display_obituary_publication" */
export type IDisplay_Obituary_Publication_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IDisplay_Obituary_Publication_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "display_obituary_publication" */
export type IDisplay_Obituary_Publication_Aggregate_Order_By = {
  count?: Maybe<IOrder_By>;
  max?: Maybe<IDisplay_Obituary_Publication_Max_Order_By>;
  min?: Maybe<IDisplay_Obituary_Publication_Min_Order_By>;
};

/** input type for inserting array relation for remote table "display_obituary_publication" */
export type IDisplay_Obituary_Publication_Arr_Rel_Insert_Input = {
  data: Array<IDisplay_Obituary_Publication_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<IDisplay_Obituary_Publication_On_Conflict>;
};

/** Boolean expression to filter rows from the table "display_obituary_publication". All fields are combined with a logical 'AND'. */
export type IDisplay_Obituary_Publication_Bool_Exp = {
  _and?: Maybe<Array<IDisplay_Obituary_Publication_Bool_Exp>>;
  _not?: Maybe<IDisplay_Obituary_Publication_Bool_Exp>;
  _or?: Maybe<Array<IDisplay_Obituary_Publication_Bool_Exp>>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  display_obituary?: Maybe<IDisplay_Obituary_Bool_Exp>;
  display_obituary_id?: Maybe<IUuid_Comparison_Exp>;
  publication?: Maybe<IPublication_Bool_Exp>;
  publication_id?: Maybe<IUuid_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "display_obituary_publication" */
export enum IDisplay_Obituary_Publication_Constraint {
  /** unique or primary key constraint on columns "publication_id", "display_obituary_id" */
  DisplayObituaryPublicationPkey = 'display_obituary_publication_pkey'
}

/** input type for inserting data into table "display_obituary_publication" */
export type IDisplay_Obituary_Publication_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  display_obituary?: Maybe<IDisplay_Obituary_Obj_Rel_Insert_Input>;
  display_obituary_id?: Maybe<Scalars['uuid']>;
  publication?: Maybe<IPublication_Obj_Rel_Insert_Input>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type IDisplay_Obituary_Publication_Max_Fields = {
  __typename?: 'display_obituary_publication_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  display_obituary_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "display_obituary_publication" */
export type IDisplay_Obituary_Publication_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  display_obituary_id?: Maybe<IOrder_By>;
  publication_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IDisplay_Obituary_Publication_Min_Fields = {
  __typename?: 'display_obituary_publication_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  display_obituary_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "display_obituary_publication" */
export type IDisplay_Obituary_Publication_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  display_obituary_id?: Maybe<IOrder_By>;
  publication_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "display_obituary_publication" */
export type IDisplay_Obituary_Publication_Mutation_Response = {
  __typename?: 'display_obituary_publication_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IDisplay_Obituary_Publication>;
};

/** on_conflict condition type for table "display_obituary_publication" */
export type IDisplay_Obituary_Publication_On_Conflict = {
  constraint: IDisplay_Obituary_Publication_Constraint;
  update_columns?: Array<IDisplay_Obituary_Publication_Update_Column>;
  where?: Maybe<IDisplay_Obituary_Publication_Bool_Exp>;
};

/** Ordering options when selecting data from "display_obituary_publication". */
export type IDisplay_Obituary_Publication_Order_By = {
  created_at?: Maybe<IOrder_By>;
  display_obituary?: Maybe<IDisplay_Obituary_Order_By>;
  display_obituary_id?: Maybe<IOrder_By>;
  publication?: Maybe<IPublication_Order_By>;
  publication_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** primary key columns input for table: display_obituary_publication */
export type IDisplay_Obituary_Publication_Pk_Columns_Input = {
  display_obituary_id: Scalars['uuid'];
  publication_id: Scalars['uuid'];
};

/** select columns of table "display_obituary_publication" */
export enum IDisplay_Obituary_Publication_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayObituaryId = 'display_obituary_id',
  /** column name */
  PublicationId = 'publication_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "display_obituary_publication" */
export type IDisplay_Obituary_Publication_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  display_obituary_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "display_obituary_publication" */
export enum IDisplay_Obituary_Publication_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayObituaryId = 'display_obituary_id',
  /** column name */
  PublicationId = 'publication_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select columns of table "display_obituary" */
export enum IDisplay_Obituary_Select_Column {
  /** column name */
  Age = 'age',
  /** column name */
  Body = 'body',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateBorn = 'date_born',
  /** column name */
  DateDeceased = 'date_deceased',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FullName = 'full_name',
  /** column name */
  FuneralHome = 'funeral_home',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  MiddleName = 'middle_name',
  /** column name */
  ObituaryType = 'obituary_type',
  /** column name */
  PhotoUrls = 'photo_urls',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  SourcePublishedAt = 'source_published_at',
  /** column name */
  SourceSlug = 'source_slug',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "display_obituary" */
export type IDisplay_Obituary_Set_Input = {
  age?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  date_born?: Maybe<Scalars['String']>;
  date_deceased?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  funeral_home?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  obituary_type?: Maybe<Scalars['obituary_type']>;
  photo_urls?: Maybe<Scalars['_varchar']>;
  source_id?: Maybe<Scalars['uuid']>;
  source_published_at?: Maybe<Scalars['timestamp']>;
  source_slug?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** columns and relationships of "display_obituary_tag" */
export type IDisplay_Obituary_Tag = {
  __typename?: 'display_obituary_tag';
  created_at: Scalars['timestamp'];
  /** An object relationship */
  display_obituary: IDisplay_Obituary;
  display_obituary_id: Scalars['uuid'];
  /** An object relationship */
  tag: ITag;
  tag_id: Scalars['uuid'];
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "display_obituary_tag" */
export type IDisplay_Obituary_Tag_Aggregate = {
  __typename?: 'display_obituary_tag_aggregate';
  aggregate?: Maybe<IDisplay_Obituary_Tag_Aggregate_Fields>;
  nodes: Array<IDisplay_Obituary_Tag>;
};

/** aggregate fields of "display_obituary_tag" */
export type IDisplay_Obituary_Tag_Aggregate_Fields = {
  __typename?: 'display_obituary_tag_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IDisplay_Obituary_Tag_Max_Fields>;
  min?: Maybe<IDisplay_Obituary_Tag_Min_Fields>;
};


/** aggregate fields of "display_obituary_tag" */
export type IDisplay_Obituary_Tag_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IDisplay_Obituary_Tag_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "display_obituary_tag" */
export type IDisplay_Obituary_Tag_Aggregate_Order_By = {
  count?: Maybe<IOrder_By>;
  max?: Maybe<IDisplay_Obituary_Tag_Max_Order_By>;
  min?: Maybe<IDisplay_Obituary_Tag_Min_Order_By>;
};

/** input type for inserting array relation for remote table "display_obituary_tag" */
export type IDisplay_Obituary_Tag_Arr_Rel_Insert_Input = {
  data: Array<IDisplay_Obituary_Tag_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<IDisplay_Obituary_Tag_On_Conflict>;
};

/** Boolean expression to filter rows from the table "display_obituary_tag". All fields are combined with a logical 'AND'. */
export type IDisplay_Obituary_Tag_Bool_Exp = {
  _and?: Maybe<Array<IDisplay_Obituary_Tag_Bool_Exp>>;
  _not?: Maybe<IDisplay_Obituary_Tag_Bool_Exp>;
  _or?: Maybe<Array<IDisplay_Obituary_Tag_Bool_Exp>>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  display_obituary?: Maybe<IDisplay_Obituary_Bool_Exp>;
  display_obituary_id?: Maybe<IUuid_Comparison_Exp>;
  tag?: Maybe<ITag_Bool_Exp>;
  tag_id?: Maybe<IUuid_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "display_obituary_tag" */
export enum IDisplay_Obituary_Tag_Constraint {
  /** unique or primary key constraint on columns "tag_id", "display_obituary_id" */
  DisplayObituaryTagPkey = 'display_obituary_tag_pkey'
}

/** input type for inserting data into table "display_obituary_tag" */
export type IDisplay_Obituary_Tag_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  display_obituary?: Maybe<IDisplay_Obituary_Obj_Rel_Insert_Input>;
  display_obituary_id?: Maybe<Scalars['uuid']>;
  tag?: Maybe<ITag_Obj_Rel_Insert_Input>;
  tag_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type IDisplay_Obituary_Tag_Max_Fields = {
  __typename?: 'display_obituary_tag_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  display_obituary_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "display_obituary_tag" */
export type IDisplay_Obituary_Tag_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  display_obituary_id?: Maybe<IOrder_By>;
  tag_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IDisplay_Obituary_Tag_Min_Fields = {
  __typename?: 'display_obituary_tag_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  display_obituary_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "display_obituary_tag" */
export type IDisplay_Obituary_Tag_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  display_obituary_id?: Maybe<IOrder_By>;
  tag_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "display_obituary_tag" */
export type IDisplay_Obituary_Tag_Mutation_Response = {
  __typename?: 'display_obituary_tag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IDisplay_Obituary_Tag>;
};

/** on_conflict condition type for table "display_obituary_tag" */
export type IDisplay_Obituary_Tag_On_Conflict = {
  constraint: IDisplay_Obituary_Tag_Constraint;
  update_columns?: Array<IDisplay_Obituary_Tag_Update_Column>;
  where?: Maybe<IDisplay_Obituary_Tag_Bool_Exp>;
};

/** Ordering options when selecting data from "display_obituary_tag". */
export type IDisplay_Obituary_Tag_Order_By = {
  created_at?: Maybe<IOrder_By>;
  display_obituary?: Maybe<IDisplay_Obituary_Order_By>;
  display_obituary_id?: Maybe<IOrder_By>;
  tag?: Maybe<ITag_Order_By>;
  tag_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** primary key columns input for table: display_obituary_tag */
export type IDisplay_Obituary_Tag_Pk_Columns_Input = {
  display_obituary_id: Scalars['uuid'];
  tag_id: Scalars['uuid'];
};

/** select columns of table "display_obituary_tag" */
export enum IDisplay_Obituary_Tag_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayObituaryId = 'display_obituary_id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "display_obituary_tag" */
export type IDisplay_Obituary_Tag_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  display_obituary_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "display_obituary_tag" */
export enum IDisplay_Obituary_Tag_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayObituaryId = 'display_obituary_id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** update columns of table "display_obituary" */
export enum IDisplay_Obituary_Update_Column {
  /** column name */
  Age = 'age',
  /** column name */
  Body = 'body',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateBorn = 'date_born',
  /** column name */
  DateDeceased = 'date_deceased',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FullName = 'full_name',
  /** column name */
  FuneralHome = 'funeral_home',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  MiddleName = 'middle_name',
  /** column name */
  ObituaryType = 'obituary_type',
  /** column name */
  PhotoUrls = 'photo_urls',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  SourcePublishedAt = 'source_published_at',
  /** column name */
  SourceSlug = 'source_slug',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "display_run_date" */
export type IDisplay_Run_Date = {
  __typename?: 'display_run_date';
  created_at: Scalars['timestamptz'];
  display_obituary_id: Scalars['uuid'];
  /** An object relationship */
  display_run_date?: Maybe<IDisplay_Obituary>;
  id: Scalars['uuid'];
  run_date: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "display_run_date" */
export type IDisplay_Run_Date_Aggregate = {
  __typename?: 'display_run_date_aggregate';
  aggregate?: Maybe<IDisplay_Run_Date_Aggregate_Fields>;
  nodes: Array<IDisplay_Run_Date>;
};

/** aggregate fields of "display_run_date" */
export type IDisplay_Run_Date_Aggregate_Fields = {
  __typename?: 'display_run_date_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IDisplay_Run_Date_Max_Fields>;
  min?: Maybe<IDisplay_Run_Date_Min_Fields>;
};


/** aggregate fields of "display_run_date" */
export type IDisplay_Run_Date_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IDisplay_Run_Date_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "display_run_date". All fields are combined with a logical 'AND'. */
export type IDisplay_Run_Date_Bool_Exp = {
  _and?: Maybe<Array<IDisplay_Run_Date_Bool_Exp>>;
  _not?: Maybe<IDisplay_Run_Date_Bool_Exp>;
  _or?: Maybe<Array<IDisplay_Run_Date_Bool_Exp>>;
  created_at?: Maybe<ITimestamptz_Comparison_Exp>;
  display_obituary_id?: Maybe<IUuid_Comparison_Exp>;
  display_run_date?: Maybe<IDisplay_Obituary_Bool_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  run_date?: Maybe<ITimestamptz_Comparison_Exp>;
  updated_at?: Maybe<ITimestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "display_run_date" */
export enum IDisplay_Run_Date_Constraint {
  /** unique or primary key constraint on columns "id" */
  DisplayRunDatePkey = 'display_run_date_pkey'
}

/** input type for inserting data into table "display_run_date" */
export type IDisplay_Run_Date_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  display_obituary_id?: Maybe<Scalars['uuid']>;
  display_run_date?: Maybe<IDisplay_Obituary_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  run_date?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type IDisplay_Run_Date_Max_Fields = {
  __typename?: 'display_run_date_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  display_obituary_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  run_date?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type IDisplay_Run_Date_Min_Fields = {
  __typename?: 'display_run_date_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  display_obituary_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  run_date?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "display_run_date" */
export type IDisplay_Run_Date_Mutation_Response = {
  __typename?: 'display_run_date_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IDisplay_Run_Date>;
};

/** on_conflict condition type for table "display_run_date" */
export type IDisplay_Run_Date_On_Conflict = {
  constraint: IDisplay_Run_Date_Constraint;
  update_columns?: Array<IDisplay_Run_Date_Update_Column>;
  where?: Maybe<IDisplay_Run_Date_Bool_Exp>;
};

/** Ordering options when selecting data from "display_run_date". */
export type IDisplay_Run_Date_Order_By = {
  created_at?: Maybe<IOrder_By>;
  display_obituary_id?: Maybe<IOrder_By>;
  display_run_date?: Maybe<IDisplay_Obituary_Order_By>;
  id?: Maybe<IOrder_By>;
  run_date?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** primary key columns input for table: display_run_date */
export type IDisplay_Run_Date_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "display_run_date" */
export enum IDisplay_Run_Date_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayObituaryId = 'display_obituary_id',
  /** column name */
  Id = 'id',
  /** column name */
  RunDate = 'run_date',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "display_run_date" */
export type IDisplay_Run_Date_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  display_obituary_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  run_date?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "display_run_date" */
export enum IDisplay_Run_Date_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayObituaryId = 'display_obituary_id',
  /** column name */
  Id = 'id',
  /** column name */
  RunDate = 'run_date',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "duplicate_locations" */
export type IDuplicate_Locations = {
  __typename?: 'duplicate_locations';
  created_at: Scalars['timestamptz'];
  data?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  location_id?: Maybe<Scalars['bigint']>;
  reason_code?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "duplicate_locations" */
export type IDuplicate_Locations_Aggregate = {
  __typename?: 'duplicate_locations_aggregate';
  aggregate?: Maybe<IDuplicate_Locations_Aggregate_Fields>;
  nodes: Array<IDuplicate_Locations>;
};

/** aggregate fields of "duplicate_locations" */
export type IDuplicate_Locations_Aggregate_Fields = {
  __typename?: 'duplicate_locations_aggregate_fields';
  avg?: Maybe<IDuplicate_Locations_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<IDuplicate_Locations_Max_Fields>;
  min?: Maybe<IDuplicate_Locations_Min_Fields>;
  stddev?: Maybe<IDuplicate_Locations_Stddev_Fields>;
  stddev_pop?: Maybe<IDuplicate_Locations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IDuplicate_Locations_Stddev_Samp_Fields>;
  sum?: Maybe<IDuplicate_Locations_Sum_Fields>;
  var_pop?: Maybe<IDuplicate_Locations_Var_Pop_Fields>;
  var_samp?: Maybe<IDuplicate_Locations_Var_Samp_Fields>;
  variance?: Maybe<IDuplicate_Locations_Variance_Fields>;
};


/** aggregate fields of "duplicate_locations" */
export type IDuplicate_Locations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IDuplicate_Locations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "duplicate_locations" */
export type IDuplicate_Locations_Aggregate_Order_By = {
  avg?: Maybe<IDuplicate_Locations_Avg_Order_By>;
  count?: Maybe<IOrder_By>;
  max?: Maybe<IDuplicate_Locations_Max_Order_By>;
  min?: Maybe<IDuplicate_Locations_Min_Order_By>;
  stddev?: Maybe<IDuplicate_Locations_Stddev_Order_By>;
  stddev_pop?: Maybe<IDuplicate_Locations_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IDuplicate_Locations_Stddev_Samp_Order_By>;
  sum?: Maybe<IDuplicate_Locations_Sum_Order_By>;
  var_pop?: Maybe<IDuplicate_Locations_Var_Pop_Order_By>;
  var_samp?: Maybe<IDuplicate_Locations_Var_Samp_Order_By>;
  variance?: Maybe<IDuplicate_Locations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "duplicate_locations" */
export type IDuplicate_Locations_Arr_Rel_Insert_Input = {
  data: Array<IDuplicate_Locations_Insert_Input>;
  on_conflict?: Maybe<IDuplicate_Locations_On_Conflict>;
};

/** aggregate avg on columns */
export type IDuplicate_Locations_Avg_Fields = {
  __typename?: 'duplicate_locations_avg_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "duplicate_locations" */
export type IDuplicate_Locations_Avg_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** Boolean expression to filter rows from the table "duplicate_locations". All fields are combined with a logical 'AND'. */
export type IDuplicate_Locations_Bool_Exp = {
  _and?: Maybe<Array<Maybe<IDuplicate_Locations_Bool_Exp>>>;
  _not?: Maybe<IDuplicate_Locations_Bool_Exp>;
  _or?: Maybe<Array<Maybe<IDuplicate_Locations_Bool_Exp>>>;
  created_at?: Maybe<ITimestamptz_Comparison_Exp>;
  data?: Maybe<IString_Comparison_Exp>;
  id?: Maybe<IBigint_Comparison_Exp>;
  location_id?: Maybe<IBigint_Comparison_Exp>;
  reason_code?: Maybe<IString_Comparison_Exp>;
  updated_at?: Maybe<ITimestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "duplicate_locations" */
export enum IDuplicate_Locations_Constraint {
  /** unique or primary key constraint */
  Idx_17019Primary = 'idx_17019_primary'
}

/** input type for incrementing integer column in table "duplicate_locations" */
export type IDuplicate_Locations_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "duplicate_locations" */
export type IDuplicate_Locations_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  reason_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type IDuplicate_Locations_Max_Fields = {
  __typename?: 'duplicate_locations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  reason_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "duplicate_locations" */
export type IDuplicate_Locations_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  data?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  reason_code?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IDuplicate_Locations_Min_Fields = {
  __typename?: 'duplicate_locations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  reason_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "duplicate_locations" */
export type IDuplicate_Locations_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  data?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  reason_code?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "duplicate_locations" */
export type IDuplicate_Locations_Mutation_Response = {
  __typename?: 'duplicate_locations_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<IDuplicate_Locations>;
};

/** input type for inserting object relation for remote table "duplicate_locations" */
export type IDuplicate_Locations_Obj_Rel_Insert_Input = {
  data: IDuplicate_Locations_Insert_Input;
  on_conflict?: Maybe<IDuplicate_Locations_On_Conflict>;
};

/** on conflict condition type for table "duplicate_locations" */
export type IDuplicate_Locations_On_Conflict = {
  constraint: IDuplicate_Locations_Constraint;
  update_columns: Array<IDuplicate_Locations_Update_Column>;
  where?: Maybe<IDuplicate_Locations_Bool_Exp>;
};

/** ordering options when selecting data from "duplicate_locations" */
export type IDuplicate_Locations_Order_By = {
  created_at?: Maybe<IOrder_By>;
  data?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  reason_code?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** select columns of table "duplicate_locations" */
export enum IDuplicate_Locations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  ReasonCode = 'reason_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "duplicate_locations" */
export type IDuplicate_Locations_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  reason_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type IDuplicate_Locations_Stddev_Fields = {
  __typename?: 'duplicate_locations_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "duplicate_locations" */
export type IDuplicate_Locations_Stddev_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IDuplicate_Locations_Stddev_Pop_Fields = {
  __typename?: 'duplicate_locations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "duplicate_locations" */
export type IDuplicate_Locations_Stddev_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IDuplicate_Locations_Stddev_Samp_Fields = {
  __typename?: 'duplicate_locations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "duplicate_locations" */
export type IDuplicate_Locations_Stddev_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate sum on columns */
export type IDuplicate_Locations_Sum_Fields = {
  __typename?: 'duplicate_locations_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "duplicate_locations" */
export type IDuplicate_Locations_Sum_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** update columns of table "duplicate_locations" */
export enum IDuplicate_Locations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  ReasonCode = 'reason_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type IDuplicate_Locations_Var_Pop_Fields = {
  __typename?: 'duplicate_locations_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "duplicate_locations" */
export type IDuplicate_Locations_Var_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate var_samp on columns */
export type IDuplicate_Locations_Var_Samp_Fields = {
  __typename?: 'duplicate_locations_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "duplicate_locations" */
export type IDuplicate_Locations_Var_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate variance on columns */
export type IDuplicate_Locations_Variance_Fields = {
  __typename?: 'duplicate_locations_variance_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "duplicate_locations" */
export type IDuplicate_Locations_Variance_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** columns and relationships of "events" */
export type IEvents = {
  __typename?: 'events';
  content?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  memory: IMemories;
  memory_id: Scalars['uuid'];
  obit_slug: Scalars['String'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<IUsers>;
  user_id?: Maybe<Scalars['String']>;
};


/** columns and relationships of "events" */
export type IEventsContentArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "events" */
export type IEvents_Aggregate = {
  __typename?: 'events_aggregate';
  aggregate?: Maybe<IEvents_Aggregate_Fields>;
  nodes: Array<IEvents>;
};

export type IEvents_Aggregate_Bool_Exp = {
  count?: Maybe<IEvents_Aggregate_Bool_Exp_Count>;
};

export type IEvents_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IEvents_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IEvents_Bool_Exp>;
  predicate: IInt_Comparison_Exp;
};

/** aggregate fields of "events" */
export type IEvents_Aggregate_Fields = {
  __typename?: 'events_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IEvents_Max_Fields>;
  min?: Maybe<IEvents_Min_Fields>;
};


/** aggregate fields of "events" */
export type IEvents_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IEvents_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "events" */
export type IEvents_Aggregate_Order_By = {
  count?: Maybe<IOrder_By>;
  max?: Maybe<IEvents_Max_Order_By>;
  min?: Maybe<IEvents_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IEvents_Append_Input = {
  content?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "events" */
export type IEvents_Arr_Rel_Insert_Input = {
  data: Array<IEvents_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<IEvents_On_Conflict>;
};

/** Boolean expression to filter rows from the table "events". All fields are combined with a logical 'AND'. */
export type IEvents_Bool_Exp = {
  _and?: Maybe<Array<IEvents_Bool_Exp>>;
  _not?: Maybe<IEvents_Bool_Exp>;
  _or?: Maybe<Array<IEvents_Bool_Exp>>;
  content?: Maybe<IJsonb_Comparison_Exp>;
  created_at?: Maybe<ITimestamptz_Comparison_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  memory?: Maybe<IMemories_Bool_Exp>;
  memory_id?: Maybe<IUuid_Comparison_Exp>;
  obit_slug?: Maybe<IString_Comparison_Exp>;
  type?: Maybe<IString_Comparison_Exp>;
  updated_at?: Maybe<ITimestamptz_Comparison_Exp>;
  user?: Maybe<IUsers_Bool_Exp>;
  user_id?: Maybe<IString_Comparison_Exp>;
};

/** unique or primary key constraints on table "events" */
export enum IEvents_Constraint {
  /** unique or primary key constraint on columns "id" */
  EventsPkey = 'events_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IEvents_Delete_At_Path_Input = {
  content?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IEvents_Delete_Elem_Input = {
  content?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IEvents_Delete_Key_Input = {
  content?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "events" */
export type IEvents_Insert_Input = {
  content?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  memory?: Maybe<IMemories_Obj_Rel_Insert_Input>;
  memory_id?: Maybe<Scalars['uuid']>;
  obit_slug?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<IUsers_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type IEvents_Max_Fields = {
  __typename?: 'events_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  memory_id?: Maybe<Scalars['uuid']>;
  obit_slug?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "events" */
export type IEvents_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  memory_id?: Maybe<IOrder_By>;
  obit_slug?: Maybe<IOrder_By>;
  type?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  user_id?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IEvents_Min_Fields = {
  __typename?: 'events_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  memory_id?: Maybe<Scalars['uuid']>;
  obit_slug?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "events" */
export type IEvents_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  memory_id?: Maybe<IOrder_By>;
  obit_slug?: Maybe<IOrder_By>;
  type?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  user_id?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "events" */
export type IEvents_Mutation_Response = {
  __typename?: 'events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IEvents>;
};

/** on_conflict condition type for table "events" */
export type IEvents_On_Conflict = {
  constraint: IEvents_Constraint;
  update_columns?: Array<IEvents_Update_Column>;
  where?: Maybe<IEvents_Bool_Exp>;
};

/** Ordering options when selecting data from "events". */
export type IEvents_Order_By = {
  content?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  memory?: Maybe<IMemories_Order_By>;
  memory_id?: Maybe<IOrder_By>;
  obit_slug?: Maybe<IOrder_By>;
  type?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  user?: Maybe<IUsers_Order_By>;
  user_id?: Maybe<IOrder_By>;
};

/** primary key columns input for table: events */
export type IEvents_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IEvents_Prepend_Input = {
  content?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "events" */
export enum IEvents_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MemoryId = 'memory_id',
  /** column name */
  ObitSlug = 'obit_slug',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "events" */
export type IEvents_Set_Input = {
  content?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  memory_id?: Maybe<Scalars['uuid']>;
  obit_slug?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "events" */
export type IEvents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IEvents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<ICursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IEvents_Stream_Cursor_Value_Input = {
  content?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  memory_id?: Maybe<Scalars['uuid']>;
  obit_slug?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** columns and relationships of "events_types" */
export type IEvents_Types = {
  __typename?: 'events_types';
  id: Scalars['Int'];
  value: Scalars['String'];
};

/** aggregated selection of "events_types" */
export type IEvents_Types_Aggregate = {
  __typename?: 'events_types_aggregate';
  aggregate?: Maybe<IEvents_Types_Aggregate_Fields>;
  nodes: Array<IEvents_Types>;
};

/** aggregate fields of "events_types" */
export type IEvents_Types_Aggregate_Fields = {
  __typename?: 'events_types_aggregate_fields';
  avg?: Maybe<IEvents_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IEvents_Types_Max_Fields>;
  min?: Maybe<IEvents_Types_Min_Fields>;
  stddev?: Maybe<IEvents_Types_Stddev_Fields>;
  stddev_pop?: Maybe<IEvents_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IEvents_Types_Stddev_Samp_Fields>;
  sum?: Maybe<IEvents_Types_Sum_Fields>;
  var_pop?: Maybe<IEvents_Types_Var_Pop_Fields>;
  var_samp?: Maybe<IEvents_Types_Var_Samp_Fields>;
  variance?: Maybe<IEvents_Types_Variance_Fields>;
};


/** aggregate fields of "events_types" */
export type IEvents_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IEvents_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type IEvents_Types_Avg_Fields = {
  __typename?: 'events_types_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "events_types". All fields are combined with a logical 'AND'. */
export type IEvents_Types_Bool_Exp = {
  _and?: Maybe<Array<IEvents_Types_Bool_Exp>>;
  _not?: Maybe<IEvents_Types_Bool_Exp>;
  _or?: Maybe<Array<IEvents_Types_Bool_Exp>>;
  id?: Maybe<IInt_Comparison_Exp>;
  value?: Maybe<IString_Comparison_Exp>;
};

/** unique or primary key constraints on table "events_types" */
export enum IEvents_Types_Constraint {
  /** unique or primary key constraint on columns "id" */
  EventsTypesPkey = 'events_types_pkey',
  /** unique or primary key constraint on columns "value" */
  EventsTypesValueKey = 'events_types_value_key'
}

/** input type for incrementing numeric columns in table "events_types" */
export type IEvents_Types_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "events_types" */
export type IEvents_Types_Insert_Input = {
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type IEvents_Types_Max_Fields = {
  __typename?: 'events_types_max_fields';
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type IEvents_Types_Min_Fields = {
  __typename?: 'events_types_min_fields';
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "events_types" */
export type IEvents_Types_Mutation_Response = {
  __typename?: 'events_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IEvents_Types>;
};

/** on_conflict condition type for table "events_types" */
export type IEvents_Types_On_Conflict = {
  constraint: IEvents_Types_Constraint;
  update_columns?: Array<IEvents_Types_Update_Column>;
  where?: Maybe<IEvents_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "events_types". */
export type IEvents_Types_Order_By = {
  id?: Maybe<IOrder_By>;
  value?: Maybe<IOrder_By>;
};

/** primary key columns input for table: events_types */
export type IEvents_Types_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "events_types" */
export enum IEvents_Types_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "events_types" */
export type IEvents_Types_Set_Input = {
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type IEvents_Types_Stddev_Fields = {
  __typename?: 'events_types_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type IEvents_Types_Stddev_Pop_Fields = {
  __typename?: 'events_types_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type IEvents_Types_Stddev_Samp_Fields = {
  __typename?: 'events_types_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "events_types" */
export type IEvents_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IEvents_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<ICursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IEvents_Types_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type IEvents_Types_Sum_Fields = {
  __typename?: 'events_types_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "events_types" */
export enum IEvents_Types_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

export type IEvents_Types_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<IEvents_Types_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<IEvents_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: IEvents_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type IEvents_Types_Var_Pop_Fields = {
  __typename?: 'events_types_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type IEvents_Types_Var_Samp_Fields = {
  __typename?: 'events_types_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type IEvents_Types_Variance_Fields = {
  __typename?: 'events_types_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** update columns of table "events" */
export enum IEvents_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MemoryId = 'memory_id',
  /** column name */
  ObitSlug = 'obit_slug',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type IEvents_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<IEvents_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<IEvents_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<IEvents_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<IEvents_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<IEvents_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<IEvents_Set_Input>;
  /** filter the rows which have to be updated */
  where: IEvents_Bool_Exp;
};

/** columns and relationships of "external_link" */
export type IExternal_Link = {
  __typename?: 'external_link';
  created_at?: Maybe<Scalars['timestamp']>;
  id: Scalars['uuid'];
  /** An object relationship */
  obituary: IObituary;
  obituary_id: Scalars['uuid'];
  source: IExternal_Link_Source_Enum;
  type: IExternal_Link_Type_Enum;
  updated_at?: Maybe<Scalars['timestamp']>;
  uri: Scalars['String'];
};

/** aggregated selection of "external_link" */
export type IExternal_Link_Aggregate = {
  __typename?: 'external_link_aggregate';
  aggregate?: Maybe<IExternal_Link_Aggregate_Fields>;
  nodes: Array<IExternal_Link>;
};

/** aggregate fields of "external_link" */
export type IExternal_Link_Aggregate_Fields = {
  __typename?: 'external_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IExternal_Link_Max_Fields>;
  min?: Maybe<IExternal_Link_Min_Fields>;
};


/** aggregate fields of "external_link" */
export type IExternal_Link_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IExternal_Link_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "external_link" */
export type IExternal_Link_Aggregate_Order_By = {
  count?: Maybe<IOrder_By>;
  max?: Maybe<IExternal_Link_Max_Order_By>;
  min?: Maybe<IExternal_Link_Min_Order_By>;
};

/** input type for inserting array relation for remote table "external_link" */
export type IExternal_Link_Arr_Rel_Insert_Input = {
  data: Array<IExternal_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<IExternal_Link_On_Conflict>;
};

/** Boolean expression to filter rows from the table "external_link". All fields are combined with a logical 'AND'. */
export type IExternal_Link_Bool_Exp = {
  _and?: Maybe<Array<IExternal_Link_Bool_Exp>>;
  _not?: Maybe<IExternal_Link_Bool_Exp>;
  _or?: Maybe<Array<IExternal_Link_Bool_Exp>>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  obituary?: Maybe<IObituary_Bool_Exp>;
  obituary_id?: Maybe<IUuid_Comparison_Exp>;
  source?: Maybe<IExternal_Link_Source_Enum_Comparison_Exp>;
  type?: Maybe<IExternal_Link_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
  uri?: Maybe<IString_Comparison_Exp>;
};

/** unique or primary key constraints on table "external_link" */
export enum IExternal_Link_Constraint {
  /** unique or primary key constraint on columns "id" */
  ExternalLinkPkey = 'external_link_pkey'
}

/** input type for inserting data into table "external_link" */
export type IExternal_Link_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  obituary?: Maybe<IObituary_Obj_Rel_Insert_Input>;
  obituary_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<IExternal_Link_Source_Enum>;
  type?: Maybe<IExternal_Link_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
  uri?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type IExternal_Link_Max_Fields = {
  __typename?: 'external_link_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  uri?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "external_link" */
export type IExternal_Link_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  obituary_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  uri?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IExternal_Link_Min_Fields = {
  __typename?: 'external_link_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  uri?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "external_link" */
export type IExternal_Link_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  obituary_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  uri?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "external_link" */
export type IExternal_Link_Mutation_Response = {
  __typename?: 'external_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IExternal_Link>;
};

/** on_conflict condition type for table "external_link" */
export type IExternal_Link_On_Conflict = {
  constraint: IExternal_Link_Constraint;
  update_columns?: Array<IExternal_Link_Update_Column>;
  where?: Maybe<IExternal_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "external_link". */
export type IExternal_Link_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  obituary?: Maybe<IObituary_Order_By>;
  obituary_id?: Maybe<IOrder_By>;
  source?: Maybe<IOrder_By>;
  type?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  uri?: Maybe<IOrder_By>;
};

/** primary key columns input for table: external_link */
export type IExternal_Link_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "external_link" */
export enum IExternal_Link_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ObituaryId = 'obituary_id',
  /** column name */
  Source = 'source',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uri = 'uri'
}

/** input type for updating data in table "external_link" */
export type IExternal_Link_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<IExternal_Link_Source_Enum>;
  type?: Maybe<IExternal_Link_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
  uri?: Maybe<Scalars['String']>;
};

/** where did the link originate */
export type IExternal_Link_Source = {
  __typename?: 'external_link_source';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "external_link_source" */
export type IExternal_Link_Source_Aggregate = {
  __typename?: 'external_link_source_aggregate';
  aggregate?: Maybe<IExternal_Link_Source_Aggregate_Fields>;
  nodes: Array<IExternal_Link_Source>;
};

/** aggregate fields of "external_link_source" */
export type IExternal_Link_Source_Aggregate_Fields = {
  __typename?: 'external_link_source_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IExternal_Link_Source_Max_Fields>;
  min?: Maybe<IExternal_Link_Source_Min_Fields>;
};


/** aggregate fields of "external_link_source" */
export type IExternal_Link_Source_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IExternal_Link_Source_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "external_link_source". All fields are combined with a logical 'AND'. */
export type IExternal_Link_Source_Bool_Exp = {
  _and?: Maybe<Array<IExternal_Link_Source_Bool_Exp>>;
  _not?: Maybe<IExternal_Link_Source_Bool_Exp>;
  _or?: Maybe<Array<IExternal_Link_Source_Bool_Exp>>;
  comment?: Maybe<IString_Comparison_Exp>;
  value?: Maybe<IString_Comparison_Exp>;
};

/** unique or primary key constraints on table "external_link_source" */
export enum IExternal_Link_Source_Constraint {
  /** unique or primary key constraint on columns "value" */
  ExternalLinkSourcePkey = 'external_link_source_pkey'
}

export enum IExternal_Link_Source_Enum {
  /** Originated from gofundme */
  Gofundme = 'gofundme',
  /** Originated from the external obituary importer */
  Import = 'import',
  /** Originated from the obituary manager */
  Manager = 'manager',
  /** Originated from a user on the web */
  Web = 'web'
}

/** Boolean expression to compare columns of type "external_link_source_enum". All fields are combined with logical 'AND'. */
export type IExternal_Link_Source_Enum_Comparison_Exp = {
  _eq?: Maybe<IExternal_Link_Source_Enum>;
  _in?: Maybe<Array<IExternal_Link_Source_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<IExternal_Link_Source_Enum>;
  _nin?: Maybe<Array<IExternal_Link_Source_Enum>>;
};

/** input type for inserting data into table "external_link_source" */
export type IExternal_Link_Source_Insert_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type IExternal_Link_Source_Max_Fields = {
  __typename?: 'external_link_source_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type IExternal_Link_Source_Min_Fields = {
  __typename?: 'external_link_source_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "external_link_source" */
export type IExternal_Link_Source_Mutation_Response = {
  __typename?: 'external_link_source_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IExternal_Link_Source>;
};

/** on_conflict condition type for table "external_link_source" */
export type IExternal_Link_Source_On_Conflict = {
  constraint: IExternal_Link_Source_Constraint;
  update_columns?: Array<IExternal_Link_Source_Update_Column>;
  where?: Maybe<IExternal_Link_Source_Bool_Exp>;
};

/** Ordering options when selecting data from "external_link_source". */
export type IExternal_Link_Source_Order_By = {
  comment?: Maybe<IOrder_By>;
  value?: Maybe<IOrder_By>;
};

/** primary key columns input for table: external_link_source */
export type IExternal_Link_Source_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "external_link_source" */
export enum IExternal_Link_Source_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "external_link_source" */
export type IExternal_Link_Source_Set_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "external_link_source" */
export enum IExternal_Link_Source_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** Type enum for external link */
export type IExternal_Link_Type = {
  __typename?: 'external_link_type';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "external_link_type" */
export type IExternal_Link_Type_Aggregate = {
  __typename?: 'external_link_type_aggregate';
  aggregate?: Maybe<IExternal_Link_Type_Aggregate_Fields>;
  nodes: Array<IExternal_Link_Type>;
};

/** aggregate fields of "external_link_type" */
export type IExternal_Link_Type_Aggregate_Fields = {
  __typename?: 'external_link_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IExternal_Link_Type_Max_Fields>;
  min?: Maybe<IExternal_Link_Type_Min_Fields>;
};


/** aggregate fields of "external_link_type" */
export type IExternal_Link_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IExternal_Link_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "external_link_type". All fields are combined with a logical 'AND'. */
export type IExternal_Link_Type_Bool_Exp = {
  _and?: Maybe<Array<IExternal_Link_Type_Bool_Exp>>;
  _not?: Maybe<IExternal_Link_Type_Bool_Exp>;
  _or?: Maybe<Array<IExternal_Link_Type_Bool_Exp>>;
  comment?: Maybe<IString_Comparison_Exp>;
  value?: Maybe<IString_Comparison_Exp>;
};

/** unique or primary key constraints on table "external_link_type" */
export enum IExternal_Link_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  ExternalLinkTypePkey = 'external_link_type_pkey'
}

export enum IExternal_Link_Type_Enum {
  /** Go fund me campaign link */
  Gofundme = 'gofundme',
  /** Unspecified link */
  Other = 'other',
  /** Video embed link from Legacy */
  VideoLegacy = 'video_legacy'
}

/** Boolean expression to compare columns of type "external_link_type_enum". All fields are combined with logical 'AND'. */
export type IExternal_Link_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<IExternal_Link_Type_Enum>;
  _in?: Maybe<Array<IExternal_Link_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<IExternal_Link_Type_Enum>;
  _nin?: Maybe<Array<IExternal_Link_Type_Enum>>;
};

/** input type for inserting data into table "external_link_type" */
export type IExternal_Link_Type_Insert_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type IExternal_Link_Type_Max_Fields = {
  __typename?: 'external_link_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type IExternal_Link_Type_Min_Fields = {
  __typename?: 'external_link_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "external_link_type" */
export type IExternal_Link_Type_Mutation_Response = {
  __typename?: 'external_link_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IExternal_Link_Type>;
};

/** on_conflict condition type for table "external_link_type" */
export type IExternal_Link_Type_On_Conflict = {
  constraint: IExternal_Link_Type_Constraint;
  update_columns?: Array<IExternal_Link_Type_Update_Column>;
  where?: Maybe<IExternal_Link_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "external_link_type". */
export type IExternal_Link_Type_Order_By = {
  comment?: Maybe<IOrder_By>;
  value?: Maybe<IOrder_By>;
};

/** primary key columns input for table: external_link_type */
export type IExternal_Link_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "external_link_type" */
export enum IExternal_Link_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "external_link_type" */
export type IExternal_Link_Type_Set_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "external_link_type" */
export enum IExternal_Link_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** update columns of table "external_link" */
export enum IExternal_Link_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ObituaryId = 'obituary_id',
  /** column name */
  Source = 'source',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uri = 'uri'
}

/** columns and relationships of "facebook_config" */
export type IFacebook_Config = {
  __typename?: 'facebook_config';
  app_id: Scalars['String'];
  created_at: Scalars['timestamp'];
  id: Scalars['uuid'];
  /** An object relationship */
  publication: IPublication;
  publication_id: Scalars['uuid'];
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "facebook_config" */
export type IFacebook_Config_Aggregate = {
  __typename?: 'facebook_config_aggregate';
  aggregate?: Maybe<IFacebook_Config_Aggregate_Fields>;
  nodes: Array<IFacebook_Config>;
};

/** aggregate fields of "facebook_config" */
export type IFacebook_Config_Aggregate_Fields = {
  __typename?: 'facebook_config_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IFacebook_Config_Max_Fields>;
  min?: Maybe<IFacebook_Config_Min_Fields>;
};


/** aggregate fields of "facebook_config" */
export type IFacebook_Config_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IFacebook_Config_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "facebook_config" */
export type IFacebook_Config_Aggregate_Order_By = {
  count?: Maybe<IOrder_By>;
  max?: Maybe<IFacebook_Config_Max_Order_By>;
  min?: Maybe<IFacebook_Config_Min_Order_By>;
};

/** input type for inserting array relation for remote table "facebook_config" */
export type IFacebook_Config_Arr_Rel_Insert_Input = {
  data: Array<IFacebook_Config_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<IFacebook_Config_On_Conflict>;
};

/** Boolean expression to filter rows from the table "facebook_config". All fields are combined with a logical 'AND'. */
export type IFacebook_Config_Bool_Exp = {
  _and?: Maybe<Array<IFacebook_Config_Bool_Exp>>;
  _not?: Maybe<IFacebook_Config_Bool_Exp>;
  _or?: Maybe<Array<IFacebook_Config_Bool_Exp>>;
  app_id?: Maybe<IString_Comparison_Exp>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  publication?: Maybe<IPublication_Bool_Exp>;
  publication_id?: Maybe<IUuid_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "facebook_config" */
export enum IFacebook_Config_Constraint {
  /** unique or primary key constraint on columns "id" */
  FacebookConfigPkey = 'facebook_config_pkey'
}

/** input type for inserting data into table "facebook_config" */
export type IFacebook_Config_Insert_Input = {
  app_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  publication?: Maybe<IPublication_Obj_Rel_Insert_Input>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type IFacebook_Config_Max_Fields = {
  __typename?: 'facebook_config_max_fields';
  app_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "facebook_config" */
export type IFacebook_Config_Max_Order_By = {
  app_id?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  publication_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IFacebook_Config_Min_Fields = {
  __typename?: 'facebook_config_min_fields';
  app_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "facebook_config" */
export type IFacebook_Config_Min_Order_By = {
  app_id?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  publication_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "facebook_config" */
export type IFacebook_Config_Mutation_Response = {
  __typename?: 'facebook_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IFacebook_Config>;
};

/** on_conflict condition type for table "facebook_config" */
export type IFacebook_Config_On_Conflict = {
  constraint: IFacebook_Config_Constraint;
  update_columns?: Array<IFacebook_Config_Update_Column>;
  where?: Maybe<IFacebook_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "facebook_config". */
export type IFacebook_Config_Order_By = {
  app_id?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  publication?: Maybe<IPublication_Order_By>;
  publication_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** primary key columns input for table: facebook_config */
export type IFacebook_Config_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "facebook_config" */
export enum IFacebook_Config_Select_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PublicationId = 'publication_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "facebook_config" */
export type IFacebook_Config_Set_Input = {
  app_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "facebook_config" */
export enum IFacebook_Config_Update_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PublicationId = 'publication_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "from_sales_order" */
export type IFrom_Sales_Order = {
  __typename?: 'from_sales_order';
  billing_address: Scalars['String'];
  billing_city: Scalars['String'];
  billing_email: Scalars['String'];
  billing_phone: Scalars['String'];
  billing_state: Scalars['String'];
  billing_zip: Scalars['String'];
  contact_firstname: Scalars['String'];
  contact_lastname: Scalars['String'];
  created_at: Scalars['timestamptz'];
  email_sent_to_sales: Scalars['Boolean'];
  /** An array relationship */
  from_sales_order_from_sales_publications: Array<IFrom_Sales_Order_From_Sales_Publication>;
  /** An aggregate relationship */
  from_sales_order_from_sales_publications_aggregate: IFrom_Sales_Order_From_Sales_Publication_Aggregate;
  have_accepted_terms_and_service: Scalars['Boolean'];
  id: Scalars['uuid'];
  /** An object relationship */
  obituary: IObituary;
  obituary_id: Scalars['uuid'];
  print_end: Scalars['date'];
  print_start: Scalars['date'];
  updated_at: Scalars['timestamptz'];
  verification_contact: Scalars['String'];
  verification_phone: Scalars['String'];
  verification_provider: Scalars['String'];
};


/** columns and relationships of "from_sales_order" */
export type IFrom_Sales_OrderFrom_Sales_Order_From_Sales_PublicationsArgs = {
  distinct_on?: Maybe<Array<IFrom_Sales_Order_From_Sales_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFrom_Sales_Order_From_Sales_Publication_Order_By>>;
  where?: Maybe<IFrom_Sales_Order_From_Sales_Publication_Bool_Exp>;
};


/** columns and relationships of "from_sales_order" */
export type IFrom_Sales_OrderFrom_Sales_Order_From_Sales_Publications_AggregateArgs = {
  distinct_on?: Maybe<Array<IFrom_Sales_Order_From_Sales_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFrom_Sales_Order_From_Sales_Publication_Order_By>>;
  where?: Maybe<IFrom_Sales_Order_From_Sales_Publication_Bool_Exp>;
};

/** aggregated selection of "from_sales_order" */
export type IFrom_Sales_Order_Aggregate = {
  __typename?: 'from_sales_order_aggregate';
  aggregate?: Maybe<IFrom_Sales_Order_Aggregate_Fields>;
  nodes: Array<IFrom_Sales_Order>;
};

/** aggregate fields of "from_sales_order" */
export type IFrom_Sales_Order_Aggregate_Fields = {
  __typename?: 'from_sales_order_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IFrom_Sales_Order_Max_Fields>;
  min?: Maybe<IFrom_Sales_Order_Min_Fields>;
};


/** aggregate fields of "from_sales_order" */
export type IFrom_Sales_Order_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IFrom_Sales_Order_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "from_sales_order" */
export type IFrom_Sales_Order_Aggregate_Order_By = {
  count?: Maybe<IOrder_By>;
  max?: Maybe<IFrom_Sales_Order_Max_Order_By>;
  min?: Maybe<IFrom_Sales_Order_Min_Order_By>;
};

/** input type for inserting array relation for remote table "from_sales_order" */
export type IFrom_Sales_Order_Arr_Rel_Insert_Input = {
  data: Array<IFrom_Sales_Order_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<IFrom_Sales_Order_On_Conflict>;
};

/** Boolean expression to filter rows from the table "from_sales_order". All fields are combined with a logical 'AND'. */
export type IFrom_Sales_Order_Bool_Exp = {
  _and?: Maybe<Array<IFrom_Sales_Order_Bool_Exp>>;
  _not?: Maybe<IFrom_Sales_Order_Bool_Exp>;
  _or?: Maybe<Array<IFrom_Sales_Order_Bool_Exp>>;
  billing_address?: Maybe<IString_Comparison_Exp>;
  billing_city?: Maybe<IString_Comparison_Exp>;
  billing_email?: Maybe<IString_Comparison_Exp>;
  billing_phone?: Maybe<IString_Comparison_Exp>;
  billing_state?: Maybe<IString_Comparison_Exp>;
  billing_zip?: Maybe<IString_Comparison_Exp>;
  contact_firstname?: Maybe<IString_Comparison_Exp>;
  contact_lastname?: Maybe<IString_Comparison_Exp>;
  created_at?: Maybe<ITimestamptz_Comparison_Exp>;
  email_sent_to_sales?: Maybe<IBoolean_Comparison_Exp>;
  from_sales_order_from_sales_publications?: Maybe<IFrom_Sales_Order_From_Sales_Publication_Bool_Exp>;
  have_accepted_terms_and_service?: Maybe<IBoolean_Comparison_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  obituary?: Maybe<IObituary_Bool_Exp>;
  obituary_id?: Maybe<IUuid_Comparison_Exp>;
  print_end?: Maybe<IDate_Comparison_Exp>;
  print_start?: Maybe<IDate_Comparison_Exp>;
  updated_at?: Maybe<ITimestamptz_Comparison_Exp>;
  verification_contact?: Maybe<IString_Comparison_Exp>;
  verification_phone?: Maybe<IString_Comparison_Exp>;
  verification_provider?: Maybe<IString_Comparison_Exp>;
};

/** unique or primary key constraints on table "from_sales_order" */
export enum IFrom_Sales_Order_Constraint {
  /** unique or primary key constraint on columns "id" */
  FromSalesOrderPkey = 'from_sales_order_pkey'
}

/** columns and relationships of "from_sales_order_from_sales_publication" */
export type IFrom_Sales_Order_From_Sales_Publication = {
  __typename?: 'from_sales_order_from_sales_publication';
  /** An object relationship */
  from_sales_order: IFrom_Sales_Order;
  /** An object relationship */
  from_sales_publication: IFrom_Sales_Publication;
  order_id: Scalars['uuid'];
  publication_id: Scalars['uuid'];
};

/** aggregated selection of "from_sales_order_from_sales_publication" */
export type IFrom_Sales_Order_From_Sales_Publication_Aggregate = {
  __typename?: 'from_sales_order_from_sales_publication_aggregate';
  aggregate?: Maybe<IFrom_Sales_Order_From_Sales_Publication_Aggregate_Fields>;
  nodes: Array<IFrom_Sales_Order_From_Sales_Publication>;
};

/** aggregate fields of "from_sales_order_from_sales_publication" */
export type IFrom_Sales_Order_From_Sales_Publication_Aggregate_Fields = {
  __typename?: 'from_sales_order_from_sales_publication_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IFrom_Sales_Order_From_Sales_Publication_Max_Fields>;
  min?: Maybe<IFrom_Sales_Order_From_Sales_Publication_Min_Fields>;
};


/** aggregate fields of "from_sales_order_from_sales_publication" */
export type IFrom_Sales_Order_From_Sales_Publication_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IFrom_Sales_Order_From_Sales_Publication_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "from_sales_order_from_sales_publication" */
export type IFrom_Sales_Order_From_Sales_Publication_Aggregate_Order_By = {
  count?: Maybe<IOrder_By>;
  max?: Maybe<IFrom_Sales_Order_From_Sales_Publication_Max_Order_By>;
  min?: Maybe<IFrom_Sales_Order_From_Sales_Publication_Min_Order_By>;
};

/** input type for inserting array relation for remote table "from_sales_order_from_sales_publication" */
export type IFrom_Sales_Order_From_Sales_Publication_Arr_Rel_Insert_Input = {
  data: Array<IFrom_Sales_Order_From_Sales_Publication_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<IFrom_Sales_Order_From_Sales_Publication_On_Conflict>;
};

/** Boolean expression to filter rows from the table "from_sales_order_from_sales_publication". All fields are combined with a logical 'AND'. */
export type IFrom_Sales_Order_From_Sales_Publication_Bool_Exp = {
  _and?: Maybe<Array<IFrom_Sales_Order_From_Sales_Publication_Bool_Exp>>;
  _not?: Maybe<IFrom_Sales_Order_From_Sales_Publication_Bool_Exp>;
  _or?: Maybe<Array<IFrom_Sales_Order_From_Sales_Publication_Bool_Exp>>;
  from_sales_order?: Maybe<IFrom_Sales_Order_Bool_Exp>;
  from_sales_publication?: Maybe<IFrom_Sales_Publication_Bool_Exp>;
  order_id?: Maybe<IUuid_Comparison_Exp>;
  publication_id?: Maybe<IUuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "from_sales_order_from_sales_publication" */
export enum IFrom_Sales_Order_From_Sales_Publication_Constraint {
  /** unique or primary key constraint on columns "order_id", "publication_id" */
  FromSalesOrderFromSalesPublicationPkey = 'from_sales_order_from_sales_publication_pkey'
}

/** input type for inserting data into table "from_sales_order_from_sales_publication" */
export type IFrom_Sales_Order_From_Sales_Publication_Insert_Input = {
  from_sales_order?: Maybe<IFrom_Sales_Order_Obj_Rel_Insert_Input>;
  from_sales_publication?: Maybe<IFrom_Sales_Publication_Obj_Rel_Insert_Input>;
  order_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type IFrom_Sales_Order_From_Sales_Publication_Max_Fields = {
  __typename?: 'from_sales_order_from_sales_publication_max_fields';
  order_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "from_sales_order_from_sales_publication" */
export type IFrom_Sales_Order_From_Sales_Publication_Max_Order_By = {
  order_id?: Maybe<IOrder_By>;
  publication_id?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IFrom_Sales_Order_From_Sales_Publication_Min_Fields = {
  __typename?: 'from_sales_order_from_sales_publication_min_fields';
  order_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "from_sales_order_from_sales_publication" */
export type IFrom_Sales_Order_From_Sales_Publication_Min_Order_By = {
  order_id?: Maybe<IOrder_By>;
  publication_id?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "from_sales_order_from_sales_publication" */
export type IFrom_Sales_Order_From_Sales_Publication_Mutation_Response = {
  __typename?: 'from_sales_order_from_sales_publication_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IFrom_Sales_Order_From_Sales_Publication>;
};

/** on_conflict condition type for table "from_sales_order_from_sales_publication" */
export type IFrom_Sales_Order_From_Sales_Publication_On_Conflict = {
  constraint: IFrom_Sales_Order_From_Sales_Publication_Constraint;
  update_columns?: Array<IFrom_Sales_Order_From_Sales_Publication_Update_Column>;
  where?: Maybe<IFrom_Sales_Order_From_Sales_Publication_Bool_Exp>;
};

/** Ordering options when selecting data from "from_sales_order_from_sales_publication". */
export type IFrom_Sales_Order_From_Sales_Publication_Order_By = {
  from_sales_order?: Maybe<IFrom_Sales_Order_Order_By>;
  from_sales_publication?: Maybe<IFrom_Sales_Publication_Order_By>;
  order_id?: Maybe<IOrder_By>;
  publication_id?: Maybe<IOrder_By>;
};

/** primary key columns input for table: from_sales_order_from_sales_publication */
export type IFrom_Sales_Order_From_Sales_Publication_Pk_Columns_Input = {
  order_id: Scalars['uuid'];
  publication_id: Scalars['uuid'];
};

/** select columns of table "from_sales_order_from_sales_publication" */
export enum IFrom_Sales_Order_From_Sales_Publication_Select_Column {
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PublicationId = 'publication_id'
}

/** input type for updating data in table "from_sales_order_from_sales_publication" */
export type IFrom_Sales_Order_From_Sales_Publication_Set_Input = {
  order_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "from_sales_order_from_sales_publication" */
export enum IFrom_Sales_Order_From_Sales_Publication_Update_Column {
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PublicationId = 'publication_id'
}

/** input type for inserting data into table "from_sales_order" */
export type IFrom_Sales_Order_Insert_Input = {
  billing_address?: Maybe<Scalars['String']>;
  billing_city?: Maybe<Scalars['String']>;
  billing_email?: Maybe<Scalars['String']>;
  billing_phone?: Maybe<Scalars['String']>;
  billing_state?: Maybe<Scalars['String']>;
  billing_zip?: Maybe<Scalars['String']>;
  contact_firstname?: Maybe<Scalars['String']>;
  contact_lastname?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_sent_to_sales?: Maybe<Scalars['Boolean']>;
  from_sales_order_from_sales_publications?: Maybe<IFrom_Sales_Order_From_Sales_Publication_Arr_Rel_Insert_Input>;
  have_accepted_terms_and_service?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  obituary?: Maybe<IObituary_Obj_Rel_Insert_Input>;
  obituary_id?: Maybe<Scalars['uuid']>;
  print_end?: Maybe<Scalars['date']>;
  print_start?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verification_contact?: Maybe<Scalars['String']>;
  verification_phone?: Maybe<Scalars['String']>;
  verification_provider?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type IFrom_Sales_Order_Max_Fields = {
  __typename?: 'from_sales_order_max_fields';
  billing_address?: Maybe<Scalars['String']>;
  billing_city?: Maybe<Scalars['String']>;
  billing_email?: Maybe<Scalars['String']>;
  billing_phone?: Maybe<Scalars['String']>;
  billing_state?: Maybe<Scalars['String']>;
  billing_zip?: Maybe<Scalars['String']>;
  contact_firstname?: Maybe<Scalars['String']>;
  contact_lastname?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  print_end?: Maybe<Scalars['date']>;
  print_start?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verification_contact?: Maybe<Scalars['String']>;
  verification_phone?: Maybe<Scalars['String']>;
  verification_provider?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "from_sales_order" */
export type IFrom_Sales_Order_Max_Order_By = {
  billing_address?: Maybe<IOrder_By>;
  billing_city?: Maybe<IOrder_By>;
  billing_email?: Maybe<IOrder_By>;
  billing_phone?: Maybe<IOrder_By>;
  billing_state?: Maybe<IOrder_By>;
  billing_zip?: Maybe<IOrder_By>;
  contact_firstname?: Maybe<IOrder_By>;
  contact_lastname?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  obituary_id?: Maybe<IOrder_By>;
  print_end?: Maybe<IOrder_By>;
  print_start?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  verification_contact?: Maybe<IOrder_By>;
  verification_phone?: Maybe<IOrder_By>;
  verification_provider?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IFrom_Sales_Order_Min_Fields = {
  __typename?: 'from_sales_order_min_fields';
  billing_address?: Maybe<Scalars['String']>;
  billing_city?: Maybe<Scalars['String']>;
  billing_email?: Maybe<Scalars['String']>;
  billing_phone?: Maybe<Scalars['String']>;
  billing_state?: Maybe<Scalars['String']>;
  billing_zip?: Maybe<Scalars['String']>;
  contact_firstname?: Maybe<Scalars['String']>;
  contact_lastname?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  print_end?: Maybe<Scalars['date']>;
  print_start?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verification_contact?: Maybe<Scalars['String']>;
  verification_phone?: Maybe<Scalars['String']>;
  verification_provider?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "from_sales_order" */
export type IFrom_Sales_Order_Min_Order_By = {
  billing_address?: Maybe<IOrder_By>;
  billing_city?: Maybe<IOrder_By>;
  billing_email?: Maybe<IOrder_By>;
  billing_phone?: Maybe<IOrder_By>;
  billing_state?: Maybe<IOrder_By>;
  billing_zip?: Maybe<IOrder_By>;
  contact_firstname?: Maybe<IOrder_By>;
  contact_lastname?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  obituary_id?: Maybe<IOrder_By>;
  print_end?: Maybe<IOrder_By>;
  print_start?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  verification_contact?: Maybe<IOrder_By>;
  verification_phone?: Maybe<IOrder_By>;
  verification_provider?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "from_sales_order" */
export type IFrom_Sales_Order_Mutation_Response = {
  __typename?: 'from_sales_order_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IFrom_Sales_Order>;
};

/** input type for inserting object relation for remote table "from_sales_order" */
export type IFrom_Sales_Order_Obj_Rel_Insert_Input = {
  data: IFrom_Sales_Order_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<IFrom_Sales_Order_On_Conflict>;
};

/** on_conflict condition type for table "from_sales_order" */
export type IFrom_Sales_Order_On_Conflict = {
  constraint: IFrom_Sales_Order_Constraint;
  update_columns?: Array<IFrom_Sales_Order_Update_Column>;
  where?: Maybe<IFrom_Sales_Order_Bool_Exp>;
};

/** Ordering options when selecting data from "from_sales_order". */
export type IFrom_Sales_Order_Order_By = {
  billing_address?: Maybe<IOrder_By>;
  billing_city?: Maybe<IOrder_By>;
  billing_email?: Maybe<IOrder_By>;
  billing_phone?: Maybe<IOrder_By>;
  billing_state?: Maybe<IOrder_By>;
  billing_zip?: Maybe<IOrder_By>;
  contact_firstname?: Maybe<IOrder_By>;
  contact_lastname?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  email_sent_to_sales?: Maybe<IOrder_By>;
  from_sales_order_from_sales_publications_aggregate?: Maybe<IFrom_Sales_Order_From_Sales_Publication_Aggregate_Order_By>;
  have_accepted_terms_and_service?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  obituary?: Maybe<IObituary_Order_By>;
  obituary_id?: Maybe<IOrder_By>;
  print_end?: Maybe<IOrder_By>;
  print_start?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  verification_contact?: Maybe<IOrder_By>;
  verification_phone?: Maybe<IOrder_By>;
  verification_provider?: Maybe<IOrder_By>;
};

/** primary key columns input for table: from_sales_order */
export type IFrom_Sales_Order_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "from_sales_order" */
export enum IFrom_Sales_Order_Select_Column {
  /** column name */
  BillingAddress = 'billing_address',
  /** column name */
  BillingCity = 'billing_city',
  /** column name */
  BillingEmail = 'billing_email',
  /** column name */
  BillingPhone = 'billing_phone',
  /** column name */
  BillingState = 'billing_state',
  /** column name */
  BillingZip = 'billing_zip',
  /** column name */
  ContactFirstname = 'contact_firstname',
  /** column name */
  ContactLastname = 'contact_lastname',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailSentToSales = 'email_sent_to_sales',
  /** column name */
  HaveAcceptedTermsAndService = 'have_accepted_terms_and_service',
  /** column name */
  Id = 'id',
  /** column name */
  ObituaryId = 'obituary_id',
  /** column name */
  PrintEnd = 'print_end',
  /** column name */
  PrintStart = 'print_start',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VerificationContact = 'verification_contact',
  /** column name */
  VerificationPhone = 'verification_phone',
  /** column name */
  VerificationProvider = 'verification_provider'
}

/** input type for updating data in table "from_sales_order" */
export type IFrom_Sales_Order_Set_Input = {
  billing_address?: Maybe<Scalars['String']>;
  billing_city?: Maybe<Scalars['String']>;
  billing_email?: Maybe<Scalars['String']>;
  billing_phone?: Maybe<Scalars['String']>;
  billing_state?: Maybe<Scalars['String']>;
  billing_zip?: Maybe<Scalars['String']>;
  contact_firstname?: Maybe<Scalars['String']>;
  contact_lastname?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_sent_to_sales?: Maybe<Scalars['Boolean']>;
  have_accepted_terms_and_service?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  print_end?: Maybe<Scalars['date']>;
  print_start?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verification_contact?: Maybe<Scalars['String']>;
  verification_phone?: Maybe<Scalars['String']>;
  verification_provider?: Maybe<Scalars['String']>;
};

/** update columns of table "from_sales_order" */
export enum IFrom_Sales_Order_Update_Column {
  /** column name */
  BillingAddress = 'billing_address',
  /** column name */
  BillingCity = 'billing_city',
  /** column name */
  BillingEmail = 'billing_email',
  /** column name */
  BillingPhone = 'billing_phone',
  /** column name */
  BillingState = 'billing_state',
  /** column name */
  BillingZip = 'billing_zip',
  /** column name */
  ContactFirstname = 'contact_firstname',
  /** column name */
  ContactLastname = 'contact_lastname',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailSentToSales = 'email_sent_to_sales',
  /** column name */
  HaveAcceptedTermsAndService = 'have_accepted_terms_and_service',
  /** column name */
  Id = 'id',
  /** column name */
  ObituaryId = 'obituary_id',
  /** column name */
  PrintEnd = 'print_end',
  /** column name */
  PrintStart = 'print_start',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VerificationContact = 'verification_contact',
  /** column name */
  VerificationPhone = 'verification_phone',
  /** column name */
  VerificationProvider = 'verification_provider'
}

/** columns and relationships of "from_sales_publication" */
export type IFrom_Sales_Publication = {
  __typename?: 'from_sales_publication';
  created_at: Scalars['date'];
  /** An array relationship */
  from_sales_order_from_sales_publications: Array<IFrom_Sales_Order_From_Sales_Publication>;
  /** An aggregate relationship */
  from_sales_order_from_sales_publications_aggregate: IFrom_Sales_Order_From_Sales_Publication_Aggregate;
  id: Scalars['uuid'];
  name: Scalars['String'];
  site_code: Scalars['String'];
  updated_at: Scalars['date'];
};


/** columns and relationships of "from_sales_publication" */
export type IFrom_Sales_PublicationFrom_Sales_Order_From_Sales_PublicationsArgs = {
  distinct_on?: Maybe<Array<IFrom_Sales_Order_From_Sales_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFrom_Sales_Order_From_Sales_Publication_Order_By>>;
  where?: Maybe<IFrom_Sales_Order_From_Sales_Publication_Bool_Exp>;
};


/** columns and relationships of "from_sales_publication" */
export type IFrom_Sales_PublicationFrom_Sales_Order_From_Sales_Publications_AggregateArgs = {
  distinct_on?: Maybe<Array<IFrom_Sales_Order_From_Sales_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFrom_Sales_Order_From_Sales_Publication_Order_By>>;
  where?: Maybe<IFrom_Sales_Order_From_Sales_Publication_Bool_Exp>;
};

/** aggregated selection of "from_sales_publication" */
export type IFrom_Sales_Publication_Aggregate = {
  __typename?: 'from_sales_publication_aggregate';
  aggregate?: Maybe<IFrom_Sales_Publication_Aggregate_Fields>;
  nodes: Array<IFrom_Sales_Publication>;
};

/** aggregate fields of "from_sales_publication" */
export type IFrom_Sales_Publication_Aggregate_Fields = {
  __typename?: 'from_sales_publication_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IFrom_Sales_Publication_Max_Fields>;
  min?: Maybe<IFrom_Sales_Publication_Min_Fields>;
};


/** aggregate fields of "from_sales_publication" */
export type IFrom_Sales_Publication_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IFrom_Sales_Publication_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "from_sales_publication". All fields are combined with a logical 'AND'. */
export type IFrom_Sales_Publication_Bool_Exp = {
  _and?: Maybe<Array<IFrom_Sales_Publication_Bool_Exp>>;
  _not?: Maybe<IFrom_Sales_Publication_Bool_Exp>;
  _or?: Maybe<Array<IFrom_Sales_Publication_Bool_Exp>>;
  created_at?: Maybe<IDate_Comparison_Exp>;
  from_sales_order_from_sales_publications?: Maybe<IFrom_Sales_Order_From_Sales_Publication_Bool_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  name?: Maybe<IString_Comparison_Exp>;
  site_code?: Maybe<IString_Comparison_Exp>;
  updated_at?: Maybe<IDate_Comparison_Exp>;
};

/** unique or primary key constraints on table "from_sales_publication" */
export enum IFrom_Sales_Publication_Constraint {
  /** unique or primary key constraint on columns "id" */
  FromSalesPublicationPkey = 'from_sales_publication_pkey'
}

/** input type for inserting data into table "from_sales_publication" */
export type IFrom_Sales_Publication_Insert_Input = {
  created_at?: Maybe<Scalars['date']>;
  from_sales_order_from_sales_publications?: Maybe<IFrom_Sales_Order_From_Sales_Publication_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  site_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['date']>;
};

/** aggregate max on columns */
export type IFrom_Sales_Publication_Max_Fields = {
  __typename?: 'from_sales_publication_max_fields';
  created_at?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  site_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['date']>;
};

/** aggregate min on columns */
export type IFrom_Sales_Publication_Min_Fields = {
  __typename?: 'from_sales_publication_min_fields';
  created_at?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  site_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['date']>;
};

/** response of any mutation on the table "from_sales_publication" */
export type IFrom_Sales_Publication_Mutation_Response = {
  __typename?: 'from_sales_publication_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IFrom_Sales_Publication>;
};

/** input type for inserting object relation for remote table "from_sales_publication" */
export type IFrom_Sales_Publication_Obj_Rel_Insert_Input = {
  data: IFrom_Sales_Publication_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<IFrom_Sales_Publication_On_Conflict>;
};

/** on_conflict condition type for table "from_sales_publication" */
export type IFrom_Sales_Publication_On_Conflict = {
  constraint: IFrom_Sales_Publication_Constraint;
  update_columns?: Array<IFrom_Sales_Publication_Update_Column>;
  where?: Maybe<IFrom_Sales_Publication_Bool_Exp>;
};

/** Ordering options when selecting data from "from_sales_publication". */
export type IFrom_Sales_Publication_Order_By = {
  created_at?: Maybe<IOrder_By>;
  from_sales_order_from_sales_publications_aggregate?: Maybe<IFrom_Sales_Order_From_Sales_Publication_Aggregate_Order_By>;
  id?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  site_code?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** primary key columns input for table: from_sales_publication */
export type IFrom_Sales_Publication_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "from_sales_publication" */
export enum IFrom_Sales_Publication_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SiteCode = 'site_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "from_sales_publication" */
export type IFrom_Sales_Publication_Set_Input = {
  created_at?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  site_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['date']>;
};

/** update columns of table "from_sales_publication" */
export enum IFrom_Sales_Publication_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SiteCode = 'site_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "funeral_home" */
export type IFuneral_Home = {
  __typename?: 'funeral_home';
  created_at?: Maybe<Scalars['timestamp']>;
  external_location_id: Scalars['bigint'];
  /** An array relationship */
  funeral_home_external_ids: Array<IFuneral_Home_External_Id>;
  /** An aggregate relationship */
  funeral_home_external_ids_aggregate: IFuneral_Home_External_Id_Aggregate;
  /** An array relationship */
  funeral_home_external_user_ids: Array<IFuneral_Home_External_User_Id>;
  /** An aggregate relationship */
  funeral_home_external_user_ids_aggregate: IFuneral_Home_External_User_Id_Aggregate;
  id: Scalars['uuid'];
  name: Scalars['String'];
  status?: Maybe<Scalars['funeral_home_status']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};


/** columns and relationships of "funeral_home" */
export type IFuneral_HomeFuneral_Home_External_IdsArgs = {
  distinct_on?: Maybe<Array<IFuneral_Home_External_Id_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFuneral_Home_External_Id_Order_By>>;
  where?: Maybe<IFuneral_Home_External_Id_Bool_Exp>;
};


/** columns and relationships of "funeral_home" */
export type IFuneral_HomeFuneral_Home_External_Ids_AggregateArgs = {
  distinct_on?: Maybe<Array<IFuneral_Home_External_Id_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFuneral_Home_External_Id_Order_By>>;
  where?: Maybe<IFuneral_Home_External_Id_Bool_Exp>;
};


/** columns and relationships of "funeral_home" */
export type IFuneral_HomeFuneral_Home_External_User_IdsArgs = {
  distinct_on?: Maybe<Array<IFuneral_Home_External_User_Id_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFuneral_Home_External_User_Id_Order_By>>;
  where?: Maybe<IFuneral_Home_External_User_Id_Bool_Exp>;
};


/** columns and relationships of "funeral_home" */
export type IFuneral_HomeFuneral_Home_External_User_Ids_AggregateArgs = {
  distinct_on?: Maybe<Array<IFuneral_Home_External_User_Id_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFuneral_Home_External_User_Id_Order_By>>;
  where?: Maybe<IFuneral_Home_External_User_Id_Bool_Exp>;
};

/** aggregated selection of "funeral_home" */
export type IFuneral_Home_Aggregate = {
  __typename?: 'funeral_home_aggregate';
  aggregate?: Maybe<IFuneral_Home_Aggregate_Fields>;
  nodes: Array<IFuneral_Home>;
};

/** aggregate fields of "funeral_home" */
export type IFuneral_Home_Aggregate_Fields = {
  __typename?: 'funeral_home_aggregate_fields';
  avg?: Maybe<IFuneral_Home_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IFuneral_Home_Max_Fields>;
  min?: Maybe<IFuneral_Home_Min_Fields>;
  stddev?: Maybe<IFuneral_Home_Stddev_Fields>;
  stddev_pop?: Maybe<IFuneral_Home_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IFuneral_Home_Stddev_Samp_Fields>;
  sum?: Maybe<IFuneral_Home_Sum_Fields>;
  var_pop?: Maybe<IFuneral_Home_Var_Pop_Fields>;
  var_samp?: Maybe<IFuneral_Home_Var_Samp_Fields>;
  variance?: Maybe<IFuneral_Home_Variance_Fields>;
};


/** aggregate fields of "funeral_home" */
export type IFuneral_Home_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IFuneral_Home_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type IFuneral_Home_Avg_Fields = {
  __typename?: 'funeral_home_avg_fields';
  external_location_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "funeral_home". All fields are combined with a logical 'AND'. */
export type IFuneral_Home_Bool_Exp = {
  _and?: Maybe<Array<IFuneral_Home_Bool_Exp>>;
  _not?: Maybe<IFuneral_Home_Bool_Exp>;
  _or?: Maybe<Array<IFuneral_Home_Bool_Exp>>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  external_location_id?: Maybe<IBigint_Comparison_Exp>;
  funeral_home_external_ids?: Maybe<IFuneral_Home_External_Id_Bool_Exp>;
  funeral_home_external_user_ids?: Maybe<IFuneral_Home_External_User_Id_Bool_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  name?: Maybe<IString_Comparison_Exp>;
  status?: Maybe<IFuneral_Home_Status_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "funeral_home" */
export enum IFuneral_Home_Constraint {
  /** unique or primary key constraint on columns "external_location_id" */
  FuneralHomeExternalLocationIdKey = 'funeral_home_external_location_id_key',
  /** unique or primary key constraint on columns "id" */
  FuneralHomePkey = 'funeral_home_pkey'
}

/** columns and relationships of "funeral_home_external_id" */
export type IFuneral_Home_External_Id = {
  __typename?: 'funeral_home_external_id';
  created_at: Scalars['timestamp'];
  external_id: Scalars['String'];
  funeral_home_id: Scalars['uuid'];
  id: Scalars['uuid'];
  partial: Scalars['String'];
  partial_priority: Scalars['Int'];
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "funeral_home_external_id" */
export type IFuneral_Home_External_Id_Aggregate = {
  __typename?: 'funeral_home_external_id_aggregate';
  aggregate?: Maybe<IFuneral_Home_External_Id_Aggregate_Fields>;
  nodes: Array<IFuneral_Home_External_Id>;
};

/** aggregate fields of "funeral_home_external_id" */
export type IFuneral_Home_External_Id_Aggregate_Fields = {
  __typename?: 'funeral_home_external_id_aggregate_fields';
  avg?: Maybe<IFuneral_Home_External_Id_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IFuneral_Home_External_Id_Max_Fields>;
  min?: Maybe<IFuneral_Home_External_Id_Min_Fields>;
  stddev?: Maybe<IFuneral_Home_External_Id_Stddev_Fields>;
  stddev_pop?: Maybe<IFuneral_Home_External_Id_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IFuneral_Home_External_Id_Stddev_Samp_Fields>;
  sum?: Maybe<IFuneral_Home_External_Id_Sum_Fields>;
  var_pop?: Maybe<IFuneral_Home_External_Id_Var_Pop_Fields>;
  var_samp?: Maybe<IFuneral_Home_External_Id_Var_Samp_Fields>;
  variance?: Maybe<IFuneral_Home_External_Id_Variance_Fields>;
};


/** aggregate fields of "funeral_home_external_id" */
export type IFuneral_Home_External_Id_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IFuneral_Home_External_Id_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "funeral_home_external_id" */
export type IFuneral_Home_External_Id_Aggregate_Order_By = {
  avg?: Maybe<IFuneral_Home_External_Id_Avg_Order_By>;
  count?: Maybe<IOrder_By>;
  max?: Maybe<IFuneral_Home_External_Id_Max_Order_By>;
  min?: Maybe<IFuneral_Home_External_Id_Min_Order_By>;
  stddev?: Maybe<IFuneral_Home_External_Id_Stddev_Order_By>;
  stddev_pop?: Maybe<IFuneral_Home_External_Id_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IFuneral_Home_External_Id_Stddev_Samp_Order_By>;
  sum?: Maybe<IFuneral_Home_External_Id_Sum_Order_By>;
  var_pop?: Maybe<IFuneral_Home_External_Id_Var_Pop_Order_By>;
  var_samp?: Maybe<IFuneral_Home_External_Id_Var_Samp_Order_By>;
  variance?: Maybe<IFuneral_Home_External_Id_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "funeral_home_external_id" */
export type IFuneral_Home_External_Id_Arr_Rel_Insert_Input = {
  data: Array<IFuneral_Home_External_Id_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<IFuneral_Home_External_Id_On_Conflict>;
};

/** aggregate avg on columns */
export type IFuneral_Home_External_Id_Avg_Fields = {
  __typename?: 'funeral_home_external_id_avg_fields';
  partial_priority?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "funeral_home_external_id" */
export type IFuneral_Home_External_Id_Avg_Order_By = {
  partial_priority?: Maybe<IOrder_By>;
};

/** Boolean expression to filter rows from the table "funeral_home_external_id". All fields are combined with a logical 'AND'. */
export type IFuneral_Home_External_Id_Bool_Exp = {
  _and?: Maybe<Array<IFuneral_Home_External_Id_Bool_Exp>>;
  _not?: Maybe<IFuneral_Home_External_Id_Bool_Exp>;
  _or?: Maybe<Array<IFuneral_Home_External_Id_Bool_Exp>>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  external_id?: Maybe<IString_Comparison_Exp>;
  funeral_home_id?: Maybe<IUuid_Comparison_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  partial?: Maybe<IString_Comparison_Exp>;
  partial_priority?: Maybe<IInt_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "funeral_home_external_id" */
export enum IFuneral_Home_External_Id_Constraint {
  /** unique or primary key constraint on columns "id" */
  FuneralHomeExternalIdPkey = 'funeral_home_external_id_pkey'
}

/** input type for incrementing numeric columns in table "funeral_home_external_id" */
export type IFuneral_Home_External_Id_Inc_Input = {
  partial_priority?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "funeral_home_external_id" */
export type IFuneral_Home_External_Id_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  external_id?: Maybe<Scalars['String']>;
  funeral_home_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  partial?: Maybe<Scalars['String']>;
  partial_priority?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type IFuneral_Home_External_Id_Max_Fields = {
  __typename?: 'funeral_home_external_id_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  external_id?: Maybe<Scalars['String']>;
  funeral_home_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  partial?: Maybe<Scalars['String']>;
  partial_priority?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "funeral_home_external_id" */
export type IFuneral_Home_External_Id_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  external_id?: Maybe<IOrder_By>;
  funeral_home_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  partial?: Maybe<IOrder_By>;
  partial_priority?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IFuneral_Home_External_Id_Min_Fields = {
  __typename?: 'funeral_home_external_id_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  external_id?: Maybe<Scalars['String']>;
  funeral_home_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  partial?: Maybe<Scalars['String']>;
  partial_priority?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "funeral_home_external_id" */
export type IFuneral_Home_External_Id_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  external_id?: Maybe<IOrder_By>;
  funeral_home_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  partial?: Maybe<IOrder_By>;
  partial_priority?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "funeral_home_external_id" */
export type IFuneral_Home_External_Id_Mutation_Response = {
  __typename?: 'funeral_home_external_id_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IFuneral_Home_External_Id>;
};

/** on_conflict condition type for table "funeral_home_external_id" */
export type IFuneral_Home_External_Id_On_Conflict = {
  constraint: IFuneral_Home_External_Id_Constraint;
  update_columns?: Array<IFuneral_Home_External_Id_Update_Column>;
  where?: Maybe<IFuneral_Home_External_Id_Bool_Exp>;
};

/** Ordering options when selecting data from "funeral_home_external_id". */
export type IFuneral_Home_External_Id_Order_By = {
  created_at?: Maybe<IOrder_By>;
  external_id?: Maybe<IOrder_By>;
  funeral_home_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  partial?: Maybe<IOrder_By>;
  partial_priority?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** primary key columns input for table: funeral_home_external_id */
export type IFuneral_Home_External_Id_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "funeral_home_external_id" */
export enum IFuneral_Home_External_Id_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  FuneralHomeId = 'funeral_home_id',
  /** column name */
  Id = 'id',
  /** column name */
  Partial = 'partial',
  /** column name */
  PartialPriority = 'partial_priority',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "funeral_home_external_id" */
export type IFuneral_Home_External_Id_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  external_id?: Maybe<Scalars['String']>;
  funeral_home_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  partial?: Maybe<Scalars['String']>;
  partial_priority?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type IFuneral_Home_External_Id_Stddev_Fields = {
  __typename?: 'funeral_home_external_id_stddev_fields';
  partial_priority?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "funeral_home_external_id" */
export type IFuneral_Home_External_Id_Stddev_Order_By = {
  partial_priority?: Maybe<IOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IFuneral_Home_External_Id_Stddev_Pop_Fields = {
  __typename?: 'funeral_home_external_id_stddev_pop_fields';
  partial_priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "funeral_home_external_id" */
export type IFuneral_Home_External_Id_Stddev_Pop_Order_By = {
  partial_priority?: Maybe<IOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IFuneral_Home_External_Id_Stddev_Samp_Fields = {
  __typename?: 'funeral_home_external_id_stddev_samp_fields';
  partial_priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "funeral_home_external_id" */
export type IFuneral_Home_External_Id_Stddev_Samp_Order_By = {
  partial_priority?: Maybe<IOrder_By>;
};

/** aggregate sum on columns */
export type IFuneral_Home_External_Id_Sum_Fields = {
  __typename?: 'funeral_home_external_id_sum_fields';
  partial_priority?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "funeral_home_external_id" */
export type IFuneral_Home_External_Id_Sum_Order_By = {
  partial_priority?: Maybe<IOrder_By>;
};

/** update columns of table "funeral_home_external_id" */
export enum IFuneral_Home_External_Id_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  FuneralHomeId = 'funeral_home_id',
  /** column name */
  Id = 'id',
  /** column name */
  Partial = 'partial',
  /** column name */
  PartialPriority = 'partial_priority',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type IFuneral_Home_External_Id_Var_Pop_Fields = {
  __typename?: 'funeral_home_external_id_var_pop_fields';
  partial_priority?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "funeral_home_external_id" */
export type IFuneral_Home_External_Id_Var_Pop_Order_By = {
  partial_priority?: Maybe<IOrder_By>;
};

/** aggregate var_samp on columns */
export type IFuneral_Home_External_Id_Var_Samp_Fields = {
  __typename?: 'funeral_home_external_id_var_samp_fields';
  partial_priority?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "funeral_home_external_id" */
export type IFuneral_Home_External_Id_Var_Samp_Order_By = {
  partial_priority?: Maybe<IOrder_By>;
};

/** aggregate variance on columns */
export type IFuneral_Home_External_Id_Variance_Fields = {
  __typename?: 'funeral_home_external_id_variance_fields';
  partial_priority?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "funeral_home_external_id" */
export type IFuneral_Home_External_Id_Variance_Order_By = {
  partial_priority?: Maybe<IOrder_By>;
};

/** columns and relationships of "funeral_home_external_user_id" */
export type IFuneral_Home_External_User_Id = {
  __typename?: 'funeral_home_external_user_id';
  created_at?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  funeral_home: IFuneral_Home;
  funeral_home_id: Scalars['uuid'];
  id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id: Scalars['String'];
};

/** aggregated selection of "funeral_home_external_user_id" */
export type IFuneral_Home_External_User_Id_Aggregate = {
  __typename?: 'funeral_home_external_user_id_aggregate';
  aggregate?: Maybe<IFuneral_Home_External_User_Id_Aggregate_Fields>;
  nodes: Array<IFuneral_Home_External_User_Id>;
};

/** aggregate fields of "funeral_home_external_user_id" */
export type IFuneral_Home_External_User_Id_Aggregate_Fields = {
  __typename?: 'funeral_home_external_user_id_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IFuneral_Home_External_User_Id_Max_Fields>;
  min?: Maybe<IFuneral_Home_External_User_Id_Min_Fields>;
};


/** aggregate fields of "funeral_home_external_user_id" */
export type IFuneral_Home_External_User_Id_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IFuneral_Home_External_User_Id_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "funeral_home_external_user_id" */
export type IFuneral_Home_External_User_Id_Aggregate_Order_By = {
  count?: Maybe<IOrder_By>;
  max?: Maybe<IFuneral_Home_External_User_Id_Max_Order_By>;
  min?: Maybe<IFuneral_Home_External_User_Id_Min_Order_By>;
};

/** input type for inserting array relation for remote table "funeral_home_external_user_id" */
export type IFuneral_Home_External_User_Id_Arr_Rel_Insert_Input = {
  data: Array<IFuneral_Home_External_User_Id_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<IFuneral_Home_External_User_Id_On_Conflict>;
};

/** Boolean expression to filter rows from the table "funeral_home_external_user_id". All fields are combined with a logical 'AND'. */
export type IFuneral_Home_External_User_Id_Bool_Exp = {
  _and?: Maybe<Array<IFuneral_Home_External_User_Id_Bool_Exp>>;
  _not?: Maybe<IFuneral_Home_External_User_Id_Bool_Exp>;
  _or?: Maybe<Array<IFuneral_Home_External_User_Id_Bool_Exp>>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  funeral_home?: Maybe<IFuneral_Home_Bool_Exp>;
  funeral_home_id?: Maybe<IUuid_Comparison_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
  user_id?: Maybe<IString_Comparison_Exp>;
};

/** unique or primary key constraints on table "funeral_home_external_user_id" */
export enum IFuneral_Home_External_User_Id_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  FuneralHomeExternalUserIdUserIdKey = 'funeral_home_external_user_id_user_id_key',
  /** unique or primary key constraint on columns "id" */
  FuneralHomeImportUserIdPkey = 'funeral_home_import_user_id_pkey'
}

/** input type for inserting data into table "funeral_home_external_user_id" */
export type IFuneral_Home_External_User_Id_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  funeral_home?: Maybe<IFuneral_Home_Obj_Rel_Insert_Input>;
  funeral_home_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type IFuneral_Home_External_User_Id_Max_Fields = {
  __typename?: 'funeral_home_external_user_id_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  funeral_home_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "funeral_home_external_user_id" */
export type IFuneral_Home_External_User_Id_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  funeral_home_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  user_id?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IFuneral_Home_External_User_Id_Min_Fields = {
  __typename?: 'funeral_home_external_user_id_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  funeral_home_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "funeral_home_external_user_id" */
export type IFuneral_Home_External_User_Id_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  funeral_home_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  user_id?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "funeral_home_external_user_id" */
export type IFuneral_Home_External_User_Id_Mutation_Response = {
  __typename?: 'funeral_home_external_user_id_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IFuneral_Home_External_User_Id>;
};

/** on_conflict condition type for table "funeral_home_external_user_id" */
export type IFuneral_Home_External_User_Id_On_Conflict = {
  constraint: IFuneral_Home_External_User_Id_Constraint;
  update_columns?: Array<IFuneral_Home_External_User_Id_Update_Column>;
  where?: Maybe<IFuneral_Home_External_User_Id_Bool_Exp>;
};

/** Ordering options when selecting data from "funeral_home_external_user_id". */
export type IFuneral_Home_External_User_Id_Order_By = {
  created_at?: Maybe<IOrder_By>;
  funeral_home?: Maybe<IFuneral_Home_Order_By>;
  funeral_home_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  user_id?: Maybe<IOrder_By>;
};

/** primary key columns input for table: funeral_home_external_user_id */
export type IFuneral_Home_External_User_Id_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "funeral_home_external_user_id" */
export enum IFuneral_Home_External_User_Id_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FuneralHomeId = 'funeral_home_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "funeral_home_external_user_id" */
export type IFuneral_Home_External_User_Id_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  funeral_home_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['String']>;
};

/** update columns of table "funeral_home_external_user_id" */
export enum IFuneral_Home_External_User_Id_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FuneralHomeId = 'funeral_home_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for incrementing numeric columns in table "funeral_home" */
export type IFuneral_Home_Inc_Input = {
  external_location_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "funeral_home" */
export type IFuneral_Home_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  external_location_id?: Maybe<Scalars['bigint']>;
  funeral_home_external_ids?: Maybe<IFuneral_Home_External_Id_Arr_Rel_Insert_Input>;
  funeral_home_external_user_ids?: Maybe<IFuneral_Home_External_User_Id_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['funeral_home_status']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type IFuneral_Home_Max_Fields = {
  __typename?: 'funeral_home_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  external_location_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['funeral_home_status']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type IFuneral_Home_Min_Fields = {
  __typename?: 'funeral_home_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  external_location_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['funeral_home_status']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "funeral_home" */
export type IFuneral_Home_Mutation_Response = {
  __typename?: 'funeral_home_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IFuneral_Home>;
};

/** input type for inserting object relation for remote table "funeral_home" */
export type IFuneral_Home_Obj_Rel_Insert_Input = {
  data: IFuneral_Home_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<IFuneral_Home_On_Conflict>;
};

/** on_conflict condition type for table "funeral_home" */
export type IFuneral_Home_On_Conflict = {
  constraint: IFuneral_Home_Constraint;
  update_columns?: Array<IFuneral_Home_Update_Column>;
  where?: Maybe<IFuneral_Home_Bool_Exp>;
};

/** Ordering options when selecting data from "funeral_home". */
export type IFuneral_Home_Order_By = {
  created_at?: Maybe<IOrder_By>;
  external_location_id?: Maybe<IOrder_By>;
  funeral_home_external_ids_aggregate?: Maybe<IFuneral_Home_External_Id_Aggregate_Order_By>;
  funeral_home_external_user_ids_aggregate?: Maybe<IFuneral_Home_External_User_Id_Aggregate_Order_By>;
  id?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  status?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** primary key columns input for table: funeral_home */
export type IFuneral_Home_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "funeral_home" */
export enum IFuneral_Home_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalLocationId = 'external_location_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "funeral_home" */
export type IFuneral_Home_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  external_location_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['funeral_home_status']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};


/** Boolean expression to compare columns of type "funeral_home_status". All fields are combined with logical 'AND'. */
export type IFuneral_Home_Status_Comparison_Exp = {
  _eq?: Maybe<Scalars['funeral_home_status']>;
  _gt?: Maybe<Scalars['funeral_home_status']>;
  _gte?: Maybe<Scalars['funeral_home_status']>;
  _in?: Maybe<Array<Scalars['funeral_home_status']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['funeral_home_status']>;
  _lte?: Maybe<Scalars['funeral_home_status']>;
  _neq?: Maybe<Scalars['funeral_home_status']>;
  _nin?: Maybe<Array<Scalars['funeral_home_status']>>;
};

/** aggregate stddev on columns */
export type IFuneral_Home_Stddev_Fields = {
  __typename?: 'funeral_home_stddev_fields';
  external_location_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type IFuneral_Home_Stddev_Pop_Fields = {
  __typename?: 'funeral_home_stddev_pop_fields';
  external_location_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type IFuneral_Home_Stddev_Samp_Fields = {
  __typename?: 'funeral_home_stddev_samp_fields';
  external_location_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type IFuneral_Home_Sum_Fields = {
  __typename?: 'funeral_home_sum_fields';
  external_location_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "funeral_home" */
export enum IFuneral_Home_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalLocationId = 'external_location_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type IFuneral_Home_Var_Pop_Fields = {
  __typename?: 'funeral_home_var_pop_fields';
  external_location_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type IFuneral_Home_Var_Samp_Fields = {
  __typename?: 'funeral_home_var_samp_fields';
  external_location_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type IFuneral_Home_Variance_Fields = {
  __typename?: 'funeral_home_variance_fields';
  external_location_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "historical_obituary" */
export type IHistorical_Obituary = {
  __typename?: 'historical_obituary';
  age?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamp'];
  date_born?: Maybe<Scalars['String']>;
  date_deceased?: Maybe<Scalars['String']>;
  external_created_at: Scalars['timestamp'];
  external_id: Scalars['String'];
  external_original: Scalars['String'];
  external_photos?: Maybe<Scalars['_text']>;
  external_published_at: Scalars['timestamp'];
  external_ran_from?: Maybe<Scalars['timestamp']>;
  external_ran_to?: Maybe<Scalars['timestamp']>;
  external_updated_at: Scalars['timestamp'];
  external_url: Scalars['String'];
  first_name: Scalars['String'];
  /** An object relationship */
  funeralHome?: Maybe<IFuneral_Home>;
  funeral_home?: Maybe<Scalars['String']>;
  funeral_home_id?: Maybe<Scalars['uuid']>;
  gannett_edit: Scalars['Boolean'];
  /** An array relationship */
  historical_obituary_events: Array<IHistorical_Obituary_Event>;
  /** An aggregate relationship */
  historical_obituary_events_aggregate: IHistorical_Obituary_Event_Aggregate;
  /** An array relationship */
  historical_obituary_publications: Array<IHistorical_Obituary_Publication>;
  /** An aggregate relationship */
  historical_obituary_publications_aggregate: IHistorical_Obituary_Publication_Aggregate;
  /** An array relationship */
  historical_obituary_tags: Array<IHistorical_Obituary_Tag>;
  /** An aggregate relationship */
  historical_obituary_tags_aggregate: IHistorical_Obituary_Tag_Aggregate;
  id: Scalars['uuid'];
  image_paths: Scalars['_text'];
  import_external_id: Scalars['_text'];
  import_external_user_id: Scalars['_text'];
  last_name: Scalars['String'];
  logos: Scalars['_text'];
  middle_name: Scalars['String'];
  /** An object relationship */
  obituary: IObituary;
  obituary_id: Scalars['uuid'];
  person: Scalars['String'];
  publish_status?: Maybe<Scalars['obit_publish_status']>;
  slug: Scalars['String'];
  source: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  subtitle: Scalars['String'];
  title: Scalars['String'];
  updated_at: Scalars['timestamp'];
};


/** columns and relationships of "historical_obituary" */
export type IHistorical_ObituaryHistorical_Obituary_EventsArgs = {
  distinct_on?: Maybe<Array<IHistorical_Obituary_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHistorical_Obituary_Event_Order_By>>;
  where?: Maybe<IHistorical_Obituary_Event_Bool_Exp>;
};


/** columns and relationships of "historical_obituary" */
export type IHistorical_ObituaryHistorical_Obituary_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<IHistorical_Obituary_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHistorical_Obituary_Event_Order_By>>;
  where?: Maybe<IHistorical_Obituary_Event_Bool_Exp>;
};


/** columns and relationships of "historical_obituary" */
export type IHistorical_ObituaryHistorical_Obituary_PublicationsArgs = {
  distinct_on?: Maybe<Array<IHistorical_Obituary_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHistorical_Obituary_Publication_Order_By>>;
  where?: Maybe<IHistorical_Obituary_Publication_Bool_Exp>;
};


/** columns and relationships of "historical_obituary" */
export type IHistorical_ObituaryHistorical_Obituary_Publications_AggregateArgs = {
  distinct_on?: Maybe<Array<IHistorical_Obituary_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHistorical_Obituary_Publication_Order_By>>;
  where?: Maybe<IHistorical_Obituary_Publication_Bool_Exp>;
};


/** columns and relationships of "historical_obituary" */
export type IHistorical_ObituaryHistorical_Obituary_TagsArgs = {
  distinct_on?: Maybe<Array<IHistorical_Obituary_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHistorical_Obituary_Tag_Order_By>>;
  where?: Maybe<IHistorical_Obituary_Tag_Bool_Exp>;
};


/** columns and relationships of "historical_obituary" */
export type IHistorical_ObituaryHistorical_Obituary_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<IHistorical_Obituary_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHistorical_Obituary_Tag_Order_By>>;
  where?: Maybe<IHistorical_Obituary_Tag_Bool_Exp>;
};

/** aggregated selection of "historical_obituary" */
export type IHistorical_Obituary_Aggregate = {
  __typename?: 'historical_obituary_aggregate';
  aggregate?: Maybe<IHistorical_Obituary_Aggregate_Fields>;
  nodes: Array<IHistorical_Obituary>;
};

/** aggregate fields of "historical_obituary" */
export type IHistorical_Obituary_Aggregate_Fields = {
  __typename?: 'historical_obituary_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IHistorical_Obituary_Max_Fields>;
  min?: Maybe<IHistorical_Obituary_Min_Fields>;
};


/** aggregate fields of "historical_obituary" */
export type IHistorical_Obituary_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IHistorical_Obituary_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "historical_obituary". All fields are combined with a logical 'AND'. */
export type IHistorical_Obituary_Bool_Exp = {
  _and?: Maybe<Array<IHistorical_Obituary_Bool_Exp>>;
  _not?: Maybe<IHistorical_Obituary_Bool_Exp>;
  _or?: Maybe<Array<IHistorical_Obituary_Bool_Exp>>;
  age?: Maybe<IString_Comparison_Exp>;
  body?: Maybe<IString_Comparison_Exp>;
  city?: Maybe<IString_Comparison_Exp>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  date_born?: Maybe<IString_Comparison_Exp>;
  date_deceased?: Maybe<IString_Comparison_Exp>;
  external_created_at?: Maybe<ITimestamp_Comparison_Exp>;
  external_id?: Maybe<IString_Comparison_Exp>;
  external_original?: Maybe<IString_Comparison_Exp>;
  external_photos?: Maybe<I_Text_Comparison_Exp>;
  external_published_at?: Maybe<ITimestamp_Comparison_Exp>;
  external_ran_from?: Maybe<ITimestamp_Comparison_Exp>;
  external_ran_to?: Maybe<ITimestamp_Comparison_Exp>;
  external_updated_at?: Maybe<ITimestamp_Comparison_Exp>;
  external_url?: Maybe<IString_Comparison_Exp>;
  first_name?: Maybe<IString_Comparison_Exp>;
  funeralHome?: Maybe<IFuneral_Home_Bool_Exp>;
  funeral_home?: Maybe<IString_Comparison_Exp>;
  funeral_home_id?: Maybe<IUuid_Comparison_Exp>;
  gannett_edit?: Maybe<IBoolean_Comparison_Exp>;
  historical_obituary_events?: Maybe<IHistorical_Obituary_Event_Bool_Exp>;
  historical_obituary_publications?: Maybe<IHistorical_Obituary_Publication_Bool_Exp>;
  historical_obituary_tags?: Maybe<IHistorical_Obituary_Tag_Bool_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  image_paths?: Maybe<I_Text_Comparison_Exp>;
  import_external_id?: Maybe<I_Text_Comparison_Exp>;
  import_external_user_id?: Maybe<I_Text_Comparison_Exp>;
  last_name?: Maybe<IString_Comparison_Exp>;
  logos?: Maybe<I_Text_Comparison_Exp>;
  middle_name?: Maybe<IString_Comparison_Exp>;
  obituary?: Maybe<IObituary_Bool_Exp>;
  obituary_id?: Maybe<IUuid_Comparison_Exp>;
  person?: Maybe<IString_Comparison_Exp>;
  publish_status?: Maybe<IObit_Publish_Status_Comparison_Exp>;
  slug?: Maybe<IString_Comparison_Exp>;
  source?: Maybe<IString_Comparison_Exp>;
  state?: Maybe<IString_Comparison_Exp>;
  subtitle?: Maybe<IString_Comparison_Exp>;
  title?: Maybe<IString_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "historical_obituary" */
export enum IHistorical_Obituary_Constraint {
  /** unique or primary key constraint on columns "external_id" */
  HistoricalObituaryExternalIdKey = 'historical_obituary_external_id_key',
  /** unique or primary key constraint on columns "obituary_id" */
  HistoricalObituaryObituaryIdPkey = 'historical_obituary_obituary_id_pkey',
  /** unique or primary key constraint on columns "id" */
  HistoricalObituaryPkey = 'historical_obituary_pkey'
}

/** columns and relationships of "historical_obituary_event" */
export type IHistorical_Obituary_Event = {
  __typename?: 'historical_obituary_event';
  created_at: Scalars['timestamp'];
  date: Scalars['timestamp'];
  /** An object relationship */
  historical_obituary: IHistorical_Obituary;
  historical_obituary_id: Scalars['uuid'];
  id: Scalars['uuid'];
  location?: Maybe<Scalars['String']>;
  private: Scalars['Boolean'];
  time: Scalars['String'];
  type: Scalars['String'];
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "historical_obituary_event" */
export type IHistorical_Obituary_Event_Aggregate = {
  __typename?: 'historical_obituary_event_aggregate';
  aggregate?: Maybe<IHistorical_Obituary_Event_Aggregate_Fields>;
  nodes: Array<IHistorical_Obituary_Event>;
};

/** aggregate fields of "historical_obituary_event" */
export type IHistorical_Obituary_Event_Aggregate_Fields = {
  __typename?: 'historical_obituary_event_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IHistorical_Obituary_Event_Max_Fields>;
  min?: Maybe<IHistorical_Obituary_Event_Min_Fields>;
};


/** aggregate fields of "historical_obituary_event" */
export type IHistorical_Obituary_Event_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IHistorical_Obituary_Event_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "historical_obituary_event" */
export type IHistorical_Obituary_Event_Aggregate_Order_By = {
  count?: Maybe<IOrder_By>;
  max?: Maybe<IHistorical_Obituary_Event_Max_Order_By>;
  min?: Maybe<IHistorical_Obituary_Event_Min_Order_By>;
};

/** input type for inserting array relation for remote table "historical_obituary_event" */
export type IHistorical_Obituary_Event_Arr_Rel_Insert_Input = {
  data: Array<IHistorical_Obituary_Event_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<IHistorical_Obituary_Event_On_Conflict>;
};

/** Boolean expression to filter rows from the table "historical_obituary_event". All fields are combined with a logical 'AND'. */
export type IHistorical_Obituary_Event_Bool_Exp = {
  _and?: Maybe<Array<IHistorical_Obituary_Event_Bool_Exp>>;
  _not?: Maybe<IHistorical_Obituary_Event_Bool_Exp>;
  _or?: Maybe<Array<IHistorical_Obituary_Event_Bool_Exp>>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  date?: Maybe<ITimestamp_Comparison_Exp>;
  historical_obituary?: Maybe<IHistorical_Obituary_Bool_Exp>;
  historical_obituary_id?: Maybe<IUuid_Comparison_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  location?: Maybe<IString_Comparison_Exp>;
  private?: Maybe<IBoolean_Comparison_Exp>;
  time?: Maybe<IString_Comparison_Exp>;
  type?: Maybe<IString_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "historical_obituary_event" */
export enum IHistorical_Obituary_Event_Constraint {
  /** unique or primary key constraint on columns "id" */
  HistoricalObituaryEventPkey = 'historical_obituary_event_pkey'
}

/** input type for inserting data into table "historical_obituary_event" */
export type IHistorical_Obituary_Event_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  date?: Maybe<Scalars['timestamp']>;
  historical_obituary?: Maybe<IHistorical_Obituary_Obj_Rel_Insert_Input>;
  historical_obituary_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['String']>;
  private?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type IHistorical_Obituary_Event_Max_Fields = {
  __typename?: 'historical_obituary_event_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  date?: Maybe<Scalars['timestamp']>;
  historical_obituary_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "historical_obituary_event" */
export type IHistorical_Obituary_Event_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  date?: Maybe<IOrder_By>;
  historical_obituary_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location?: Maybe<IOrder_By>;
  time?: Maybe<IOrder_By>;
  type?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IHistorical_Obituary_Event_Min_Fields = {
  __typename?: 'historical_obituary_event_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  date?: Maybe<Scalars['timestamp']>;
  historical_obituary_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "historical_obituary_event" */
export type IHistorical_Obituary_Event_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  date?: Maybe<IOrder_By>;
  historical_obituary_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location?: Maybe<IOrder_By>;
  time?: Maybe<IOrder_By>;
  type?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "historical_obituary_event" */
export type IHistorical_Obituary_Event_Mutation_Response = {
  __typename?: 'historical_obituary_event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IHistorical_Obituary_Event>;
};

/** on_conflict condition type for table "historical_obituary_event" */
export type IHistorical_Obituary_Event_On_Conflict = {
  constraint: IHistorical_Obituary_Event_Constraint;
  update_columns?: Array<IHistorical_Obituary_Event_Update_Column>;
  where?: Maybe<IHistorical_Obituary_Event_Bool_Exp>;
};

/** Ordering options when selecting data from "historical_obituary_event". */
export type IHistorical_Obituary_Event_Order_By = {
  created_at?: Maybe<IOrder_By>;
  date?: Maybe<IOrder_By>;
  historical_obituary?: Maybe<IHistorical_Obituary_Order_By>;
  historical_obituary_id?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location?: Maybe<IOrder_By>;
  private?: Maybe<IOrder_By>;
  time?: Maybe<IOrder_By>;
  type?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** primary key columns input for table: historical_obituary_event */
export type IHistorical_Obituary_Event_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "historical_obituary_event" */
export enum IHistorical_Obituary_Event_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  HistoricalObituaryId = 'historical_obituary_id',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Private = 'private',
  /** column name */
  Time = 'time',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "historical_obituary_event" */
export type IHistorical_Obituary_Event_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  date?: Maybe<Scalars['timestamp']>;
  historical_obituary_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['String']>;
  private?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "historical_obituary_event" */
export enum IHistorical_Obituary_Event_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  HistoricalObituaryId = 'historical_obituary_id',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Private = 'private',
  /** column name */
  Time = 'time',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for inserting data into table "historical_obituary" */
export type IHistorical_Obituary_Insert_Input = {
  age?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  date_born?: Maybe<Scalars['String']>;
  date_deceased?: Maybe<Scalars['String']>;
  external_created_at?: Maybe<Scalars['timestamp']>;
  external_id?: Maybe<Scalars['String']>;
  external_original?: Maybe<Scalars['String']>;
  external_photos?: Maybe<Scalars['_text']>;
  external_published_at?: Maybe<Scalars['timestamp']>;
  external_ran_from?: Maybe<Scalars['timestamp']>;
  external_ran_to?: Maybe<Scalars['timestamp']>;
  external_updated_at?: Maybe<Scalars['timestamp']>;
  external_url?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  funeralHome?: Maybe<IFuneral_Home_Obj_Rel_Insert_Input>;
  funeral_home?: Maybe<Scalars['String']>;
  funeral_home_id?: Maybe<Scalars['uuid']>;
  gannett_edit?: Maybe<Scalars['Boolean']>;
  historical_obituary_events?: Maybe<IHistorical_Obituary_Event_Arr_Rel_Insert_Input>;
  historical_obituary_publications?: Maybe<IHistorical_Obituary_Publication_Arr_Rel_Insert_Input>;
  historical_obituary_tags?: Maybe<IHistorical_Obituary_Tag_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  image_paths?: Maybe<Scalars['_text']>;
  import_external_id?: Maybe<Scalars['_text']>;
  import_external_user_id?: Maybe<Scalars['_text']>;
  last_name?: Maybe<Scalars['String']>;
  logos?: Maybe<Scalars['_text']>;
  middle_name?: Maybe<Scalars['String']>;
  obituary?: Maybe<IObituary_Obj_Rel_Insert_Input>;
  obituary_id?: Maybe<Scalars['uuid']>;
  person?: Maybe<Scalars['String']>;
  publish_status?: Maybe<Scalars['obit_publish_status']>;
  slug?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type IHistorical_Obituary_Max_Fields = {
  __typename?: 'historical_obituary_max_fields';
  age?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  date_born?: Maybe<Scalars['String']>;
  date_deceased?: Maybe<Scalars['String']>;
  external_created_at?: Maybe<Scalars['timestamp']>;
  external_id?: Maybe<Scalars['String']>;
  external_original?: Maybe<Scalars['String']>;
  external_published_at?: Maybe<Scalars['timestamp']>;
  external_ran_from?: Maybe<Scalars['timestamp']>;
  external_ran_to?: Maybe<Scalars['timestamp']>;
  external_updated_at?: Maybe<Scalars['timestamp']>;
  external_url?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  funeral_home?: Maybe<Scalars['String']>;
  funeral_home_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  person?: Maybe<Scalars['String']>;
  publish_status?: Maybe<Scalars['obit_publish_status']>;
  slug?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type IHistorical_Obituary_Min_Fields = {
  __typename?: 'historical_obituary_min_fields';
  age?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  date_born?: Maybe<Scalars['String']>;
  date_deceased?: Maybe<Scalars['String']>;
  external_created_at?: Maybe<Scalars['timestamp']>;
  external_id?: Maybe<Scalars['String']>;
  external_original?: Maybe<Scalars['String']>;
  external_published_at?: Maybe<Scalars['timestamp']>;
  external_ran_from?: Maybe<Scalars['timestamp']>;
  external_ran_to?: Maybe<Scalars['timestamp']>;
  external_updated_at?: Maybe<Scalars['timestamp']>;
  external_url?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  funeral_home?: Maybe<Scalars['String']>;
  funeral_home_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  person?: Maybe<Scalars['String']>;
  publish_status?: Maybe<Scalars['obit_publish_status']>;
  slug?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "historical_obituary" */
export type IHistorical_Obituary_Mutation_Response = {
  __typename?: 'historical_obituary_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IHistorical_Obituary>;
};

/** input type for inserting object relation for remote table "historical_obituary" */
export type IHistorical_Obituary_Obj_Rel_Insert_Input = {
  data: IHistorical_Obituary_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<IHistorical_Obituary_On_Conflict>;
};

/** on_conflict condition type for table "historical_obituary" */
export type IHistorical_Obituary_On_Conflict = {
  constraint: IHistorical_Obituary_Constraint;
  update_columns?: Array<IHistorical_Obituary_Update_Column>;
  where?: Maybe<IHistorical_Obituary_Bool_Exp>;
};

/** Ordering options when selecting data from "historical_obituary". */
export type IHistorical_Obituary_Order_By = {
  age?: Maybe<IOrder_By>;
  body?: Maybe<IOrder_By>;
  city?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  date_born?: Maybe<IOrder_By>;
  date_deceased?: Maybe<IOrder_By>;
  external_created_at?: Maybe<IOrder_By>;
  external_id?: Maybe<IOrder_By>;
  external_original?: Maybe<IOrder_By>;
  external_photos?: Maybe<IOrder_By>;
  external_published_at?: Maybe<IOrder_By>;
  external_ran_from?: Maybe<IOrder_By>;
  external_ran_to?: Maybe<IOrder_By>;
  external_updated_at?: Maybe<IOrder_By>;
  external_url?: Maybe<IOrder_By>;
  first_name?: Maybe<IOrder_By>;
  funeralHome?: Maybe<IFuneral_Home_Order_By>;
  funeral_home?: Maybe<IOrder_By>;
  funeral_home_id?: Maybe<IOrder_By>;
  gannett_edit?: Maybe<IOrder_By>;
  historical_obituary_events_aggregate?: Maybe<IHistorical_Obituary_Event_Aggregate_Order_By>;
  historical_obituary_publications_aggregate?: Maybe<IHistorical_Obituary_Publication_Aggregate_Order_By>;
  historical_obituary_tags_aggregate?: Maybe<IHistorical_Obituary_Tag_Aggregate_Order_By>;
  id?: Maybe<IOrder_By>;
  image_paths?: Maybe<IOrder_By>;
  import_external_id?: Maybe<IOrder_By>;
  import_external_user_id?: Maybe<IOrder_By>;
  last_name?: Maybe<IOrder_By>;
  logos?: Maybe<IOrder_By>;
  middle_name?: Maybe<IOrder_By>;
  obituary?: Maybe<IObituary_Order_By>;
  obituary_id?: Maybe<IOrder_By>;
  person?: Maybe<IOrder_By>;
  publish_status?: Maybe<IOrder_By>;
  slug?: Maybe<IOrder_By>;
  source?: Maybe<IOrder_By>;
  state?: Maybe<IOrder_By>;
  subtitle?: Maybe<IOrder_By>;
  title?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** primary key columns input for table: historical_obituary */
export type IHistorical_Obituary_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "historical_obituary_publication" */
export type IHistorical_Obituary_Publication = {
  __typename?: 'historical_obituary_publication';
  created_at: Scalars['timestamp'];
  historial_obituary_id: Scalars['uuid'];
  /** An object relationship */
  historical_obituary: IHistorical_Obituary;
  /** An object relationship */
  publication: IPublication;
  publication_id: Scalars['uuid'];
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "historical_obituary_publication" */
export type IHistorical_Obituary_Publication_Aggregate = {
  __typename?: 'historical_obituary_publication_aggregate';
  aggregate?: Maybe<IHistorical_Obituary_Publication_Aggregate_Fields>;
  nodes: Array<IHistorical_Obituary_Publication>;
};

/** aggregate fields of "historical_obituary_publication" */
export type IHistorical_Obituary_Publication_Aggregate_Fields = {
  __typename?: 'historical_obituary_publication_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IHistorical_Obituary_Publication_Max_Fields>;
  min?: Maybe<IHistorical_Obituary_Publication_Min_Fields>;
};


/** aggregate fields of "historical_obituary_publication" */
export type IHistorical_Obituary_Publication_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IHistorical_Obituary_Publication_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "historical_obituary_publication" */
export type IHistorical_Obituary_Publication_Aggregate_Order_By = {
  count?: Maybe<IOrder_By>;
  max?: Maybe<IHistorical_Obituary_Publication_Max_Order_By>;
  min?: Maybe<IHistorical_Obituary_Publication_Min_Order_By>;
};

/** input type for inserting array relation for remote table "historical_obituary_publication" */
export type IHistorical_Obituary_Publication_Arr_Rel_Insert_Input = {
  data: Array<IHistorical_Obituary_Publication_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<IHistorical_Obituary_Publication_On_Conflict>;
};

/** Boolean expression to filter rows from the table "historical_obituary_publication". All fields are combined with a logical 'AND'. */
export type IHistorical_Obituary_Publication_Bool_Exp = {
  _and?: Maybe<Array<IHistorical_Obituary_Publication_Bool_Exp>>;
  _not?: Maybe<IHistorical_Obituary_Publication_Bool_Exp>;
  _or?: Maybe<Array<IHistorical_Obituary_Publication_Bool_Exp>>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  historial_obituary_id?: Maybe<IUuid_Comparison_Exp>;
  historical_obituary?: Maybe<IHistorical_Obituary_Bool_Exp>;
  publication?: Maybe<IPublication_Bool_Exp>;
  publication_id?: Maybe<IUuid_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "historical_obituary_publication" */
export enum IHistorical_Obituary_Publication_Constraint {
  /** unique or primary key constraint on columns "historial_obituary_id", "publication_id" */
  HistoricalObituaryPublicationPkey = 'historical_obituary_publication_pkey'
}

/** input type for inserting data into table "historical_obituary_publication" */
export type IHistorical_Obituary_Publication_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  historial_obituary_id?: Maybe<Scalars['uuid']>;
  historical_obituary?: Maybe<IHistorical_Obituary_Obj_Rel_Insert_Input>;
  publication?: Maybe<IPublication_Obj_Rel_Insert_Input>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type IHistorical_Obituary_Publication_Max_Fields = {
  __typename?: 'historical_obituary_publication_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  historial_obituary_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "historical_obituary_publication" */
export type IHistorical_Obituary_Publication_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  historial_obituary_id?: Maybe<IOrder_By>;
  publication_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IHistorical_Obituary_Publication_Min_Fields = {
  __typename?: 'historical_obituary_publication_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  historial_obituary_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "historical_obituary_publication" */
export type IHistorical_Obituary_Publication_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  historial_obituary_id?: Maybe<IOrder_By>;
  publication_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "historical_obituary_publication" */
export type IHistorical_Obituary_Publication_Mutation_Response = {
  __typename?: 'historical_obituary_publication_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IHistorical_Obituary_Publication>;
};

/** on_conflict condition type for table "historical_obituary_publication" */
export type IHistorical_Obituary_Publication_On_Conflict = {
  constraint: IHistorical_Obituary_Publication_Constraint;
  update_columns?: Array<IHistorical_Obituary_Publication_Update_Column>;
  where?: Maybe<IHistorical_Obituary_Publication_Bool_Exp>;
};

/** Ordering options when selecting data from "historical_obituary_publication". */
export type IHistorical_Obituary_Publication_Order_By = {
  created_at?: Maybe<IOrder_By>;
  historial_obituary_id?: Maybe<IOrder_By>;
  historical_obituary?: Maybe<IHistorical_Obituary_Order_By>;
  publication?: Maybe<IPublication_Order_By>;
  publication_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** primary key columns input for table: historical_obituary_publication */
export type IHistorical_Obituary_Publication_Pk_Columns_Input = {
  historial_obituary_id: Scalars['uuid'];
  publication_id: Scalars['uuid'];
};

/** select columns of table "historical_obituary_publication" */
export enum IHistorical_Obituary_Publication_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HistorialObituaryId = 'historial_obituary_id',
  /** column name */
  PublicationId = 'publication_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "historical_obituary_publication" */
export type IHistorical_Obituary_Publication_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  historial_obituary_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "historical_obituary_publication" */
export enum IHistorical_Obituary_Publication_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HistorialObituaryId = 'historial_obituary_id',
  /** column name */
  PublicationId = 'publication_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select columns of table "historical_obituary" */
export enum IHistorical_Obituary_Select_Column {
  /** column name */
  Age = 'age',
  /** column name */
  Body = 'body',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateBorn = 'date_born',
  /** column name */
  DateDeceased = 'date_deceased',
  /** column name */
  ExternalCreatedAt = 'external_created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  ExternalOriginal = 'external_original',
  /** column name */
  ExternalPhotos = 'external_photos',
  /** column name */
  ExternalPublishedAt = 'external_published_at',
  /** column name */
  ExternalRanFrom = 'external_ran_from',
  /** column name */
  ExternalRanTo = 'external_ran_to',
  /** column name */
  ExternalUpdatedAt = 'external_updated_at',
  /** column name */
  ExternalUrl = 'external_url',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FuneralHome = 'funeral_home',
  /** column name */
  FuneralHomeId = 'funeral_home_id',
  /** column name */
  GannettEdit = 'gannett_edit',
  /** column name */
  Id = 'id',
  /** column name */
  ImagePaths = 'image_paths',
  /** column name */
  ImportExternalId = 'import_external_id',
  /** column name */
  ImportExternalUserId = 'import_external_user_id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Logos = 'logos',
  /** column name */
  MiddleName = 'middle_name',
  /** column name */
  ObituaryId = 'obituary_id',
  /** column name */
  Person = 'person',
  /** column name */
  PublishStatus = 'publish_status',
  /** column name */
  Slug = 'slug',
  /** column name */
  Source = 'source',
  /** column name */
  State = 'state',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "historical_obituary" */
export type IHistorical_Obituary_Set_Input = {
  age?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  date_born?: Maybe<Scalars['String']>;
  date_deceased?: Maybe<Scalars['String']>;
  external_created_at?: Maybe<Scalars['timestamp']>;
  external_id?: Maybe<Scalars['String']>;
  external_original?: Maybe<Scalars['String']>;
  external_photos?: Maybe<Scalars['_text']>;
  external_published_at?: Maybe<Scalars['timestamp']>;
  external_ran_from?: Maybe<Scalars['timestamp']>;
  external_ran_to?: Maybe<Scalars['timestamp']>;
  external_updated_at?: Maybe<Scalars['timestamp']>;
  external_url?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  funeral_home?: Maybe<Scalars['String']>;
  funeral_home_id?: Maybe<Scalars['uuid']>;
  gannett_edit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  image_paths?: Maybe<Scalars['_text']>;
  import_external_id?: Maybe<Scalars['_text']>;
  import_external_user_id?: Maybe<Scalars['_text']>;
  last_name?: Maybe<Scalars['String']>;
  logos?: Maybe<Scalars['_text']>;
  middle_name?: Maybe<Scalars['String']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  person?: Maybe<Scalars['String']>;
  publish_status?: Maybe<Scalars['obit_publish_status']>;
  slug?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** columns and relationships of "historical_obituary_tag" */
export type IHistorical_Obituary_Tag = {
  __typename?: 'historical_obituary_tag';
  created_at: Scalars['timestamp'];
  /** An object relationship */
  historical_obituary: IHistorical_Obituary;
  historical_obituary_id: Scalars['uuid'];
  /** An object relationship */
  tag: ITag;
  tag_id: Scalars['uuid'];
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "historical_obituary_tag" */
export type IHistorical_Obituary_Tag_Aggregate = {
  __typename?: 'historical_obituary_tag_aggregate';
  aggregate?: Maybe<IHistorical_Obituary_Tag_Aggregate_Fields>;
  nodes: Array<IHistorical_Obituary_Tag>;
};

/** aggregate fields of "historical_obituary_tag" */
export type IHistorical_Obituary_Tag_Aggregate_Fields = {
  __typename?: 'historical_obituary_tag_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IHistorical_Obituary_Tag_Max_Fields>;
  min?: Maybe<IHistorical_Obituary_Tag_Min_Fields>;
};


/** aggregate fields of "historical_obituary_tag" */
export type IHistorical_Obituary_Tag_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IHistorical_Obituary_Tag_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "historical_obituary_tag" */
export type IHistorical_Obituary_Tag_Aggregate_Order_By = {
  count?: Maybe<IOrder_By>;
  max?: Maybe<IHistorical_Obituary_Tag_Max_Order_By>;
  min?: Maybe<IHistorical_Obituary_Tag_Min_Order_By>;
};

/** input type for inserting array relation for remote table "historical_obituary_tag" */
export type IHistorical_Obituary_Tag_Arr_Rel_Insert_Input = {
  data: Array<IHistorical_Obituary_Tag_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<IHistorical_Obituary_Tag_On_Conflict>;
};

/** Boolean expression to filter rows from the table "historical_obituary_tag". All fields are combined with a logical 'AND'. */
export type IHistorical_Obituary_Tag_Bool_Exp = {
  _and?: Maybe<Array<IHistorical_Obituary_Tag_Bool_Exp>>;
  _not?: Maybe<IHistorical_Obituary_Tag_Bool_Exp>;
  _or?: Maybe<Array<IHistorical_Obituary_Tag_Bool_Exp>>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  historical_obituary?: Maybe<IHistorical_Obituary_Bool_Exp>;
  historical_obituary_id?: Maybe<IUuid_Comparison_Exp>;
  tag?: Maybe<ITag_Bool_Exp>;
  tag_id?: Maybe<IUuid_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "historical_obituary_tag" */
export enum IHistorical_Obituary_Tag_Constraint {
  /** unique or primary key constraint on columns "tag_id", "historical_obituary_id" */
  HistoricalObituaryTagPkey = 'historical_obituary_tag_pkey'
}

/** input type for inserting data into table "historical_obituary_tag" */
export type IHistorical_Obituary_Tag_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  historical_obituary?: Maybe<IHistorical_Obituary_Obj_Rel_Insert_Input>;
  historical_obituary_id?: Maybe<Scalars['uuid']>;
  tag?: Maybe<ITag_Obj_Rel_Insert_Input>;
  tag_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type IHistorical_Obituary_Tag_Max_Fields = {
  __typename?: 'historical_obituary_tag_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  historical_obituary_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "historical_obituary_tag" */
export type IHistorical_Obituary_Tag_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  historical_obituary_id?: Maybe<IOrder_By>;
  tag_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IHistorical_Obituary_Tag_Min_Fields = {
  __typename?: 'historical_obituary_tag_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  historical_obituary_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "historical_obituary_tag" */
export type IHistorical_Obituary_Tag_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  historical_obituary_id?: Maybe<IOrder_By>;
  tag_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "historical_obituary_tag" */
export type IHistorical_Obituary_Tag_Mutation_Response = {
  __typename?: 'historical_obituary_tag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IHistorical_Obituary_Tag>;
};

/** on_conflict condition type for table "historical_obituary_tag" */
export type IHistorical_Obituary_Tag_On_Conflict = {
  constraint: IHistorical_Obituary_Tag_Constraint;
  update_columns?: Array<IHistorical_Obituary_Tag_Update_Column>;
  where?: Maybe<IHistorical_Obituary_Tag_Bool_Exp>;
};

/** Ordering options when selecting data from "historical_obituary_tag". */
export type IHistorical_Obituary_Tag_Order_By = {
  created_at?: Maybe<IOrder_By>;
  historical_obituary?: Maybe<IHistorical_Obituary_Order_By>;
  historical_obituary_id?: Maybe<IOrder_By>;
  tag?: Maybe<ITag_Order_By>;
  tag_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** primary key columns input for table: historical_obituary_tag */
export type IHistorical_Obituary_Tag_Pk_Columns_Input = {
  historical_obituary_id: Scalars['uuid'];
  tag_id: Scalars['uuid'];
};

/** select columns of table "historical_obituary_tag" */
export enum IHistorical_Obituary_Tag_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HistoricalObituaryId = 'historical_obituary_id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "historical_obituary_tag" */
export type IHistorical_Obituary_Tag_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  historical_obituary_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "historical_obituary_tag" */
export enum IHistorical_Obituary_Tag_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HistoricalObituaryId = 'historical_obituary_id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** update columns of table "historical_obituary" */
export enum IHistorical_Obituary_Update_Column {
  /** column name */
  Age = 'age',
  /** column name */
  Body = 'body',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateBorn = 'date_born',
  /** column name */
  DateDeceased = 'date_deceased',
  /** column name */
  ExternalCreatedAt = 'external_created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  ExternalOriginal = 'external_original',
  /** column name */
  ExternalPhotos = 'external_photos',
  /** column name */
  ExternalPublishedAt = 'external_published_at',
  /** column name */
  ExternalRanFrom = 'external_ran_from',
  /** column name */
  ExternalRanTo = 'external_ran_to',
  /** column name */
  ExternalUpdatedAt = 'external_updated_at',
  /** column name */
  ExternalUrl = 'external_url',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FuneralHome = 'funeral_home',
  /** column name */
  FuneralHomeId = 'funeral_home_id',
  /** column name */
  GannettEdit = 'gannett_edit',
  /** column name */
  Id = 'id',
  /** column name */
  ImagePaths = 'image_paths',
  /** column name */
  ImportExternalId = 'import_external_id',
  /** column name */
  ImportExternalUserId = 'import_external_user_id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Logos = 'logos',
  /** column name */
  MiddleName = 'middle_name',
  /** column name */
  ObituaryId = 'obituary_id',
  /** column name */
  Person = 'person',
  /** column name */
  PublishStatus = 'publish_status',
  /** column name */
  Slug = 'slug',
  /** column name */
  Source = 'source',
  /** column name */
  State = 'state',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "image" */
export type IImage = {
  __typename?: 'image';
  caption: Scalars['String'];
  created_at?: Maybe<Scalars['timestamp']>;
  id: Scalars['uuid'];
  /** An object relationship */
  obituary: IObituary;
  obituary_id: Scalars['uuid'];
  params: Scalars['String'];
  type: IImage_Type_Enum;
  updated_at?: Maybe<Scalars['timestamp']>;
  uri: Scalars['String'];
};

/** aggregated selection of "image" */
export type IImage_Aggregate = {
  __typename?: 'image_aggregate';
  aggregate?: Maybe<IImage_Aggregate_Fields>;
  nodes: Array<IImage>;
};

/** aggregate fields of "image" */
export type IImage_Aggregate_Fields = {
  __typename?: 'image_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IImage_Max_Fields>;
  min?: Maybe<IImage_Min_Fields>;
};


/** aggregate fields of "image" */
export type IImage_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IImage_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "image" */
export type IImage_Aggregate_Order_By = {
  count?: Maybe<IOrder_By>;
  max?: Maybe<IImage_Max_Order_By>;
  min?: Maybe<IImage_Min_Order_By>;
};

/** input type for inserting array relation for remote table "image" */
export type IImage_Arr_Rel_Insert_Input = {
  data: Array<IImage_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<IImage_On_Conflict>;
};

/** Boolean expression to filter rows from the table "image". All fields are combined with a logical 'AND'. */
export type IImage_Bool_Exp = {
  _and?: Maybe<Array<IImage_Bool_Exp>>;
  _not?: Maybe<IImage_Bool_Exp>;
  _or?: Maybe<Array<IImage_Bool_Exp>>;
  caption?: Maybe<IString_Comparison_Exp>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  obituary?: Maybe<IObituary_Bool_Exp>;
  obituary_id?: Maybe<IUuid_Comparison_Exp>;
  params?: Maybe<IString_Comparison_Exp>;
  type?: Maybe<IImage_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
  uri?: Maybe<IString_Comparison_Exp>;
};

/** unique or primary key constraints on table "image" */
export enum IImage_Constraint {
  /** unique or primary key constraint on columns "id" */
  ImageIdKey = 'image_id_key',
  /** unique or primary key constraint on columns "id" */
  ImagePkey = 'image_pkey',
  /** unique or primary key constraint on columns "uri" */
  ImageUriKey = 'image_uri_key'
}

/** input type for inserting data into table "image" */
export type IImage_Insert_Input = {
  caption?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  obituary?: Maybe<IObituary_Obj_Rel_Insert_Input>;
  obituary_id?: Maybe<Scalars['uuid']>;
  params?: Maybe<Scalars['String']>;
  type?: Maybe<IImage_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
  uri?: Maybe<Scalars['String']>;
};

/** obit image uris will be added here when the xml is imported */
export type IImage_Lookup = {
  __typename?: 'image_lookup';
  created_at: Scalars['timestamp'];
  deleted: Scalars['Boolean'];
  id: Scalars['uuid'];
  image_url?: Maybe<Scalars['String']>;
  import_url: Scalars['String'];
  obituary_id: Scalars['uuid'];
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "image_lookup" */
export type IImage_Lookup_Aggregate = {
  __typename?: 'image_lookup_aggregate';
  aggregate?: Maybe<IImage_Lookup_Aggregate_Fields>;
  nodes: Array<IImage_Lookup>;
};

/** aggregate fields of "image_lookup" */
export type IImage_Lookup_Aggregate_Fields = {
  __typename?: 'image_lookup_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IImage_Lookup_Max_Fields>;
  min?: Maybe<IImage_Lookup_Min_Fields>;
};


/** aggregate fields of "image_lookup" */
export type IImage_Lookup_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IImage_Lookup_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "image_lookup". All fields are combined with a logical 'AND'. */
export type IImage_Lookup_Bool_Exp = {
  _and?: Maybe<Array<IImage_Lookup_Bool_Exp>>;
  _not?: Maybe<IImage_Lookup_Bool_Exp>;
  _or?: Maybe<Array<IImage_Lookup_Bool_Exp>>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  deleted?: Maybe<IBoolean_Comparison_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  image_url?: Maybe<IString_Comparison_Exp>;
  import_url?: Maybe<IString_Comparison_Exp>;
  obituary_id?: Maybe<IUuid_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "image_lookup" */
export enum IImage_Lookup_Constraint {
  /** unique or primary key constraint on columns "image_url" */
  ImageLookupImageUrlKey = 'image_lookup_image_url_key',
  /** unique or primary key constraint on columns "id" */
  ImageLookupPkey = 'image_lookup_pkey',
  /** unique or primary key constraint on columns "import_url" */
  ImageLookupUriKey = 'image_lookup_uri_key'
}

/** input type for inserting data into table "image_lookup" */
export type IImage_Lookup_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  image_url?: Maybe<Scalars['String']>;
  import_url?: Maybe<Scalars['String']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type IImage_Lookup_Max_Fields = {
  __typename?: 'image_lookup_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  image_url?: Maybe<Scalars['String']>;
  import_url?: Maybe<Scalars['String']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type IImage_Lookup_Min_Fields = {
  __typename?: 'image_lookup_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  image_url?: Maybe<Scalars['String']>;
  import_url?: Maybe<Scalars['String']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "image_lookup" */
export type IImage_Lookup_Mutation_Response = {
  __typename?: 'image_lookup_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IImage_Lookup>;
};

/** on_conflict condition type for table "image_lookup" */
export type IImage_Lookup_On_Conflict = {
  constraint: IImage_Lookup_Constraint;
  update_columns?: Array<IImage_Lookup_Update_Column>;
  where?: Maybe<IImage_Lookup_Bool_Exp>;
};

/** Ordering options when selecting data from "image_lookup". */
export type IImage_Lookup_Order_By = {
  created_at?: Maybe<IOrder_By>;
  deleted?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  image_url?: Maybe<IOrder_By>;
  import_url?: Maybe<IOrder_By>;
  obituary_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** primary key columns input for table: image_lookup */
export type IImage_Lookup_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "image_lookup" */
export enum IImage_Lookup_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  ImportUrl = 'import_url',
  /** column name */
  ObituaryId = 'obituary_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "image_lookup" */
export type IImage_Lookup_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  image_url?: Maybe<Scalars['String']>;
  import_url?: Maybe<Scalars['String']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "image_lookup" */
export enum IImage_Lookup_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  ImportUrl = 'import_url',
  /** column name */
  ObituaryId = 'obituary_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate max on columns */
export type IImage_Max_Fields = {
  __typename?: 'image_max_fields';
  caption?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  params?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  uri?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "image" */
export type IImage_Max_Order_By = {
  caption?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  obituary_id?: Maybe<IOrder_By>;
  params?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  uri?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IImage_Min_Fields = {
  __typename?: 'image_min_fields';
  caption?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  params?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  uri?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "image" */
export type IImage_Min_Order_By = {
  caption?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  obituary_id?: Maybe<IOrder_By>;
  params?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  uri?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "image" */
export type IImage_Mutation_Response = {
  __typename?: 'image_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IImage>;
};

/** on_conflict condition type for table "image" */
export type IImage_On_Conflict = {
  constraint: IImage_Constraint;
  update_columns?: Array<IImage_Update_Column>;
  where?: Maybe<IImage_Bool_Exp>;
};

/** columns and relationships of "image_order" */
export type IImage_Order = {
  __typename?: 'image_order';
  created_at?: Maybe<Scalars['timestamp']>;
  id: Scalars['uuid'];
  /** An object relationship */
  image_type: IImage_Type;
  /** An object relationship */
  obituary: IObituary;
  obituary_id: Scalars['uuid'];
  order: Scalars['_uuid'];
  type: IImage_Type_Enum;
  updated_at?: Maybe<Scalars['timestamp']>;
  version: Scalars['Int'];
};

/** aggregated selection of "image_order" */
export type IImage_Order_Aggregate = {
  __typename?: 'image_order_aggregate';
  aggregate?: Maybe<IImage_Order_Aggregate_Fields>;
  nodes: Array<IImage_Order>;
};

/** aggregate fields of "image_order" */
export type IImage_Order_Aggregate_Fields = {
  __typename?: 'image_order_aggregate_fields';
  avg?: Maybe<IImage_Order_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IImage_Order_Max_Fields>;
  min?: Maybe<IImage_Order_Min_Fields>;
  stddev?: Maybe<IImage_Order_Stddev_Fields>;
  stddev_pop?: Maybe<IImage_Order_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IImage_Order_Stddev_Samp_Fields>;
  sum?: Maybe<IImage_Order_Sum_Fields>;
  var_pop?: Maybe<IImage_Order_Var_Pop_Fields>;
  var_samp?: Maybe<IImage_Order_Var_Samp_Fields>;
  variance?: Maybe<IImage_Order_Variance_Fields>;
};


/** aggregate fields of "image_order" */
export type IImage_Order_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IImage_Order_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "image_order" */
export type IImage_Order_Aggregate_Order_By = {
  avg?: Maybe<IImage_Order_Avg_Order_By>;
  count?: Maybe<IOrder_By>;
  max?: Maybe<IImage_Order_Max_Order_By>;
  min?: Maybe<IImage_Order_Min_Order_By>;
  stddev?: Maybe<IImage_Order_Stddev_Order_By>;
  stddev_pop?: Maybe<IImage_Order_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IImage_Order_Stddev_Samp_Order_By>;
  sum?: Maybe<IImage_Order_Sum_Order_By>;
  var_pop?: Maybe<IImage_Order_Var_Pop_Order_By>;
  var_samp?: Maybe<IImage_Order_Var_Samp_Order_By>;
  variance?: Maybe<IImage_Order_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "image_order" */
export type IImage_Order_Arr_Rel_Insert_Input = {
  data: Array<IImage_Order_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<IImage_Order_On_Conflict>;
};

/** aggregate avg on columns */
export type IImage_Order_Avg_Fields = {
  __typename?: 'image_order_avg_fields';
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "image_order" */
export type IImage_Order_Avg_Order_By = {
  version?: Maybe<IOrder_By>;
};

/** Boolean expression to filter rows from the table "image_order". All fields are combined with a logical 'AND'. */
export type IImage_Order_Bool_Exp = {
  _and?: Maybe<Array<IImage_Order_Bool_Exp>>;
  _not?: Maybe<IImage_Order_Bool_Exp>;
  _or?: Maybe<Array<IImage_Order_Bool_Exp>>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  image_type?: Maybe<IImage_Type_Bool_Exp>;
  obituary?: Maybe<IObituary_Bool_Exp>;
  obituary_id?: Maybe<IUuid_Comparison_Exp>;
  order?: Maybe<I_Uuid_Comparison_Exp>;
  type?: Maybe<IImage_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
  version?: Maybe<IInt_Comparison_Exp>;
};

/** Ordering options when selecting data from "image". */
export type IImage_Order_By = {
  caption?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  obituary?: Maybe<IObituary_Order_By>;
  obituary_id?: Maybe<IOrder_By>;
  params?: Maybe<IOrder_By>;
  type?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  uri?: Maybe<IOrder_By>;
};

/** unique or primary key constraints on table "image_order" */
export enum IImage_Order_Constraint {
  /** unique or primary key constraint on columns "obituary_id", "type" */
  ImageOrderObituaryIdTypeKey = 'image_order_obituary_id_type_key',
  /** unique or primary key constraint on columns "id" */
  ImageOrderPkey = 'image_order_pkey'
}

/** input type for incrementing numeric columns in table "image_order" */
export type IImage_Order_Inc_Input = {
  version?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "image_order" */
export type IImage_Order_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  image_type?: Maybe<IImage_Type_Obj_Rel_Insert_Input>;
  obituary?: Maybe<IObituary_Obj_Rel_Insert_Input>;
  obituary_id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['_uuid']>;
  type?: Maybe<IImage_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
  version?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type IImage_Order_Max_Fields = {
  __typename?: 'image_order_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  version?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "image_order" */
export type IImage_Order_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  obituary_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  version?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IImage_Order_Min_Fields = {
  __typename?: 'image_order_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  version?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "image_order" */
export type IImage_Order_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  obituary_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  version?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "image_order" */
export type IImage_Order_Mutation_Response = {
  __typename?: 'image_order_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IImage_Order>;
};

/** on_conflict condition type for table "image_order" */
export type IImage_Order_On_Conflict = {
  constraint: IImage_Order_Constraint;
  update_columns?: Array<IImage_Order_Update_Column>;
  where?: Maybe<IImage_Order_Bool_Exp>;
};

/** Ordering options when selecting data from "image_order". */
export type IImage_Order_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  image_type?: Maybe<IImage_Type_Order_By>;
  obituary?: Maybe<IObituary_Order_By>;
  obituary_id?: Maybe<IOrder_By>;
  order?: Maybe<IOrder_By>;
  type?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  version?: Maybe<IOrder_By>;
};

/** primary key columns input for table: image_order */
export type IImage_Order_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "image_order" */
export enum IImage_Order_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ObituaryId = 'obituary_id',
  /** column name */
  Order = 'order',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "image_order" */
export type IImage_Order_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['_uuid']>;
  type?: Maybe<IImage_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
  version?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type IImage_Order_Stddev_Fields = {
  __typename?: 'image_order_stddev_fields';
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "image_order" */
export type IImage_Order_Stddev_Order_By = {
  version?: Maybe<IOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IImage_Order_Stddev_Pop_Fields = {
  __typename?: 'image_order_stddev_pop_fields';
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "image_order" */
export type IImage_Order_Stddev_Pop_Order_By = {
  version?: Maybe<IOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IImage_Order_Stddev_Samp_Fields = {
  __typename?: 'image_order_stddev_samp_fields';
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "image_order" */
export type IImage_Order_Stddev_Samp_Order_By = {
  version?: Maybe<IOrder_By>;
};

/** aggregate sum on columns */
export type IImage_Order_Sum_Fields = {
  __typename?: 'image_order_sum_fields';
  version?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "image_order" */
export type IImage_Order_Sum_Order_By = {
  version?: Maybe<IOrder_By>;
};

/** update columns of table "image_order" */
export enum IImage_Order_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ObituaryId = 'obituary_id',
  /** column name */
  Order = 'order',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version'
}

/** aggregate var_pop on columns */
export type IImage_Order_Var_Pop_Fields = {
  __typename?: 'image_order_var_pop_fields';
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "image_order" */
export type IImage_Order_Var_Pop_Order_By = {
  version?: Maybe<IOrder_By>;
};

/** aggregate var_samp on columns */
export type IImage_Order_Var_Samp_Fields = {
  __typename?: 'image_order_var_samp_fields';
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "image_order" */
export type IImage_Order_Var_Samp_Order_By = {
  version?: Maybe<IOrder_By>;
};

/** aggregate variance on columns */
export type IImage_Order_Variance_Fields = {
  __typename?: 'image_order_variance_fields';
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "image_order" */
export type IImage_Order_Variance_Order_By = {
  version?: Maybe<IOrder_By>;
};

/** primary key columns input for table: image */
export type IImage_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "image" */
export enum IImage_Select_Column {
  /** column name */
  Caption = 'caption',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ObituaryId = 'obituary_id',
  /** column name */
  Params = 'params',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uri = 'uri'
}

/** input type for updating data in table "image" */
export type IImage_Set_Input = {
  caption?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  params?: Maybe<Scalars['String']>;
  type?: Maybe<IImage_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
  uri?: Maybe<Scalars['String']>;
};

/** columns and relationships of "image_type" */
export type IImage_Type = {
  __typename?: 'image_type';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "image_type" */
export type IImage_Type_Aggregate = {
  __typename?: 'image_type_aggregate';
  aggregate?: Maybe<IImage_Type_Aggregate_Fields>;
  nodes: Array<IImage_Type>;
};

/** aggregate fields of "image_type" */
export type IImage_Type_Aggregate_Fields = {
  __typename?: 'image_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IImage_Type_Max_Fields>;
  min?: Maybe<IImage_Type_Min_Fields>;
};


/** aggregate fields of "image_type" */
export type IImage_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IImage_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "image_type". All fields are combined with a logical 'AND'. */
export type IImage_Type_Bool_Exp = {
  _and?: Maybe<Array<IImage_Type_Bool_Exp>>;
  _not?: Maybe<IImage_Type_Bool_Exp>;
  _or?: Maybe<Array<IImage_Type_Bool_Exp>>;
  comment?: Maybe<IString_Comparison_Exp>;
  value?: Maybe<IString_Comparison_Exp>;
};

/** unique or primary key constraints on table "image_type" */
export enum IImage_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  ImageTypePkey = 'image_type_pkey'
}

export enum IImage_Type_Enum {
  /** Gallery feature. */
  LifeInPictures = 'life_in_pictures',
  /** Main obituary photo(s). */
  Obituary = 'obituary'
}

/** Boolean expression to compare columns of type "image_type_enum". All fields are combined with logical 'AND'. */
export type IImage_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<IImage_Type_Enum>;
  _in?: Maybe<Array<IImage_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<IImage_Type_Enum>;
  _nin?: Maybe<Array<IImage_Type_Enum>>;
};

/** input type for inserting data into table "image_type" */
export type IImage_Type_Insert_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type IImage_Type_Max_Fields = {
  __typename?: 'image_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type IImage_Type_Min_Fields = {
  __typename?: 'image_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "image_type" */
export type IImage_Type_Mutation_Response = {
  __typename?: 'image_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IImage_Type>;
};

/** input type for inserting object relation for remote table "image_type" */
export type IImage_Type_Obj_Rel_Insert_Input = {
  data: IImage_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<IImage_Type_On_Conflict>;
};

/** on_conflict condition type for table "image_type" */
export type IImage_Type_On_Conflict = {
  constraint: IImage_Type_Constraint;
  update_columns?: Array<IImage_Type_Update_Column>;
  where?: Maybe<IImage_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "image_type". */
export type IImage_Type_Order_By = {
  comment?: Maybe<IOrder_By>;
  value?: Maybe<IOrder_By>;
};

/** primary key columns input for table: image_type */
export type IImage_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "image_type" */
export enum IImage_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "image_type" */
export type IImage_Type_Set_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "image_type" */
export enum IImage_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** update columns of table "image" */
export enum IImage_Update_Column {
  /** column name */
  Caption = 'caption',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ObituaryId = 'obituary_id',
  /** column name */
  Params = 'params',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uri = 'uri'
}

/** columns and relationships of "images" */
export type IImages = {
  __typename?: 'images';
  caption?: Maybe<Scalars['String']>;
  crop_params?: Maybe<Scalars['String']>;
  file_name: Scalars['String'];
  id: Scalars['uuid'];
  memory_id: Scalars['uuid'];
};

/** aggregated selection of "images" */
export type IImages_Aggregate = {
  __typename?: 'images_aggregate';
  aggregate?: Maybe<IImages_Aggregate_Fields>;
  nodes: Array<IImages>;
};

export type IImages_Aggregate_Bool_Exp = {
  count?: Maybe<IImages_Aggregate_Bool_Exp_Count>;
};

export type IImages_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IImages_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IImages_Bool_Exp>;
  predicate: IInt_Comparison_Exp;
};

/** aggregate fields of "images" */
export type IImages_Aggregate_Fields = {
  __typename?: 'images_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IImages_Max_Fields>;
  min?: Maybe<IImages_Min_Fields>;
};


/** aggregate fields of "images" */
export type IImages_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IImages_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "images" */
export type IImages_Aggregate_Order_By = {
  count?: Maybe<IOrder_By>;
  max?: Maybe<IImages_Max_Order_By>;
  min?: Maybe<IImages_Min_Order_By>;
};

/** input type for inserting array relation for remote table "images" */
export type IImages_Arr_Rel_Insert_Input = {
  data: Array<IImages_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<IImages_On_Conflict>;
};

/** Boolean expression to filter rows from the table "images". All fields are combined with a logical 'AND'. */
export type IImages_Bool_Exp = {
  _and?: Maybe<Array<IImages_Bool_Exp>>;
  _not?: Maybe<IImages_Bool_Exp>;
  _or?: Maybe<Array<IImages_Bool_Exp>>;
  caption?: Maybe<IString_Comparison_Exp>;
  crop_params?: Maybe<IString_Comparison_Exp>;
  file_name?: Maybe<IString_Comparison_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  memory_id?: Maybe<IUuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "images" */
export enum IImages_Constraint {
  /** unique or primary key constraint on columns "id" */
  ImagesPkey = 'images_pkey'
}

/** input type for inserting data into table "images" */
export type IImages_Insert_Input = {
  caption?: Maybe<Scalars['String']>;
  crop_params?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  memory_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type IImages_Max_Fields = {
  __typename?: 'images_max_fields';
  caption?: Maybe<Scalars['String']>;
  crop_params?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  memory_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "images" */
export type IImages_Max_Order_By = {
  caption?: Maybe<IOrder_By>;
  crop_params?: Maybe<IOrder_By>;
  file_name?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  memory_id?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IImages_Min_Fields = {
  __typename?: 'images_min_fields';
  caption?: Maybe<Scalars['String']>;
  crop_params?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  memory_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "images" */
export type IImages_Min_Order_By = {
  caption?: Maybe<IOrder_By>;
  crop_params?: Maybe<IOrder_By>;
  file_name?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  memory_id?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "images" */
export type IImages_Mutation_Response = {
  __typename?: 'images_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IImages>;
};

/** on_conflict condition type for table "images" */
export type IImages_On_Conflict = {
  constraint: IImages_Constraint;
  update_columns?: Array<IImages_Update_Column>;
  where?: Maybe<IImages_Bool_Exp>;
};

/** Ordering options when selecting data from "images". */
export type IImages_Order_By = {
  caption?: Maybe<IOrder_By>;
  crop_params?: Maybe<IOrder_By>;
  file_name?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  memory_id?: Maybe<IOrder_By>;
};

/** primary key columns input for table: images */
export type IImages_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "images" */
export enum IImages_Select_Column {
  /** column name */
  Caption = 'caption',
  /** column name */
  CropParams = 'crop_params',
  /** column name */
  FileName = 'file_name',
  /** column name */
  Id = 'id',
  /** column name */
  MemoryId = 'memory_id'
}

/** input type for updating data in table "images" */
export type IImages_Set_Input = {
  caption?: Maybe<Scalars['String']>;
  crop_params?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  memory_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "images" */
export type IImages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IImages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<ICursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IImages_Stream_Cursor_Value_Input = {
  caption?: Maybe<Scalars['String']>;
  crop_params?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  memory_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "images" */
export enum IImages_Update_Column {
  /** column name */
  Caption = 'caption',
  /** column name */
  CropParams = 'crop_params',
  /** column name */
  FileName = 'file_name',
  /** column name */
  Id = 'id',
  /** column name */
  MemoryId = 'memory_id'
}

export type IImages_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<IImages_Set_Input>;
  /** filter the rows which have to be updated */
  where: IImages_Bool_Exp;
};

export type IInput_Memory = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  images?: Maybe<Array<Maybe<IInput_Memory_Image>>>;
  last_name: Scalars['String'];
  parent_id?: Maybe<Scalars['uuid']>;
  slug: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  topic_id?: Maybe<Scalars['Int']>;
};

export type IInput_Memory_Image = {
  caption?: Maybe<Scalars['String']>;
  crop_params?: Maybe<Scalars['String']>;
  file_name: Scalars['String'];
};


export type IJsonb_Cast_Exp = {
  String?: Maybe<IString_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type IJsonb_Comparison_Exp = {
  _cast?: Maybe<IJsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "latest_events" */
export type ILatest_Events = {
  __typename?: 'latest_events';
  content?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  memory?: Maybe<IMemories>;
  memory_id?: Maybe<Scalars['uuid']>;
  obit_slug?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<IUsers>;
  user_id?: Maybe<Scalars['String']>;
};


/** columns and relationships of "latest_events" */
export type ILatest_EventsContentArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "latest_events" */
export type ILatest_Events_Aggregate = {
  __typename?: 'latest_events_aggregate';
  aggregate?: Maybe<ILatest_Events_Aggregate_Fields>;
  nodes: Array<ILatest_Events>;
};

/** aggregate fields of "latest_events" */
export type ILatest_Events_Aggregate_Fields = {
  __typename?: 'latest_events_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ILatest_Events_Max_Fields>;
  min?: Maybe<ILatest_Events_Min_Fields>;
};


/** aggregate fields of "latest_events" */
export type ILatest_Events_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ILatest_Events_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ILatest_Events_Append_Input = {
  content?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "latest_events". All fields are combined with a logical 'AND'. */
export type ILatest_Events_Bool_Exp = {
  _and?: Maybe<Array<ILatest_Events_Bool_Exp>>;
  _not?: Maybe<ILatest_Events_Bool_Exp>;
  _or?: Maybe<Array<ILatest_Events_Bool_Exp>>;
  content?: Maybe<IJsonb_Comparison_Exp>;
  created_at?: Maybe<ITimestamptz_Comparison_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  memory?: Maybe<IMemories_Bool_Exp>;
  memory_id?: Maybe<IUuid_Comparison_Exp>;
  obit_slug?: Maybe<IString_Comparison_Exp>;
  type?: Maybe<IString_Comparison_Exp>;
  updated_at?: Maybe<ITimestamptz_Comparison_Exp>;
  user?: Maybe<IUsers_Bool_Exp>;
  user_id?: Maybe<IString_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ILatest_Events_Delete_At_Path_Input = {
  content?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ILatest_Events_Delete_Elem_Input = {
  content?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ILatest_Events_Delete_Key_Input = {
  content?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "latest_events" */
export type ILatest_Events_Insert_Input = {
  content?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  memory?: Maybe<IMemories_Obj_Rel_Insert_Input>;
  memory_id?: Maybe<Scalars['uuid']>;
  obit_slug?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<IUsers_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ILatest_Events_Max_Fields = {
  __typename?: 'latest_events_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  memory_id?: Maybe<Scalars['uuid']>;
  obit_slug?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ILatest_Events_Min_Fields = {
  __typename?: 'latest_events_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  memory_id?: Maybe<Scalars['uuid']>;
  obit_slug?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "latest_events" */
export type ILatest_Events_Mutation_Response = {
  __typename?: 'latest_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ILatest_Events>;
};

/** input type for inserting object relation for remote table "latest_events" */
export type ILatest_Events_Obj_Rel_Insert_Input = {
  data: ILatest_Events_Insert_Input;
};

/** Ordering options when selecting data from "latest_events". */
export type ILatest_Events_Order_By = {
  content?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  memory?: Maybe<IMemories_Order_By>;
  memory_id?: Maybe<IOrder_By>;
  obit_slug?: Maybe<IOrder_By>;
  type?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  user?: Maybe<IUsers_Order_By>;
  user_id?: Maybe<IOrder_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ILatest_Events_Prepend_Input = {
  content?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "latest_events" */
export enum ILatest_Events_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MemoryId = 'memory_id',
  /** column name */
  ObitSlug = 'obit_slug',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "latest_events" */
export type ILatest_Events_Set_Input = {
  content?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  memory_id?: Maybe<Scalars['uuid']>;
  obit_slug?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "latest_events" */
export type ILatest_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ILatest_Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<ICursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ILatest_Events_Stream_Cursor_Value_Input = {
  content?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  memory_id?: Maybe<Scalars['uuid']>;
  obit_slug?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type ILatest_Events_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<ILatest_Events_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<ILatest_Events_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<ILatest_Events_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<ILatest_Events_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<ILatest_Events_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ILatest_Events_Set_Input>;
  /** filter the rows which have to be updated */
  where: ILatest_Events_Bool_Exp;
};

/** columns and relationships of "legacycom_config" */
export type ILegacycom_Config = {
  __typename?: 'legacycom_config';
  created_at: Scalars['timestamp'];
  external_publication: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  publication: IPublication;
  publication_id: Scalars['uuid'];
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "legacycom_config" */
export type ILegacycom_Config_Aggregate = {
  __typename?: 'legacycom_config_aggregate';
  aggregate?: Maybe<ILegacycom_Config_Aggregate_Fields>;
  nodes: Array<ILegacycom_Config>;
};

/** aggregate fields of "legacycom_config" */
export type ILegacycom_Config_Aggregate_Fields = {
  __typename?: 'legacycom_config_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ILegacycom_Config_Max_Fields>;
  min?: Maybe<ILegacycom_Config_Min_Fields>;
};


/** aggregate fields of "legacycom_config" */
export type ILegacycom_Config_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ILegacycom_Config_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "legacycom_config" */
export type ILegacycom_Config_Aggregate_Order_By = {
  count?: Maybe<IOrder_By>;
  max?: Maybe<ILegacycom_Config_Max_Order_By>;
  min?: Maybe<ILegacycom_Config_Min_Order_By>;
};

/** input type for inserting array relation for remote table "legacycom_config" */
export type ILegacycom_Config_Arr_Rel_Insert_Input = {
  data: Array<ILegacycom_Config_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<ILegacycom_Config_On_Conflict>;
};

/** Boolean expression to filter rows from the table "legacycom_config". All fields are combined with a logical 'AND'. */
export type ILegacycom_Config_Bool_Exp = {
  _and?: Maybe<Array<ILegacycom_Config_Bool_Exp>>;
  _not?: Maybe<ILegacycom_Config_Bool_Exp>;
  _or?: Maybe<Array<ILegacycom_Config_Bool_Exp>>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  external_publication?: Maybe<IString_Comparison_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  publication?: Maybe<IPublication_Bool_Exp>;
  publication_id?: Maybe<IUuid_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "legacycom_config" */
export enum ILegacycom_Config_Constraint {
  /** unique or primary key constraint on columns "external_publication" */
  LegacycomConfigExternalPublicationKey = 'legacycom_config_external_publication_key',
  /** unique or primary key constraint on columns "id" */
  LegacycomConfigPkey = 'legacycom_config_pkey'
}

/** input type for inserting data into table "legacycom_config" */
export type ILegacycom_Config_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  external_publication?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  publication?: Maybe<IPublication_Obj_Rel_Insert_Input>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type ILegacycom_Config_Max_Fields = {
  __typename?: 'legacycom_config_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  external_publication?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "legacycom_config" */
export type ILegacycom_Config_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  external_publication?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  publication_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type ILegacycom_Config_Min_Fields = {
  __typename?: 'legacycom_config_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  external_publication?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "legacycom_config" */
export type ILegacycom_Config_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  external_publication?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  publication_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "legacycom_config" */
export type ILegacycom_Config_Mutation_Response = {
  __typename?: 'legacycom_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ILegacycom_Config>;
};

/** on_conflict condition type for table "legacycom_config" */
export type ILegacycom_Config_On_Conflict = {
  constraint: ILegacycom_Config_Constraint;
  update_columns?: Array<ILegacycom_Config_Update_Column>;
  where?: Maybe<ILegacycom_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "legacycom_config". */
export type ILegacycom_Config_Order_By = {
  created_at?: Maybe<IOrder_By>;
  external_publication?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  publication?: Maybe<IPublication_Order_By>;
  publication_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** primary key columns input for table: legacycom_config */
export type ILegacycom_Config_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "legacycom_config" */
export enum ILegacycom_Config_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalPublication = 'external_publication',
  /** column name */
  Id = 'id',
  /** column name */
  PublicationId = 'publication_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "legacycom_config" */
export type ILegacycom_Config_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  external_publication?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "legacycom_config" */
export enum ILegacycom_Config_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalPublication = 'external_publication',
  /** column name */
  Id = 'id',
  /** column name */
  PublicationId = 'publication_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "life_story" */
export type ILife_Story = {
  __typename?: 'life_story';
  birth: Scalars['date'];
  created_at: Scalars['timestamp'];
  first_name: Scalars['String'];
  id: Scalars['uuid'];
  last_name: Scalars['String'];
  middle_name: Scalars['String'];
  /** An object relationship */
  obituary: IObituary;
  obituary_id: Scalars['uuid'];
  passing: Scalars['date'];
  story: Scalars['String'];
  suffix: Scalars['String'];
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "life_story" */
export type ILife_Story_Aggregate = {
  __typename?: 'life_story_aggregate';
  aggregate?: Maybe<ILife_Story_Aggregate_Fields>;
  nodes: Array<ILife_Story>;
};

/** aggregate fields of "life_story" */
export type ILife_Story_Aggregate_Fields = {
  __typename?: 'life_story_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ILife_Story_Max_Fields>;
  min?: Maybe<ILife_Story_Min_Fields>;
};


/** aggregate fields of "life_story" */
export type ILife_Story_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ILife_Story_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "life_story" */
export type ILife_Story_Aggregate_Order_By = {
  count?: Maybe<IOrder_By>;
  max?: Maybe<ILife_Story_Max_Order_By>;
  min?: Maybe<ILife_Story_Min_Order_By>;
};

/** input type for inserting array relation for remote table "life_story" */
export type ILife_Story_Arr_Rel_Insert_Input = {
  data: Array<ILife_Story_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<ILife_Story_On_Conflict>;
};

/** Boolean expression to filter rows from the table "life_story". All fields are combined with a logical 'AND'. */
export type ILife_Story_Bool_Exp = {
  _and?: Maybe<Array<ILife_Story_Bool_Exp>>;
  _not?: Maybe<ILife_Story_Bool_Exp>;
  _or?: Maybe<Array<ILife_Story_Bool_Exp>>;
  birth?: Maybe<IDate_Comparison_Exp>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  first_name?: Maybe<IString_Comparison_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  last_name?: Maybe<IString_Comparison_Exp>;
  middle_name?: Maybe<IString_Comparison_Exp>;
  obituary?: Maybe<IObituary_Bool_Exp>;
  obituary_id?: Maybe<IUuid_Comparison_Exp>;
  passing?: Maybe<IDate_Comparison_Exp>;
  story?: Maybe<IString_Comparison_Exp>;
  suffix?: Maybe<IString_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "life_story" */
export enum ILife_Story_Constraint {
  /** unique or primary key constraint on columns "id" */
  LifeStoryPkey = 'life_story_pkey'
}

/** input type for inserting data into table "life_story" */
export type ILife_Story_Insert_Input = {
  birth?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamp']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  obituary?: Maybe<IObituary_Obj_Rel_Insert_Input>;
  obituary_id?: Maybe<Scalars['uuid']>;
  passing?: Maybe<Scalars['date']>;
  story?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** columns and relationships of "life_story_location" */
export type ILife_Story_Location = {
  __typename?: 'life_story_location';
  city: Scalars['String'];
  country: Scalars['String'];
  created_at: Scalars['timestamp'];
  id: Scalars['uuid'];
  notes: Scalars['String'];
  /** An object relationship */
  obituary: IObituary;
  obituary_id: Scalars['uuid'];
  state: Scalars['String'];
  type: ILife_Story_Locations_Types_Enum;
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "life_story_location" */
export type ILife_Story_Location_Aggregate = {
  __typename?: 'life_story_location_aggregate';
  aggregate?: Maybe<ILife_Story_Location_Aggregate_Fields>;
  nodes: Array<ILife_Story_Location>;
};

/** aggregate fields of "life_story_location" */
export type ILife_Story_Location_Aggregate_Fields = {
  __typename?: 'life_story_location_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ILife_Story_Location_Max_Fields>;
  min?: Maybe<ILife_Story_Location_Min_Fields>;
};


/** aggregate fields of "life_story_location" */
export type ILife_Story_Location_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ILife_Story_Location_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "life_story_location" */
export type ILife_Story_Location_Aggregate_Order_By = {
  count?: Maybe<IOrder_By>;
  max?: Maybe<ILife_Story_Location_Max_Order_By>;
  min?: Maybe<ILife_Story_Location_Min_Order_By>;
};

/** input type for inserting array relation for remote table "life_story_location" */
export type ILife_Story_Location_Arr_Rel_Insert_Input = {
  data: Array<ILife_Story_Location_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<ILife_Story_Location_On_Conflict>;
};

/** Boolean expression to filter rows from the table "life_story_location". All fields are combined with a logical 'AND'. */
export type ILife_Story_Location_Bool_Exp = {
  _and?: Maybe<Array<ILife_Story_Location_Bool_Exp>>;
  _not?: Maybe<ILife_Story_Location_Bool_Exp>;
  _or?: Maybe<Array<ILife_Story_Location_Bool_Exp>>;
  city?: Maybe<IString_Comparison_Exp>;
  country?: Maybe<IString_Comparison_Exp>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  notes?: Maybe<IString_Comparison_Exp>;
  obituary?: Maybe<IObituary_Bool_Exp>;
  obituary_id?: Maybe<IUuid_Comparison_Exp>;
  state?: Maybe<IString_Comparison_Exp>;
  type?: Maybe<ILife_Story_Locations_Types_Enum_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "life_story_location" */
export enum ILife_Story_Location_Constraint {
  /** unique or primary key constraint on columns "id" */
  LifeStoryLocationPkey = 'life_story_location_pkey'
}

/** input type for inserting data into table "life_story_location" */
export type ILife_Story_Location_Insert_Input = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  obituary?: Maybe<IObituary_Obj_Rel_Insert_Input>;
  obituary_id?: Maybe<Scalars['uuid']>;
  state?: Maybe<Scalars['String']>;
  type?: Maybe<ILife_Story_Locations_Types_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type ILife_Story_Location_Max_Fields = {
  __typename?: 'life_story_location_max_fields';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "life_story_location" */
export type ILife_Story_Location_Max_Order_By = {
  city?: Maybe<IOrder_By>;
  country?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  notes?: Maybe<IOrder_By>;
  obituary_id?: Maybe<IOrder_By>;
  state?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type ILife_Story_Location_Min_Fields = {
  __typename?: 'life_story_location_min_fields';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "life_story_location" */
export type ILife_Story_Location_Min_Order_By = {
  city?: Maybe<IOrder_By>;
  country?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  notes?: Maybe<IOrder_By>;
  obituary_id?: Maybe<IOrder_By>;
  state?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "life_story_location" */
export type ILife_Story_Location_Mutation_Response = {
  __typename?: 'life_story_location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ILife_Story_Location>;
};

/** on_conflict condition type for table "life_story_location" */
export type ILife_Story_Location_On_Conflict = {
  constraint: ILife_Story_Location_Constraint;
  update_columns?: Array<ILife_Story_Location_Update_Column>;
  where?: Maybe<ILife_Story_Location_Bool_Exp>;
};

/** Ordering options when selecting data from "life_story_location". */
export type ILife_Story_Location_Order_By = {
  city?: Maybe<IOrder_By>;
  country?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  notes?: Maybe<IOrder_By>;
  obituary?: Maybe<IObituary_Order_By>;
  obituary_id?: Maybe<IOrder_By>;
  state?: Maybe<IOrder_By>;
  type?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** primary key columns input for table: life_story_location */
export type ILife_Story_Location_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "life_story_location" */
export enum ILife_Story_Location_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  ObituaryId = 'obituary_id',
  /** column name */
  State = 'state',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "life_story_location" */
export type ILife_Story_Location_Set_Input = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  state?: Maybe<Scalars['String']>;
  type?: Maybe<ILife_Story_Locations_Types_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "life_story_location" */
export enum ILife_Story_Location_Update_Column {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  ObituaryId = 'obituary_id',
  /** column name */
  State = 'state',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "life_story_locations_types" */
export type ILife_Story_Locations_Types = {
  __typename?: 'life_story_locations_types';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "life_story_locations_types" */
export type ILife_Story_Locations_Types_Aggregate = {
  __typename?: 'life_story_locations_types_aggregate';
  aggregate?: Maybe<ILife_Story_Locations_Types_Aggregate_Fields>;
  nodes: Array<ILife_Story_Locations_Types>;
};

/** aggregate fields of "life_story_locations_types" */
export type ILife_Story_Locations_Types_Aggregate_Fields = {
  __typename?: 'life_story_locations_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ILife_Story_Locations_Types_Max_Fields>;
  min?: Maybe<ILife_Story_Locations_Types_Min_Fields>;
};


/** aggregate fields of "life_story_locations_types" */
export type ILife_Story_Locations_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ILife_Story_Locations_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "life_story_locations_types". All fields are combined with a logical 'AND'. */
export type ILife_Story_Locations_Types_Bool_Exp = {
  _and?: Maybe<Array<ILife_Story_Locations_Types_Bool_Exp>>;
  _not?: Maybe<ILife_Story_Locations_Types_Bool_Exp>;
  _or?: Maybe<Array<ILife_Story_Locations_Types_Bool_Exp>>;
  comment?: Maybe<IString_Comparison_Exp>;
  value?: Maybe<IString_Comparison_Exp>;
};

/** unique or primary key constraints on table "life_story_locations_types" */
export enum ILife_Story_Locations_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  LifeStoryLocationsTypesPkey = 'life_story_locations_types_pkey'
}

export enum ILife_Story_Locations_Types_Enum {
  /** Hometown location */
  Hometown = 'hometown'
}

/** Boolean expression to compare columns of type "life_story_locations_types_enum". All fields are combined with logical 'AND'. */
export type ILife_Story_Locations_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<ILife_Story_Locations_Types_Enum>;
  _in?: Maybe<Array<ILife_Story_Locations_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ILife_Story_Locations_Types_Enum>;
  _nin?: Maybe<Array<ILife_Story_Locations_Types_Enum>>;
};

/** input type for inserting data into table "life_story_locations_types" */
export type ILife_Story_Locations_Types_Insert_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ILife_Story_Locations_Types_Max_Fields = {
  __typename?: 'life_story_locations_types_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ILife_Story_Locations_Types_Min_Fields = {
  __typename?: 'life_story_locations_types_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "life_story_locations_types" */
export type ILife_Story_Locations_Types_Mutation_Response = {
  __typename?: 'life_story_locations_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ILife_Story_Locations_Types>;
};

/** on_conflict condition type for table "life_story_locations_types" */
export type ILife_Story_Locations_Types_On_Conflict = {
  constraint: ILife_Story_Locations_Types_Constraint;
  update_columns?: Array<ILife_Story_Locations_Types_Update_Column>;
  where?: Maybe<ILife_Story_Locations_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "life_story_locations_types". */
export type ILife_Story_Locations_Types_Order_By = {
  comment?: Maybe<IOrder_By>;
  value?: Maybe<IOrder_By>;
};

/** primary key columns input for table: life_story_locations_types */
export type ILife_Story_Locations_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "life_story_locations_types" */
export enum ILife_Story_Locations_Types_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "life_story_locations_types" */
export type ILife_Story_Locations_Types_Set_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "life_story_locations_types" */
export enum ILife_Story_Locations_Types_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** aggregate max on columns */
export type ILife_Story_Max_Fields = {
  __typename?: 'life_story_max_fields';
  birth?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamp']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  passing?: Maybe<Scalars['date']>;
  story?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "life_story" */
export type ILife_Story_Max_Order_By = {
  birth?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  first_name?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  last_name?: Maybe<IOrder_By>;
  middle_name?: Maybe<IOrder_By>;
  obituary_id?: Maybe<IOrder_By>;
  passing?: Maybe<IOrder_By>;
  story?: Maybe<IOrder_By>;
  suffix?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type ILife_Story_Min_Fields = {
  __typename?: 'life_story_min_fields';
  birth?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamp']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  passing?: Maybe<Scalars['date']>;
  story?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "life_story" */
export type ILife_Story_Min_Order_By = {
  birth?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  first_name?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  last_name?: Maybe<IOrder_By>;
  middle_name?: Maybe<IOrder_By>;
  obituary_id?: Maybe<IOrder_By>;
  passing?: Maybe<IOrder_By>;
  story?: Maybe<IOrder_By>;
  suffix?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "life_story" */
export type ILife_Story_Mutation_Response = {
  __typename?: 'life_story_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ILife_Story>;
};

/** on_conflict condition type for table "life_story" */
export type ILife_Story_On_Conflict = {
  constraint: ILife_Story_Constraint;
  update_columns?: Array<ILife_Story_Update_Column>;
  where?: Maybe<ILife_Story_Bool_Exp>;
};

/** Ordering options when selecting data from "life_story". */
export type ILife_Story_Order_By = {
  birth?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  first_name?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  last_name?: Maybe<IOrder_By>;
  middle_name?: Maybe<IOrder_By>;
  obituary?: Maybe<IObituary_Order_By>;
  obituary_id?: Maybe<IOrder_By>;
  passing?: Maybe<IOrder_By>;
  story?: Maybe<IOrder_By>;
  suffix?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** primary key columns input for table: life_story */
export type ILife_Story_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "life_story" */
export enum ILife_Story_Select_Column {
  /** column name */
  Birth = 'birth',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  MiddleName = 'middle_name',
  /** column name */
  ObituaryId = 'obituary_id',
  /** column name */
  Passing = 'passing',
  /** column name */
  Story = 'story',
  /** column name */
  Suffix = 'suffix',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "life_story" */
export type ILife_Story_Set_Input = {
  birth?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamp']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  passing?: Maybe<Scalars['date']>;
  story?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "life_story" */
export enum ILife_Story_Update_Column {
  /** column name */
  Birth = 'birth',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  MiddleName = 'middle_name',
  /** column name */
  ObituaryId = 'obituary_id',
  /** column name */
  Passing = 'passing',
  /** column name */
  Story = 'story',
  /** column name */
  Suffix = 'suffix',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "locations" */
export type ILocations = {
  __typename?: 'locations';
  address?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  approved_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  categories: Array<ICategories_Locations>;
  /** An aggregated array relationship */
  categories_aggregate: ICategories_Locations_Aggregate;
  city?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
  claimed_at?: Maybe<Scalars['timestamptz']>;
  country?: Maybe<Scalars['String']>;
  /** An array relationship */
  coupon_adas: Array<ICoupon_Adas>;
  /** An aggregated array relationship */
  coupon_adas_aggregate: ICoupon_Adas_Aggregate;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  gmaid?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  latitude?: Maybe<Scalars['numeric']>;
  lock_version: Scalars['bigint'];
  longitude?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  metadata: Array<IMetadata_Locations>;
  /** An aggregated array relationship */
  metadata_aggregate: IMetadata_Locations_Aggregate;
  name?: Maybe<Scalars['String']>;
  operating_hours?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  payment_methods: Array<IPayment_Methods>;
  /** An aggregated array relationship */
  payment_methods_aggregate: IPayment_Methods_Aggregate;
  pending?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  phone_numbers: Array<IPhone_Numbers>;
  /** An aggregated array relationship */
  phone_numbers_aggregate: IPhone_Numbers_Aggregate;
  place_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  possible_matches: Array<IPossible_Matches>;
  /** An aggregated array relationship */
  possible_matches_aggregate: IPossible_Matches_Aggregate;
  postal_code?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  publishers: Array<ILocations_Publishers>;
  /** An aggregated array relationship */
  publishers_aggregate: ILocations_Publishers_Aggregate;
  /** An array relationship */
  reviews: Array<IReviews>;
  /** An aggregated array relationship */
  reviews_aggregate: IReviews_Aggregate;
  /** An array relationship */
  service_offers: Array<IService_Offers>;
  /** An aggregated array relationship */
  service_offers_aggregate: IService_Offers_Aggregate;
  site_id?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  submitter?: Maybe<Scalars['String']>;
  tos_agreed_to?: Maybe<Scalars['Boolean']>;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  urls: Array<IUrls>;
  /** An aggregated array relationship */
  urls_aggregate: IUrls_Aggregate;
  weighted_tsv?: Maybe<Scalars['tsvector']>;
};


/** columns and relationships of "locations" */
export type ILocationsCategoriesArgs = {
  distinct_on?: Maybe<Array<ICategories_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategories_Locations_Order_By>>;
  where?: Maybe<ICategories_Locations_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type ILocationsCategories_AggregateArgs = {
  distinct_on?: Maybe<Array<ICategories_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategories_Locations_Order_By>>;
  where?: Maybe<ICategories_Locations_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type ILocationsCoupon_AdasArgs = {
  distinct_on?: Maybe<Array<ICoupon_Adas_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICoupon_Adas_Order_By>>;
  where?: Maybe<ICoupon_Adas_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type ILocationsCoupon_Adas_AggregateArgs = {
  distinct_on?: Maybe<Array<ICoupon_Adas_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICoupon_Adas_Order_By>>;
  where?: Maybe<ICoupon_Adas_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type ILocationsMetadataArgs = {
  distinct_on?: Maybe<Array<IMetadata_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMetadata_Locations_Order_By>>;
  where?: Maybe<IMetadata_Locations_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type ILocationsMetadata_AggregateArgs = {
  distinct_on?: Maybe<Array<IMetadata_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMetadata_Locations_Order_By>>;
  where?: Maybe<IMetadata_Locations_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type ILocationsPayment_MethodsArgs = {
  distinct_on?: Maybe<Array<IPayment_Methods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPayment_Methods_Order_By>>;
  where?: Maybe<IPayment_Methods_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type ILocationsPayment_Methods_AggregateArgs = {
  distinct_on?: Maybe<Array<IPayment_Methods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPayment_Methods_Order_By>>;
  where?: Maybe<IPayment_Methods_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type ILocationsPhone_NumbersArgs = {
  distinct_on?: Maybe<Array<IPhone_Numbers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPhone_Numbers_Order_By>>;
  where?: Maybe<IPhone_Numbers_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type ILocationsPhone_Numbers_AggregateArgs = {
  distinct_on?: Maybe<Array<IPhone_Numbers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPhone_Numbers_Order_By>>;
  where?: Maybe<IPhone_Numbers_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type ILocationsPossible_MatchesArgs = {
  distinct_on?: Maybe<Array<IPossible_Matches_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPossible_Matches_Order_By>>;
  where?: Maybe<IPossible_Matches_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type ILocationsPossible_Matches_AggregateArgs = {
  distinct_on?: Maybe<Array<IPossible_Matches_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPossible_Matches_Order_By>>;
  where?: Maybe<IPossible_Matches_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type ILocationsPublishersArgs = {
  distinct_on?: Maybe<Array<ILocations_Publishers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILocations_Publishers_Order_By>>;
  where?: Maybe<ILocations_Publishers_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type ILocationsPublishers_AggregateArgs = {
  distinct_on?: Maybe<Array<ILocations_Publishers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILocations_Publishers_Order_By>>;
  where?: Maybe<ILocations_Publishers_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type ILocationsReviewsArgs = {
  distinct_on?: Maybe<Array<IReviews_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IReviews_Order_By>>;
  where?: Maybe<IReviews_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type ILocationsReviews_AggregateArgs = {
  distinct_on?: Maybe<Array<IReviews_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IReviews_Order_By>>;
  where?: Maybe<IReviews_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type ILocationsService_OffersArgs = {
  distinct_on?: Maybe<Array<IService_Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IService_Offers_Order_By>>;
  where?: Maybe<IService_Offers_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type ILocationsService_Offers_AggregateArgs = {
  distinct_on?: Maybe<Array<IService_Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IService_Offers_Order_By>>;
  where?: Maybe<IService_Offers_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type ILocationsUrlsArgs = {
  distinct_on?: Maybe<Array<IUrls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IUrls_Order_By>>;
  where?: Maybe<IUrls_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type ILocationsUrls_AggregateArgs = {
  distinct_on?: Maybe<Array<IUrls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IUrls_Order_By>>;
  where?: Maybe<IUrls_Bool_Exp>;
};

/** aggregated selection of "locations" */
export type ILocations_Aggregate = {
  __typename?: 'locations_aggregate';
  aggregate?: Maybe<ILocations_Aggregate_Fields>;
  nodes: Array<ILocations>;
};

/** aggregate fields of "locations" */
export type ILocations_Aggregate_Fields = {
  __typename?: 'locations_aggregate_fields';
  avg?: Maybe<ILocations_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ILocations_Max_Fields>;
  min?: Maybe<ILocations_Min_Fields>;
  stddev?: Maybe<ILocations_Stddev_Fields>;
  stddev_pop?: Maybe<ILocations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ILocations_Stddev_Samp_Fields>;
  sum?: Maybe<ILocations_Sum_Fields>;
  var_pop?: Maybe<ILocations_Var_Pop_Fields>;
  var_samp?: Maybe<ILocations_Var_Samp_Fields>;
  variance?: Maybe<ILocations_Variance_Fields>;
};


/** aggregate fields of "locations" */
export type ILocations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ILocations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "locations" */
export type ILocations_Aggregate_Order_By = {
  avg?: Maybe<ILocations_Avg_Order_By>;
  count?: Maybe<IOrder_By>;
  max?: Maybe<ILocations_Max_Order_By>;
  min?: Maybe<ILocations_Min_Order_By>;
  stddev?: Maybe<ILocations_Stddev_Order_By>;
  stddev_pop?: Maybe<ILocations_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ILocations_Stddev_Samp_Order_By>;
  sum?: Maybe<ILocations_Sum_Order_By>;
  var_pop?: Maybe<ILocations_Var_Pop_Order_By>;
  var_samp?: Maybe<ILocations_Var_Samp_Order_By>;
  variance?: Maybe<ILocations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "locations" */
export type ILocations_Arr_Rel_Insert_Input = {
  data: Array<ILocations_Insert_Input>;
  on_conflict?: Maybe<ILocations_On_Conflict>;
};

/** aggregate avg on columns */
export type ILocations_Avg_Fields = {
  __typename?: 'locations_avg_fields';
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  lock_version?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "locations" */
export type ILocations_Avg_Order_By = {
  id?: Maybe<IOrder_By>;
  latitude?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  longitude?: Maybe<IOrder_By>;
};

/** Boolean expression to filter rows from the table "locations". All fields are combined with a logical 'AND'. */
export type ILocations_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ILocations_Bool_Exp>>>;
  _not?: Maybe<ILocations_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ILocations_Bool_Exp>>>;
  address?: Maybe<IString_Comparison_Exp>;
  address_line_2?: Maybe<IString_Comparison_Exp>;
  approved_at?: Maybe<ITimestamptz_Comparison_Exp>;
  categories?: Maybe<ICategories_Locations_Bool_Exp>;
  city?: Maybe<IString_Comparison_Exp>;
  claimed?: Maybe<IBoolean_Comparison_Exp>;
  claimed_at?: Maybe<ITimestamptz_Comparison_Exp>;
  country?: Maybe<IString_Comparison_Exp>;
  coupon_adas?: Maybe<ICoupon_Adas_Bool_Exp>;
  created_at?: Maybe<ITimestamptz_Comparison_Exp>;
  description?: Maybe<IString_Comparison_Exp>;
  discarded_at?: Maybe<ITimestamptz_Comparison_Exp>;
  gmaid?: Maybe<IString_Comparison_Exp>;
  id?: Maybe<IBigint_Comparison_Exp>;
  latitude?: Maybe<INumeric_Comparison_Exp>;
  lock_version?: Maybe<IBigint_Comparison_Exp>;
  longitude?: Maybe<INumeric_Comparison_Exp>;
  metadata?: Maybe<IMetadata_Locations_Bool_Exp>;
  name?: Maybe<IString_Comparison_Exp>;
  operating_hours?: Maybe<IString_Comparison_Exp>;
  owner?: Maybe<IBoolean_Comparison_Exp>;
  payment_methods?: Maybe<IPayment_Methods_Bool_Exp>;
  pending?: Maybe<IBoolean_Comparison_Exp>;
  phone_numbers?: Maybe<IPhone_Numbers_Bool_Exp>;
  place_id?: Maybe<IString_Comparison_Exp>;
  possible_matches?: Maybe<IPossible_Matches_Bool_Exp>;
  postal_code?: Maybe<IString_Comparison_Exp>;
  profile_id?: Maybe<IString_Comparison_Exp>;
  publishers?: Maybe<ILocations_Publishers_Bool_Exp>;
  reviews?: Maybe<IReviews_Bool_Exp>;
  service_offers?: Maybe<IService_Offers_Bool_Exp>;
  site_id?: Maybe<IString_Comparison_Exp>;
  slug?: Maybe<IString_Comparison_Exp>;
  source_id?: Maybe<IString_Comparison_Exp>;
  state?: Maybe<IString_Comparison_Exp>;
  submitter?: Maybe<IString_Comparison_Exp>;
  tos_agreed_to?: Maybe<IBoolean_Comparison_Exp>;
  updated_at?: Maybe<ITimestamptz_Comparison_Exp>;
  urls?: Maybe<IUrls_Bool_Exp>;
  weighted_tsv?: Maybe<ITsvector_Comparison_Exp>;
};

/** unique or primary key constraints on table "locations" */
export enum ILocations_Constraint {
  /** unique or primary key constraint */
  Idx_17028IndexLocationsOnProfileId = 'idx_17028_index_locations_on_profile_id',
  /** unique or primary key constraint */
  Idx_17028IndexLocationsOnSlug = 'idx_17028_index_locations_on_slug',
  /** unique or primary key constraint */
  Idx_17028Primary = 'idx_17028_primary',
  /** unique or primary key constraint */
  LocationsPkey = 'locations_pkey',
  /** unique or primary key constraint */
  LocationsProfileIdKey = 'locations_profile_id_key',
  /** unique or primary key constraint */
  LocationsSlugKey = 'locations_slug_key',
  /** unique or primary key constraint */
  LocationsSourceIdKey = 'locations_source_id_key'
}

/** input type for incrementing integer column in table "locations" */
export type ILocations_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  latitude?: Maybe<Scalars['numeric']>;
  lock_version?: Maybe<Scalars['bigint']>;
  longitude?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "locations" */
export type ILocations_Insert_Input = {
  address?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  approved_at?: Maybe<Scalars['timestamptz']>;
  categories?: Maybe<ICategories_Locations_Arr_Rel_Insert_Input>;
  city?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
  claimed_at?: Maybe<Scalars['timestamptz']>;
  country?: Maybe<Scalars['String']>;
  coupon_adas?: Maybe<ICoupon_Adas_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  gmaid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  latitude?: Maybe<Scalars['numeric']>;
  lock_version?: Maybe<Scalars['bigint']>;
  longitude?: Maybe<Scalars['numeric']>;
  metadata?: Maybe<IMetadata_Locations_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  operating_hours?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['Boolean']>;
  payment_methods?: Maybe<IPayment_Methods_Arr_Rel_Insert_Input>;
  pending?: Maybe<Scalars['Boolean']>;
  phone_numbers?: Maybe<IPhone_Numbers_Arr_Rel_Insert_Input>;
  place_id?: Maybe<Scalars['String']>;
  possible_matches?: Maybe<IPossible_Matches_Arr_Rel_Insert_Input>;
  postal_code?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['String']>;
  publishers?: Maybe<ILocations_Publishers_Arr_Rel_Insert_Input>;
  reviews?: Maybe<IReviews_Arr_Rel_Insert_Input>;
  service_offers?: Maybe<IService_Offers_Arr_Rel_Insert_Input>;
  site_id?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  submitter?: Maybe<Scalars['String']>;
  tos_agreed_to?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  urls?: Maybe<IUrls_Arr_Rel_Insert_Input>;
  weighted_tsv?: Maybe<Scalars['tsvector']>;
};

/** aggregate max on columns */
export type ILocations_Max_Fields = {
  __typename?: 'locations_max_fields';
  address?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  approved_at?: Maybe<Scalars['timestamptz']>;
  city?: Maybe<Scalars['String']>;
  claimed_at?: Maybe<Scalars['timestamptz']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  gmaid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  latitude?: Maybe<Scalars['numeric']>;
  lock_version?: Maybe<Scalars['bigint']>;
  longitude?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  operating_hours?: Maybe<Scalars['String']>;
  place_id?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['String']>;
  site_id?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  submitter?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "locations" */
export type ILocations_Max_Order_By = {
  address?: Maybe<IOrder_By>;
  address_line_2?: Maybe<IOrder_By>;
  approved_at?: Maybe<IOrder_By>;
  city?: Maybe<IOrder_By>;
  claimed_at?: Maybe<IOrder_By>;
  country?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  description?: Maybe<IOrder_By>;
  discarded_at?: Maybe<IOrder_By>;
  gmaid?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  latitude?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  longitude?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  operating_hours?: Maybe<IOrder_By>;
  place_id?: Maybe<IOrder_By>;
  postal_code?: Maybe<IOrder_By>;
  profile_id?: Maybe<IOrder_By>;
  site_id?: Maybe<IOrder_By>;
  slug?: Maybe<IOrder_By>;
  source_id?: Maybe<IOrder_By>;
  state?: Maybe<IOrder_By>;
  submitter?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type ILocations_Min_Fields = {
  __typename?: 'locations_min_fields';
  address?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  approved_at?: Maybe<Scalars['timestamptz']>;
  city?: Maybe<Scalars['String']>;
  claimed_at?: Maybe<Scalars['timestamptz']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  gmaid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  latitude?: Maybe<Scalars['numeric']>;
  lock_version?: Maybe<Scalars['bigint']>;
  longitude?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  operating_hours?: Maybe<Scalars['String']>;
  place_id?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['String']>;
  site_id?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  submitter?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "locations" */
export type ILocations_Min_Order_By = {
  address?: Maybe<IOrder_By>;
  address_line_2?: Maybe<IOrder_By>;
  approved_at?: Maybe<IOrder_By>;
  city?: Maybe<IOrder_By>;
  claimed_at?: Maybe<IOrder_By>;
  country?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  description?: Maybe<IOrder_By>;
  discarded_at?: Maybe<IOrder_By>;
  gmaid?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  latitude?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  longitude?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  operating_hours?: Maybe<IOrder_By>;
  place_id?: Maybe<IOrder_By>;
  postal_code?: Maybe<IOrder_By>;
  profile_id?: Maybe<IOrder_By>;
  site_id?: Maybe<IOrder_By>;
  slug?: Maybe<IOrder_By>;
  source_id?: Maybe<IOrder_By>;
  state?: Maybe<IOrder_By>;
  submitter?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "locations" */
export type ILocations_Mutation_Response = {
  __typename?: 'locations_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ILocations>;
};

/** input type for inserting object relation for remote table "locations" */
export type ILocations_Obj_Rel_Insert_Input = {
  data: ILocations_Insert_Input;
  on_conflict?: Maybe<ILocations_On_Conflict>;
};

/** on conflict condition type for table "locations" */
export type ILocations_On_Conflict = {
  constraint: ILocations_Constraint;
  update_columns: Array<ILocations_Update_Column>;
  where?: Maybe<ILocations_Bool_Exp>;
};

/** ordering options when selecting data from "locations" */
export type ILocations_Order_By = {
  address?: Maybe<IOrder_By>;
  address_line_2?: Maybe<IOrder_By>;
  approved_at?: Maybe<IOrder_By>;
  categories_aggregate?: Maybe<ICategories_Locations_Aggregate_Order_By>;
  city?: Maybe<IOrder_By>;
  claimed?: Maybe<IOrder_By>;
  claimed_at?: Maybe<IOrder_By>;
  country?: Maybe<IOrder_By>;
  coupon_adas_aggregate?: Maybe<ICoupon_Adas_Aggregate_Order_By>;
  created_at?: Maybe<IOrder_By>;
  description?: Maybe<IOrder_By>;
  discarded_at?: Maybe<IOrder_By>;
  gmaid?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  latitude?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  longitude?: Maybe<IOrder_By>;
  metadata_aggregate?: Maybe<IMetadata_Locations_Aggregate_Order_By>;
  name?: Maybe<IOrder_By>;
  operating_hours?: Maybe<IOrder_By>;
  owner?: Maybe<IOrder_By>;
  payment_methods_aggregate?: Maybe<IPayment_Methods_Aggregate_Order_By>;
  pending?: Maybe<IOrder_By>;
  phone_numbers_aggregate?: Maybe<IPhone_Numbers_Aggregate_Order_By>;
  place_id?: Maybe<IOrder_By>;
  possible_matches_aggregate?: Maybe<IPossible_Matches_Aggregate_Order_By>;
  postal_code?: Maybe<IOrder_By>;
  profile_id?: Maybe<IOrder_By>;
  publishers_aggregate?: Maybe<ILocations_Publishers_Aggregate_Order_By>;
  reviews_aggregate?: Maybe<IReviews_Aggregate_Order_By>;
  service_offers_aggregate?: Maybe<IService_Offers_Aggregate_Order_By>;
  site_id?: Maybe<IOrder_By>;
  slug?: Maybe<IOrder_By>;
  source_id?: Maybe<IOrder_By>;
  state?: Maybe<IOrder_By>;
  submitter?: Maybe<IOrder_By>;
  tos_agreed_to?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  urls_aggregate?: Maybe<IUrls_Aggregate_Order_By>;
  weighted_tsv?: Maybe<IOrder_By>;
};

/** primary key columns input for table: "locations" */
export type ILocations_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** columns and relationships of "locations_publishers" */
export type ILocations_Publishers = {
  __typename?: 'locations_publishers';
  id: Scalars['bigint'];
  /** An object relationship */
  location: ILocations;
  location_id: Scalars['bigint'];
  /** An object relationship */
  publisher: IPublishers;
  publisher_id: Scalars['bigint'];
};

/** aggregated selection of "locations_publishers" */
export type ILocations_Publishers_Aggregate = {
  __typename?: 'locations_publishers_aggregate';
  aggregate?: Maybe<ILocations_Publishers_Aggregate_Fields>;
  nodes: Array<ILocations_Publishers>;
};

/** aggregate fields of "locations_publishers" */
export type ILocations_Publishers_Aggregate_Fields = {
  __typename?: 'locations_publishers_aggregate_fields';
  avg?: Maybe<ILocations_Publishers_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ILocations_Publishers_Max_Fields>;
  min?: Maybe<ILocations_Publishers_Min_Fields>;
  stddev?: Maybe<ILocations_Publishers_Stddev_Fields>;
  stddev_pop?: Maybe<ILocations_Publishers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ILocations_Publishers_Stddev_Samp_Fields>;
  sum?: Maybe<ILocations_Publishers_Sum_Fields>;
  var_pop?: Maybe<ILocations_Publishers_Var_Pop_Fields>;
  var_samp?: Maybe<ILocations_Publishers_Var_Samp_Fields>;
  variance?: Maybe<ILocations_Publishers_Variance_Fields>;
};


/** aggregate fields of "locations_publishers" */
export type ILocations_Publishers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ILocations_Publishers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "locations_publishers" */
export type ILocations_Publishers_Aggregate_Order_By = {
  avg?: Maybe<ILocations_Publishers_Avg_Order_By>;
  count?: Maybe<IOrder_By>;
  max?: Maybe<ILocations_Publishers_Max_Order_By>;
  min?: Maybe<ILocations_Publishers_Min_Order_By>;
  stddev?: Maybe<ILocations_Publishers_Stddev_Order_By>;
  stddev_pop?: Maybe<ILocations_Publishers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ILocations_Publishers_Stddev_Samp_Order_By>;
  sum?: Maybe<ILocations_Publishers_Sum_Order_By>;
  var_pop?: Maybe<ILocations_Publishers_Var_Pop_Order_By>;
  var_samp?: Maybe<ILocations_Publishers_Var_Samp_Order_By>;
  variance?: Maybe<ILocations_Publishers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "locations_publishers" */
export type ILocations_Publishers_Arr_Rel_Insert_Input = {
  data: Array<ILocations_Publishers_Insert_Input>;
  on_conflict?: Maybe<ILocations_Publishers_On_Conflict>;
};

/** aggregate avg on columns */
export type ILocations_Publishers_Avg_Fields = {
  __typename?: 'locations_publishers_avg_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  publisher_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "locations_publishers" */
export type ILocations_Publishers_Avg_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  publisher_id?: Maybe<IOrder_By>;
};

/** Boolean expression to filter rows from the table "locations_publishers". All fields are combined with a logical 'AND'. */
export type ILocations_Publishers_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ILocations_Publishers_Bool_Exp>>>;
  _not?: Maybe<ILocations_Publishers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ILocations_Publishers_Bool_Exp>>>;
  id?: Maybe<IBigint_Comparison_Exp>;
  location?: Maybe<ILocations_Bool_Exp>;
  location_id?: Maybe<IBigint_Comparison_Exp>;
  publisher?: Maybe<IPublishers_Bool_Exp>;
  publisher_id?: Maybe<IBigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "locations_publishers" */
export enum ILocations_Publishers_Constraint {
  /** unique or primary key constraint */
  Idx_17039Primary = 'idx_17039_primary'
}

/** input type for incrementing integer column in table "locations_publishers" */
export type ILocations_Publishers_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  publisher_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "locations_publishers" */
export type ILocations_Publishers_Insert_Input = {
  id?: Maybe<Scalars['bigint']>;
  location?: Maybe<ILocations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['bigint']>;
  publisher?: Maybe<IPublishers_Obj_Rel_Insert_Input>;
  publisher_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type ILocations_Publishers_Max_Fields = {
  __typename?: 'locations_publishers_max_fields';
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  publisher_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "locations_publishers" */
export type ILocations_Publishers_Max_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  publisher_id?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type ILocations_Publishers_Min_Fields = {
  __typename?: 'locations_publishers_min_fields';
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  publisher_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "locations_publishers" */
export type ILocations_Publishers_Min_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  publisher_id?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "locations_publishers" */
export type ILocations_Publishers_Mutation_Response = {
  __typename?: 'locations_publishers_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ILocations_Publishers>;
};

/** input type for inserting object relation for remote table "locations_publishers" */
export type ILocations_Publishers_Obj_Rel_Insert_Input = {
  data: ILocations_Publishers_Insert_Input;
  on_conflict?: Maybe<ILocations_Publishers_On_Conflict>;
};

/** on conflict condition type for table "locations_publishers" */
export type ILocations_Publishers_On_Conflict = {
  constraint: ILocations_Publishers_Constraint;
  update_columns: Array<ILocations_Publishers_Update_Column>;
  where?: Maybe<ILocations_Publishers_Bool_Exp>;
};

/** ordering options when selecting data from "locations_publishers" */
export type ILocations_Publishers_Order_By = {
  id?: Maybe<IOrder_By>;
  location?: Maybe<ILocations_Order_By>;
  location_id?: Maybe<IOrder_By>;
  publisher?: Maybe<IPublishers_Order_By>;
  publisher_id?: Maybe<IOrder_By>;
};

/** select columns of table "locations_publishers" */
export enum ILocations_Publishers_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  PublisherId = 'publisher_id'
}

/** input type for updating data in table "locations_publishers" */
export type ILocations_Publishers_Set_Input = {
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  publisher_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type ILocations_Publishers_Stddev_Fields = {
  __typename?: 'locations_publishers_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  publisher_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "locations_publishers" */
export type ILocations_Publishers_Stddev_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  publisher_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_pop on columns */
export type ILocations_Publishers_Stddev_Pop_Fields = {
  __typename?: 'locations_publishers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  publisher_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "locations_publishers" */
export type ILocations_Publishers_Stddev_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  publisher_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_samp on columns */
export type ILocations_Publishers_Stddev_Samp_Fields = {
  __typename?: 'locations_publishers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  publisher_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "locations_publishers" */
export type ILocations_Publishers_Stddev_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  publisher_id?: Maybe<IOrder_By>;
};

/** aggregate sum on columns */
export type ILocations_Publishers_Sum_Fields = {
  __typename?: 'locations_publishers_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  publisher_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "locations_publishers" */
export type ILocations_Publishers_Sum_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  publisher_id?: Maybe<IOrder_By>;
};

/** update columns of table "locations_publishers" */
export enum ILocations_Publishers_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  PublisherId = 'publisher_id'
}

/** aggregate var_pop on columns */
export type ILocations_Publishers_Var_Pop_Fields = {
  __typename?: 'locations_publishers_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  publisher_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "locations_publishers" */
export type ILocations_Publishers_Var_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  publisher_id?: Maybe<IOrder_By>;
};

/** aggregate var_samp on columns */
export type ILocations_Publishers_Var_Samp_Fields = {
  __typename?: 'locations_publishers_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  publisher_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "locations_publishers" */
export type ILocations_Publishers_Var_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  publisher_id?: Maybe<IOrder_By>;
};

/** aggregate variance on columns */
export type ILocations_Publishers_Variance_Fields = {
  __typename?: 'locations_publishers_variance_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  publisher_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "locations_publishers" */
export type ILocations_Publishers_Variance_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  publisher_id?: Maybe<IOrder_By>;
};

/** select columns of table "locations" */
export enum ILocations_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  AddressLine_2 = 'address_line_2',
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  City = 'city',
  /** column name */
  Claimed = 'claimed',
  /** column name */
  ClaimedAt = 'claimed_at',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DiscardedAt = 'discarded_at',
  /** column name */
  Gmaid = 'gmaid',
  /** column name */
  Id = 'id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  LockVersion = 'lock_version',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Name = 'name',
  /** column name */
  OperatingHours = 'operating_hours',
  /** column name */
  Owner = 'owner',
  /** column name */
  Pending = 'pending',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  PostalCode = 'postal_code',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  SiteId = 'site_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  State = 'state',
  /** column name */
  Submitter = 'submitter',
  /** column name */
  TosAgreedTo = 'tos_agreed_to',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WeightedTsv = 'weighted_tsv'
}

/** input type for updating data in table "locations" */
export type ILocations_Set_Input = {
  address?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  approved_at?: Maybe<Scalars['timestamptz']>;
  city?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
  claimed_at?: Maybe<Scalars['timestamptz']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  gmaid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  latitude?: Maybe<Scalars['numeric']>;
  lock_version?: Maybe<Scalars['bigint']>;
  longitude?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  operating_hours?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['Boolean']>;
  pending?: Maybe<Scalars['Boolean']>;
  place_id?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['String']>;
  site_id?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  submitter?: Maybe<Scalars['String']>;
  tos_agreed_to?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  weighted_tsv?: Maybe<Scalars['tsvector']>;
};

/** aggregate stddev on columns */
export type ILocations_Stddev_Fields = {
  __typename?: 'locations_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  lock_version?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "locations" */
export type ILocations_Stddev_Order_By = {
  id?: Maybe<IOrder_By>;
  latitude?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  longitude?: Maybe<IOrder_By>;
};

/** aggregate stddev_pop on columns */
export type ILocations_Stddev_Pop_Fields = {
  __typename?: 'locations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  lock_version?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "locations" */
export type ILocations_Stddev_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
  latitude?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  longitude?: Maybe<IOrder_By>;
};

/** aggregate stddev_samp on columns */
export type ILocations_Stddev_Samp_Fields = {
  __typename?: 'locations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  lock_version?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "locations" */
export type ILocations_Stddev_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
  latitude?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  longitude?: Maybe<IOrder_By>;
};

/** aggregate sum on columns */
export type ILocations_Sum_Fields = {
  __typename?: 'locations_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  latitude?: Maybe<Scalars['numeric']>;
  lock_version?: Maybe<Scalars['bigint']>;
  longitude?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "locations" */
export type ILocations_Sum_Order_By = {
  id?: Maybe<IOrder_By>;
  latitude?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  longitude?: Maybe<IOrder_By>;
};

/** update columns of table "locations" */
export enum ILocations_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  AddressLine_2 = 'address_line_2',
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  City = 'city',
  /** column name */
  Claimed = 'claimed',
  /** column name */
  ClaimedAt = 'claimed_at',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DiscardedAt = 'discarded_at',
  /** column name */
  Gmaid = 'gmaid',
  /** column name */
  Id = 'id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  LockVersion = 'lock_version',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Name = 'name',
  /** column name */
  OperatingHours = 'operating_hours',
  /** column name */
  Owner = 'owner',
  /** column name */
  Pending = 'pending',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  PostalCode = 'postal_code',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  SiteId = 'site_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  State = 'state',
  /** column name */
  Submitter = 'submitter',
  /** column name */
  TosAgreedTo = 'tos_agreed_to',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WeightedTsv = 'weighted_tsv'
}

/** aggregate var_pop on columns */
export type ILocations_Var_Pop_Fields = {
  __typename?: 'locations_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  lock_version?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "locations" */
export type ILocations_Var_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
  latitude?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  longitude?: Maybe<IOrder_By>;
};

/** aggregate var_samp on columns */
export type ILocations_Var_Samp_Fields = {
  __typename?: 'locations_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  lock_version?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "locations" */
export type ILocations_Var_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
  latitude?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  longitude?: Maybe<IOrder_By>;
};

/** aggregate variance on columns */
export type ILocations_Variance_Fields = {
  __typename?: 'locations_variance_fields';
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  lock_version?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "locations" */
export type ILocations_Variance_Order_By = {
  id?: Maybe<IOrder_By>;
  latitude?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  longitude?: Maybe<IOrder_By>;
};

/** columns and relationships of "memories" */
export type IMemories = {
  __typename?: 'memories';
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  /** An array relationship */
  events: Array<IEvents>;
  /** An aggregate relationship */
  events_aggregate: IEvents_Aggregate;
  first_name: Scalars['String'];
  id: Scalars['uuid'];
  /** An array relationship */
  images: Array<IImages>;
  /** An aggregate relationship */
  images_aggregate: IImages_Aggregate;
  last_name: Scalars['String'];
  /** An object relationship */
  lastest_event?: Maybe<ILatest_Events>;
  /** An object relationship */
  obit?: Maybe<IObits>;
  obit_id: Scalars['uuid'];
  parent_id?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
  /** An object relationship */
  topic?: Maybe<ITopics>;
  topic_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "memories" */
export type IMemoriesEventsArgs = {
  distinct_on?: Maybe<Array<IEvents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IEvents_Order_By>>;
  where?: Maybe<IEvents_Bool_Exp>;
};


/** columns and relationships of "memories" */
export type IMemoriesEvents_AggregateArgs = {
  distinct_on?: Maybe<Array<IEvents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IEvents_Order_By>>;
  where?: Maybe<IEvents_Bool_Exp>;
};


/** columns and relationships of "memories" */
export type IMemoriesImagesArgs = {
  distinct_on?: Maybe<Array<IImages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IImages_Order_By>>;
  where?: Maybe<IImages_Bool_Exp>;
};


/** columns and relationships of "memories" */
export type IMemoriesImages_AggregateArgs = {
  distinct_on?: Maybe<Array<IImages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IImages_Order_By>>;
  where?: Maybe<IImages_Bool_Exp>;
};

/** aggregated selection of "memories" */
export type IMemories_Aggregate = {
  __typename?: 'memories_aggregate';
  aggregate?: Maybe<IMemories_Aggregate_Fields>;
  nodes: Array<IMemories>;
};

export type IMemories_Aggregate_Bool_Exp = {
  count?: Maybe<IMemories_Aggregate_Bool_Exp_Count>;
};

export type IMemories_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IMemories_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IMemories_Bool_Exp>;
  predicate: IInt_Comparison_Exp;
};

/** aggregate fields of "memories" */
export type IMemories_Aggregate_Fields = {
  __typename?: 'memories_aggregate_fields';
  avg?: Maybe<IMemories_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IMemories_Max_Fields>;
  min?: Maybe<IMemories_Min_Fields>;
  stddev?: Maybe<IMemories_Stddev_Fields>;
  stddev_pop?: Maybe<IMemories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IMemories_Stddev_Samp_Fields>;
  sum?: Maybe<IMemories_Sum_Fields>;
  var_pop?: Maybe<IMemories_Var_Pop_Fields>;
  var_samp?: Maybe<IMemories_Var_Samp_Fields>;
  variance?: Maybe<IMemories_Variance_Fields>;
};


/** aggregate fields of "memories" */
export type IMemories_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IMemories_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "memories" */
export type IMemories_Aggregate_Order_By = {
  avg?: Maybe<IMemories_Avg_Order_By>;
  count?: Maybe<IOrder_By>;
  max?: Maybe<IMemories_Max_Order_By>;
  min?: Maybe<IMemories_Min_Order_By>;
  stddev?: Maybe<IMemories_Stddev_Order_By>;
  stddev_pop?: Maybe<IMemories_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IMemories_Stddev_Samp_Order_By>;
  sum?: Maybe<IMemories_Sum_Order_By>;
  var_pop?: Maybe<IMemories_Var_Pop_Order_By>;
  var_samp?: Maybe<IMemories_Var_Samp_Order_By>;
  variance?: Maybe<IMemories_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "memories" */
export type IMemories_Arr_Rel_Insert_Input = {
  data: Array<IMemories_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<IMemories_On_Conflict>;
};

/** aggregate avg on columns */
export type IMemories_Avg_Fields = {
  __typename?: 'memories_avg_fields';
  topic_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "memories" */
export type IMemories_Avg_Order_By = {
  topic_id?: Maybe<IOrder_By>;
};

/** Boolean expression to filter rows from the table "memories". All fields are combined with a logical 'AND'. */
export type IMemories_Bool_Exp = {
  _and?: Maybe<Array<IMemories_Bool_Exp>>;
  _not?: Maybe<IMemories_Bool_Exp>;
  _or?: Maybe<Array<IMemories_Bool_Exp>>;
  created_at?: Maybe<ITimestamptz_Comparison_Exp>;
  email?: Maybe<IString_Comparison_Exp>;
  events?: Maybe<IEvents_Bool_Exp>;
  events_aggregate?: Maybe<IEvents_Aggregate_Bool_Exp>;
  first_name?: Maybe<IString_Comparison_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  images?: Maybe<IImages_Bool_Exp>;
  images_aggregate?: Maybe<IImages_Aggregate_Bool_Exp>;
  last_name?: Maybe<IString_Comparison_Exp>;
  lastest_event?: Maybe<ILatest_Events_Bool_Exp>;
  obit?: Maybe<IObits_Bool_Exp>;
  obit_id?: Maybe<IUuid_Comparison_Exp>;
  parent_id?: Maybe<IUuid_Comparison_Exp>;
  text?: Maybe<IString_Comparison_Exp>;
  topic?: Maybe<ITopics_Bool_Exp>;
  topic_id?: Maybe<IInt_Comparison_Exp>;
  updated_at?: Maybe<ITimestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "memories" */
export enum IMemories_Constraint {
  /** unique or primary key constraint on columns "id" */
  MemoriesIdKey = 'memories_id_key',
  /** unique or primary key constraint on columns "obit_id", "id" */
  MemoriesPkey = 'memories_pkey'
}

/** input type for incrementing numeric columns in table "memories" */
export type IMemories_Inc_Input = {
  topic_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "memories" */
export type IMemories_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  events?: Maybe<IEvents_Arr_Rel_Insert_Input>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  images?: Maybe<IImages_Arr_Rel_Insert_Input>;
  last_name?: Maybe<Scalars['String']>;
  lastest_event?: Maybe<ILatest_Events_Obj_Rel_Insert_Input>;
  obit?: Maybe<IObits_Obj_Rel_Insert_Input>;
  obit_id?: Maybe<Scalars['uuid']>;
  parent_id?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
  topic?: Maybe<ITopics_Obj_Rel_Insert_Input>;
  topic_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type IMemories_Max_Fields = {
  __typename?: 'memories_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  obit_id?: Maybe<Scalars['uuid']>;
  parent_id?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
  topic_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "memories" */
export type IMemories_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  email?: Maybe<IOrder_By>;
  first_name?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  last_name?: Maybe<IOrder_By>;
  obit_id?: Maybe<IOrder_By>;
  parent_id?: Maybe<IOrder_By>;
  text?: Maybe<IOrder_By>;
  topic_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IMemories_Min_Fields = {
  __typename?: 'memories_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  obit_id?: Maybe<Scalars['uuid']>;
  parent_id?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
  topic_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "memories" */
export type IMemories_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  email?: Maybe<IOrder_By>;
  first_name?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  last_name?: Maybe<IOrder_By>;
  obit_id?: Maybe<IOrder_By>;
  parent_id?: Maybe<IOrder_By>;
  text?: Maybe<IOrder_By>;
  topic_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "memories" */
export type IMemories_Mutation_Response = {
  __typename?: 'memories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IMemories>;
};

/** input type for inserting object relation for remote table "memories" */
export type IMemories_Obj_Rel_Insert_Input = {
  data: IMemories_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<IMemories_On_Conflict>;
};

/** on_conflict condition type for table "memories" */
export type IMemories_On_Conflict = {
  constraint: IMemories_Constraint;
  update_columns?: Array<IMemories_Update_Column>;
  where?: Maybe<IMemories_Bool_Exp>;
};

/** Ordering options when selecting data from "memories". */
export type IMemories_Order_By = {
  created_at?: Maybe<IOrder_By>;
  email?: Maybe<IOrder_By>;
  events_aggregate?: Maybe<IEvents_Aggregate_Order_By>;
  first_name?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  images_aggregate?: Maybe<IImages_Aggregate_Order_By>;
  last_name?: Maybe<IOrder_By>;
  lastest_event?: Maybe<ILatest_Events_Order_By>;
  obit?: Maybe<IObits_Order_By>;
  obit_id?: Maybe<IOrder_By>;
  parent_id?: Maybe<IOrder_By>;
  text?: Maybe<IOrder_By>;
  topic?: Maybe<ITopics_Order_By>;
  topic_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** primary key columns input for table: memories */
export type IMemories_Pk_Columns_Input = {
  id: Scalars['uuid'];
  obit_id: Scalars['uuid'];
};

/** select columns of table "memories" */
export enum IMemories_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  ObitId = 'obit_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Text = 'text',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "memories" */
export type IMemories_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  obit_id?: Maybe<Scalars['uuid']>;
  parent_id?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
  topic_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type IMemories_Stddev_Fields = {
  __typename?: 'memories_stddev_fields';
  topic_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "memories" */
export type IMemories_Stddev_Order_By = {
  topic_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IMemories_Stddev_Pop_Fields = {
  __typename?: 'memories_stddev_pop_fields';
  topic_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "memories" */
export type IMemories_Stddev_Pop_Order_By = {
  topic_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IMemories_Stddev_Samp_Fields = {
  __typename?: 'memories_stddev_samp_fields';
  topic_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "memories" */
export type IMemories_Stddev_Samp_Order_By = {
  topic_id?: Maybe<IOrder_By>;
};

/** Streaming cursor of the table "memories" */
export type IMemories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IMemories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<ICursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IMemories_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  obit_id?: Maybe<Scalars['uuid']>;
  parent_id?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
  topic_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type IMemories_Sum_Fields = {
  __typename?: 'memories_sum_fields';
  topic_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "memories" */
export type IMemories_Sum_Order_By = {
  topic_id?: Maybe<IOrder_By>;
};

/** update columns of table "memories" */
export enum IMemories_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  ObitId = 'obit_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Text = 'text',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type IMemories_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<IMemories_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<IMemories_Set_Input>;
  /** filter the rows which have to be updated */
  where: IMemories_Bool_Exp;
};

/** aggregate var_pop on columns */
export type IMemories_Var_Pop_Fields = {
  __typename?: 'memories_var_pop_fields';
  topic_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "memories" */
export type IMemories_Var_Pop_Order_By = {
  topic_id?: Maybe<IOrder_By>;
};

/** aggregate var_samp on columns */
export type IMemories_Var_Samp_Fields = {
  __typename?: 'memories_var_samp_fields';
  topic_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "memories" */
export type IMemories_Var_Samp_Order_By = {
  topic_id?: Maybe<IOrder_By>;
};

/** aggregate variance on columns */
export type IMemories_Variance_Fields = {
  __typename?: 'memories_variance_fields';
  topic_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "memories" */
export type IMemories_Variance_Order_By = {
  topic_id?: Maybe<IOrder_By>;
};

export type IMemory = {
  __typename?: 'memory';
  id?: Maybe<Scalars['String']>;
};

/** columns and relationships of "metadata_locations" */
export type IMetadata_Locations = {
  __typename?: 'metadata_locations';
  id: Scalars['bigint'];
  location_id: Scalars['bigint'];
  name: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "metadata_locations" */
export type IMetadata_Locations_Aggregate = {
  __typename?: 'metadata_locations_aggregate';
  aggregate?: Maybe<IMetadata_Locations_Aggregate_Fields>;
  nodes: Array<IMetadata_Locations>;
};

/** aggregate fields of "metadata_locations" */
export type IMetadata_Locations_Aggregate_Fields = {
  __typename?: 'metadata_locations_aggregate_fields';
  avg?: Maybe<IMetadata_Locations_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<IMetadata_Locations_Max_Fields>;
  min?: Maybe<IMetadata_Locations_Min_Fields>;
  stddev?: Maybe<IMetadata_Locations_Stddev_Fields>;
  stddev_pop?: Maybe<IMetadata_Locations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IMetadata_Locations_Stddev_Samp_Fields>;
  sum?: Maybe<IMetadata_Locations_Sum_Fields>;
  var_pop?: Maybe<IMetadata_Locations_Var_Pop_Fields>;
  var_samp?: Maybe<IMetadata_Locations_Var_Samp_Fields>;
  variance?: Maybe<IMetadata_Locations_Variance_Fields>;
};


/** aggregate fields of "metadata_locations" */
export type IMetadata_Locations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IMetadata_Locations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "metadata_locations" */
export type IMetadata_Locations_Aggregate_Order_By = {
  avg?: Maybe<IMetadata_Locations_Avg_Order_By>;
  count?: Maybe<IOrder_By>;
  max?: Maybe<IMetadata_Locations_Max_Order_By>;
  min?: Maybe<IMetadata_Locations_Min_Order_By>;
  stddev?: Maybe<IMetadata_Locations_Stddev_Order_By>;
  stddev_pop?: Maybe<IMetadata_Locations_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IMetadata_Locations_Stddev_Samp_Order_By>;
  sum?: Maybe<IMetadata_Locations_Sum_Order_By>;
  var_pop?: Maybe<IMetadata_Locations_Var_Pop_Order_By>;
  var_samp?: Maybe<IMetadata_Locations_Var_Samp_Order_By>;
  variance?: Maybe<IMetadata_Locations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "metadata_locations" */
export type IMetadata_Locations_Arr_Rel_Insert_Input = {
  data: Array<IMetadata_Locations_Insert_Input>;
  on_conflict?: Maybe<IMetadata_Locations_On_Conflict>;
};

/** aggregate avg on columns */
export type IMetadata_Locations_Avg_Fields = {
  __typename?: 'metadata_locations_avg_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "metadata_locations" */
export type IMetadata_Locations_Avg_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** Boolean expression to filter rows from the table "metadata_locations". All fields are combined with a logical 'AND'. */
export type IMetadata_Locations_Bool_Exp = {
  _and?: Maybe<Array<Maybe<IMetadata_Locations_Bool_Exp>>>;
  _not?: Maybe<IMetadata_Locations_Bool_Exp>;
  _or?: Maybe<Array<Maybe<IMetadata_Locations_Bool_Exp>>>;
  id?: Maybe<IBigint_Comparison_Exp>;
  location_id?: Maybe<IBigint_Comparison_Exp>;
  name?: Maybe<IString_Comparison_Exp>;
  value?: Maybe<IString_Comparison_Exp>;
};

/** unique or primary key constraints on table "metadata_locations" */
export enum IMetadata_Locations_Constraint {
  /** unique or primary key constraint */
  MetadataLocationsPkey = 'metadata_locations_pkey'
}

/** input type for incrementing integer column in table "metadata_locations" */
export type IMetadata_Locations_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "metadata_locations" */
export type IMetadata_Locations_Insert_Input = {
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type IMetadata_Locations_Max_Fields = {
  __typename?: 'metadata_locations_max_fields';
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "metadata_locations" */
export type IMetadata_Locations_Max_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  value?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IMetadata_Locations_Min_Fields = {
  __typename?: 'metadata_locations_min_fields';
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "metadata_locations" */
export type IMetadata_Locations_Min_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  value?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "metadata_locations" */
export type IMetadata_Locations_Mutation_Response = {
  __typename?: 'metadata_locations_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<IMetadata_Locations>;
};

/** input type for inserting object relation for remote table "metadata_locations" */
export type IMetadata_Locations_Obj_Rel_Insert_Input = {
  data: IMetadata_Locations_Insert_Input;
  on_conflict?: Maybe<IMetadata_Locations_On_Conflict>;
};

/** on conflict condition type for table "metadata_locations" */
export type IMetadata_Locations_On_Conflict = {
  constraint: IMetadata_Locations_Constraint;
  update_columns: Array<IMetadata_Locations_Update_Column>;
  where?: Maybe<IMetadata_Locations_Bool_Exp>;
};

/** ordering options when selecting data from "metadata_locations" */
export type IMetadata_Locations_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  value?: Maybe<IOrder_By>;
};

/** primary key columns input for table: "metadata_locations" */
export type IMetadata_Locations_Pk_Columns_Input = {
  location_id: Scalars['bigint'];
  name: Scalars['String'];
  value: Scalars['String'];
};

/** select columns of table "metadata_locations" */
export enum IMetadata_Locations_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "metadata_locations" */
export type IMetadata_Locations_Set_Input = {
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type IMetadata_Locations_Stddev_Fields = {
  __typename?: 'metadata_locations_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "metadata_locations" */
export type IMetadata_Locations_Stddev_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IMetadata_Locations_Stddev_Pop_Fields = {
  __typename?: 'metadata_locations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "metadata_locations" */
export type IMetadata_Locations_Stddev_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IMetadata_Locations_Stddev_Samp_Fields = {
  __typename?: 'metadata_locations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "metadata_locations" */
export type IMetadata_Locations_Stddev_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate sum on columns */
export type IMetadata_Locations_Sum_Fields = {
  __typename?: 'metadata_locations_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "metadata_locations" */
export type IMetadata_Locations_Sum_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** update columns of table "metadata_locations" */
export enum IMetadata_Locations_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type IMetadata_Locations_Var_Pop_Fields = {
  __typename?: 'metadata_locations_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "metadata_locations" */
export type IMetadata_Locations_Var_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate var_samp on columns */
export type IMetadata_Locations_Var_Samp_Fields = {
  __typename?: 'metadata_locations_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "metadata_locations" */
export type IMetadata_Locations_Var_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate variance on columns */
export type IMetadata_Locations_Variance_Fields = {
  __typename?: 'metadata_locations_variance_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "metadata_locations" */
export type IMetadata_Locations_Variance_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** mutation root */
export type IMutation_Root = {
  __typename?: 'mutation_root';
  /** Validate and insert a memory */
  add_memory?: Maybe<IMemory>;
  /** delete data from the table: "archived_obituary" */
  delete_archived_obituary?: Maybe<IArchived_Obituary_Mutation_Response>;
  /** delete single row from the table: "archived_obituary" */
  delete_archived_obituary_by_pk?: Maybe<IArchived_Obituary>;
  /** delete data from the table: "capi_config" */
  delete_capi_config?: Maybe<ICapi_Config_Mutation_Response>;
  /** delete single row from the table: "capi_config" */
  delete_capi_config_by_pk?: Maybe<ICapi_Config>;
  /** delete data from the table: "categories" */
  delete_categories?: Maybe<ICategories_Mutation_Response>;
  /** delete data from the table: "categories_locations" */
  delete_categories_locations?: Maybe<ICategories_Locations_Mutation_Response>;
  /** delete data from the table: "categories_verticals" */
  delete_categories_verticals?: Maybe<ICategories_Verticals_Mutation_Response>;
  /** delete data from the table: "category_image_urls" */
  delete_category_image_urls?: Maybe<ICategory_Image_Urls_Mutation_Response>;
  /** delete data from the table: "category_keywords" */
  delete_category_keywords?: Maybe<ICategory_Keywords_Mutation_Response>;
  /** delete data from the table: "coupon_adas" */
  delete_coupon_adas?: Maybe<ICoupon_Adas_Mutation_Response>;
  /** delete data from the table: "daily_locations_counts" */
  delete_daily_locations_counts?: Maybe<IDaily_Locations_Counts_Mutation_Response>;
  /** delete data from the table: "display_obituary" */
  delete_display_obituary?: Maybe<IDisplay_Obituary_Mutation_Response>;
  /** delete single row from the table: "display_obituary" */
  delete_display_obituary_by_pk?: Maybe<IDisplay_Obituary>;
  /** delete data from the table: "display_obituary_publication" */
  delete_display_obituary_publication?: Maybe<IDisplay_Obituary_Publication_Mutation_Response>;
  /** delete single row from the table: "display_obituary_publication" */
  delete_display_obituary_publication_by_pk?: Maybe<IDisplay_Obituary_Publication>;
  /** delete data from the table: "display_obituary_tag" */
  delete_display_obituary_tag?: Maybe<IDisplay_Obituary_Tag_Mutation_Response>;
  /** delete single row from the table: "display_obituary_tag" */
  delete_display_obituary_tag_by_pk?: Maybe<IDisplay_Obituary_Tag>;
  /** delete data from the table: "display_run_date" */
  delete_display_run_date?: Maybe<IDisplay_Run_Date_Mutation_Response>;
  /** delete single row from the table: "display_run_date" */
  delete_display_run_date_by_pk?: Maybe<IDisplay_Run_Date>;
  /** delete data from the table: "duplicate_locations" */
  delete_duplicate_locations?: Maybe<IDuplicate_Locations_Mutation_Response>;
  /** delete data from the table: "events" */
  delete_events?: Maybe<IEvents_Mutation_Response>;
  /** delete single row from the table: "events" */
  delete_events_by_pk?: Maybe<IEvents>;
  /** delete data from the table: "events_types" */
  delete_events_types?: Maybe<IEvents_Types_Mutation_Response>;
  /** delete single row from the table: "events_types" */
  delete_events_types_by_pk?: Maybe<IEvents_Types>;
  /** delete data from the table: "external_link" */
  delete_external_link?: Maybe<IExternal_Link_Mutation_Response>;
  /** delete single row from the table: "external_link" */
  delete_external_link_by_pk?: Maybe<IExternal_Link>;
  /** delete data from the table: "external_link_source" */
  delete_external_link_source?: Maybe<IExternal_Link_Source_Mutation_Response>;
  /** delete single row from the table: "external_link_source" */
  delete_external_link_source_by_pk?: Maybe<IExternal_Link_Source>;
  /** delete data from the table: "external_link_type" */
  delete_external_link_type?: Maybe<IExternal_Link_Type_Mutation_Response>;
  /** delete single row from the table: "external_link_type" */
  delete_external_link_type_by_pk?: Maybe<IExternal_Link_Type>;
  /** delete data from the table: "facebook_config" */
  delete_facebook_config?: Maybe<IFacebook_Config_Mutation_Response>;
  /** delete single row from the table: "facebook_config" */
  delete_facebook_config_by_pk?: Maybe<IFacebook_Config>;
  /** delete data from the table: "from_sales_order" */
  delete_from_sales_order?: Maybe<IFrom_Sales_Order_Mutation_Response>;
  /** delete single row from the table: "from_sales_order" */
  delete_from_sales_order_by_pk?: Maybe<IFrom_Sales_Order>;
  /** delete data from the table: "from_sales_order_from_sales_publication" */
  delete_from_sales_order_from_sales_publication?: Maybe<IFrom_Sales_Order_From_Sales_Publication_Mutation_Response>;
  /** delete single row from the table: "from_sales_order_from_sales_publication" */
  delete_from_sales_order_from_sales_publication_by_pk?: Maybe<IFrom_Sales_Order_From_Sales_Publication>;
  /** delete data from the table: "from_sales_publication" */
  delete_from_sales_publication?: Maybe<IFrom_Sales_Publication_Mutation_Response>;
  /** delete single row from the table: "from_sales_publication" */
  delete_from_sales_publication_by_pk?: Maybe<IFrom_Sales_Publication>;
  /** delete data from the table: "funeral_home" */
  delete_funeral_home?: Maybe<IFuneral_Home_Mutation_Response>;
  /** delete single row from the table: "funeral_home" */
  delete_funeral_home_by_pk?: Maybe<IFuneral_Home>;
  /** delete data from the table: "funeral_home_external_id" */
  delete_funeral_home_external_id?: Maybe<IFuneral_Home_External_Id_Mutation_Response>;
  /** delete single row from the table: "funeral_home_external_id" */
  delete_funeral_home_external_id_by_pk?: Maybe<IFuneral_Home_External_Id>;
  /** delete data from the table: "funeral_home_external_user_id" */
  delete_funeral_home_external_user_id?: Maybe<IFuneral_Home_External_User_Id_Mutation_Response>;
  /** delete single row from the table: "funeral_home_external_user_id" */
  delete_funeral_home_external_user_id_by_pk?: Maybe<IFuneral_Home_External_User_Id>;
  /** delete data from the table: "historical_obituary" */
  delete_historical_obituary?: Maybe<IHistorical_Obituary_Mutation_Response>;
  /** delete single row from the table: "historical_obituary" */
  delete_historical_obituary_by_pk?: Maybe<IHistorical_Obituary>;
  /** delete data from the table: "historical_obituary_event" */
  delete_historical_obituary_event?: Maybe<IHistorical_Obituary_Event_Mutation_Response>;
  /** delete single row from the table: "historical_obituary_event" */
  delete_historical_obituary_event_by_pk?: Maybe<IHistorical_Obituary_Event>;
  /** delete data from the table: "historical_obituary_publication" */
  delete_historical_obituary_publication?: Maybe<IHistorical_Obituary_Publication_Mutation_Response>;
  /** delete single row from the table: "historical_obituary_publication" */
  delete_historical_obituary_publication_by_pk?: Maybe<IHistorical_Obituary_Publication>;
  /** delete data from the table: "historical_obituary_tag" */
  delete_historical_obituary_tag?: Maybe<IHistorical_Obituary_Tag_Mutation_Response>;
  /** delete single row from the table: "historical_obituary_tag" */
  delete_historical_obituary_tag_by_pk?: Maybe<IHistorical_Obituary_Tag>;
  /** delete data from the table: "image" */
  delete_image?: Maybe<IImage_Mutation_Response>;
  /** delete single row from the table: "image" */
  delete_image_by_pk?: Maybe<IImage>;
  /** delete data from the table: "image_lookup" */
  delete_image_lookup?: Maybe<IImage_Lookup_Mutation_Response>;
  /** delete single row from the table: "image_lookup" */
  delete_image_lookup_by_pk?: Maybe<IImage_Lookup>;
  /** delete data from the table: "image_order" */
  delete_image_order?: Maybe<IImage_Order_Mutation_Response>;
  /** delete single row from the table: "image_order" */
  delete_image_order_by_pk?: Maybe<IImage_Order>;
  /** delete data from the table: "image_type" */
  delete_image_type?: Maybe<IImage_Type_Mutation_Response>;
  /** delete single row from the table: "image_type" */
  delete_image_type_by_pk?: Maybe<IImage_Type>;
  /** delete data from the table: "images" */
  delete_images?: Maybe<IImages_Mutation_Response>;
  /** delete single row from the table: "images" */
  delete_images_by_pk?: Maybe<IImages>;
  /** delete data from the table: "latest_events" */
  delete_latest_events?: Maybe<ILatest_Events_Mutation_Response>;
  /** delete data from the table: "legacycom_config" */
  delete_legacycom_config?: Maybe<ILegacycom_Config_Mutation_Response>;
  /** delete single row from the table: "legacycom_config" */
  delete_legacycom_config_by_pk?: Maybe<ILegacycom_Config>;
  /** delete data from the table: "life_story" */
  delete_life_story?: Maybe<ILife_Story_Mutation_Response>;
  /** delete single row from the table: "life_story" */
  delete_life_story_by_pk?: Maybe<ILife_Story>;
  /** delete data from the table: "life_story_location" */
  delete_life_story_location?: Maybe<ILife_Story_Location_Mutation_Response>;
  /** delete single row from the table: "life_story_location" */
  delete_life_story_location_by_pk?: Maybe<ILife_Story_Location>;
  /** delete data from the table: "life_story_locations_types" */
  delete_life_story_locations_types?: Maybe<ILife_Story_Locations_Types_Mutation_Response>;
  /** delete single row from the table: "life_story_locations_types" */
  delete_life_story_locations_types_by_pk?: Maybe<ILife_Story_Locations_Types>;
  /** delete data from the table: "locations" */
  delete_locations?: Maybe<ILocations_Mutation_Response>;
  /** delete single row from the table: "locations" */
  delete_locations_by_pk?: Maybe<ILocations>;
  /** delete data from the table: "locations_publishers" */
  delete_locations_publishers?: Maybe<ILocations_Publishers_Mutation_Response>;
  /** delete data from the table: "memories" */
  delete_memories?: Maybe<IMemories_Mutation_Response>;
  /** delete single row from the table: "memories" */
  delete_memories_by_pk?: Maybe<IMemories>;
  /** delete data from the table: "metadata_locations" */
  delete_metadata_locations?: Maybe<IMetadata_Locations_Mutation_Response>;
  /** delete single row from the table: "metadata_locations" */
  delete_metadata_locations_by_pk?: Maybe<IMetadata_Locations>;
  /** delete data from the table: "obits" */
  delete_obits?: Maybe<IObits_Mutation_Response>;
  /** delete single row from the table: "obits" */
  delete_obits_by_pk?: Maybe<IObits>;
  /** delete data from the table: "obituary" */
  delete_obituary?: Maybe<IObituary_Mutation_Response>;
  /** delete single row from the table: "obituary" */
  delete_obituary_by_pk?: Maybe<IObituary>;
  /** delete data from the table: "obituary_publication" */
  delete_obituary_publication?: Maybe<IObituary_Publication_Mutation_Response>;
  /** delete single row from the table: "obituary_publication" */
  delete_obituary_publication_by_pk?: Maybe<IObituary_Publication>;
  /** delete data from the table: "obituary_status" */
  delete_obituary_status?: Maybe<IObituary_Status_Mutation_Response>;
  /** delete single row from the table: "obituary_status" */
  delete_obituary_status_by_pk?: Maybe<IObituary_Status>;
  /** delete data from the table: "obituary_tag" */
  delete_obituary_tag?: Maybe<IObituary_Tag_Mutation_Response>;
  /** delete single row from the table: "obituary_tag" */
  delete_obituary_tag_by_pk?: Maybe<IObituary_Tag>;
  /** delete data from the table: "payment_methods" */
  delete_payment_methods?: Maybe<IPayment_Methods_Mutation_Response>;
  /** delete data from the table: "phone_numbers" */
  delete_phone_numbers?: Maybe<IPhone_Numbers_Mutation_Response>;
  /** delete data from the table: "possible_matches" */
  delete_possible_matches?: Maybe<IPossible_Matches_Mutation_Response>;
  /** delete data from the table: "publication" */
  delete_publication?: Maybe<IPublication_Mutation_Response>;
  /** delete single row from the table: "publication" */
  delete_publication_by_pk?: Maybe<IPublication>;
  /** delete data from the table: "publication_info" */
  delete_publication_info?: Maybe<IPublication_Info_Mutation_Response>;
  /** delete single row from the table: "publication_info" */
  delete_publication_info_by_pk?: Maybe<IPublication_Info>;
  /** delete data from the table: "publisher_phone_numbers" */
  delete_publisher_phone_numbers?: Maybe<IPublisher_Phone_Numbers_Mutation_Response>;
  /** delete data from the table: "publishers" */
  delete_publishers?: Maybe<IPublishers_Mutation_Response>;
  /** delete data from the table: "raw_data" */
  delete_raw_data?: Maybe<IRaw_Data_Mutation_Response>;
  /** delete single row from the table: "raw_data" */
  delete_raw_data_by_pk?: Maybe<IRaw_Data>;
  /** delete data from the table: "reviews" */
  delete_reviews?: Maybe<IReviews_Mutation_Response>;
  /** delete data from the table: "service" */
  delete_service?: Maybe<IService_Mutation_Response>;
  /** delete single row from the table: "service" */
  delete_service_by_pk?: Maybe<IService>;
  /** delete data from the table: "service_duration" */
  delete_service_duration?: Maybe<IService_Duration_Mutation_Response>;
  /** delete single row from the table: "service_duration" */
  delete_service_duration_by_pk?: Maybe<IService_Duration>;
  /** delete data from the table: "service_duration_type" */
  delete_service_duration_type?: Maybe<IService_Duration_Type_Mutation_Response>;
  /** delete single row from the table: "service_duration_type" */
  delete_service_duration_type_by_pk?: Maybe<IService_Duration_Type>;
  /** delete data from the table: "service_offers" */
  delete_service_offers?: Maybe<IService_Offers_Mutation_Response>;
  /** delete data from the table: "tag" */
  delete_tag?: Maybe<ITag_Mutation_Response>;
  /** delete single row from the table: "tag" */
  delete_tag_by_pk?: Maybe<ITag>;
  /** delete data from the table: "topics" */
  delete_topics?: Maybe<ITopics_Mutation_Response>;
  /** delete single row from the table: "topics" */
  delete_topics_by_pk?: Maybe<ITopics>;
  /** delete data from the table: "urls" */
  delete_urls?: Maybe<IUrls_Mutation_Response>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<IUsers_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<IUsers>;
  /** delete data from the table: "verticals" */
  delete_verticals?: Maybe<IVerticals_Mutation_Response>;
  /** delete data from the table: "web_config" */
  delete_web_config?: Maybe<IWeb_Config_Mutation_Response>;
  /** delete single row from the table: "web_config" */
  delete_web_config_by_pk?: Maybe<IWeb_Config>;
  /** delete data from the table: "web_config_filter" */
  delete_web_config_filter?: Maybe<IWeb_Config_Filter_Mutation_Response>;
  /** delete single row from the table: "web_config_filter" */
  delete_web_config_filter_by_pk?: Maybe<IWeb_Config_Filter>;
  /** delete data from the table: "web_config_filter_tags" */
  delete_web_config_filter_tags?: Maybe<IWeb_Config_Filter_Tags_Mutation_Response>;
  /** delete single row from the table: "web_config_filter_tags" */
  delete_web_config_filter_tags_by_pk?: Maybe<IWeb_Config_Filter_Tags>;
  ds_local_directories_process_pdf: Scalars['String'];
  ds_local_directories_run?: Maybe<IDatasource>;
  /** insert data into the table: "archived_obituary" */
  insert_archived_obituary?: Maybe<IArchived_Obituary_Mutation_Response>;
  /** insert a single row into the table: "archived_obituary" */
  insert_archived_obituary_one?: Maybe<IArchived_Obituary>;
  /** insert data into the table: "capi_config" */
  insert_capi_config?: Maybe<ICapi_Config_Mutation_Response>;
  /** insert a single row into the table: "capi_config" */
  insert_capi_config_one?: Maybe<ICapi_Config>;
  /** insert data into the table: "categories" */
  insert_categories?: Maybe<ICategories_Mutation_Response>;
  /** insert data into the table: "categories_locations" */
  insert_categories_locations?: Maybe<ICategories_Locations_Mutation_Response>;
  /** insert a single row into the table: "categories_locations" */
  insert_categories_locations_one?: Maybe<ICategories_Locations>;
  /** insert a single row into the table: "categories" */
  insert_categories_one?: Maybe<ICategories>;
  /** insert data into the table: "categories_verticals" */
  insert_categories_verticals?: Maybe<ICategories_Verticals_Mutation_Response>;
  /** insert a single row into the table: "categories_verticals" */
  insert_categories_verticals_one?: Maybe<ICategories_Verticals>;
  /** insert data into the table: "category_image_urls" */
  insert_category_image_urls?: Maybe<ICategory_Image_Urls_Mutation_Response>;
  /** insert a single row into the table: "category_image_urls" */
  insert_category_image_urls_one?: Maybe<ICategory_Image_Urls>;
  /** insert data into the table: "category_keywords" */
  insert_category_keywords?: Maybe<ICategory_Keywords_Mutation_Response>;
  /** insert a single row into the table: "category_keywords" */
  insert_category_keywords_one?: Maybe<ICategory_Keywords>;
  /** insert data into the table: "coupon_adas" */
  insert_coupon_adas?: Maybe<ICoupon_Adas_Mutation_Response>;
  /** insert a single row into the table: "coupon_adas" */
  insert_coupon_adas_one?: Maybe<ICoupon_Adas>;
  /** insert data into the table: "daily_locations_counts" */
  insert_daily_locations_counts?: Maybe<IDaily_Locations_Counts_Mutation_Response>;
  /** insert a single row into the table: "daily_locations_counts" */
  insert_daily_locations_counts_one?: Maybe<IDaily_Locations_Counts>;
  /** insert data into the table: "display_obituary" */
  insert_display_obituary?: Maybe<IDisplay_Obituary_Mutation_Response>;
  /** insert a single row into the table: "display_obituary" */
  insert_display_obituary_one?: Maybe<IDisplay_Obituary>;
  /** insert data into the table: "display_obituary_publication" */
  insert_display_obituary_publication?: Maybe<IDisplay_Obituary_Publication_Mutation_Response>;
  /** insert a single row into the table: "display_obituary_publication" */
  insert_display_obituary_publication_one?: Maybe<IDisplay_Obituary_Publication>;
  /** insert data into the table: "display_obituary_tag" */
  insert_display_obituary_tag?: Maybe<IDisplay_Obituary_Tag_Mutation_Response>;
  /** insert a single row into the table: "display_obituary_tag" */
  insert_display_obituary_tag_one?: Maybe<IDisplay_Obituary_Tag>;
  /** insert data into the table: "display_run_date" */
  insert_display_run_date?: Maybe<IDisplay_Run_Date_Mutation_Response>;
  /** insert a single row into the table: "display_run_date" */
  insert_display_run_date_one?: Maybe<IDisplay_Run_Date>;
  /** insert data into the table: "duplicate_locations" */
  insert_duplicate_locations?: Maybe<IDuplicate_Locations_Mutation_Response>;
  /** insert a single row into the table: "duplicate_locations" */
  insert_duplicate_locations_one?: Maybe<IDuplicate_Locations>;
  /** insert data into the table: "events" */
  insert_events?: Maybe<IEvents_Mutation_Response>;
  /** insert a single row into the table: "events" */
  insert_events_one?: Maybe<IEvents>;
  /** insert data into the table: "events_types" */
  insert_events_types?: Maybe<IEvents_Types_Mutation_Response>;
  /** insert a single row into the table: "events_types" */
  insert_events_types_one?: Maybe<IEvents_Types>;
  /** insert data into the table: "external_link" */
  insert_external_link?: Maybe<IExternal_Link_Mutation_Response>;
  /** insert a single row into the table: "external_link" */
  insert_external_link_one?: Maybe<IExternal_Link>;
  /** insert data into the table: "external_link_source" */
  insert_external_link_source?: Maybe<IExternal_Link_Source_Mutation_Response>;
  /** insert a single row into the table: "external_link_source" */
  insert_external_link_source_one?: Maybe<IExternal_Link_Source>;
  /** insert data into the table: "external_link_type" */
  insert_external_link_type?: Maybe<IExternal_Link_Type_Mutation_Response>;
  /** insert a single row into the table: "external_link_type" */
  insert_external_link_type_one?: Maybe<IExternal_Link_Type>;
  /** insert data into the table: "facebook_config" */
  insert_facebook_config?: Maybe<IFacebook_Config_Mutation_Response>;
  /** insert a single row into the table: "facebook_config" */
  insert_facebook_config_one?: Maybe<IFacebook_Config>;
  /** insert data into the table: "from_sales_order" */
  insert_from_sales_order?: Maybe<IFrom_Sales_Order_Mutation_Response>;
  /** insert data into the table: "from_sales_order_from_sales_publication" */
  insert_from_sales_order_from_sales_publication?: Maybe<IFrom_Sales_Order_From_Sales_Publication_Mutation_Response>;
  /** insert a single row into the table: "from_sales_order_from_sales_publication" */
  insert_from_sales_order_from_sales_publication_one?: Maybe<IFrom_Sales_Order_From_Sales_Publication>;
  /** insert a single row into the table: "from_sales_order" */
  insert_from_sales_order_one?: Maybe<IFrom_Sales_Order>;
  /** insert data into the table: "from_sales_publication" */
  insert_from_sales_publication?: Maybe<IFrom_Sales_Publication_Mutation_Response>;
  /** insert a single row into the table: "from_sales_publication" */
  insert_from_sales_publication_one?: Maybe<IFrom_Sales_Publication>;
  /** insert data into the table: "funeral_home" */
  insert_funeral_home?: Maybe<IFuneral_Home_Mutation_Response>;
  /** insert data into the table: "funeral_home_external_id" */
  insert_funeral_home_external_id?: Maybe<IFuneral_Home_External_Id_Mutation_Response>;
  /** insert a single row into the table: "funeral_home_external_id" */
  insert_funeral_home_external_id_one?: Maybe<IFuneral_Home_External_Id>;
  /** insert data into the table: "funeral_home_external_user_id" */
  insert_funeral_home_external_user_id?: Maybe<IFuneral_Home_External_User_Id_Mutation_Response>;
  /** insert a single row into the table: "funeral_home_external_user_id" */
  insert_funeral_home_external_user_id_one?: Maybe<IFuneral_Home_External_User_Id>;
  /** insert a single row into the table: "funeral_home" */
  insert_funeral_home_one?: Maybe<IFuneral_Home>;
  /** insert data into the table: "historical_obituary" */
  insert_historical_obituary?: Maybe<IHistorical_Obituary_Mutation_Response>;
  /** insert data into the table: "historical_obituary_event" */
  insert_historical_obituary_event?: Maybe<IHistorical_Obituary_Event_Mutation_Response>;
  /** insert a single row into the table: "historical_obituary_event" */
  insert_historical_obituary_event_one?: Maybe<IHistorical_Obituary_Event>;
  /** insert a single row into the table: "historical_obituary" */
  insert_historical_obituary_one?: Maybe<IHistorical_Obituary>;
  /** insert data into the table: "historical_obituary_publication" */
  insert_historical_obituary_publication?: Maybe<IHistorical_Obituary_Publication_Mutation_Response>;
  /** insert a single row into the table: "historical_obituary_publication" */
  insert_historical_obituary_publication_one?: Maybe<IHistorical_Obituary_Publication>;
  /** insert data into the table: "historical_obituary_tag" */
  insert_historical_obituary_tag?: Maybe<IHistorical_Obituary_Tag_Mutation_Response>;
  /** insert a single row into the table: "historical_obituary_tag" */
  insert_historical_obituary_tag_one?: Maybe<IHistorical_Obituary_Tag>;
  /** insert data into the table: "image" */
  insert_image?: Maybe<IImage_Mutation_Response>;
  /** insert data into the table: "image_lookup" */
  insert_image_lookup?: Maybe<IImage_Lookup_Mutation_Response>;
  /** insert a single row into the table: "image_lookup" */
  insert_image_lookup_one?: Maybe<IImage_Lookup>;
  /** insert a single row into the table: "image" */
  insert_image_one?: Maybe<IImage>;
  /** insert data into the table: "image_order" */
  insert_image_order?: Maybe<IImage_Order_Mutation_Response>;
  /** insert a single row into the table: "image_order" */
  insert_image_order_one?: Maybe<IImage_Order>;
  /** insert data into the table: "image_type" */
  insert_image_type?: Maybe<IImage_Type_Mutation_Response>;
  /** insert a single row into the table: "image_type" */
  insert_image_type_one?: Maybe<IImage_Type>;
  /** insert data into the table: "images" */
  insert_images?: Maybe<IImages_Mutation_Response>;
  /** insert a single row into the table: "images" */
  insert_images_one?: Maybe<IImages>;
  /** insert data into the table: "latest_events" */
  insert_latest_events?: Maybe<ILatest_Events_Mutation_Response>;
  /** insert a single row into the table: "latest_events" */
  insert_latest_events_one?: Maybe<ILatest_Events>;
  /** insert data into the table: "legacycom_config" */
  insert_legacycom_config?: Maybe<ILegacycom_Config_Mutation_Response>;
  /** insert a single row into the table: "legacycom_config" */
  insert_legacycom_config_one?: Maybe<ILegacycom_Config>;
  /** insert data into the table: "life_story" */
  insert_life_story?: Maybe<ILife_Story_Mutation_Response>;
  /** insert data into the table: "life_story_location" */
  insert_life_story_location?: Maybe<ILife_Story_Location_Mutation_Response>;
  /** insert a single row into the table: "life_story_location" */
  insert_life_story_location_one?: Maybe<ILife_Story_Location>;
  /** insert data into the table: "life_story_locations_types" */
  insert_life_story_locations_types?: Maybe<ILife_Story_Locations_Types_Mutation_Response>;
  /** insert a single row into the table: "life_story_locations_types" */
  insert_life_story_locations_types_one?: Maybe<ILife_Story_Locations_Types>;
  /** insert a single row into the table: "life_story" */
  insert_life_story_one?: Maybe<ILife_Story>;
  /** insert data into the table: "locations" */
  insert_locations?: Maybe<ILocations_Mutation_Response>;
  /** insert a single row into the table: "locations" */
  insert_locations_one?: Maybe<ILocations>;
  /** insert data into the table: "locations_publishers" */
  insert_locations_publishers?: Maybe<ILocations_Publishers_Mutation_Response>;
  /** insert a single row into the table: "locations_publishers" */
  insert_locations_publishers_one?: Maybe<ILocations_Publishers>;
  /** insert data into the table: "memories" */
  insert_memories?: Maybe<IMemories_Mutation_Response>;
  /** insert a single row into the table: "memories" */
  insert_memories_one?: Maybe<IMemories>;
  /** insert data into the table: "metadata_locations" */
  insert_metadata_locations?: Maybe<IMetadata_Locations_Mutation_Response>;
  /** insert a single row into the table: "metadata_locations" */
  insert_metadata_locations_one?: Maybe<IMetadata_Locations>;
  /** insert data into the table: "obits" */
  insert_obits?: Maybe<IObits_Mutation_Response>;
  /** insert a single row into the table: "obits" */
  insert_obits_one?: Maybe<IObits>;
  /** insert data into the table: "obituary" */
  insert_obituary?: Maybe<IObituary_Mutation_Response>;
  /** insert a single row into the table: "obituary" */
  insert_obituary_one?: Maybe<IObituary>;
  /** insert data into the table: "obituary_publication" */
  insert_obituary_publication?: Maybe<IObituary_Publication_Mutation_Response>;
  /** insert a single row into the table: "obituary_publication" */
  insert_obituary_publication_one?: Maybe<IObituary_Publication>;
  /** insert data into the table: "obituary_status" */
  insert_obituary_status?: Maybe<IObituary_Status_Mutation_Response>;
  /** insert a single row into the table: "obituary_status" */
  insert_obituary_status_one?: Maybe<IObituary_Status>;
  /** insert data into the table: "obituary_tag" */
  insert_obituary_tag?: Maybe<IObituary_Tag_Mutation_Response>;
  /** insert a single row into the table: "obituary_tag" */
  insert_obituary_tag_one?: Maybe<IObituary_Tag>;
  /** insert data into the table: "payment_methods" */
  insert_payment_methods?: Maybe<IPayment_Methods_Mutation_Response>;
  /** insert a single row into the table: "payment_methods" */
  insert_payment_methods_one?: Maybe<IPayment_Methods>;
  /** insert data into the table: "phone_numbers" */
  insert_phone_numbers?: Maybe<IPhone_Numbers_Mutation_Response>;
  /** insert a single row into the table: "phone_numbers" */
  insert_phone_numbers_one?: Maybe<IPhone_Numbers>;
  /** insert data into the table: "possible_matches" */
  insert_possible_matches?: Maybe<IPossible_Matches_Mutation_Response>;
  /** insert a single row into the table: "possible_matches" */
  insert_possible_matches_one?: Maybe<IPossible_Matches>;
  /** insert data into the table: "publication" */
  insert_publication?: Maybe<IPublication_Mutation_Response>;
  /** insert data into the table: "publication_info" */
  insert_publication_info?: Maybe<IPublication_Info_Mutation_Response>;
  /** insert a single row into the table: "publication_info" */
  insert_publication_info_one?: Maybe<IPublication_Info>;
  /** insert a single row into the table: "publication" */
  insert_publication_one?: Maybe<IPublication>;
  /** insert data into the table: "publisher_phone_numbers" */
  insert_publisher_phone_numbers?: Maybe<IPublisher_Phone_Numbers_Mutation_Response>;
  /** insert a single row into the table: "publisher_phone_numbers" */
  insert_publisher_phone_numbers_one?: Maybe<IPublisher_Phone_Numbers>;
  /** insert data into the table: "publishers" */
  insert_publishers?: Maybe<IPublishers_Mutation_Response>;
  /** insert a single row into the table: "publishers" */
  insert_publishers_one?: Maybe<IPublishers>;
  /** insert data into the table: "raw_data" */
  insert_raw_data?: Maybe<IRaw_Data_Mutation_Response>;
  /** insert a single row into the table: "raw_data" */
  insert_raw_data_one?: Maybe<IRaw_Data>;
  /** insert data into the table: "reviews" */
  insert_reviews?: Maybe<IReviews_Mutation_Response>;
  /** insert a single row into the table: "reviews" */
  insert_reviews_one?: Maybe<IReviews>;
  /** insert data into the table: "service" */
  insert_service?: Maybe<IService_Mutation_Response>;
  /** insert data into the table: "service_duration" */
  insert_service_duration?: Maybe<IService_Duration_Mutation_Response>;
  /** insert a single row into the table: "service_duration" */
  insert_service_duration_one?: Maybe<IService_Duration>;
  /** insert data into the table: "service_duration_type" */
  insert_service_duration_type?: Maybe<IService_Duration_Type_Mutation_Response>;
  /** insert a single row into the table: "service_duration_type" */
  insert_service_duration_type_one?: Maybe<IService_Duration_Type>;
  /** insert data into the table: "service_offers" */
  insert_service_offers?: Maybe<IService_Offers_Mutation_Response>;
  /** insert a single row into the table: "service_offers" */
  insert_service_offers_one?: Maybe<IService_Offers>;
  /** insert a single row into the table: "service" */
  insert_service_one?: Maybe<IService>;
  /** insert data into the table: "tag" */
  insert_tag?: Maybe<ITag_Mutation_Response>;
  /** insert a single row into the table: "tag" */
  insert_tag_one?: Maybe<ITag>;
  /** insert data into the table: "topics" */
  insert_topics?: Maybe<ITopics_Mutation_Response>;
  /** insert a single row into the table: "topics" */
  insert_topics_one?: Maybe<ITopics>;
  /** insert data into the table: "urls" */
  insert_urls?: Maybe<IUrls_Mutation_Response>;
  /** insert a single row into the table: "urls" */
  insert_urls_one?: Maybe<IUrls>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<IUsers_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<IUsers>;
  /** insert data into the table: "verticals" */
  insert_verticals?: Maybe<IVerticals_Mutation_Response>;
  /** insert a single row into the table: "verticals" */
  insert_verticals_one?: Maybe<IVerticals>;
  /** insert data into the table: "web_config" */
  insert_web_config?: Maybe<IWeb_Config_Mutation_Response>;
  /** insert data into the table: "web_config_filter" */
  insert_web_config_filter?: Maybe<IWeb_Config_Filter_Mutation_Response>;
  /** insert a single row into the table: "web_config_filter" */
  insert_web_config_filter_one?: Maybe<IWeb_Config_Filter>;
  /** insert data into the table: "web_config_filter_tags" */
  insert_web_config_filter_tags?: Maybe<IWeb_Config_Filter_Tags_Mutation_Response>;
  /** insert a single row into the table: "web_config_filter_tags" */
  insert_web_config_filter_tags_one?: Maybe<IWeb_Config_Filter_Tags>;
  /** insert a single row into the table: "web_config" */
  insert_web_config_one?: Maybe<IWeb_Config>;
  /** update data of the table: "archived_obituary" */
  update_archived_obituary?: Maybe<IArchived_Obituary_Mutation_Response>;
  /** update single row of the table: "archived_obituary" */
  update_archived_obituary_by_pk?: Maybe<IArchived_Obituary>;
  /** update data of the table: "capi_config" */
  update_capi_config?: Maybe<ICapi_Config_Mutation_Response>;
  /** update single row of the table: "capi_config" */
  update_capi_config_by_pk?: Maybe<ICapi_Config>;
  /** update data of the table: "categories" */
  update_categories?: Maybe<ICategories_Mutation_Response>;
  /** update data of the table: "categories_locations" */
  update_categories_locations?: Maybe<ICategories_Locations_Mutation_Response>;
  /** update data of the table: "categories_verticals" */
  update_categories_verticals?: Maybe<ICategories_Verticals_Mutation_Response>;
  /** update data of the table: "category_image_urls" */
  update_category_image_urls?: Maybe<ICategory_Image_Urls_Mutation_Response>;
  /** update data of the table: "category_keywords" */
  update_category_keywords?: Maybe<ICategory_Keywords_Mutation_Response>;
  /** update data of the table: "coupon_adas" */
  update_coupon_adas?: Maybe<ICoupon_Adas_Mutation_Response>;
  /** update data of the table: "daily_locations_counts" */
  update_daily_locations_counts?: Maybe<IDaily_Locations_Counts_Mutation_Response>;
  /** update data of the table: "display_obituary" */
  update_display_obituary?: Maybe<IDisplay_Obituary_Mutation_Response>;
  /** update single row of the table: "display_obituary" */
  update_display_obituary_by_pk?: Maybe<IDisplay_Obituary>;
  /** update data of the table: "display_obituary_publication" */
  update_display_obituary_publication?: Maybe<IDisplay_Obituary_Publication_Mutation_Response>;
  /** update single row of the table: "display_obituary_publication" */
  update_display_obituary_publication_by_pk?: Maybe<IDisplay_Obituary_Publication>;
  /** update data of the table: "display_obituary_tag" */
  update_display_obituary_tag?: Maybe<IDisplay_Obituary_Tag_Mutation_Response>;
  /** update single row of the table: "display_obituary_tag" */
  update_display_obituary_tag_by_pk?: Maybe<IDisplay_Obituary_Tag>;
  /** update data of the table: "display_run_date" */
  update_display_run_date?: Maybe<IDisplay_Run_Date_Mutation_Response>;
  /** update single row of the table: "display_run_date" */
  update_display_run_date_by_pk?: Maybe<IDisplay_Run_Date>;
  /** update data of the table: "duplicate_locations" */
  update_duplicate_locations?: Maybe<IDuplicate_Locations_Mutation_Response>;
  /** update data of the table: "events" */
  update_events?: Maybe<IEvents_Mutation_Response>;
  /** update single row of the table: "events" */
  update_events_by_pk?: Maybe<IEvents>;
  /** update multiples rows of table: "events" */
  update_events_many?: Maybe<Array<Maybe<IEvents_Mutation_Response>>>;
  /** update data of the table: "events_types" */
  update_events_types?: Maybe<IEvents_Types_Mutation_Response>;
  /** update single row of the table: "events_types" */
  update_events_types_by_pk?: Maybe<IEvents_Types>;
  /** update multiples rows of table: "events_types" */
  update_events_types_many?: Maybe<Array<Maybe<IEvents_Types_Mutation_Response>>>;
  /** update data of the table: "external_link" */
  update_external_link?: Maybe<IExternal_Link_Mutation_Response>;
  /** update single row of the table: "external_link" */
  update_external_link_by_pk?: Maybe<IExternal_Link>;
  /** update data of the table: "external_link_source" */
  update_external_link_source?: Maybe<IExternal_Link_Source_Mutation_Response>;
  /** update single row of the table: "external_link_source" */
  update_external_link_source_by_pk?: Maybe<IExternal_Link_Source>;
  /** update data of the table: "external_link_type" */
  update_external_link_type?: Maybe<IExternal_Link_Type_Mutation_Response>;
  /** update single row of the table: "external_link_type" */
  update_external_link_type_by_pk?: Maybe<IExternal_Link_Type>;
  /** update data of the table: "facebook_config" */
  update_facebook_config?: Maybe<IFacebook_Config_Mutation_Response>;
  /** update single row of the table: "facebook_config" */
  update_facebook_config_by_pk?: Maybe<IFacebook_Config>;
  /** update data of the table: "from_sales_order" */
  update_from_sales_order?: Maybe<IFrom_Sales_Order_Mutation_Response>;
  /** update single row of the table: "from_sales_order" */
  update_from_sales_order_by_pk?: Maybe<IFrom_Sales_Order>;
  /** update data of the table: "from_sales_order_from_sales_publication" */
  update_from_sales_order_from_sales_publication?: Maybe<IFrom_Sales_Order_From_Sales_Publication_Mutation_Response>;
  /** update single row of the table: "from_sales_order_from_sales_publication" */
  update_from_sales_order_from_sales_publication_by_pk?: Maybe<IFrom_Sales_Order_From_Sales_Publication>;
  /** update data of the table: "from_sales_publication" */
  update_from_sales_publication?: Maybe<IFrom_Sales_Publication_Mutation_Response>;
  /** update single row of the table: "from_sales_publication" */
  update_from_sales_publication_by_pk?: Maybe<IFrom_Sales_Publication>;
  /** update data of the table: "funeral_home" */
  update_funeral_home?: Maybe<IFuneral_Home_Mutation_Response>;
  /** update single row of the table: "funeral_home" */
  update_funeral_home_by_pk?: Maybe<IFuneral_Home>;
  /** update data of the table: "funeral_home_external_id" */
  update_funeral_home_external_id?: Maybe<IFuneral_Home_External_Id_Mutation_Response>;
  /** update single row of the table: "funeral_home_external_id" */
  update_funeral_home_external_id_by_pk?: Maybe<IFuneral_Home_External_Id>;
  /** update data of the table: "funeral_home_external_user_id" */
  update_funeral_home_external_user_id?: Maybe<IFuneral_Home_External_User_Id_Mutation_Response>;
  /** update single row of the table: "funeral_home_external_user_id" */
  update_funeral_home_external_user_id_by_pk?: Maybe<IFuneral_Home_External_User_Id>;
  /** update data of the table: "historical_obituary" */
  update_historical_obituary?: Maybe<IHistorical_Obituary_Mutation_Response>;
  /** update single row of the table: "historical_obituary" */
  update_historical_obituary_by_pk?: Maybe<IHistorical_Obituary>;
  /** update data of the table: "historical_obituary_event" */
  update_historical_obituary_event?: Maybe<IHistorical_Obituary_Event_Mutation_Response>;
  /** update single row of the table: "historical_obituary_event" */
  update_historical_obituary_event_by_pk?: Maybe<IHistorical_Obituary_Event>;
  /** update data of the table: "historical_obituary_publication" */
  update_historical_obituary_publication?: Maybe<IHistorical_Obituary_Publication_Mutation_Response>;
  /** update single row of the table: "historical_obituary_publication" */
  update_historical_obituary_publication_by_pk?: Maybe<IHistorical_Obituary_Publication>;
  /** update data of the table: "historical_obituary_tag" */
  update_historical_obituary_tag?: Maybe<IHistorical_Obituary_Tag_Mutation_Response>;
  /** update single row of the table: "historical_obituary_tag" */
  update_historical_obituary_tag_by_pk?: Maybe<IHistorical_Obituary_Tag>;
  /** update data of the table: "image" */
  update_image?: Maybe<IImage_Mutation_Response>;
  /** update single row of the table: "image" */
  update_image_by_pk?: Maybe<IImage>;
  /** update data of the table: "image_lookup" */
  update_image_lookup?: Maybe<IImage_Lookup_Mutation_Response>;
  /** update single row of the table: "image_lookup" */
  update_image_lookup_by_pk?: Maybe<IImage_Lookup>;
  /** update data of the table: "image_order" */
  update_image_order?: Maybe<IImage_Order_Mutation_Response>;
  /** update single row of the table: "image_order" */
  update_image_order_by_pk?: Maybe<IImage_Order>;
  /** update data of the table: "image_type" */
  update_image_type?: Maybe<IImage_Type_Mutation_Response>;
  /** update single row of the table: "image_type" */
  update_image_type_by_pk?: Maybe<IImage_Type>;
  /** update data of the table: "images" */
  update_images?: Maybe<IImages_Mutation_Response>;
  /** update single row of the table: "images" */
  update_images_by_pk?: Maybe<IImages>;
  /** update multiples rows of table: "images" */
  update_images_many?: Maybe<Array<Maybe<IImages_Mutation_Response>>>;
  /** update data of the table: "latest_events" */
  update_latest_events?: Maybe<ILatest_Events_Mutation_Response>;
  /** update multiples rows of table: "latest_events" */
  update_latest_events_many?: Maybe<Array<Maybe<ILatest_Events_Mutation_Response>>>;
  /** update data of the table: "legacycom_config" */
  update_legacycom_config?: Maybe<ILegacycom_Config_Mutation_Response>;
  /** update single row of the table: "legacycom_config" */
  update_legacycom_config_by_pk?: Maybe<ILegacycom_Config>;
  /** update data of the table: "life_story" */
  update_life_story?: Maybe<ILife_Story_Mutation_Response>;
  /** update single row of the table: "life_story" */
  update_life_story_by_pk?: Maybe<ILife_Story>;
  /** update data of the table: "life_story_location" */
  update_life_story_location?: Maybe<ILife_Story_Location_Mutation_Response>;
  /** update single row of the table: "life_story_location" */
  update_life_story_location_by_pk?: Maybe<ILife_Story_Location>;
  /** update data of the table: "life_story_locations_types" */
  update_life_story_locations_types?: Maybe<ILife_Story_Locations_Types_Mutation_Response>;
  /** update single row of the table: "life_story_locations_types" */
  update_life_story_locations_types_by_pk?: Maybe<ILife_Story_Locations_Types>;
  /** update data of the table: "locations" */
  update_locations?: Maybe<ILocations_Mutation_Response>;
  /** update single row of the table: "locations" */
  update_locations_by_pk?: Maybe<ILocations>;
  /** update data of the table: "locations_publishers" */
  update_locations_publishers?: Maybe<ILocations_Publishers_Mutation_Response>;
  /** update data of the table: "memories" */
  update_memories?: Maybe<IMemories_Mutation_Response>;
  /** update single row of the table: "memories" */
  update_memories_by_pk?: Maybe<IMemories>;
  /** update multiples rows of table: "memories" */
  update_memories_many?: Maybe<Array<Maybe<IMemories_Mutation_Response>>>;
  /** update data of the table: "metadata_locations" */
  update_metadata_locations?: Maybe<IMetadata_Locations_Mutation_Response>;
  /** update single row of the table: "metadata_locations" */
  update_metadata_locations_by_pk?: Maybe<IMetadata_Locations>;
  /** update data of the table: "obits" */
  update_obits?: Maybe<IObits_Mutation_Response>;
  /** update single row of the table: "obits" */
  update_obits_by_pk?: Maybe<IObits>;
  /** update multiples rows of table: "obits" */
  update_obits_many?: Maybe<Array<Maybe<IObits_Mutation_Response>>>;
  /** update data of the table: "obituary" */
  update_obituary?: Maybe<IObituary_Mutation_Response>;
  /** update single row of the table: "obituary" */
  update_obituary_by_pk?: Maybe<IObituary>;
  /** update data of the table: "obituary_publication" */
  update_obituary_publication?: Maybe<IObituary_Publication_Mutation_Response>;
  /** update single row of the table: "obituary_publication" */
  update_obituary_publication_by_pk?: Maybe<IObituary_Publication>;
  /** update data of the table: "obituary_status" */
  update_obituary_status?: Maybe<IObituary_Status_Mutation_Response>;
  /** update single row of the table: "obituary_status" */
  update_obituary_status_by_pk?: Maybe<IObituary_Status>;
  /** update data of the table: "obituary_tag" */
  update_obituary_tag?: Maybe<IObituary_Tag_Mutation_Response>;
  /** update single row of the table: "obituary_tag" */
  update_obituary_tag_by_pk?: Maybe<IObituary_Tag>;
  /** update data of the table: "payment_methods" */
  update_payment_methods?: Maybe<IPayment_Methods_Mutation_Response>;
  /** update data of the table: "phone_numbers" */
  update_phone_numbers?: Maybe<IPhone_Numbers_Mutation_Response>;
  /** update data of the table: "possible_matches" */
  update_possible_matches?: Maybe<IPossible_Matches_Mutation_Response>;
  /** update data of the table: "publication" */
  update_publication?: Maybe<IPublication_Mutation_Response>;
  /** update single row of the table: "publication" */
  update_publication_by_pk?: Maybe<IPublication>;
  /** update data of the table: "publication_info" */
  update_publication_info?: Maybe<IPublication_Info_Mutation_Response>;
  /** update single row of the table: "publication_info" */
  update_publication_info_by_pk?: Maybe<IPublication_Info>;
  /** update data of the table: "publisher_phone_numbers" */
  update_publisher_phone_numbers?: Maybe<IPublisher_Phone_Numbers_Mutation_Response>;
  /** update data of the table: "publishers" */
  update_publishers?: Maybe<IPublishers_Mutation_Response>;
  /** update data of the table: "raw_data" */
  update_raw_data?: Maybe<IRaw_Data_Mutation_Response>;
  /** update single row of the table: "raw_data" */
  update_raw_data_by_pk?: Maybe<IRaw_Data>;
  /** update data of the table: "reviews" */
  update_reviews?: Maybe<IReviews_Mutation_Response>;
  /** update data of the table: "service" */
  update_service?: Maybe<IService_Mutation_Response>;
  /** update single row of the table: "service" */
  update_service_by_pk?: Maybe<IService>;
  /** update data of the table: "service_duration" */
  update_service_duration?: Maybe<IService_Duration_Mutation_Response>;
  /** update single row of the table: "service_duration" */
  update_service_duration_by_pk?: Maybe<IService_Duration>;
  /** update data of the table: "service_duration_type" */
  update_service_duration_type?: Maybe<IService_Duration_Type_Mutation_Response>;
  /** update single row of the table: "service_duration_type" */
  update_service_duration_type_by_pk?: Maybe<IService_Duration_Type>;
  /** update data of the table: "service_offers" */
  update_service_offers?: Maybe<IService_Offers_Mutation_Response>;
  /** update data of the table: "tag" */
  update_tag?: Maybe<ITag_Mutation_Response>;
  /** update single row of the table: "tag" */
  update_tag_by_pk?: Maybe<ITag>;
  /** update data of the table: "topics" */
  update_topics?: Maybe<ITopics_Mutation_Response>;
  /** update single row of the table: "topics" */
  update_topics_by_pk?: Maybe<ITopics>;
  /** update multiples rows of table: "topics" */
  update_topics_many?: Maybe<Array<Maybe<ITopics_Mutation_Response>>>;
  /** update data of the table: "urls" */
  update_urls?: Maybe<IUrls_Mutation_Response>;
  /** update data of the table: "users" */
  update_users?: Maybe<IUsers_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<IUsers>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<IUsers_Mutation_Response>>>;
  /** update data of the table: "verticals" */
  update_verticals?: Maybe<IVerticals_Mutation_Response>;
  /** update data of the table: "web_config" */
  update_web_config?: Maybe<IWeb_Config_Mutation_Response>;
  /** update single row of the table: "web_config" */
  update_web_config_by_pk?: Maybe<IWeb_Config>;
  /** update data of the table: "web_config_filter" */
  update_web_config_filter?: Maybe<IWeb_Config_Filter_Mutation_Response>;
  /** update single row of the table: "web_config_filter" */
  update_web_config_filter_by_pk?: Maybe<IWeb_Config_Filter>;
  /** update data of the table: "web_config_filter_tags" */
  update_web_config_filter_tags?: Maybe<IWeb_Config_Filter_Tags_Mutation_Response>;
  /** update single row of the table: "web_config_filter_tags" */
  update_web_config_filter_tags_by_pk?: Maybe<IWeb_Config_Filter_Tags>;
};


/** mutation root */
export type IMutation_RootAdd_MemoryArgs = {
  memory?: Maybe<IInput_Memory>;
};


/** mutation root */
export type IMutation_RootDelete_Archived_ObituaryArgs = {
  where: IArchived_Obituary_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Archived_Obituary_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Capi_ConfigArgs = {
  where: ICapi_Config_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Capi_Config_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_CategoriesArgs = {
  where: ICategories_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Categories_LocationsArgs = {
  where: ICategories_Locations_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Categories_VerticalsArgs = {
  where: ICategories_Verticals_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Category_Image_UrlsArgs = {
  where: ICategory_Image_Urls_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Category_KeywordsArgs = {
  where: ICategory_Keywords_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Coupon_AdasArgs = {
  where: ICoupon_Adas_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Daily_Locations_CountsArgs = {
  where: IDaily_Locations_Counts_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Display_ObituaryArgs = {
  where: IDisplay_Obituary_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Display_Obituary_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Display_Obituary_PublicationArgs = {
  where: IDisplay_Obituary_Publication_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Display_Obituary_Publication_By_PkArgs = {
  display_obituary_id: Scalars['uuid'];
  publication_id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Display_Obituary_TagArgs = {
  where: IDisplay_Obituary_Tag_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Display_Obituary_Tag_By_PkArgs = {
  display_obituary_id: Scalars['uuid'];
  tag_id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Display_Run_DateArgs = {
  where: IDisplay_Run_Date_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Display_Run_Date_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Duplicate_LocationsArgs = {
  where: IDuplicate_Locations_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_EventsArgs = {
  where: IEvents_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Events_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Events_TypesArgs = {
  where: IEvents_Types_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Events_Types_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type IMutation_RootDelete_External_LinkArgs = {
  where: IExternal_Link_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_External_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_External_Link_SourceArgs = {
  where: IExternal_Link_Source_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_External_Link_Source_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type IMutation_RootDelete_External_Link_TypeArgs = {
  where: IExternal_Link_Type_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_External_Link_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type IMutation_RootDelete_Facebook_ConfigArgs = {
  where: IFacebook_Config_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Facebook_Config_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_From_Sales_OrderArgs = {
  where: IFrom_Sales_Order_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_From_Sales_Order_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_From_Sales_Order_From_Sales_PublicationArgs = {
  where: IFrom_Sales_Order_From_Sales_Publication_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_From_Sales_Order_From_Sales_Publication_By_PkArgs = {
  order_id: Scalars['uuid'];
  publication_id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_From_Sales_PublicationArgs = {
  where: IFrom_Sales_Publication_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_From_Sales_Publication_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Funeral_HomeArgs = {
  where: IFuneral_Home_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Funeral_Home_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Funeral_Home_External_IdArgs = {
  where: IFuneral_Home_External_Id_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Funeral_Home_External_Id_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Funeral_Home_External_User_IdArgs = {
  where: IFuneral_Home_External_User_Id_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Funeral_Home_External_User_Id_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Historical_ObituaryArgs = {
  where: IHistorical_Obituary_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Historical_Obituary_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Historical_Obituary_EventArgs = {
  where: IHistorical_Obituary_Event_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Historical_Obituary_Event_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Historical_Obituary_PublicationArgs = {
  where: IHistorical_Obituary_Publication_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Historical_Obituary_Publication_By_PkArgs = {
  historial_obituary_id: Scalars['uuid'];
  publication_id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Historical_Obituary_TagArgs = {
  where: IHistorical_Obituary_Tag_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Historical_Obituary_Tag_By_PkArgs = {
  historical_obituary_id: Scalars['uuid'];
  tag_id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_ImageArgs = {
  where: IImage_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Image_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Image_LookupArgs = {
  where: IImage_Lookup_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Image_Lookup_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Image_OrderArgs = {
  where: IImage_Order_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Image_Order_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Image_TypeArgs = {
  where: IImage_Type_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Image_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type IMutation_RootDelete_ImagesArgs = {
  where: IImages_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Images_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Latest_EventsArgs = {
  where: ILatest_Events_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Legacycom_ConfigArgs = {
  where: ILegacycom_Config_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Legacycom_Config_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Life_StoryArgs = {
  where: ILife_Story_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Life_Story_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Life_Story_LocationArgs = {
  where: ILife_Story_Location_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Life_Story_Location_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Life_Story_Locations_TypesArgs = {
  where: ILife_Story_Locations_Types_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Life_Story_Locations_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type IMutation_RootDelete_LocationsArgs = {
  where: ILocations_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Locations_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type IMutation_RootDelete_Locations_PublishersArgs = {
  where: ILocations_Publishers_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_MemoriesArgs = {
  where: IMemories_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Memories_By_PkArgs = {
  id: Scalars['uuid'];
  obit_id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Metadata_LocationsArgs = {
  where: IMetadata_Locations_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Metadata_Locations_By_PkArgs = {
  location_id: Scalars['bigint'];
  name: Scalars['String'];
  value: Scalars['String'];
};


/** mutation root */
export type IMutation_RootDelete_ObitsArgs = {
  where: IObits_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Obits_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_ObituaryArgs = {
  where: IObituary_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Obituary_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Obituary_PublicationArgs = {
  where: IObituary_Publication_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Obituary_Publication_By_PkArgs = {
  obituary_id: Scalars['uuid'];
  publication_id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Obituary_StatusArgs = {
  where: IObituary_Status_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Obituary_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type IMutation_RootDelete_Obituary_TagArgs = {
  where: IObituary_Tag_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Obituary_Tag_By_PkArgs = {
  obituary_id: Scalars['uuid'];
  tag_id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Payment_MethodsArgs = {
  where: IPayment_Methods_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Phone_NumbersArgs = {
  where: IPhone_Numbers_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Possible_MatchesArgs = {
  where: IPossible_Matches_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_PublicationArgs = {
  where: IPublication_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Publication_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Publication_InfoArgs = {
  where: IPublication_Info_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Publication_Info_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Publisher_Phone_NumbersArgs = {
  where: IPublisher_Phone_Numbers_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_PublishersArgs = {
  where: IPublishers_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Raw_DataArgs = {
  where: IRaw_Data_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Raw_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_ReviewsArgs = {
  where: IReviews_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_ServiceArgs = {
  where: IService_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Service_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Service_DurationArgs = {
  where: IService_Duration_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Service_Duration_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Service_Duration_TypeArgs = {
  where: IService_Duration_Type_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Service_Duration_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type IMutation_RootDelete_Service_OffersArgs = {
  where: IService_Offers_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_TagArgs = {
  where: ITag_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Tag_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_TopicsArgs = {
  where: ITopics_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Topics_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type IMutation_RootDelete_UrlsArgs = {
  where: IUrls_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_UsersArgs = {
  where: IUsers_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type IMutation_RootDelete_VerticalsArgs = {
  where: IVerticals_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Web_ConfigArgs = {
  where: IWeb_Config_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Web_Config_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Web_Config_FilterArgs = {
  where: IWeb_Config_Filter_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Web_Config_Filter_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDelete_Web_Config_Filter_TagsArgs = {
  where: IWeb_Config_Filter_Tags_Bool_Exp;
};


/** mutation root */
export type IMutation_RootDelete_Web_Config_Filter_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type IMutation_RootDs_Local_Directories_Process_PdfArgs = {
  file: Scalars['Upload'];
};


/** mutation root */
export type IMutation_RootDs_Local_Directories_RunArgs = {
  datasource?: Maybe<ILocalDirectoriesDatasourceInput>;
};


/** mutation root */
export type IMutation_RootInsert_Archived_ObituaryArgs = {
  objects: Array<IArchived_Obituary_Insert_Input>;
  on_conflict?: Maybe<IArchived_Obituary_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Archived_Obituary_OneArgs = {
  object: IArchived_Obituary_Insert_Input;
  on_conflict?: Maybe<IArchived_Obituary_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Capi_ConfigArgs = {
  objects: Array<ICapi_Config_Insert_Input>;
  on_conflict?: Maybe<ICapi_Config_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Capi_Config_OneArgs = {
  object: ICapi_Config_Insert_Input;
  on_conflict?: Maybe<ICapi_Config_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_CategoriesArgs = {
  objects: Array<ICategories_Insert_Input>;
  on_conflict?: Maybe<ICategories_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Categories_LocationsArgs = {
  objects: Array<ICategories_Locations_Insert_Input>;
  on_conflict?: Maybe<ICategories_Locations_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Categories_Locations_OneArgs = {
  object: ICategories_Locations_Insert_Input;
  on_conflict?: Maybe<ICategories_Locations_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Categories_OneArgs = {
  object: ICategories_Insert_Input;
  on_conflict?: Maybe<ICategories_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Categories_VerticalsArgs = {
  objects: Array<ICategories_Verticals_Insert_Input>;
  on_conflict?: Maybe<ICategories_Verticals_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Categories_Verticals_OneArgs = {
  object: ICategories_Verticals_Insert_Input;
  on_conflict?: Maybe<ICategories_Verticals_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Category_Image_UrlsArgs = {
  objects: Array<ICategory_Image_Urls_Insert_Input>;
  on_conflict?: Maybe<ICategory_Image_Urls_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Category_Image_Urls_OneArgs = {
  object: ICategory_Image_Urls_Insert_Input;
  on_conflict?: Maybe<ICategory_Image_Urls_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Category_KeywordsArgs = {
  objects: Array<ICategory_Keywords_Insert_Input>;
  on_conflict?: Maybe<ICategory_Keywords_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Category_Keywords_OneArgs = {
  object: ICategory_Keywords_Insert_Input;
  on_conflict?: Maybe<ICategory_Keywords_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Coupon_AdasArgs = {
  objects: Array<ICoupon_Adas_Insert_Input>;
  on_conflict?: Maybe<ICoupon_Adas_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Coupon_Adas_OneArgs = {
  object: ICoupon_Adas_Insert_Input;
  on_conflict?: Maybe<ICoupon_Adas_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Daily_Locations_CountsArgs = {
  objects: Array<IDaily_Locations_Counts_Insert_Input>;
  on_conflict?: Maybe<IDaily_Locations_Counts_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Daily_Locations_Counts_OneArgs = {
  object: IDaily_Locations_Counts_Insert_Input;
  on_conflict?: Maybe<IDaily_Locations_Counts_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Display_ObituaryArgs = {
  objects: Array<IDisplay_Obituary_Insert_Input>;
  on_conflict?: Maybe<IDisplay_Obituary_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Display_Obituary_OneArgs = {
  object: IDisplay_Obituary_Insert_Input;
  on_conflict?: Maybe<IDisplay_Obituary_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Display_Obituary_PublicationArgs = {
  objects: Array<IDisplay_Obituary_Publication_Insert_Input>;
  on_conflict?: Maybe<IDisplay_Obituary_Publication_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Display_Obituary_Publication_OneArgs = {
  object: IDisplay_Obituary_Publication_Insert_Input;
  on_conflict?: Maybe<IDisplay_Obituary_Publication_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Display_Obituary_TagArgs = {
  objects: Array<IDisplay_Obituary_Tag_Insert_Input>;
  on_conflict?: Maybe<IDisplay_Obituary_Tag_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Display_Obituary_Tag_OneArgs = {
  object: IDisplay_Obituary_Tag_Insert_Input;
  on_conflict?: Maybe<IDisplay_Obituary_Tag_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Display_Run_DateArgs = {
  objects: Array<IDisplay_Run_Date_Insert_Input>;
  on_conflict?: Maybe<IDisplay_Run_Date_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Display_Run_Date_OneArgs = {
  object: IDisplay_Run_Date_Insert_Input;
  on_conflict?: Maybe<IDisplay_Run_Date_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Duplicate_LocationsArgs = {
  objects: Array<IDuplicate_Locations_Insert_Input>;
  on_conflict?: Maybe<IDuplicate_Locations_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Duplicate_Locations_OneArgs = {
  object: IDuplicate_Locations_Insert_Input;
  on_conflict?: Maybe<IDuplicate_Locations_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_EventsArgs = {
  objects: Array<IEvents_Insert_Input>;
  on_conflict?: Maybe<IEvents_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Events_OneArgs = {
  object: IEvents_Insert_Input;
  on_conflict?: Maybe<IEvents_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Events_TypesArgs = {
  objects: Array<IEvents_Types_Insert_Input>;
  on_conflict?: Maybe<IEvents_Types_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Events_Types_OneArgs = {
  object: IEvents_Types_Insert_Input;
  on_conflict?: Maybe<IEvents_Types_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_External_LinkArgs = {
  objects: Array<IExternal_Link_Insert_Input>;
  on_conflict?: Maybe<IExternal_Link_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_External_Link_OneArgs = {
  object: IExternal_Link_Insert_Input;
  on_conflict?: Maybe<IExternal_Link_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_External_Link_SourceArgs = {
  objects: Array<IExternal_Link_Source_Insert_Input>;
  on_conflict?: Maybe<IExternal_Link_Source_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_External_Link_Source_OneArgs = {
  object: IExternal_Link_Source_Insert_Input;
  on_conflict?: Maybe<IExternal_Link_Source_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_External_Link_TypeArgs = {
  objects: Array<IExternal_Link_Type_Insert_Input>;
  on_conflict?: Maybe<IExternal_Link_Type_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_External_Link_Type_OneArgs = {
  object: IExternal_Link_Type_Insert_Input;
  on_conflict?: Maybe<IExternal_Link_Type_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Facebook_ConfigArgs = {
  objects: Array<IFacebook_Config_Insert_Input>;
  on_conflict?: Maybe<IFacebook_Config_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Facebook_Config_OneArgs = {
  object: IFacebook_Config_Insert_Input;
  on_conflict?: Maybe<IFacebook_Config_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_From_Sales_OrderArgs = {
  objects: Array<IFrom_Sales_Order_Insert_Input>;
  on_conflict?: Maybe<IFrom_Sales_Order_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_From_Sales_Order_From_Sales_PublicationArgs = {
  objects: Array<IFrom_Sales_Order_From_Sales_Publication_Insert_Input>;
  on_conflict?: Maybe<IFrom_Sales_Order_From_Sales_Publication_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_From_Sales_Order_From_Sales_Publication_OneArgs = {
  object: IFrom_Sales_Order_From_Sales_Publication_Insert_Input;
  on_conflict?: Maybe<IFrom_Sales_Order_From_Sales_Publication_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_From_Sales_Order_OneArgs = {
  object: IFrom_Sales_Order_Insert_Input;
  on_conflict?: Maybe<IFrom_Sales_Order_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_From_Sales_PublicationArgs = {
  objects: Array<IFrom_Sales_Publication_Insert_Input>;
  on_conflict?: Maybe<IFrom_Sales_Publication_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_From_Sales_Publication_OneArgs = {
  object: IFrom_Sales_Publication_Insert_Input;
  on_conflict?: Maybe<IFrom_Sales_Publication_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Funeral_HomeArgs = {
  objects: Array<IFuneral_Home_Insert_Input>;
  on_conflict?: Maybe<IFuneral_Home_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Funeral_Home_External_IdArgs = {
  objects: Array<IFuneral_Home_External_Id_Insert_Input>;
  on_conflict?: Maybe<IFuneral_Home_External_Id_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Funeral_Home_External_Id_OneArgs = {
  object: IFuneral_Home_External_Id_Insert_Input;
  on_conflict?: Maybe<IFuneral_Home_External_Id_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Funeral_Home_External_User_IdArgs = {
  objects: Array<IFuneral_Home_External_User_Id_Insert_Input>;
  on_conflict?: Maybe<IFuneral_Home_External_User_Id_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Funeral_Home_External_User_Id_OneArgs = {
  object: IFuneral_Home_External_User_Id_Insert_Input;
  on_conflict?: Maybe<IFuneral_Home_External_User_Id_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Funeral_Home_OneArgs = {
  object: IFuneral_Home_Insert_Input;
  on_conflict?: Maybe<IFuneral_Home_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Historical_ObituaryArgs = {
  objects: Array<IHistorical_Obituary_Insert_Input>;
  on_conflict?: Maybe<IHistorical_Obituary_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Historical_Obituary_EventArgs = {
  objects: Array<IHistorical_Obituary_Event_Insert_Input>;
  on_conflict?: Maybe<IHistorical_Obituary_Event_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Historical_Obituary_Event_OneArgs = {
  object: IHistorical_Obituary_Event_Insert_Input;
  on_conflict?: Maybe<IHistorical_Obituary_Event_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Historical_Obituary_OneArgs = {
  object: IHistorical_Obituary_Insert_Input;
  on_conflict?: Maybe<IHistorical_Obituary_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Historical_Obituary_PublicationArgs = {
  objects: Array<IHistorical_Obituary_Publication_Insert_Input>;
  on_conflict?: Maybe<IHistorical_Obituary_Publication_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Historical_Obituary_Publication_OneArgs = {
  object: IHistorical_Obituary_Publication_Insert_Input;
  on_conflict?: Maybe<IHistorical_Obituary_Publication_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Historical_Obituary_TagArgs = {
  objects: Array<IHistorical_Obituary_Tag_Insert_Input>;
  on_conflict?: Maybe<IHistorical_Obituary_Tag_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Historical_Obituary_Tag_OneArgs = {
  object: IHistorical_Obituary_Tag_Insert_Input;
  on_conflict?: Maybe<IHistorical_Obituary_Tag_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_ImageArgs = {
  objects: Array<IImage_Insert_Input>;
  on_conflict?: Maybe<IImage_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Image_LookupArgs = {
  objects: Array<IImage_Lookup_Insert_Input>;
  on_conflict?: Maybe<IImage_Lookup_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Image_Lookup_OneArgs = {
  object: IImage_Lookup_Insert_Input;
  on_conflict?: Maybe<IImage_Lookup_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Image_OneArgs = {
  object: IImage_Insert_Input;
  on_conflict?: Maybe<IImage_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Image_OrderArgs = {
  objects: Array<IImage_Order_Insert_Input>;
  on_conflict?: Maybe<IImage_Order_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Image_Order_OneArgs = {
  object: IImage_Order_Insert_Input;
  on_conflict?: Maybe<IImage_Order_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Image_TypeArgs = {
  objects: Array<IImage_Type_Insert_Input>;
  on_conflict?: Maybe<IImage_Type_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Image_Type_OneArgs = {
  object: IImage_Type_Insert_Input;
  on_conflict?: Maybe<IImage_Type_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_ImagesArgs = {
  objects: Array<IImages_Insert_Input>;
  on_conflict?: Maybe<IImages_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Images_OneArgs = {
  object: IImages_Insert_Input;
  on_conflict?: Maybe<IImages_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Latest_EventsArgs = {
  objects: Array<ILatest_Events_Insert_Input>;
};


/** mutation root */
export type IMutation_RootInsert_Latest_Events_OneArgs = {
  object: ILatest_Events_Insert_Input;
};


/** mutation root */
export type IMutation_RootInsert_Legacycom_ConfigArgs = {
  objects: Array<ILegacycom_Config_Insert_Input>;
  on_conflict?: Maybe<ILegacycom_Config_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Legacycom_Config_OneArgs = {
  object: ILegacycom_Config_Insert_Input;
  on_conflict?: Maybe<ILegacycom_Config_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Life_StoryArgs = {
  objects: Array<ILife_Story_Insert_Input>;
  on_conflict?: Maybe<ILife_Story_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Life_Story_LocationArgs = {
  objects: Array<ILife_Story_Location_Insert_Input>;
  on_conflict?: Maybe<ILife_Story_Location_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Life_Story_Location_OneArgs = {
  object: ILife_Story_Location_Insert_Input;
  on_conflict?: Maybe<ILife_Story_Location_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Life_Story_Locations_TypesArgs = {
  objects: Array<ILife_Story_Locations_Types_Insert_Input>;
  on_conflict?: Maybe<ILife_Story_Locations_Types_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Life_Story_Locations_Types_OneArgs = {
  object: ILife_Story_Locations_Types_Insert_Input;
  on_conflict?: Maybe<ILife_Story_Locations_Types_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Life_Story_OneArgs = {
  object: ILife_Story_Insert_Input;
  on_conflict?: Maybe<ILife_Story_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_LocationsArgs = {
  objects: Array<ILocations_Insert_Input>;
  on_conflict?: Maybe<ILocations_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Locations_OneArgs = {
  object: ILocations_Insert_Input;
  on_conflict?: Maybe<ILocations_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Locations_PublishersArgs = {
  objects: Array<ILocations_Publishers_Insert_Input>;
  on_conflict?: Maybe<ILocations_Publishers_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Locations_Publishers_OneArgs = {
  object: ILocations_Publishers_Insert_Input;
  on_conflict?: Maybe<ILocations_Publishers_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_MemoriesArgs = {
  objects: Array<IMemories_Insert_Input>;
  on_conflict?: Maybe<IMemories_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Memories_OneArgs = {
  object: IMemories_Insert_Input;
  on_conflict?: Maybe<IMemories_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Metadata_LocationsArgs = {
  objects: Array<IMetadata_Locations_Insert_Input>;
  on_conflict?: Maybe<IMetadata_Locations_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Metadata_Locations_OneArgs = {
  object: IMetadata_Locations_Insert_Input;
  on_conflict?: Maybe<IMetadata_Locations_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_ObitsArgs = {
  objects: Array<IObits_Insert_Input>;
  on_conflict?: Maybe<IObits_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Obits_OneArgs = {
  object: IObits_Insert_Input;
  on_conflict?: Maybe<IObits_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_ObituaryArgs = {
  objects: Array<IObituary_Insert_Input>;
  on_conflict?: Maybe<IObituary_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Obituary_OneArgs = {
  object: IObituary_Insert_Input;
  on_conflict?: Maybe<IObituary_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Obituary_PublicationArgs = {
  objects: Array<IObituary_Publication_Insert_Input>;
  on_conflict?: Maybe<IObituary_Publication_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Obituary_Publication_OneArgs = {
  object: IObituary_Publication_Insert_Input;
  on_conflict?: Maybe<IObituary_Publication_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Obituary_StatusArgs = {
  objects: Array<IObituary_Status_Insert_Input>;
  on_conflict?: Maybe<IObituary_Status_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Obituary_Status_OneArgs = {
  object: IObituary_Status_Insert_Input;
  on_conflict?: Maybe<IObituary_Status_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Obituary_TagArgs = {
  objects: Array<IObituary_Tag_Insert_Input>;
  on_conflict?: Maybe<IObituary_Tag_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Obituary_Tag_OneArgs = {
  object: IObituary_Tag_Insert_Input;
  on_conflict?: Maybe<IObituary_Tag_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Payment_MethodsArgs = {
  objects: Array<IPayment_Methods_Insert_Input>;
  on_conflict?: Maybe<IPayment_Methods_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Payment_Methods_OneArgs = {
  object: IPayment_Methods_Insert_Input;
  on_conflict?: Maybe<IPayment_Methods_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Phone_NumbersArgs = {
  objects: Array<IPhone_Numbers_Insert_Input>;
  on_conflict?: Maybe<IPhone_Numbers_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Phone_Numbers_OneArgs = {
  object: IPhone_Numbers_Insert_Input;
  on_conflict?: Maybe<IPhone_Numbers_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Possible_MatchesArgs = {
  objects: Array<IPossible_Matches_Insert_Input>;
  on_conflict?: Maybe<IPossible_Matches_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Possible_Matches_OneArgs = {
  object: IPossible_Matches_Insert_Input;
  on_conflict?: Maybe<IPossible_Matches_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_PublicationArgs = {
  objects: Array<IPublication_Insert_Input>;
  on_conflict?: Maybe<IPublication_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Publication_InfoArgs = {
  objects: Array<IPublication_Info_Insert_Input>;
  on_conflict?: Maybe<IPublication_Info_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Publication_Info_OneArgs = {
  object: IPublication_Info_Insert_Input;
  on_conflict?: Maybe<IPublication_Info_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Publication_OneArgs = {
  object: IPublication_Insert_Input;
  on_conflict?: Maybe<IPublication_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Publisher_Phone_NumbersArgs = {
  objects: Array<IPublisher_Phone_Numbers_Insert_Input>;
  on_conflict?: Maybe<IPublisher_Phone_Numbers_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Publisher_Phone_Numbers_OneArgs = {
  object: IPublisher_Phone_Numbers_Insert_Input;
  on_conflict?: Maybe<IPublisher_Phone_Numbers_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_PublishersArgs = {
  objects: Array<IPublishers_Insert_Input>;
  on_conflict?: Maybe<IPublishers_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Publishers_OneArgs = {
  object: IPublishers_Insert_Input;
  on_conflict?: Maybe<IPublishers_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Raw_DataArgs = {
  objects: Array<IRaw_Data_Insert_Input>;
  on_conflict?: Maybe<IRaw_Data_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Raw_Data_OneArgs = {
  object: IRaw_Data_Insert_Input;
  on_conflict?: Maybe<IRaw_Data_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_ReviewsArgs = {
  objects: Array<IReviews_Insert_Input>;
  on_conflict?: Maybe<IReviews_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Reviews_OneArgs = {
  object: IReviews_Insert_Input;
  on_conflict?: Maybe<IReviews_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_ServiceArgs = {
  objects: Array<IService_Insert_Input>;
  on_conflict?: Maybe<IService_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Service_DurationArgs = {
  objects: Array<IService_Duration_Insert_Input>;
  on_conflict?: Maybe<IService_Duration_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Service_Duration_OneArgs = {
  object: IService_Duration_Insert_Input;
  on_conflict?: Maybe<IService_Duration_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Service_Duration_TypeArgs = {
  objects: Array<IService_Duration_Type_Insert_Input>;
  on_conflict?: Maybe<IService_Duration_Type_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Service_Duration_Type_OneArgs = {
  object: IService_Duration_Type_Insert_Input;
  on_conflict?: Maybe<IService_Duration_Type_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Service_OffersArgs = {
  objects: Array<IService_Offers_Insert_Input>;
  on_conflict?: Maybe<IService_Offers_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Service_Offers_OneArgs = {
  object: IService_Offers_Insert_Input;
  on_conflict?: Maybe<IService_Offers_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Service_OneArgs = {
  object: IService_Insert_Input;
  on_conflict?: Maybe<IService_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_TagArgs = {
  objects: Array<ITag_Insert_Input>;
  on_conflict?: Maybe<ITag_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Tag_OneArgs = {
  object: ITag_Insert_Input;
  on_conflict?: Maybe<ITag_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_TopicsArgs = {
  objects: Array<ITopics_Insert_Input>;
  on_conflict?: Maybe<ITopics_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Topics_OneArgs = {
  object: ITopics_Insert_Input;
  on_conflict?: Maybe<ITopics_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_UrlsArgs = {
  objects: Array<IUrls_Insert_Input>;
  on_conflict?: Maybe<IUrls_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Urls_OneArgs = {
  object: IUrls_Insert_Input;
  on_conflict?: Maybe<IUrls_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_UsersArgs = {
  objects: Array<IUsers_Insert_Input>;
  on_conflict?: Maybe<IUsers_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Users_OneArgs = {
  object: IUsers_Insert_Input;
  on_conflict?: Maybe<IUsers_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_VerticalsArgs = {
  objects: Array<IVerticals_Insert_Input>;
  on_conflict?: Maybe<IVerticals_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Verticals_OneArgs = {
  object: IVerticals_Insert_Input;
  on_conflict?: Maybe<IVerticals_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Web_ConfigArgs = {
  objects: Array<IWeb_Config_Insert_Input>;
  on_conflict?: Maybe<IWeb_Config_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Web_Config_FilterArgs = {
  objects: Array<IWeb_Config_Filter_Insert_Input>;
  on_conflict?: Maybe<IWeb_Config_Filter_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Web_Config_Filter_OneArgs = {
  object: IWeb_Config_Filter_Insert_Input;
  on_conflict?: Maybe<IWeb_Config_Filter_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Web_Config_Filter_TagsArgs = {
  objects: Array<IWeb_Config_Filter_Tags_Insert_Input>;
  on_conflict?: Maybe<IWeb_Config_Filter_Tags_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Web_Config_Filter_Tags_OneArgs = {
  object: IWeb_Config_Filter_Tags_Insert_Input;
  on_conflict?: Maybe<IWeb_Config_Filter_Tags_On_Conflict>;
};


/** mutation root */
export type IMutation_RootInsert_Web_Config_OneArgs = {
  object: IWeb_Config_Insert_Input;
  on_conflict?: Maybe<IWeb_Config_On_Conflict>;
};


/** mutation root */
export type IMutation_RootUpdate_Archived_ObituaryArgs = {
  _inc?: Maybe<IArchived_Obituary_Inc_Input>;
  _set?: Maybe<IArchived_Obituary_Set_Input>;
  where: IArchived_Obituary_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Archived_Obituary_By_PkArgs = {
  _inc?: Maybe<IArchived_Obituary_Inc_Input>;
  _set?: Maybe<IArchived_Obituary_Set_Input>;
  pk_columns: IArchived_Obituary_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Capi_ConfigArgs = {
  _set?: Maybe<ICapi_Config_Set_Input>;
  where: ICapi_Config_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Capi_Config_By_PkArgs = {
  _set?: Maybe<ICapi_Config_Set_Input>;
  pk_columns: ICapi_Config_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_CategoriesArgs = {
  _inc?: Maybe<ICategories_Inc_Input>;
  _set?: Maybe<ICategories_Set_Input>;
  where: ICategories_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Categories_LocationsArgs = {
  _inc?: Maybe<ICategories_Locations_Inc_Input>;
  _set?: Maybe<ICategories_Locations_Set_Input>;
  where: ICategories_Locations_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Categories_VerticalsArgs = {
  _inc?: Maybe<ICategories_Verticals_Inc_Input>;
  _set?: Maybe<ICategories_Verticals_Set_Input>;
  where: ICategories_Verticals_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Category_Image_UrlsArgs = {
  _inc?: Maybe<ICategory_Image_Urls_Inc_Input>;
  _set?: Maybe<ICategory_Image_Urls_Set_Input>;
  where: ICategory_Image_Urls_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Category_KeywordsArgs = {
  _inc?: Maybe<ICategory_Keywords_Inc_Input>;
  _set?: Maybe<ICategory_Keywords_Set_Input>;
  where: ICategory_Keywords_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Coupon_AdasArgs = {
  _inc?: Maybe<ICoupon_Adas_Inc_Input>;
  _set?: Maybe<ICoupon_Adas_Set_Input>;
  where: ICoupon_Adas_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Daily_Locations_CountsArgs = {
  _inc?: Maybe<IDaily_Locations_Counts_Inc_Input>;
  _set?: Maybe<IDaily_Locations_Counts_Set_Input>;
  where: IDaily_Locations_Counts_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Display_ObituaryArgs = {
  _set?: Maybe<IDisplay_Obituary_Set_Input>;
  where: IDisplay_Obituary_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Display_Obituary_By_PkArgs = {
  _set?: Maybe<IDisplay_Obituary_Set_Input>;
  pk_columns: IDisplay_Obituary_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Display_Obituary_PublicationArgs = {
  _set?: Maybe<IDisplay_Obituary_Publication_Set_Input>;
  where: IDisplay_Obituary_Publication_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Display_Obituary_Publication_By_PkArgs = {
  _set?: Maybe<IDisplay_Obituary_Publication_Set_Input>;
  pk_columns: IDisplay_Obituary_Publication_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Display_Obituary_TagArgs = {
  _set?: Maybe<IDisplay_Obituary_Tag_Set_Input>;
  where: IDisplay_Obituary_Tag_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Display_Obituary_Tag_By_PkArgs = {
  _set?: Maybe<IDisplay_Obituary_Tag_Set_Input>;
  pk_columns: IDisplay_Obituary_Tag_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Display_Run_DateArgs = {
  _set?: Maybe<IDisplay_Run_Date_Set_Input>;
  where: IDisplay_Run_Date_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Display_Run_Date_By_PkArgs = {
  _set?: Maybe<IDisplay_Run_Date_Set_Input>;
  pk_columns: IDisplay_Run_Date_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Duplicate_LocationsArgs = {
  _inc?: Maybe<IDuplicate_Locations_Inc_Input>;
  _set?: Maybe<IDuplicate_Locations_Set_Input>;
  where: IDuplicate_Locations_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_EventsArgs = {
  _append?: Maybe<IEvents_Append_Input>;
  _delete_at_path?: Maybe<IEvents_Delete_At_Path_Input>;
  _delete_elem?: Maybe<IEvents_Delete_Elem_Input>;
  _delete_key?: Maybe<IEvents_Delete_Key_Input>;
  _prepend?: Maybe<IEvents_Prepend_Input>;
  _set?: Maybe<IEvents_Set_Input>;
  where: IEvents_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Events_By_PkArgs = {
  _append?: Maybe<IEvents_Append_Input>;
  _delete_at_path?: Maybe<IEvents_Delete_At_Path_Input>;
  _delete_elem?: Maybe<IEvents_Delete_Elem_Input>;
  _delete_key?: Maybe<IEvents_Delete_Key_Input>;
  _prepend?: Maybe<IEvents_Prepend_Input>;
  _set?: Maybe<IEvents_Set_Input>;
  pk_columns: IEvents_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Events_ManyArgs = {
  updates: Array<IEvents_Updates>;
};


/** mutation root */
export type IMutation_RootUpdate_Events_TypesArgs = {
  _inc?: Maybe<IEvents_Types_Inc_Input>;
  _set?: Maybe<IEvents_Types_Set_Input>;
  where: IEvents_Types_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Events_Types_By_PkArgs = {
  _inc?: Maybe<IEvents_Types_Inc_Input>;
  _set?: Maybe<IEvents_Types_Set_Input>;
  pk_columns: IEvents_Types_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Events_Types_ManyArgs = {
  updates: Array<IEvents_Types_Updates>;
};


/** mutation root */
export type IMutation_RootUpdate_External_LinkArgs = {
  _set?: Maybe<IExternal_Link_Set_Input>;
  where: IExternal_Link_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_External_Link_By_PkArgs = {
  _set?: Maybe<IExternal_Link_Set_Input>;
  pk_columns: IExternal_Link_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_External_Link_SourceArgs = {
  _set?: Maybe<IExternal_Link_Source_Set_Input>;
  where: IExternal_Link_Source_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_External_Link_Source_By_PkArgs = {
  _set?: Maybe<IExternal_Link_Source_Set_Input>;
  pk_columns: IExternal_Link_Source_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_External_Link_TypeArgs = {
  _set?: Maybe<IExternal_Link_Type_Set_Input>;
  where: IExternal_Link_Type_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_External_Link_Type_By_PkArgs = {
  _set?: Maybe<IExternal_Link_Type_Set_Input>;
  pk_columns: IExternal_Link_Type_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Facebook_ConfigArgs = {
  _set?: Maybe<IFacebook_Config_Set_Input>;
  where: IFacebook_Config_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Facebook_Config_By_PkArgs = {
  _set?: Maybe<IFacebook_Config_Set_Input>;
  pk_columns: IFacebook_Config_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_From_Sales_OrderArgs = {
  _set?: Maybe<IFrom_Sales_Order_Set_Input>;
  where: IFrom_Sales_Order_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_From_Sales_Order_By_PkArgs = {
  _set?: Maybe<IFrom_Sales_Order_Set_Input>;
  pk_columns: IFrom_Sales_Order_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_From_Sales_Order_From_Sales_PublicationArgs = {
  _set?: Maybe<IFrom_Sales_Order_From_Sales_Publication_Set_Input>;
  where: IFrom_Sales_Order_From_Sales_Publication_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_From_Sales_Order_From_Sales_Publication_By_PkArgs = {
  _set?: Maybe<IFrom_Sales_Order_From_Sales_Publication_Set_Input>;
  pk_columns: IFrom_Sales_Order_From_Sales_Publication_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_From_Sales_PublicationArgs = {
  _set?: Maybe<IFrom_Sales_Publication_Set_Input>;
  where: IFrom_Sales_Publication_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_From_Sales_Publication_By_PkArgs = {
  _set?: Maybe<IFrom_Sales_Publication_Set_Input>;
  pk_columns: IFrom_Sales_Publication_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Funeral_HomeArgs = {
  _inc?: Maybe<IFuneral_Home_Inc_Input>;
  _set?: Maybe<IFuneral_Home_Set_Input>;
  where: IFuneral_Home_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Funeral_Home_By_PkArgs = {
  _inc?: Maybe<IFuneral_Home_Inc_Input>;
  _set?: Maybe<IFuneral_Home_Set_Input>;
  pk_columns: IFuneral_Home_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Funeral_Home_External_IdArgs = {
  _inc?: Maybe<IFuneral_Home_External_Id_Inc_Input>;
  _set?: Maybe<IFuneral_Home_External_Id_Set_Input>;
  where: IFuneral_Home_External_Id_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Funeral_Home_External_Id_By_PkArgs = {
  _inc?: Maybe<IFuneral_Home_External_Id_Inc_Input>;
  _set?: Maybe<IFuneral_Home_External_Id_Set_Input>;
  pk_columns: IFuneral_Home_External_Id_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Funeral_Home_External_User_IdArgs = {
  _set?: Maybe<IFuneral_Home_External_User_Id_Set_Input>;
  where: IFuneral_Home_External_User_Id_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Funeral_Home_External_User_Id_By_PkArgs = {
  _set?: Maybe<IFuneral_Home_External_User_Id_Set_Input>;
  pk_columns: IFuneral_Home_External_User_Id_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Historical_ObituaryArgs = {
  _set?: Maybe<IHistorical_Obituary_Set_Input>;
  where: IHistorical_Obituary_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Historical_Obituary_By_PkArgs = {
  _set?: Maybe<IHistorical_Obituary_Set_Input>;
  pk_columns: IHistorical_Obituary_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Historical_Obituary_EventArgs = {
  _set?: Maybe<IHistorical_Obituary_Event_Set_Input>;
  where: IHistorical_Obituary_Event_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Historical_Obituary_Event_By_PkArgs = {
  _set?: Maybe<IHistorical_Obituary_Event_Set_Input>;
  pk_columns: IHistorical_Obituary_Event_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Historical_Obituary_PublicationArgs = {
  _set?: Maybe<IHistorical_Obituary_Publication_Set_Input>;
  where: IHistorical_Obituary_Publication_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Historical_Obituary_Publication_By_PkArgs = {
  _set?: Maybe<IHistorical_Obituary_Publication_Set_Input>;
  pk_columns: IHistorical_Obituary_Publication_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Historical_Obituary_TagArgs = {
  _set?: Maybe<IHistorical_Obituary_Tag_Set_Input>;
  where: IHistorical_Obituary_Tag_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Historical_Obituary_Tag_By_PkArgs = {
  _set?: Maybe<IHistorical_Obituary_Tag_Set_Input>;
  pk_columns: IHistorical_Obituary_Tag_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_ImageArgs = {
  _set?: Maybe<IImage_Set_Input>;
  where: IImage_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Image_By_PkArgs = {
  _set?: Maybe<IImage_Set_Input>;
  pk_columns: IImage_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Image_LookupArgs = {
  _set?: Maybe<IImage_Lookup_Set_Input>;
  where: IImage_Lookup_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Image_Lookup_By_PkArgs = {
  _set?: Maybe<IImage_Lookup_Set_Input>;
  pk_columns: IImage_Lookup_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Image_OrderArgs = {
  _inc?: Maybe<IImage_Order_Inc_Input>;
  _set?: Maybe<IImage_Order_Set_Input>;
  where: IImage_Order_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Image_Order_By_PkArgs = {
  _inc?: Maybe<IImage_Order_Inc_Input>;
  _set?: Maybe<IImage_Order_Set_Input>;
  pk_columns: IImage_Order_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Image_TypeArgs = {
  _set?: Maybe<IImage_Type_Set_Input>;
  where: IImage_Type_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Image_Type_By_PkArgs = {
  _set?: Maybe<IImage_Type_Set_Input>;
  pk_columns: IImage_Type_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_ImagesArgs = {
  _set?: Maybe<IImages_Set_Input>;
  where: IImages_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Images_By_PkArgs = {
  _set?: Maybe<IImages_Set_Input>;
  pk_columns: IImages_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Images_ManyArgs = {
  updates: Array<IImages_Updates>;
};


/** mutation root */
export type IMutation_RootUpdate_Latest_EventsArgs = {
  _append?: Maybe<ILatest_Events_Append_Input>;
  _delete_at_path?: Maybe<ILatest_Events_Delete_At_Path_Input>;
  _delete_elem?: Maybe<ILatest_Events_Delete_Elem_Input>;
  _delete_key?: Maybe<ILatest_Events_Delete_Key_Input>;
  _prepend?: Maybe<ILatest_Events_Prepend_Input>;
  _set?: Maybe<ILatest_Events_Set_Input>;
  where: ILatest_Events_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Latest_Events_ManyArgs = {
  updates: Array<ILatest_Events_Updates>;
};


/** mutation root */
export type IMutation_RootUpdate_Legacycom_ConfigArgs = {
  _set?: Maybe<ILegacycom_Config_Set_Input>;
  where: ILegacycom_Config_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Legacycom_Config_By_PkArgs = {
  _set?: Maybe<ILegacycom_Config_Set_Input>;
  pk_columns: ILegacycom_Config_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Life_StoryArgs = {
  _set?: Maybe<ILife_Story_Set_Input>;
  where: ILife_Story_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Life_Story_By_PkArgs = {
  _set?: Maybe<ILife_Story_Set_Input>;
  pk_columns: ILife_Story_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Life_Story_LocationArgs = {
  _set?: Maybe<ILife_Story_Location_Set_Input>;
  where: ILife_Story_Location_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Life_Story_Location_By_PkArgs = {
  _set?: Maybe<ILife_Story_Location_Set_Input>;
  pk_columns: ILife_Story_Location_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Life_Story_Locations_TypesArgs = {
  _set?: Maybe<ILife_Story_Locations_Types_Set_Input>;
  where: ILife_Story_Locations_Types_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Life_Story_Locations_Types_By_PkArgs = {
  _set?: Maybe<ILife_Story_Locations_Types_Set_Input>;
  pk_columns: ILife_Story_Locations_Types_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_LocationsArgs = {
  _inc?: Maybe<ILocations_Inc_Input>;
  _set?: Maybe<ILocations_Set_Input>;
  where: ILocations_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Locations_By_PkArgs = {
  _inc?: Maybe<ILocations_Inc_Input>;
  _set?: Maybe<ILocations_Set_Input>;
  pk_columns: ILocations_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Locations_PublishersArgs = {
  _inc?: Maybe<ILocations_Publishers_Inc_Input>;
  _set?: Maybe<ILocations_Publishers_Set_Input>;
  where: ILocations_Publishers_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_MemoriesArgs = {
  _inc?: Maybe<IMemories_Inc_Input>;
  _set?: Maybe<IMemories_Set_Input>;
  where: IMemories_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Memories_By_PkArgs = {
  _inc?: Maybe<IMemories_Inc_Input>;
  _set?: Maybe<IMemories_Set_Input>;
  pk_columns: IMemories_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Memories_ManyArgs = {
  updates: Array<IMemories_Updates>;
};


/** mutation root */
export type IMutation_RootUpdate_Metadata_LocationsArgs = {
  _inc?: Maybe<IMetadata_Locations_Inc_Input>;
  _set?: Maybe<IMetadata_Locations_Set_Input>;
  where: IMetadata_Locations_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Metadata_Locations_By_PkArgs = {
  _inc?: Maybe<IMetadata_Locations_Inc_Input>;
  _set?: Maybe<IMetadata_Locations_Set_Input>;
  pk_columns: IMetadata_Locations_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_ObitsArgs = {
  _set?: Maybe<IObits_Set_Input>;
  where: IObits_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Obits_By_PkArgs = {
  _set?: Maybe<IObits_Set_Input>;
  pk_columns: IObits_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Obits_ManyArgs = {
  updates: Array<IObits_Updates>;
};


/** mutation root */
export type IMutation_RootUpdate_ObituaryArgs = {
  _set?: Maybe<IObituary_Set_Input>;
  where: IObituary_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Obituary_By_PkArgs = {
  _set?: Maybe<IObituary_Set_Input>;
  pk_columns: IObituary_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Obituary_PublicationArgs = {
  _set?: Maybe<IObituary_Publication_Set_Input>;
  where: IObituary_Publication_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Obituary_Publication_By_PkArgs = {
  _set?: Maybe<IObituary_Publication_Set_Input>;
  pk_columns: IObituary_Publication_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Obituary_StatusArgs = {
  _set?: Maybe<IObituary_Status_Set_Input>;
  where: IObituary_Status_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Obituary_Status_By_PkArgs = {
  _set?: Maybe<IObituary_Status_Set_Input>;
  pk_columns: IObituary_Status_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Obituary_TagArgs = {
  _set?: Maybe<IObituary_Tag_Set_Input>;
  where: IObituary_Tag_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Obituary_Tag_By_PkArgs = {
  _set?: Maybe<IObituary_Tag_Set_Input>;
  pk_columns: IObituary_Tag_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Payment_MethodsArgs = {
  _inc?: Maybe<IPayment_Methods_Inc_Input>;
  _set?: Maybe<IPayment_Methods_Set_Input>;
  where: IPayment_Methods_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Phone_NumbersArgs = {
  _inc?: Maybe<IPhone_Numbers_Inc_Input>;
  _set?: Maybe<IPhone_Numbers_Set_Input>;
  where: IPhone_Numbers_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Possible_MatchesArgs = {
  _inc?: Maybe<IPossible_Matches_Inc_Input>;
  _set?: Maybe<IPossible_Matches_Set_Input>;
  where: IPossible_Matches_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_PublicationArgs = {
  _inc?: Maybe<IPublication_Inc_Input>;
  _set?: Maybe<IPublication_Set_Input>;
  where: IPublication_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Publication_By_PkArgs = {
  _inc?: Maybe<IPublication_Inc_Input>;
  _set?: Maybe<IPublication_Set_Input>;
  pk_columns: IPublication_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Publication_InfoArgs = {
  _set?: Maybe<IPublication_Info_Set_Input>;
  where: IPublication_Info_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Publication_Info_By_PkArgs = {
  _set?: Maybe<IPublication_Info_Set_Input>;
  pk_columns: IPublication_Info_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Publisher_Phone_NumbersArgs = {
  _inc?: Maybe<IPublisher_Phone_Numbers_Inc_Input>;
  _set?: Maybe<IPublisher_Phone_Numbers_Set_Input>;
  where: IPublisher_Phone_Numbers_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_PublishersArgs = {
  _inc?: Maybe<IPublishers_Inc_Input>;
  _set?: Maybe<IPublishers_Set_Input>;
  where: IPublishers_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Raw_DataArgs = {
  _append?: Maybe<IRaw_Data_Append_Input>;
  _delete_at_path?: Maybe<IRaw_Data_Delete_At_Path_Input>;
  _delete_elem?: Maybe<IRaw_Data_Delete_Elem_Input>;
  _delete_key?: Maybe<IRaw_Data_Delete_Key_Input>;
  _prepend?: Maybe<IRaw_Data_Prepend_Input>;
  _set?: Maybe<IRaw_Data_Set_Input>;
  where: IRaw_Data_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Raw_Data_By_PkArgs = {
  _append?: Maybe<IRaw_Data_Append_Input>;
  _delete_at_path?: Maybe<IRaw_Data_Delete_At_Path_Input>;
  _delete_elem?: Maybe<IRaw_Data_Delete_Elem_Input>;
  _delete_key?: Maybe<IRaw_Data_Delete_Key_Input>;
  _prepend?: Maybe<IRaw_Data_Prepend_Input>;
  _set?: Maybe<IRaw_Data_Set_Input>;
  pk_columns: IRaw_Data_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_ReviewsArgs = {
  _inc?: Maybe<IReviews_Inc_Input>;
  _set?: Maybe<IReviews_Set_Input>;
  where: IReviews_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_ServiceArgs = {
  _inc?: Maybe<IService_Inc_Input>;
  _set?: Maybe<IService_Set_Input>;
  where: IService_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Service_By_PkArgs = {
  _inc?: Maybe<IService_Inc_Input>;
  _set?: Maybe<IService_Set_Input>;
  pk_columns: IService_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Service_DurationArgs = {
  _inc?: Maybe<IService_Duration_Inc_Input>;
  _set?: Maybe<IService_Duration_Set_Input>;
  where: IService_Duration_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Service_Duration_By_PkArgs = {
  _inc?: Maybe<IService_Duration_Inc_Input>;
  _set?: Maybe<IService_Duration_Set_Input>;
  pk_columns: IService_Duration_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Service_Duration_TypeArgs = {
  _set?: Maybe<IService_Duration_Type_Set_Input>;
  where: IService_Duration_Type_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Service_Duration_Type_By_PkArgs = {
  _set?: Maybe<IService_Duration_Type_Set_Input>;
  pk_columns: IService_Duration_Type_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Service_OffersArgs = {
  _inc?: Maybe<IService_Offers_Inc_Input>;
  _set?: Maybe<IService_Offers_Set_Input>;
  where: IService_Offers_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_TagArgs = {
  _set?: Maybe<ITag_Set_Input>;
  where: ITag_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Tag_By_PkArgs = {
  _set?: Maybe<ITag_Set_Input>;
  pk_columns: ITag_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_TopicsArgs = {
  _inc?: Maybe<ITopics_Inc_Input>;
  _set?: Maybe<ITopics_Set_Input>;
  where: ITopics_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Topics_By_PkArgs = {
  _inc?: Maybe<ITopics_Inc_Input>;
  _set?: Maybe<ITopics_Set_Input>;
  pk_columns: ITopics_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Topics_ManyArgs = {
  updates: Array<ITopics_Updates>;
};


/** mutation root */
export type IMutation_RootUpdate_UrlsArgs = {
  _inc?: Maybe<IUrls_Inc_Input>;
  _set?: Maybe<IUrls_Set_Input>;
  where: IUrls_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_UsersArgs = {
  _set?: Maybe<IUsers_Set_Input>;
  where: IUsers_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Users_By_PkArgs = {
  _set?: Maybe<IUsers_Set_Input>;
  pk_columns: IUsers_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Users_ManyArgs = {
  updates: Array<IUsers_Updates>;
};


/** mutation root */
export type IMutation_RootUpdate_VerticalsArgs = {
  _inc?: Maybe<IVerticals_Inc_Input>;
  _set?: Maybe<IVerticals_Set_Input>;
  where: IVerticals_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Web_ConfigArgs = {
  _set?: Maybe<IWeb_Config_Set_Input>;
  where: IWeb_Config_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Web_Config_By_PkArgs = {
  _set?: Maybe<IWeb_Config_Set_Input>;
  pk_columns: IWeb_Config_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Web_Config_FilterArgs = {
  _set?: Maybe<IWeb_Config_Filter_Set_Input>;
  where: IWeb_Config_Filter_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Web_Config_Filter_By_PkArgs = {
  _set?: Maybe<IWeb_Config_Filter_Set_Input>;
  pk_columns: IWeb_Config_Filter_Pk_Columns_Input;
};


/** mutation root */
export type IMutation_RootUpdate_Web_Config_Filter_TagsArgs = {
  _set?: Maybe<IWeb_Config_Filter_Tags_Set_Input>;
  where: IWeb_Config_Filter_Tags_Bool_Exp;
};


/** mutation root */
export type IMutation_RootUpdate_Web_Config_Filter_Tags_By_PkArgs = {
  _set?: Maybe<IWeb_Config_Filter_Tags_Set_Input>;
  pk_columns: IWeb_Config_Filter_Tags_Pk_Columns_Input;
};


/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type INumeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};


/** Boolean expression to compare columns of type "obit_publish_status". All fields are combined with logical 'AND'. */
export type IObit_Publish_Status_Comparison_Exp = {
  _eq?: Maybe<Scalars['obit_publish_status']>;
  _gt?: Maybe<Scalars['obit_publish_status']>;
  _gte?: Maybe<Scalars['obit_publish_status']>;
  _in?: Maybe<Array<Scalars['obit_publish_status']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['obit_publish_status']>;
  _lte?: Maybe<Scalars['obit_publish_status']>;
  _neq?: Maybe<Scalars['obit_publish_status']>;
  _nin?: Maybe<Array<Scalars['obit_publish_status']>>;
};

/** columns and relationships of "obits" */
export type IObits = {
  __typename?: 'obits';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamp']>;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  last_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  memories: Array<IMemories>;
  /** An aggregate relationship */
  memories_aggregate: IMemories_Aggregate;
  middle_name?: Maybe<Scalars['String']>;
  published_at?: Maybe<Scalars['timestamp']>;
  slug: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};


/** columns and relationships of "obits" */
export type IObitsMemoriesArgs = {
  distinct_on?: Maybe<Array<IMemories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMemories_Order_By>>;
  where?: Maybe<IMemories_Bool_Exp>;
};


/** columns and relationships of "obits" */
export type IObitsMemories_AggregateArgs = {
  distinct_on?: Maybe<Array<IMemories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMemories_Order_By>>;
  where?: Maybe<IMemories_Bool_Exp>;
};

/** aggregated selection of "obits" */
export type IObits_Aggregate = {
  __typename?: 'obits_aggregate';
  aggregate?: Maybe<IObits_Aggregate_Fields>;
  nodes: Array<IObits>;
};

/** aggregate fields of "obits" */
export type IObits_Aggregate_Fields = {
  __typename?: 'obits_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IObits_Max_Fields>;
  min?: Maybe<IObits_Min_Fields>;
};


/** aggregate fields of "obits" */
export type IObits_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IObits_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "obits". All fields are combined with a logical 'AND'. */
export type IObits_Bool_Exp = {
  _and?: Maybe<Array<IObits_Bool_Exp>>;
  _not?: Maybe<IObits_Bool_Exp>;
  _or?: Maybe<Array<IObits_Bool_Exp>>;
  created_at?: Maybe<ITimestamptz_Comparison_Exp>;
  deleted_at?: Maybe<ITimestamp_Comparison_Exp>;
  first_name?: Maybe<IString_Comparison_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  last_name?: Maybe<IString_Comparison_Exp>;
  memories?: Maybe<IMemories_Bool_Exp>;
  memories_aggregate?: Maybe<IMemories_Aggregate_Bool_Exp>;
  middle_name?: Maybe<IString_Comparison_Exp>;
  published_at?: Maybe<ITimestamp_Comparison_Exp>;
  slug?: Maybe<IString_Comparison_Exp>;
  title?: Maybe<IString_Comparison_Exp>;
};

/** unique or primary key constraints on table "obits" */
export enum IObits_Constraint {
  /** unique or primary key constraint on columns "id" */
  ObitsPkey = 'obits_pkey',
  /** unique or primary key constraint on columns "slug" */
  ObitsSlugKey = 'obits_slug_key'
}

/** input type for inserting data into table "obits" */
export type IObits_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  memories?: Maybe<IMemories_Arr_Rel_Insert_Input>;
  middle_name?: Maybe<Scalars['String']>;
  published_at?: Maybe<Scalars['timestamp']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type IObits_Max_Fields = {
  __typename?: 'obits_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  published_at?: Maybe<Scalars['timestamp']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type IObits_Min_Fields = {
  __typename?: 'obits_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  published_at?: Maybe<Scalars['timestamp']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "obits" */
export type IObits_Mutation_Response = {
  __typename?: 'obits_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IObits>;
};

/** input type for inserting object relation for remote table "obits" */
export type IObits_Obj_Rel_Insert_Input = {
  data: IObits_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<IObits_On_Conflict>;
};

/** on_conflict condition type for table "obits" */
export type IObits_On_Conflict = {
  constraint: IObits_Constraint;
  update_columns?: Array<IObits_Update_Column>;
  where?: Maybe<IObits_Bool_Exp>;
};

/** Ordering options when selecting data from "obits". */
export type IObits_Order_By = {
  created_at?: Maybe<IOrder_By>;
  deleted_at?: Maybe<IOrder_By>;
  first_name?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  last_name?: Maybe<IOrder_By>;
  memories_aggregate?: Maybe<IMemories_Aggregate_Order_By>;
  middle_name?: Maybe<IOrder_By>;
  published_at?: Maybe<IOrder_By>;
  slug?: Maybe<IOrder_By>;
  title?: Maybe<IOrder_By>;
};

/** primary key columns input for table: obits */
export type IObits_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "obits" */
export enum IObits_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  MiddleName = 'middle_name',
  /** column name */
  PublishedAt = 'published_at',
  /** column name */
  Slug = 'slug',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "obits" */
export type IObits_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  published_at?: Maybe<Scalars['timestamp']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "obits" */
export type IObits_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IObits_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<ICursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IObits_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  published_at?: Maybe<Scalars['timestamp']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** update columns of table "obits" */
export enum IObits_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  MiddleName = 'middle_name',
  /** column name */
  PublishedAt = 'published_at',
  /** column name */
  Slug = 'slug',
  /** column name */
  Title = 'title'
}

export type IObits_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<IObits_Set_Input>;
  /** filter the rows which have to be updated */
  where: IObits_Bool_Exp;
};

/** columns and relationships of "obituary" */
export type IObituary = {
  __typename?: 'obituary';
  created_at?: Maybe<Scalars['timestamp']>;
  /** An array relationship */
  external_links: Array<IExternal_Link>;
  /** An aggregate relationship */
  external_links_aggregate: IExternal_Link_Aggregate;
  /** An array relationship */
  from_sales_orders: Array<IFrom_Sales_Order>;
  /** An aggregate relationship */
  from_sales_orders_aggregate: IFrom_Sales_Order_Aggregate;
  /** An object relationship */
  historical_obituary?: Maybe<IHistorical_Obituary>;
  id: Scalars['uuid'];
  /** An array relationship */
  image_orders: Array<IImage_Order>;
  /** An aggregate relationship */
  image_orders_aggregate: IImage_Order_Aggregate;
  /** An array relationship */
  images: Array<IImage>;
  /** An aggregate relationship */
  images_aggregate: IImage_Aggregate;
  /** A computed field, executes function "is_owner" */
  is_owner?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  life_stories: Array<ILife_Story>;
  /** An aggregate relationship */
  life_stories_aggregate: ILife_Story_Aggregate;
  /** An array relationship */
  life_story_locations: Array<ILife_Story_Location>;
  /** An aggregate relationship */
  life_story_locations_aggregate: ILife_Story_Location_Aggregate;
  /** An array relationship */
  obituary_publications: Array<IObituary_Publication>;
  /** An aggregate relationship */
  obituary_publications_aggregate: IObituary_Publication_Aggregate;
  /** An array relationship */
  obituary_tags: Array<IObituary_Tag>;
  /** An aggregate relationship */
  obituary_tags_aggregate: IObituary_Tag_Aggregate;
  owner: Scalars['uuid'];
  /** An array relationship */
  services: Array<IService>;
  /** An aggregate relationship */
  services_aggregate: IService_Aggregate;
  slug: Scalars['String'];
  status: IObituary_Status_Enum;
  updated_at?: Maybe<Scalars['timestamp']>;
};


/** columns and relationships of "obituary" */
export type IObituaryExternal_LinksArgs = {
  distinct_on?: Maybe<Array<IExternal_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExternal_Link_Order_By>>;
  where?: Maybe<IExternal_Link_Bool_Exp>;
};


/** columns and relationships of "obituary" */
export type IObituaryExternal_Links_AggregateArgs = {
  distinct_on?: Maybe<Array<IExternal_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExternal_Link_Order_By>>;
  where?: Maybe<IExternal_Link_Bool_Exp>;
};


/** columns and relationships of "obituary" */
export type IObituaryFrom_Sales_OrdersArgs = {
  distinct_on?: Maybe<Array<IFrom_Sales_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFrom_Sales_Order_Order_By>>;
  where?: Maybe<IFrom_Sales_Order_Bool_Exp>;
};


/** columns and relationships of "obituary" */
export type IObituaryFrom_Sales_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<IFrom_Sales_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFrom_Sales_Order_Order_By>>;
  where?: Maybe<IFrom_Sales_Order_Bool_Exp>;
};


/** columns and relationships of "obituary" */
export type IObituaryImage_OrdersArgs = {
  distinct_on?: Maybe<Array<IImage_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IImage_Order_Order_By>>;
  where?: Maybe<IImage_Order_Bool_Exp>;
};


/** columns and relationships of "obituary" */
export type IObituaryImage_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<IImage_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IImage_Order_Order_By>>;
  where?: Maybe<IImage_Order_Bool_Exp>;
};


/** columns and relationships of "obituary" */
export type IObituaryImagesArgs = {
  distinct_on?: Maybe<Array<IImage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IImage_Order_By>>;
  where?: Maybe<IImage_Bool_Exp>;
};


/** columns and relationships of "obituary" */
export type IObituaryImages_AggregateArgs = {
  distinct_on?: Maybe<Array<IImage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IImage_Order_By>>;
  where?: Maybe<IImage_Bool_Exp>;
};


/** columns and relationships of "obituary" */
export type IObituaryLife_StoriesArgs = {
  distinct_on?: Maybe<Array<ILife_Story_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILife_Story_Order_By>>;
  where?: Maybe<ILife_Story_Bool_Exp>;
};


/** columns and relationships of "obituary" */
export type IObituaryLife_Stories_AggregateArgs = {
  distinct_on?: Maybe<Array<ILife_Story_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILife_Story_Order_By>>;
  where?: Maybe<ILife_Story_Bool_Exp>;
};


/** columns and relationships of "obituary" */
export type IObituaryLife_Story_LocationsArgs = {
  distinct_on?: Maybe<Array<ILife_Story_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILife_Story_Location_Order_By>>;
  where?: Maybe<ILife_Story_Location_Bool_Exp>;
};


/** columns and relationships of "obituary" */
export type IObituaryLife_Story_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<ILife_Story_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILife_Story_Location_Order_By>>;
  where?: Maybe<ILife_Story_Location_Bool_Exp>;
};


/** columns and relationships of "obituary" */
export type IObituaryObituary_PublicationsArgs = {
  distinct_on?: Maybe<Array<IObituary_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IObituary_Publication_Order_By>>;
  where?: Maybe<IObituary_Publication_Bool_Exp>;
};


/** columns and relationships of "obituary" */
export type IObituaryObituary_Publications_AggregateArgs = {
  distinct_on?: Maybe<Array<IObituary_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IObituary_Publication_Order_By>>;
  where?: Maybe<IObituary_Publication_Bool_Exp>;
};


/** columns and relationships of "obituary" */
export type IObituaryObituary_TagsArgs = {
  distinct_on?: Maybe<Array<IObituary_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IObituary_Tag_Order_By>>;
  where?: Maybe<IObituary_Tag_Bool_Exp>;
};


/** columns and relationships of "obituary" */
export type IObituaryObituary_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<IObituary_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IObituary_Tag_Order_By>>;
  where?: Maybe<IObituary_Tag_Bool_Exp>;
};


/** columns and relationships of "obituary" */
export type IObituaryServicesArgs = {
  distinct_on?: Maybe<Array<IService_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IService_Order_By>>;
  where?: Maybe<IService_Bool_Exp>;
};


/** columns and relationships of "obituary" */
export type IObituaryServices_AggregateArgs = {
  distinct_on?: Maybe<Array<IService_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IService_Order_By>>;
  where?: Maybe<IService_Bool_Exp>;
};

/** aggregated selection of "obituary" */
export type IObituary_Aggregate = {
  __typename?: 'obituary_aggregate';
  aggregate?: Maybe<IObituary_Aggregate_Fields>;
  nodes: Array<IObituary>;
};

/** aggregate fields of "obituary" */
export type IObituary_Aggregate_Fields = {
  __typename?: 'obituary_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IObituary_Max_Fields>;
  min?: Maybe<IObituary_Min_Fields>;
};


/** aggregate fields of "obituary" */
export type IObituary_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IObituary_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "obituary". All fields are combined with a logical 'AND'. */
export type IObituary_Bool_Exp = {
  _and?: Maybe<Array<IObituary_Bool_Exp>>;
  _not?: Maybe<IObituary_Bool_Exp>;
  _or?: Maybe<Array<IObituary_Bool_Exp>>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  external_links?: Maybe<IExternal_Link_Bool_Exp>;
  from_sales_orders?: Maybe<IFrom_Sales_Order_Bool_Exp>;
  historical_obituary?: Maybe<IHistorical_Obituary_Bool_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  image_orders?: Maybe<IImage_Order_Bool_Exp>;
  images?: Maybe<IImage_Bool_Exp>;
  is_owner?: Maybe<IBoolean_Comparison_Exp>;
  life_stories?: Maybe<ILife_Story_Bool_Exp>;
  life_story_locations?: Maybe<ILife_Story_Location_Bool_Exp>;
  obituary_publications?: Maybe<IObituary_Publication_Bool_Exp>;
  obituary_tags?: Maybe<IObituary_Tag_Bool_Exp>;
  owner?: Maybe<IUuid_Comparison_Exp>;
  services?: Maybe<IService_Bool_Exp>;
  slug?: Maybe<IString_Comparison_Exp>;
  status?: Maybe<IObituary_Status_Enum_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "obituary" */
export enum IObituary_Constraint {
  /** unique or primary key constraint on columns "id" */
  ObituaryPkey = 'obituary_pkey',
  /** unique or primary key constraint on columns "slug" */
  ObituarySlugKey = 'obituary_slug_key'
}

/** input type for inserting data into table "obituary" */
export type IObituary_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  external_links?: Maybe<IExternal_Link_Arr_Rel_Insert_Input>;
  from_sales_orders?: Maybe<IFrom_Sales_Order_Arr_Rel_Insert_Input>;
  historical_obituary?: Maybe<IHistorical_Obituary_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  image_orders?: Maybe<IImage_Order_Arr_Rel_Insert_Input>;
  images?: Maybe<IImage_Arr_Rel_Insert_Input>;
  life_stories?: Maybe<ILife_Story_Arr_Rel_Insert_Input>;
  life_story_locations?: Maybe<ILife_Story_Location_Arr_Rel_Insert_Input>;
  obituary_publications?: Maybe<IObituary_Publication_Arr_Rel_Insert_Input>;
  obituary_tags?: Maybe<IObituary_Tag_Arr_Rel_Insert_Input>;
  owner?: Maybe<Scalars['uuid']>;
  services?: Maybe<IService_Arr_Rel_Insert_Input>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<IObituary_Status_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type IObituary_Max_Fields = {
  __typename?: 'obituary_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  owner?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type IObituary_Min_Fields = {
  __typename?: 'obituary_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  owner?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "obituary" */
export type IObituary_Mutation_Response = {
  __typename?: 'obituary_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IObituary>;
};

/** input type for inserting object relation for remote table "obituary" */
export type IObituary_Obj_Rel_Insert_Input = {
  data: IObituary_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<IObituary_On_Conflict>;
};

/** on_conflict condition type for table "obituary" */
export type IObituary_On_Conflict = {
  constraint: IObituary_Constraint;
  update_columns?: Array<IObituary_Update_Column>;
  where?: Maybe<IObituary_Bool_Exp>;
};

/** Ordering options when selecting data from "obituary". */
export type IObituary_Order_By = {
  created_at?: Maybe<IOrder_By>;
  external_links_aggregate?: Maybe<IExternal_Link_Aggregate_Order_By>;
  from_sales_orders_aggregate?: Maybe<IFrom_Sales_Order_Aggregate_Order_By>;
  historical_obituary?: Maybe<IHistorical_Obituary_Order_By>;
  id?: Maybe<IOrder_By>;
  image_orders_aggregate?: Maybe<IImage_Order_Aggregate_Order_By>;
  images_aggregate?: Maybe<IImage_Aggregate_Order_By>;
  is_owner?: Maybe<IOrder_By>;
  life_stories_aggregate?: Maybe<ILife_Story_Aggregate_Order_By>;
  life_story_locations_aggregate?: Maybe<ILife_Story_Location_Aggregate_Order_By>;
  obituary_publications_aggregate?: Maybe<IObituary_Publication_Aggregate_Order_By>;
  obituary_tags_aggregate?: Maybe<IObituary_Tag_Aggregate_Order_By>;
  owner?: Maybe<IOrder_By>;
  services_aggregate?: Maybe<IService_Aggregate_Order_By>;
  slug?: Maybe<IOrder_By>;
  status?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** primary key columns input for table: obituary */
export type IObituary_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** Connecting obituaries with n number of publications */
export type IObituary_Publication = {
  __typename?: 'obituary_publication';
  created_at: Scalars['timestamp'];
  /** An object relationship */
  obituary: IObituary;
  obituary_id: Scalars['uuid'];
  /** An object relationship */
  publication: IPublication;
  publication_id: Scalars['uuid'];
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "obituary_publication" */
export type IObituary_Publication_Aggregate = {
  __typename?: 'obituary_publication_aggregate';
  aggregate?: Maybe<IObituary_Publication_Aggregate_Fields>;
  nodes: Array<IObituary_Publication>;
};

/** aggregate fields of "obituary_publication" */
export type IObituary_Publication_Aggregate_Fields = {
  __typename?: 'obituary_publication_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IObituary_Publication_Max_Fields>;
  min?: Maybe<IObituary_Publication_Min_Fields>;
};


/** aggregate fields of "obituary_publication" */
export type IObituary_Publication_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IObituary_Publication_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "obituary_publication" */
export type IObituary_Publication_Aggregate_Order_By = {
  count?: Maybe<IOrder_By>;
  max?: Maybe<IObituary_Publication_Max_Order_By>;
  min?: Maybe<IObituary_Publication_Min_Order_By>;
};

/** input type for inserting array relation for remote table "obituary_publication" */
export type IObituary_Publication_Arr_Rel_Insert_Input = {
  data: Array<IObituary_Publication_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<IObituary_Publication_On_Conflict>;
};

/** Boolean expression to filter rows from the table "obituary_publication". All fields are combined with a logical 'AND'. */
export type IObituary_Publication_Bool_Exp = {
  _and?: Maybe<Array<IObituary_Publication_Bool_Exp>>;
  _not?: Maybe<IObituary_Publication_Bool_Exp>;
  _or?: Maybe<Array<IObituary_Publication_Bool_Exp>>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  obituary?: Maybe<IObituary_Bool_Exp>;
  obituary_id?: Maybe<IUuid_Comparison_Exp>;
  publication?: Maybe<IPublication_Bool_Exp>;
  publication_id?: Maybe<IUuid_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "obituary_publication" */
export enum IObituary_Publication_Constraint {
  /** unique or primary key constraint on columns "publication_id", "obituary_id" */
  ObituaryPublicationPkey = 'obituary_publication_pkey'
}

/** input type for inserting data into table "obituary_publication" */
export type IObituary_Publication_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  obituary?: Maybe<IObituary_Obj_Rel_Insert_Input>;
  obituary_id?: Maybe<Scalars['uuid']>;
  publication?: Maybe<IPublication_Obj_Rel_Insert_Input>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type IObituary_Publication_Max_Fields = {
  __typename?: 'obituary_publication_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "obituary_publication" */
export type IObituary_Publication_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  obituary_id?: Maybe<IOrder_By>;
  publication_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IObituary_Publication_Min_Fields = {
  __typename?: 'obituary_publication_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "obituary_publication" */
export type IObituary_Publication_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  obituary_id?: Maybe<IOrder_By>;
  publication_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "obituary_publication" */
export type IObituary_Publication_Mutation_Response = {
  __typename?: 'obituary_publication_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IObituary_Publication>;
};

/** on_conflict condition type for table "obituary_publication" */
export type IObituary_Publication_On_Conflict = {
  constraint: IObituary_Publication_Constraint;
  update_columns?: Array<IObituary_Publication_Update_Column>;
  where?: Maybe<IObituary_Publication_Bool_Exp>;
};

/** Ordering options when selecting data from "obituary_publication". */
export type IObituary_Publication_Order_By = {
  created_at?: Maybe<IOrder_By>;
  obituary?: Maybe<IObituary_Order_By>;
  obituary_id?: Maybe<IOrder_By>;
  publication?: Maybe<IPublication_Order_By>;
  publication_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** primary key columns input for table: obituary_publication */
export type IObituary_Publication_Pk_Columns_Input = {
  obituary_id: Scalars['uuid'];
  publication_id: Scalars['uuid'];
};

/** select columns of table "obituary_publication" */
export enum IObituary_Publication_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ObituaryId = 'obituary_id',
  /** column name */
  PublicationId = 'publication_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "obituary_publication" */
export type IObituary_Publication_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "obituary_publication" */
export enum IObituary_Publication_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ObituaryId = 'obituary_id',
  /** column name */
  PublicationId = 'publication_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select columns of table "obituary" */
export enum IObituary_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Owner = 'owner',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "obituary" */
export type IObituary_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  owner?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<IObituary_Status_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** columns and relationships of "obituary_status" */
export type IObituary_Status = {
  __typename?: 'obituary_status';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "obituary_status" */
export type IObituary_Status_Aggregate = {
  __typename?: 'obituary_status_aggregate';
  aggregate?: Maybe<IObituary_Status_Aggregate_Fields>;
  nodes: Array<IObituary_Status>;
};

/** aggregate fields of "obituary_status" */
export type IObituary_Status_Aggregate_Fields = {
  __typename?: 'obituary_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IObituary_Status_Max_Fields>;
  min?: Maybe<IObituary_Status_Min_Fields>;
};


/** aggregate fields of "obituary_status" */
export type IObituary_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IObituary_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "obituary_status". All fields are combined with a logical 'AND'. */
export type IObituary_Status_Bool_Exp = {
  _and?: Maybe<Array<IObituary_Status_Bool_Exp>>;
  _not?: Maybe<IObituary_Status_Bool_Exp>;
  _or?: Maybe<Array<IObituary_Status_Bool_Exp>>;
  comment?: Maybe<IString_Comparison_Exp>;
  value?: Maybe<IString_Comparison_Exp>;
};

/** unique or primary key constraints on table "obituary_status" */
export enum IObituary_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  ObituaryStatusPkey = 'obituary_status_pkey'
}

export enum IObituary_Status_Enum {
  /** Draft or pending order rescinded */
  Canceled = 'canceled',
  /** Created but not finalized */
  Draft = 'draft',
  /** Obituary must not be used anywhere */
  Hidden = 'hidden',
  /** Submitted by customer, needs review */
  Pending = 'pending',
  /** Available to the public */
  Published = 'published',
  /** Previously published and removed from website */
  Unpublished = 'unpublished'
}

/** Boolean expression to compare columns of type "obituary_status_enum". All fields are combined with logical 'AND'. */
export type IObituary_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<IObituary_Status_Enum>;
  _in?: Maybe<Array<IObituary_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<IObituary_Status_Enum>;
  _nin?: Maybe<Array<IObituary_Status_Enum>>;
};

/** input type for inserting data into table "obituary_status" */
export type IObituary_Status_Insert_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type IObituary_Status_Max_Fields = {
  __typename?: 'obituary_status_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type IObituary_Status_Min_Fields = {
  __typename?: 'obituary_status_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "obituary_status" */
export type IObituary_Status_Mutation_Response = {
  __typename?: 'obituary_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IObituary_Status>;
};

/** on_conflict condition type for table "obituary_status" */
export type IObituary_Status_On_Conflict = {
  constraint: IObituary_Status_Constraint;
  update_columns?: Array<IObituary_Status_Update_Column>;
  where?: Maybe<IObituary_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "obituary_status". */
export type IObituary_Status_Order_By = {
  comment?: Maybe<IOrder_By>;
  value?: Maybe<IOrder_By>;
};

/** primary key columns input for table: obituary_status */
export type IObituary_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "obituary_status" */
export enum IObituary_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "obituary_status" */
export type IObituary_Status_Set_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "obituary_status" */
export enum IObituary_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** Connecting obituaries with n number of tags */
export type IObituary_Tag = {
  __typename?: 'obituary_tag';
  created_at: Scalars['timestamp'];
  /** An object relationship */
  obituary: IObituary;
  obituary_id: Scalars['uuid'];
  /** An object relationship */
  tag: ITag;
  tag_id: Scalars['uuid'];
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "obituary_tag" */
export type IObituary_Tag_Aggregate = {
  __typename?: 'obituary_tag_aggregate';
  aggregate?: Maybe<IObituary_Tag_Aggregate_Fields>;
  nodes: Array<IObituary_Tag>;
};

/** aggregate fields of "obituary_tag" */
export type IObituary_Tag_Aggregate_Fields = {
  __typename?: 'obituary_tag_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IObituary_Tag_Max_Fields>;
  min?: Maybe<IObituary_Tag_Min_Fields>;
};


/** aggregate fields of "obituary_tag" */
export type IObituary_Tag_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IObituary_Tag_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "obituary_tag" */
export type IObituary_Tag_Aggregate_Order_By = {
  count?: Maybe<IOrder_By>;
  max?: Maybe<IObituary_Tag_Max_Order_By>;
  min?: Maybe<IObituary_Tag_Min_Order_By>;
};

/** input type for inserting array relation for remote table "obituary_tag" */
export type IObituary_Tag_Arr_Rel_Insert_Input = {
  data: Array<IObituary_Tag_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<IObituary_Tag_On_Conflict>;
};

/** Boolean expression to filter rows from the table "obituary_tag". All fields are combined with a logical 'AND'. */
export type IObituary_Tag_Bool_Exp = {
  _and?: Maybe<Array<IObituary_Tag_Bool_Exp>>;
  _not?: Maybe<IObituary_Tag_Bool_Exp>;
  _or?: Maybe<Array<IObituary_Tag_Bool_Exp>>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  obituary?: Maybe<IObituary_Bool_Exp>;
  obituary_id?: Maybe<IUuid_Comparison_Exp>;
  tag?: Maybe<ITag_Bool_Exp>;
  tag_id?: Maybe<IUuid_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "obituary_tag" */
export enum IObituary_Tag_Constraint {
  /** unique or primary key constraint on columns "tag_id", "obituary_id" */
  ObituaryTagPkey = 'obituary_tag_pkey'
}

/** input type for inserting data into table "obituary_tag" */
export type IObituary_Tag_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  obituary?: Maybe<IObituary_Obj_Rel_Insert_Input>;
  obituary_id?: Maybe<Scalars['uuid']>;
  tag?: Maybe<ITag_Obj_Rel_Insert_Input>;
  tag_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type IObituary_Tag_Max_Fields = {
  __typename?: 'obituary_tag_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "obituary_tag" */
export type IObituary_Tag_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  obituary_id?: Maybe<IOrder_By>;
  tag_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IObituary_Tag_Min_Fields = {
  __typename?: 'obituary_tag_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "obituary_tag" */
export type IObituary_Tag_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  obituary_id?: Maybe<IOrder_By>;
  tag_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "obituary_tag" */
export type IObituary_Tag_Mutation_Response = {
  __typename?: 'obituary_tag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IObituary_Tag>;
};

/** on_conflict condition type for table "obituary_tag" */
export type IObituary_Tag_On_Conflict = {
  constraint: IObituary_Tag_Constraint;
  update_columns?: Array<IObituary_Tag_Update_Column>;
  where?: Maybe<IObituary_Tag_Bool_Exp>;
};

/** Ordering options when selecting data from "obituary_tag". */
export type IObituary_Tag_Order_By = {
  created_at?: Maybe<IOrder_By>;
  obituary?: Maybe<IObituary_Order_By>;
  obituary_id?: Maybe<IOrder_By>;
  tag?: Maybe<ITag_Order_By>;
  tag_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** primary key columns input for table: obituary_tag */
export type IObituary_Tag_Pk_Columns_Input = {
  obituary_id: Scalars['uuid'];
  tag_id: Scalars['uuid'];
};

/** select columns of table "obituary_tag" */
export enum IObituary_Tag_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ObituaryId = 'obituary_id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "obituary_tag" */
export type IObituary_Tag_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "obituary_tag" */
export enum IObituary_Tag_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ObituaryId = 'obituary_id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UpdatedAt = 'updated_at'
}


/** Boolean expression to compare columns of type "obituary_type". All fields are combined with logical 'AND'. */
export type IObituary_Type_Comparison_Exp = {
  _eq?: Maybe<Scalars['obituary_type']>;
  _gt?: Maybe<Scalars['obituary_type']>;
  _gte?: Maybe<Scalars['obituary_type']>;
  _in?: Maybe<Array<Scalars['obituary_type']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['obituary_type']>;
  _lte?: Maybe<Scalars['obituary_type']>;
  _neq?: Maybe<Scalars['obituary_type']>;
  _nin?: Maybe<Array<Scalars['obituary_type']>>;
};

/** update columns of table "obituary" */
export enum IObituary_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Owner = 'owner',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** column ordering options */
export enum IOrder_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "payment_methods" */
export type IPayment_Methods = {
  __typename?: 'payment_methods';
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  location?: Maybe<ILocations>;
  location_id?: Maybe<Scalars['bigint']>;
  payment_type?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "payment_methods" */
export type IPayment_Methods_Aggregate = {
  __typename?: 'payment_methods_aggregate';
  aggregate?: Maybe<IPayment_Methods_Aggregate_Fields>;
  nodes: Array<IPayment_Methods>;
};

/** aggregate fields of "payment_methods" */
export type IPayment_Methods_Aggregate_Fields = {
  __typename?: 'payment_methods_aggregate_fields';
  avg?: Maybe<IPayment_Methods_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<IPayment_Methods_Max_Fields>;
  min?: Maybe<IPayment_Methods_Min_Fields>;
  stddev?: Maybe<IPayment_Methods_Stddev_Fields>;
  stddev_pop?: Maybe<IPayment_Methods_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IPayment_Methods_Stddev_Samp_Fields>;
  sum?: Maybe<IPayment_Methods_Sum_Fields>;
  var_pop?: Maybe<IPayment_Methods_Var_Pop_Fields>;
  var_samp?: Maybe<IPayment_Methods_Var_Samp_Fields>;
  variance?: Maybe<IPayment_Methods_Variance_Fields>;
};


/** aggregate fields of "payment_methods" */
export type IPayment_Methods_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IPayment_Methods_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "payment_methods" */
export type IPayment_Methods_Aggregate_Order_By = {
  avg?: Maybe<IPayment_Methods_Avg_Order_By>;
  count?: Maybe<IOrder_By>;
  max?: Maybe<IPayment_Methods_Max_Order_By>;
  min?: Maybe<IPayment_Methods_Min_Order_By>;
  stddev?: Maybe<IPayment_Methods_Stddev_Order_By>;
  stddev_pop?: Maybe<IPayment_Methods_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IPayment_Methods_Stddev_Samp_Order_By>;
  sum?: Maybe<IPayment_Methods_Sum_Order_By>;
  var_pop?: Maybe<IPayment_Methods_Var_Pop_Order_By>;
  var_samp?: Maybe<IPayment_Methods_Var_Samp_Order_By>;
  variance?: Maybe<IPayment_Methods_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "payment_methods" */
export type IPayment_Methods_Arr_Rel_Insert_Input = {
  data: Array<IPayment_Methods_Insert_Input>;
  on_conflict?: Maybe<IPayment_Methods_On_Conflict>;
};

/** aggregate avg on columns */
export type IPayment_Methods_Avg_Fields = {
  __typename?: 'payment_methods_avg_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "payment_methods" */
export type IPayment_Methods_Avg_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** Boolean expression to filter rows from the table "payment_methods". All fields are combined with a logical 'AND'. */
export type IPayment_Methods_Bool_Exp = {
  _and?: Maybe<Array<Maybe<IPayment_Methods_Bool_Exp>>>;
  _not?: Maybe<IPayment_Methods_Bool_Exp>;
  _or?: Maybe<Array<Maybe<IPayment_Methods_Bool_Exp>>>;
  created_at?: Maybe<ITimestamptz_Comparison_Exp>;
  id?: Maybe<IBigint_Comparison_Exp>;
  location?: Maybe<ILocations_Bool_Exp>;
  location_id?: Maybe<IBigint_Comparison_Exp>;
  payment_type?: Maybe<IString_Comparison_Exp>;
  updated_at?: Maybe<ITimestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_methods" */
export enum IPayment_Methods_Constraint {
  /** unique or primary key constraint */
  Idx_17045Primary = 'idx_17045_primary'
}

/** input type for incrementing integer column in table "payment_methods" */
export type IPayment_Methods_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "payment_methods" */
export type IPayment_Methods_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  location?: Maybe<ILocations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['bigint']>;
  payment_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type IPayment_Methods_Max_Fields = {
  __typename?: 'payment_methods_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  payment_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "payment_methods" */
export type IPayment_Methods_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  payment_type?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IPayment_Methods_Min_Fields = {
  __typename?: 'payment_methods_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  payment_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "payment_methods" */
export type IPayment_Methods_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  payment_type?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "payment_methods" */
export type IPayment_Methods_Mutation_Response = {
  __typename?: 'payment_methods_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<IPayment_Methods>;
};

/** input type for inserting object relation for remote table "payment_methods" */
export type IPayment_Methods_Obj_Rel_Insert_Input = {
  data: IPayment_Methods_Insert_Input;
  on_conflict?: Maybe<IPayment_Methods_On_Conflict>;
};

/** on conflict condition type for table "payment_methods" */
export type IPayment_Methods_On_Conflict = {
  constraint: IPayment_Methods_Constraint;
  update_columns: Array<IPayment_Methods_Update_Column>;
  where?: Maybe<IPayment_Methods_Bool_Exp>;
};

/** ordering options when selecting data from "payment_methods" */
export type IPayment_Methods_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location?: Maybe<ILocations_Order_By>;
  location_id?: Maybe<IOrder_By>;
  payment_type?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** select columns of table "payment_methods" */
export enum IPayment_Methods_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  PaymentType = 'payment_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "payment_methods" */
export type IPayment_Methods_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  payment_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type IPayment_Methods_Stddev_Fields = {
  __typename?: 'payment_methods_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "payment_methods" */
export type IPayment_Methods_Stddev_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IPayment_Methods_Stddev_Pop_Fields = {
  __typename?: 'payment_methods_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "payment_methods" */
export type IPayment_Methods_Stddev_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IPayment_Methods_Stddev_Samp_Fields = {
  __typename?: 'payment_methods_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "payment_methods" */
export type IPayment_Methods_Stddev_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate sum on columns */
export type IPayment_Methods_Sum_Fields = {
  __typename?: 'payment_methods_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "payment_methods" */
export type IPayment_Methods_Sum_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** update columns of table "payment_methods" */
export enum IPayment_Methods_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  PaymentType = 'payment_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type IPayment_Methods_Var_Pop_Fields = {
  __typename?: 'payment_methods_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "payment_methods" */
export type IPayment_Methods_Var_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate var_samp on columns */
export type IPayment_Methods_Var_Samp_Fields = {
  __typename?: 'payment_methods_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "payment_methods" */
export type IPayment_Methods_Var_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate variance on columns */
export type IPayment_Methods_Variance_Fields = {
  __typename?: 'payment_methods_variance_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "payment_methods" */
export type IPayment_Methods_Variance_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** columns and relationships of "phone_numbers" */
export type IPhone_Numbers = {
  __typename?: 'phone_numbers';
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  location?: Maybe<ILocations>;
  location_id?: Maybe<Scalars['bigint']>;
  number?: Maybe<Scalars['String']>;
  number_normalized?: Maybe<Scalars['String']>;
  phone_type?: Maybe<Scalars['String']>;
  primary: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "phone_numbers" */
export type IPhone_Numbers_Aggregate = {
  __typename?: 'phone_numbers_aggregate';
  aggregate?: Maybe<IPhone_Numbers_Aggregate_Fields>;
  nodes: Array<IPhone_Numbers>;
};

/** aggregate fields of "phone_numbers" */
export type IPhone_Numbers_Aggregate_Fields = {
  __typename?: 'phone_numbers_aggregate_fields';
  avg?: Maybe<IPhone_Numbers_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<IPhone_Numbers_Max_Fields>;
  min?: Maybe<IPhone_Numbers_Min_Fields>;
  stddev?: Maybe<IPhone_Numbers_Stddev_Fields>;
  stddev_pop?: Maybe<IPhone_Numbers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IPhone_Numbers_Stddev_Samp_Fields>;
  sum?: Maybe<IPhone_Numbers_Sum_Fields>;
  var_pop?: Maybe<IPhone_Numbers_Var_Pop_Fields>;
  var_samp?: Maybe<IPhone_Numbers_Var_Samp_Fields>;
  variance?: Maybe<IPhone_Numbers_Variance_Fields>;
};


/** aggregate fields of "phone_numbers" */
export type IPhone_Numbers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IPhone_Numbers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "phone_numbers" */
export type IPhone_Numbers_Aggregate_Order_By = {
  avg?: Maybe<IPhone_Numbers_Avg_Order_By>;
  count?: Maybe<IOrder_By>;
  max?: Maybe<IPhone_Numbers_Max_Order_By>;
  min?: Maybe<IPhone_Numbers_Min_Order_By>;
  stddev?: Maybe<IPhone_Numbers_Stddev_Order_By>;
  stddev_pop?: Maybe<IPhone_Numbers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IPhone_Numbers_Stddev_Samp_Order_By>;
  sum?: Maybe<IPhone_Numbers_Sum_Order_By>;
  var_pop?: Maybe<IPhone_Numbers_Var_Pop_Order_By>;
  var_samp?: Maybe<IPhone_Numbers_Var_Samp_Order_By>;
  variance?: Maybe<IPhone_Numbers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "phone_numbers" */
export type IPhone_Numbers_Arr_Rel_Insert_Input = {
  data: Array<IPhone_Numbers_Insert_Input>;
  on_conflict?: Maybe<IPhone_Numbers_On_Conflict>;
};

/** aggregate avg on columns */
export type IPhone_Numbers_Avg_Fields = {
  __typename?: 'phone_numbers_avg_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "phone_numbers" */
export type IPhone_Numbers_Avg_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** Boolean expression to filter rows from the table "phone_numbers". All fields are combined with a logical 'AND'. */
export type IPhone_Numbers_Bool_Exp = {
  _and?: Maybe<Array<Maybe<IPhone_Numbers_Bool_Exp>>>;
  _not?: Maybe<IPhone_Numbers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<IPhone_Numbers_Bool_Exp>>>;
  created_at?: Maybe<ITimestamptz_Comparison_Exp>;
  id?: Maybe<IBigint_Comparison_Exp>;
  location?: Maybe<ILocations_Bool_Exp>;
  location_id?: Maybe<IBigint_Comparison_Exp>;
  number?: Maybe<IString_Comparison_Exp>;
  number_normalized?: Maybe<IString_Comparison_Exp>;
  phone_type?: Maybe<IString_Comparison_Exp>;
  primary?: Maybe<IBoolean_Comparison_Exp>;
  updated_at?: Maybe<ITimestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "phone_numbers" */
export enum IPhone_Numbers_Constraint {
  /** unique or primary key constraint */
  Idx_17051Primary = 'idx_17051_primary'
}

/** input type for incrementing integer column in table "phone_numbers" */
export type IPhone_Numbers_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "phone_numbers" */
export type IPhone_Numbers_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  location?: Maybe<ILocations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['bigint']>;
  number?: Maybe<Scalars['String']>;
  number_normalized?: Maybe<Scalars['String']>;
  phone_type?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type IPhone_Numbers_Max_Fields = {
  __typename?: 'phone_numbers_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  number?: Maybe<Scalars['String']>;
  number_normalized?: Maybe<Scalars['String']>;
  phone_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "phone_numbers" */
export type IPhone_Numbers_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  number?: Maybe<IOrder_By>;
  number_normalized?: Maybe<IOrder_By>;
  phone_type?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IPhone_Numbers_Min_Fields = {
  __typename?: 'phone_numbers_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  number?: Maybe<Scalars['String']>;
  number_normalized?: Maybe<Scalars['String']>;
  phone_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "phone_numbers" */
export type IPhone_Numbers_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  number?: Maybe<IOrder_By>;
  number_normalized?: Maybe<IOrder_By>;
  phone_type?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "phone_numbers" */
export type IPhone_Numbers_Mutation_Response = {
  __typename?: 'phone_numbers_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<IPhone_Numbers>;
};

/** input type for inserting object relation for remote table "phone_numbers" */
export type IPhone_Numbers_Obj_Rel_Insert_Input = {
  data: IPhone_Numbers_Insert_Input;
  on_conflict?: Maybe<IPhone_Numbers_On_Conflict>;
};

/** on conflict condition type for table "phone_numbers" */
export type IPhone_Numbers_On_Conflict = {
  constraint: IPhone_Numbers_Constraint;
  update_columns: Array<IPhone_Numbers_Update_Column>;
  where?: Maybe<IPhone_Numbers_Bool_Exp>;
};

/** ordering options when selecting data from "phone_numbers" */
export type IPhone_Numbers_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location?: Maybe<ILocations_Order_By>;
  location_id?: Maybe<IOrder_By>;
  number?: Maybe<IOrder_By>;
  number_normalized?: Maybe<IOrder_By>;
  phone_type?: Maybe<IOrder_By>;
  primary?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** select columns of table "phone_numbers" */
export enum IPhone_Numbers_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Number = 'number',
  /** column name */
  NumberNormalized = 'number_normalized',
  /** column name */
  PhoneType = 'phone_type',
  /** column name */
  Primary = 'primary',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "phone_numbers" */
export type IPhone_Numbers_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  number?: Maybe<Scalars['String']>;
  number_normalized?: Maybe<Scalars['String']>;
  phone_type?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type IPhone_Numbers_Stddev_Fields = {
  __typename?: 'phone_numbers_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "phone_numbers" */
export type IPhone_Numbers_Stddev_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IPhone_Numbers_Stddev_Pop_Fields = {
  __typename?: 'phone_numbers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "phone_numbers" */
export type IPhone_Numbers_Stddev_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IPhone_Numbers_Stddev_Samp_Fields = {
  __typename?: 'phone_numbers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "phone_numbers" */
export type IPhone_Numbers_Stddev_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate sum on columns */
export type IPhone_Numbers_Sum_Fields = {
  __typename?: 'phone_numbers_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "phone_numbers" */
export type IPhone_Numbers_Sum_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** update columns of table "phone_numbers" */
export enum IPhone_Numbers_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Number = 'number',
  /** column name */
  NumberNormalized = 'number_normalized',
  /** column name */
  PhoneType = 'phone_type',
  /** column name */
  Primary = 'primary',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type IPhone_Numbers_Var_Pop_Fields = {
  __typename?: 'phone_numbers_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "phone_numbers" */
export type IPhone_Numbers_Var_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate var_samp on columns */
export type IPhone_Numbers_Var_Samp_Fields = {
  __typename?: 'phone_numbers_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "phone_numbers" */
export type IPhone_Numbers_Var_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate variance on columns */
export type IPhone_Numbers_Variance_Fields = {
  __typename?: 'phone_numbers_variance_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "phone_numbers" */
export type IPhone_Numbers_Variance_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** columns and relationships of "possible_matches" */
export type IPossible_Matches = {
  __typename?: 'possible_matches';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  gmaid?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  /** An object relationship */
  location?: Maybe<ILocations>;
  location_id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "possible_matches" */
export type IPossible_Matches_Aggregate = {
  __typename?: 'possible_matches_aggregate';
  aggregate?: Maybe<IPossible_Matches_Aggregate_Fields>;
  nodes: Array<IPossible_Matches>;
};

/** aggregate fields of "possible_matches" */
export type IPossible_Matches_Aggregate_Fields = {
  __typename?: 'possible_matches_aggregate_fields';
  avg?: Maybe<IPossible_Matches_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<IPossible_Matches_Max_Fields>;
  min?: Maybe<IPossible_Matches_Min_Fields>;
  stddev?: Maybe<IPossible_Matches_Stddev_Fields>;
  stddev_pop?: Maybe<IPossible_Matches_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IPossible_Matches_Stddev_Samp_Fields>;
  sum?: Maybe<IPossible_Matches_Sum_Fields>;
  var_pop?: Maybe<IPossible_Matches_Var_Pop_Fields>;
  var_samp?: Maybe<IPossible_Matches_Var_Samp_Fields>;
  variance?: Maybe<IPossible_Matches_Variance_Fields>;
};


/** aggregate fields of "possible_matches" */
export type IPossible_Matches_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IPossible_Matches_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "possible_matches" */
export type IPossible_Matches_Aggregate_Order_By = {
  avg?: Maybe<IPossible_Matches_Avg_Order_By>;
  count?: Maybe<IOrder_By>;
  max?: Maybe<IPossible_Matches_Max_Order_By>;
  min?: Maybe<IPossible_Matches_Min_Order_By>;
  stddev?: Maybe<IPossible_Matches_Stddev_Order_By>;
  stddev_pop?: Maybe<IPossible_Matches_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IPossible_Matches_Stddev_Samp_Order_By>;
  sum?: Maybe<IPossible_Matches_Sum_Order_By>;
  var_pop?: Maybe<IPossible_Matches_Var_Pop_Order_By>;
  var_samp?: Maybe<IPossible_Matches_Var_Samp_Order_By>;
  variance?: Maybe<IPossible_Matches_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "possible_matches" */
export type IPossible_Matches_Arr_Rel_Insert_Input = {
  data: Array<IPossible_Matches_Insert_Input>;
  on_conflict?: Maybe<IPossible_Matches_On_Conflict>;
};

/** aggregate avg on columns */
export type IPossible_Matches_Avg_Fields = {
  __typename?: 'possible_matches_avg_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "possible_matches" */
export type IPossible_Matches_Avg_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** Boolean expression to filter rows from the table "possible_matches". All fields are combined with a logical 'AND'. */
export type IPossible_Matches_Bool_Exp = {
  _and?: Maybe<Array<Maybe<IPossible_Matches_Bool_Exp>>>;
  _not?: Maybe<IPossible_Matches_Bool_Exp>;
  _or?: Maybe<Array<Maybe<IPossible_Matches_Bool_Exp>>>;
  address?: Maybe<IString_Comparison_Exp>;
  city?: Maybe<IString_Comparison_Exp>;
  created_at?: Maybe<ITimestamptz_Comparison_Exp>;
  gmaid?: Maybe<IString_Comparison_Exp>;
  id?: Maybe<IBigint_Comparison_Exp>;
  location?: Maybe<ILocations_Bool_Exp>;
  location_id?: Maybe<IBigint_Comparison_Exp>;
  name?: Maybe<IString_Comparison_Exp>;
  postal_code?: Maybe<IString_Comparison_Exp>;
  state?: Maybe<IString_Comparison_Exp>;
  updated_at?: Maybe<ITimestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "possible_matches" */
export enum IPossible_Matches_Constraint {
  /** unique or primary key constraint */
  Idx_17061Primary = 'idx_17061_primary'
}

/** input type for incrementing integer column in table "possible_matches" */
export type IPossible_Matches_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "possible_matches" */
export type IPossible_Matches_Insert_Input = {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  gmaid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  location?: Maybe<ILocations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type IPossible_Matches_Max_Fields = {
  __typename?: 'possible_matches_max_fields';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  gmaid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "possible_matches" */
export type IPossible_Matches_Max_Order_By = {
  address?: Maybe<IOrder_By>;
  city?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  gmaid?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  postal_code?: Maybe<IOrder_By>;
  state?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IPossible_Matches_Min_Fields = {
  __typename?: 'possible_matches_min_fields';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  gmaid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "possible_matches" */
export type IPossible_Matches_Min_Order_By = {
  address?: Maybe<IOrder_By>;
  city?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  gmaid?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  postal_code?: Maybe<IOrder_By>;
  state?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "possible_matches" */
export type IPossible_Matches_Mutation_Response = {
  __typename?: 'possible_matches_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<IPossible_Matches>;
};

/** input type for inserting object relation for remote table "possible_matches" */
export type IPossible_Matches_Obj_Rel_Insert_Input = {
  data: IPossible_Matches_Insert_Input;
  on_conflict?: Maybe<IPossible_Matches_On_Conflict>;
};

/** on conflict condition type for table "possible_matches" */
export type IPossible_Matches_On_Conflict = {
  constraint: IPossible_Matches_Constraint;
  update_columns: Array<IPossible_Matches_Update_Column>;
  where?: Maybe<IPossible_Matches_Bool_Exp>;
};

/** ordering options when selecting data from "possible_matches" */
export type IPossible_Matches_Order_By = {
  address?: Maybe<IOrder_By>;
  city?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  gmaid?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location?: Maybe<ILocations_Order_By>;
  location_id?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  postal_code?: Maybe<IOrder_By>;
  state?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** select columns of table "possible_matches" */
export enum IPossible_Matches_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Gmaid = 'gmaid',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  PostalCode = 'postal_code',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "possible_matches" */
export type IPossible_Matches_Set_Input = {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  gmaid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type IPossible_Matches_Stddev_Fields = {
  __typename?: 'possible_matches_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "possible_matches" */
export type IPossible_Matches_Stddev_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IPossible_Matches_Stddev_Pop_Fields = {
  __typename?: 'possible_matches_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "possible_matches" */
export type IPossible_Matches_Stddev_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IPossible_Matches_Stddev_Samp_Fields = {
  __typename?: 'possible_matches_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "possible_matches" */
export type IPossible_Matches_Stddev_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate sum on columns */
export type IPossible_Matches_Sum_Fields = {
  __typename?: 'possible_matches_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "possible_matches" */
export type IPossible_Matches_Sum_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** update columns of table "possible_matches" */
export enum IPossible_Matches_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Gmaid = 'gmaid',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  PostalCode = 'postal_code',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type IPossible_Matches_Var_Pop_Fields = {
  __typename?: 'possible_matches_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "possible_matches" */
export type IPossible_Matches_Var_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate var_samp on columns */
export type IPossible_Matches_Var_Samp_Fields = {
  __typename?: 'possible_matches_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "possible_matches" */
export type IPossible_Matches_Var_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate variance on columns */
export type IPossible_Matches_Variance_Fields = {
  __typename?: 'possible_matches_variance_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "possible_matches" */
export type IPossible_Matches_Variance_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** columns and relationships of "publication" */
export type IPublication = {
  __typename?: 'publication';
  /** An array relationship */
  capi_configs: Array<ICapi_Config>;
  /** An aggregate relationship */
  capi_configs_aggregate: ICapi_Config_Aggregate;
  created_at: Scalars['timestamp'];
  /** An array relationship */
  facebook_configs: Array<IFacebook_Config>;
  /** An aggregate relationship */
  facebook_configs_aggregate: IFacebook_Config_Aggregate;
  foreign_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  historical_obituary_publications: Array<IHistorical_Obituary_Publication>;
  /** An aggregate relationship */
  historical_obituary_publications_aggregate: IHistorical_Obituary_Publication_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  legacycom_configs: Array<ILegacycom_Config>;
  /** An aggregate relationship */
  legacycom_configs_aggregate: ILegacycom_Config_Aggregate;
  name: Scalars['String'];
  /** An array relationship */
  obituary_publications: Array<IObituary_Publication>;
  /** An aggregate relationship */
  obituary_publications_aggregate: IObituary_Publication_Aggregate;
  /** An array relationship */
  publication_info: Array<IPublication_Info>;
  /** An aggregate relationship */
  publication_info_aggregate: IPublication_Info_Aggregate;
  site_code: Scalars['String'];
  site_id?: Maybe<Scalars['Int']>;
  slug: Scalars['String'];
  updated_at: Scalars['timestamp'];
  version: Scalars['Int'];
  /** An array relationship */
  web_configs: Array<IWeb_Config>;
  /** An aggregate relationship */
  web_configs_aggregate: IWeb_Config_Aggregate;
};


/** columns and relationships of "publication" */
export type IPublicationCapi_ConfigsArgs = {
  distinct_on?: Maybe<Array<ICapi_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICapi_Config_Order_By>>;
  where?: Maybe<ICapi_Config_Bool_Exp>;
};


/** columns and relationships of "publication" */
export type IPublicationCapi_Configs_AggregateArgs = {
  distinct_on?: Maybe<Array<ICapi_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICapi_Config_Order_By>>;
  where?: Maybe<ICapi_Config_Bool_Exp>;
};


/** columns and relationships of "publication" */
export type IPublicationFacebook_ConfigsArgs = {
  distinct_on?: Maybe<Array<IFacebook_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFacebook_Config_Order_By>>;
  where?: Maybe<IFacebook_Config_Bool_Exp>;
};


/** columns and relationships of "publication" */
export type IPublicationFacebook_Configs_AggregateArgs = {
  distinct_on?: Maybe<Array<IFacebook_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFacebook_Config_Order_By>>;
  where?: Maybe<IFacebook_Config_Bool_Exp>;
};


/** columns and relationships of "publication" */
export type IPublicationHistorical_Obituary_PublicationsArgs = {
  distinct_on?: Maybe<Array<IHistorical_Obituary_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHistorical_Obituary_Publication_Order_By>>;
  where?: Maybe<IHistorical_Obituary_Publication_Bool_Exp>;
};


/** columns and relationships of "publication" */
export type IPublicationHistorical_Obituary_Publications_AggregateArgs = {
  distinct_on?: Maybe<Array<IHistorical_Obituary_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHistorical_Obituary_Publication_Order_By>>;
  where?: Maybe<IHistorical_Obituary_Publication_Bool_Exp>;
};


/** columns and relationships of "publication" */
export type IPublicationLegacycom_ConfigsArgs = {
  distinct_on?: Maybe<Array<ILegacycom_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILegacycom_Config_Order_By>>;
  where?: Maybe<ILegacycom_Config_Bool_Exp>;
};


/** columns and relationships of "publication" */
export type IPublicationLegacycom_Configs_AggregateArgs = {
  distinct_on?: Maybe<Array<ILegacycom_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILegacycom_Config_Order_By>>;
  where?: Maybe<ILegacycom_Config_Bool_Exp>;
};


/** columns and relationships of "publication" */
export type IPublicationObituary_PublicationsArgs = {
  distinct_on?: Maybe<Array<IObituary_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IObituary_Publication_Order_By>>;
  where?: Maybe<IObituary_Publication_Bool_Exp>;
};


/** columns and relationships of "publication" */
export type IPublicationObituary_Publications_AggregateArgs = {
  distinct_on?: Maybe<Array<IObituary_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IObituary_Publication_Order_By>>;
  where?: Maybe<IObituary_Publication_Bool_Exp>;
};


/** columns and relationships of "publication" */
export type IPublicationPublication_InfoArgs = {
  distinct_on?: Maybe<Array<IPublication_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPublication_Info_Order_By>>;
  where?: Maybe<IPublication_Info_Bool_Exp>;
};


/** columns and relationships of "publication" */
export type IPublicationPublication_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<IPublication_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPublication_Info_Order_By>>;
  where?: Maybe<IPublication_Info_Bool_Exp>;
};


/** columns and relationships of "publication" */
export type IPublicationWeb_ConfigsArgs = {
  distinct_on?: Maybe<Array<IWeb_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWeb_Config_Order_By>>;
  where?: Maybe<IWeb_Config_Bool_Exp>;
};


/** columns and relationships of "publication" */
export type IPublicationWeb_Configs_AggregateArgs = {
  distinct_on?: Maybe<Array<IWeb_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWeb_Config_Order_By>>;
  where?: Maybe<IWeb_Config_Bool_Exp>;
};

/** aggregated selection of "publication" */
export type IPublication_Aggregate = {
  __typename?: 'publication_aggregate';
  aggregate?: Maybe<IPublication_Aggregate_Fields>;
  nodes: Array<IPublication>;
};

/** aggregate fields of "publication" */
export type IPublication_Aggregate_Fields = {
  __typename?: 'publication_aggregate_fields';
  avg?: Maybe<IPublication_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IPublication_Max_Fields>;
  min?: Maybe<IPublication_Min_Fields>;
  stddev?: Maybe<IPublication_Stddev_Fields>;
  stddev_pop?: Maybe<IPublication_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IPublication_Stddev_Samp_Fields>;
  sum?: Maybe<IPublication_Sum_Fields>;
  var_pop?: Maybe<IPublication_Var_Pop_Fields>;
  var_samp?: Maybe<IPublication_Var_Samp_Fields>;
  variance?: Maybe<IPublication_Variance_Fields>;
};


/** aggregate fields of "publication" */
export type IPublication_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IPublication_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type IPublication_Avg_Fields = {
  __typename?: 'publication_avg_fields';
  site_id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "publication". All fields are combined with a logical 'AND'. */
export type IPublication_Bool_Exp = {
  _and?: Maybe<Array<IPublication_Bool_Exp>>;
  _not?: Maybe<IPublication_Bool_Exp>;
  _or?: Maybe<Array<IPublication_Bool_Exp>>;
  capi_configs?: Maybe<ICapi_Config_Bool_Exp>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  facebook_configs?: Maybe<IFacebook_Config_Bool_Exp>;
  foreign_id?: Maybe<IString_Comparison_Exp>;
  historical_obituary_publications?: Maybe<IHistorical_Obituary_Publication_Bool_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  legacycom_configs?: Maybe<ILegacycom_Config_Bool_Exp>;
  name?: Maybe<IString_Comparison_Exp>;
  obituary_publications?: Maybe<IObituary_Publication_Bool_Exp>;
  publication_info?: Maybe<IPublication_Info_Bool_Exp>;
  site_code?: Maybe<IString_Comparison_Exp>;
  site_id?: Maybe<IInt_Comparison_Exp>;
  slug?: Maybe<IString_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
  version?: Maybe<IInt_Comparison_Exp>;
  web_configs?: Maybe<IWeb_Config_Bool_Exp>;
};

/** unique or primary key constraints on table "publication" */
export enum IPublication_Constraint {
  /** unique or primary key constraint on columns "foreign_id" */
  PublicationForeignIdKey = 'publication_foreign_id_key',
  /** unique or primary key constraint on columns "name" */
  PublicationNameKey = 'publication_name_key',
  /** unique or primary key constraint on columns "id" */
  PublicationPk = 'publication_pk',
  /** unique or primary key constraint on columns "site_id", "site_code" */
  PublicationSiteIdSiteCodeKey = 'publication_site_id_site_code_key',
  /** unique or primary key constraint on columns "slug" */
  PublicationSlugKey = 'publication_slug_key'
}

/** input type for incrementing numeric columns in table "publication" */
export type IPublication_Inc_Input = {
  site_id?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "publication_info" */
export type IPublication_Info = {
  __typename?: 'publication_info';
  created_at: Scalars['timestamp'];
  deadlines?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  phone?: Maybe<Scalars['String']>;
  /** An object relationship */
  publication: IPublication;
  publication_id: Scalars['uuid'];
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "publication_info" */
export type IPublication_Info_Aggregate = {
  __typename?: 'publication_info_aggregate';
  aggregate?: Maybe<IPublication_Info_Aggregate_Fields>;
  nodes: Array<IPublication_Info>;
};

/** aggregate fields of "publication_info" */
export type IPublication_Info_Aggregate_Fields = {
  __typename?: 'publication_info_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IPublication_Info_Max_Fields>;
  min?: Maybe<IPublication_Info_Min_Fields>;
};


/** aggregate fields of "publication_info" */
export type IPublication_Info_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IPublication_Info_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "publication_info" */
export type IPublication_Info_Aggregate_Order_By = {
  count?: Maybe<IOrder_By>;
  max?: Maybe<IPublication_Info_Max_Order_By>;
  min?: Maybe<IPublication_Info_Min_Order_By>;
};

/** input type for inserting array relation for remote table "publication_info" */
export type IPublication_Info_Arr_Rel_Insert_Input = {
  data: Array<IPublication_Info_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<IPublication_Info_On_Conflict>;
};

/** Boolean expression to filter rows from the table "publication_info". All fields are combined with a logical 'AND'. */
export type IPublication_Info_Bool_Exp = {
  _and?: Maybe<Array<IPublication_Info_Bool_Exp>>;
  _not?: Maybe<IPublication_Info_Bool_Exp>;
  _or?: Maybe<Array<IPublication_Info_Bool_Exp>>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  deadlines?: Maybe<IString_Comparison_Exp>;
  email?: Maybe<IString_Comparison_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  phone?: Maybe<IString_Comparison_Exp>;
  publication?: Maybe<IPublication_Bool_Exp>;
  publication_id?: Maybe<IUuid_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "publication_info" */
export enum IPublication_Info_Constraint {
  /** unique or primary key constraint on columns "id" */
  PublicationInfoPkey = 'publication_info_pkey'
}

/** input type for inserting data into table "publication_info" */
export type IPublication_Info_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  deadlines?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  publication?: Maybe<IPublication_Obj_Rel_Insert_Input>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type IPublication_Info_Max_Fields = {
  __typename?: 'publication_info_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  deadlines?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "publication_info" */
export type IPublication_Info_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  deadlines?: Maybe<IOrder_By>;
  email?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  phone?: Maybe<IOrder_By>;
  publication_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IPublication_Info_Min_Fields = {
  __typename?: 'publication_info_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  deadlines?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "publication_info" */
export type IPublication_Info_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  deadlines?: Maybe<IOrder_By>;
  email?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  phone?: Maybe<IOrder_By>;
  publication_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "publication_info" */
export type IPublication_Info_Mutation_Response = {
  __typename?: 'publication_info_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IPublication_Info>;
};

/** on_conflict condition type for table "publication_info" */
export type IPublication_Info_On_Conflict = {
  constraint: IPublication_Info_Constraint;
  update_columns?: Array<IPublication_Info_Update_Column>;
  where?: Maybe<IPublication_Info_Bool_Exp>;
};

/** Ordering options when selecting data from "publication_info". */
export type IPublication_Info_Order_By = {
  created_at?: Maybe<IOrder_By>;
  deadlines?: Maybe<IOrder_By>;
  email?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  phone?: Maybe<IOrder_By>;
  publication?: Maybe<IPublication_Order_By>;
  publication_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** primary key columns input for table: publication_info */
export type IPublication_Info_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "publication_info" */
export enum IPublication_Info_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deadlines = 'deadlines',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Phone = 'phone',
  /** column name */
  PublicationId = 'publication_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "publication_info" */
export type IPublication_Info_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  deadlines?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  publication_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "publication_info" */
export enum IPublication_Info_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deadlines = 'deadlines',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Phone = 'phone',
  /** column name */
  PublicationId = 'publication_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for inserting data into table "publication" */
export type IPublication_Insert_Input = {
  capi_configs?: Maybe<ICapi_Config_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamp']>;
  facebook_configs?: Maybe<IFacebook_Config_Arr_Rel_Insert_Input>;
  foreign_id?: Maybe<Scalars['String']>;
  historical_obituary_publications?: Maybe<IHistorical_Obituary_Publication_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  legacycom_configs?: Maybe<ILegacycom_Config_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  obituary_publications?: Maybe<IObituary_Publication_Arr_Rel_Insert_Input>;
  publication_info?: Maybe<IPublication_Info_Arr_Rel_Insert_Input>;
  site_code?: Maybe<Scalars['String']>;
  site_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  version?: Maybe<Scalars['Int']>;
  web_configs?: Maybe<IWeb_Config_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type IPublication_Max_Fields = {
  __typename?: 'publication_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  foreign_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  site_code?: Maybe<Scalars['String']>;
  site_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  version?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type IPublication_Min_Fields = {
  __typename?: 'publication_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  foreign_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  site_code?: Maybe<Scalars['String']>;
  site_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  version?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "publication" */
export type IPublication_Mutation_Response = {
  __typename?: 'publication_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IPublication>;
};

/** input type for inserting object relation for remote table "publication" */
export type IPublication_Obj_Rel_Insert_Input = {
  data: IPublication_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<IPublication_On_Conflict>;
};

/** on_conflict condition type for table "publication" */
export type IPublication_On_Conflict = {
  constraint: IPublication_Constraint;
  update_columns?: Array<IPublication_Update_Column>;
  where?: Maybe<IPublication_Bool_Exp>;
};

/** Ordering options when selecting data from "publication". */
export type IPublication_Order_By = {
  capi_configs_aggregate?: Maybe<ICapi_Config_Aggregate_Order_By>;
  created_at?: Maybe<IOrder_By>;
  facebook_configs_aggregate?: Maybe<IFacebook_Config_Aggregate_Order_By>;
  foreign_id?: Maybe<IOrder_By>;
  historical_obituary_publications_aggregate?: Maybe<IHistorical_Obituary_Publication_Aggregate_Order_By>;
  id?: Maybe<IOrder_By>;
  legacycom_configs_aggregate?: Maybe<ILegacycom_Config_Aggregate_Order_By>;
  name?: Maybe<IOrder_By>;
  obituary_publications_aggregate?: Maybe<IObituary_Publication_Aggregate_Order_By>;
  publication_info_aggregate?: Maybe<IPublication_Info_Aggregate_Order_By>;
  site_code?: Maybe<IOrder_By>;
  site_id?: Maybe<IOrder_By>;
  slug?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  version?: Maybe<IOrder_By>;
  web_configs_aggregate?: Maybe<IWeb_Config_Aggregate_Order_By>;
};

/** primary key columns input for table: publication */
export type IPublication_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "publication" */
export enum IPublication_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ForeignId = 'foreign_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SiteCode = 'site_code',
  /** column name */
  SiteId = 'site_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "publication" */
export type IPublication_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  foreign_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  site_code?: Maybe<Scalars['String']>;
  site_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  version?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type IPublication_Stddev_Fields = {
  __typename?: 'publication_stddev_fields';
  site_id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type IPublication_Stddev_Pop_Fields = {
  __typename?: 'publication_stddev_pop_fields';
  site_id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type IPublication_Stddev_Samp_Fields = {
  __typename?: 'publication_stddev_samp_fields';
  site_id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type IPublication_Sum_Fields = {
  __typename?: 'publication_sum_fields';
  site_id?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
};

/** update columns of table "publication" */
export enum IPublication_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ForeignId = 'foreign_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SiteCode = 'site_code',
  /** column name */
  SiteId = 'site_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version'
}

/** aggregate var_pop on columns */
export type IPublication_Var_Pop_Fields = {
  __typename?: 'publication_var_pop_fields';
  site_id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type IPublication_Var_Samp_Fields = {
  __typename?: 'publication_var_samp_fields';
  site_id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type IPublication_Variance_Fields = {
  __typename?: 'publication_variance_fields';
  site_id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "publisher_phone_numbers" */
export type IPublisher_Phone_Numbers = {
  __typename?: 'publisher_phone_numbers';
  created_at?: Maybe<Scalars['timestamptz']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  number?: Maybe<Scalars['String']>;
  number_normalized?: Maybe<Scalars['String']>;
  phone_type?: Maybe<Scalars['String']>;
  /** An object relationship */
  publisher: IPublishers;
  publisher_id: Scalars['bigint'];
};

/** aggregated selection of "publisher_phone_numbers" */
export type IPublisher_Phone_Numbers_Aggregate = {
  __typename?: 'publisher_phone_numbers_aggregate';
  aggregate?: Maybe<IPublisher_Phone_Numbers_Aggregate_Fields>;
  nodes: Array<IPublisher_Phone_Numbers>;
};

/** aggregate fields of "publisher_phone_numbers" */
export type IPublisher_Phone_Numbers_Aggregate_Fields = {
  __typename?: 'publisher_phone_numbers_aggregate_fields';
  avg?: Maybe<IPublisher_Phone_Numbers_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<IPublisher_Phone_Numbers_Max_Fields>;
  min?: Maybe<IPublisher_Phone_Numbers_Min_Fields>;
  stddev?: Maybe<IPublisher_Phone_Numbers_Stddev_Fields>;
  stddev_pop?: Maybe<IPublisher_Phone_Numbers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IPublisher_Phone_Numbers_Stddev_Samp_Fields>;
  sum?: Maybe<IPublisher_Phone_Numbers_Sum_Fields>;
  var_pop?: Maybe<IPublisher_Phone_Numbers_Var_Pop_Fields>;
  var_samp?: Maybe<IPublisher_Phone_Numbers_Var_Samp_Fields>;
  variance?: Maybe<IPublisher_Phone_Numbers_Variance_Fields>;
};


/** aggregate fields of "publisher_phone_numbers" */
export type IPublisher_Phone_Numbers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IPublisher_Phone_Numbers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "publisher_phone_numbers" */
export type IPublisher_Phone_Numbers_Aggregate_Order_By = {
  avg?: Maybe<IPublisher_Phone_Numbers_Avg_Order_By>;
  count?: Maybe<IOrder_By>;
  max?: Maybe<IPublisher_Phone_Numbers_Max_Order_By>;
  min?: Maybe<IPublisher_Phone_Numbers_Min_Order_By>;
  stddev?: Maybe<IPublisher_Phone_Numbers_Stddev_Order_By>;
  stddev_pop?: Maybe<IPublisher_Phone_Numbers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IPublisher_Phone_Numbers_Stddev_Samp_Order_By>;
  sum?: Maybe<IPublisher_Phone_Numbers_Sum_Order_By>;
  var_pop?: Maybe<IPublisher_Phone_Numbers_Var_Pop_Order_By>;
  var_samp?: Maybe<IPublisher_Phone_Numbers_Var_Samp_Order_By>;
  variance?: Maybe<IPublisher_Phone_Numbers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "publisher_phone_numbers" */
export type IPublisher_Phone_Numbers_Arr_Rel_Insert_Input = {
  data: Array<IPublisher_Phone_Numbers_Insert_Input>;
  on_conflict?: Maybe<IPublisher_Phone_Numbers_On_Conflict>;
};

/** aggregate avg on columns */
export type IPublisher_Phone_Numbers_Avg_Fields = {
  __typename?: 'publisher_phone_numbers_avg_fields';
  id?: Maybe<Scalars['Float']>;
  publisher_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "publisher_phone_numbers" */
export type IPublisher_Phone_Numbers_Avg_Order_By = {
  id?: Maybe<IOrder_By>;
  publisher_id?: Maybe<IOrder_By>;
};

/** Boolean expression to filter rows from the table "publisher_phone_numbers". All fields are combined with a logical 'AND'. */
export type IPublisher_Phone_Numbers_Bool_Exp = {
  _and?: Maybe<Array<Maybe<IPublisher_Phone_Numbers_Bool_Exp>>>;
  _not?: Maybe<IPublisher_Phone_Numbers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<IPublisher_Phone_Numbers_Bool_Exp>>>;
  created_at?: Maybe<ITimestamptz_Comparison_Exp>;
  discarded_at?: Maybe<ITimestamptz_Comparison_Exp>;
  id?: Maybe<IBigint_Comparison_Exp>;
  number?: Maybe<IString_Comparison_Exp>;
  number_normalized?: Maybe<IString_Comparison_Exp>;
  phone_type?: Maybe<IString_Comparison_Exp>;
  publisher?: Maybe<IPublishers_Bool_Exp>;
  publisher_id?: Maybe<IBigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "publisher_phone_numbers" */
export enum IPublisher_Phone_Numbers_Constraint {
  /** unique or primary key constraint */
  Idx_17079Primary = 'idx_17079_primary'
}

/** input type for incrementing integer column in table "publisher_phone_numbers" */
export type IPublisher_Phone_Numbers_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  publisher_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "publisher_phone_numbers" */
export type IPublisher_Phone_Numbers_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  number?: Maybe<Scalars['String']>;
  number_normalized?: Maybe<Scalars['String']>;
  phone_type?: Maybe<Scalars['String']>;
  publisher?: Maybe<IPublishers_Obj_Rel_Insert_Input>;
  publisher_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type IPublisher_Phone_Numbers_Max_Fields = {
  __typename?: 'publisher_phone_numbers_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  number?: Maybe<Scalars['String']>;
  number_normalized?: Maybe<Scalars['String']>;
  phone_type?: Maybe<Scalars['String']>;
  publisher_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "publisher_phone_numbers" */
export type IPublisher_Phone_Numbers_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  discarded_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  number?: Maybe<IOrder_By>;
  number_normalized?: Maybe<IOrder_By>;
  phone_type?: Maybe<IOrder_By>;
  publisher_id?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IPublisher_Phone_Numbers_Min_Fields = {
  __typename?: 'publisher_phone_numbers_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  number?: Maybe<Scalars['String']>;
  number_normalized?: Maybe<Scalars['String']>;
  phone_type?: Maybe<Scalars['String']>;
  publisher_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "publisher_phone_numbers" */
export type IPublisher_Phone_Numbers_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  discarded_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  number?: Maybe<IOrder_By>;
  number_normalized?: Maybe<IOrder_By>;
  phone_type?: Maybe<IOrder_By>;
  publisher_id?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "publisher_phone_numbers" */
export type IPublisher_Phone_Numbers_Mutation_Response = {
  __typename?: 'publisher_phone_numbers_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<IPublisher_Phone_Numbers>;
};

/** input type for inserting object relation for remote table "publisher_phone_numbers" */
export type IPublisher_Phone_Numbers_Obj_Rel_Insert_Input = {
  data: IPublisher_Phone_Numbers_Insert_Input;
  on_conflict?: Maybe<IPublisher_Phone_Numbers_On_Conflict>;
};

/** on conflict condition type for table "publisher_phone_numbers" */
export type IPublisher_Phone_Numbers_On_Conflict = {
  constraint: IPublisher_Phone_Numbers_Constraint;
  update_columns: Array<IPublisher_Phone_Numbers_Update_Column>;
  where?: Maybe<IPublisher_Phone_Numbers_Bool_Exp>;
};

/** ordering options when selecting data from "publisher_phone_numbers" */
export type IPublisher_Phone_Numbers_Order_By = {
  created_at?: Maybe<IOrder_By>;
  discarded_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  number?: Maybe<IOrder_By>;
  number_normalized?: Maybe<IOrder_By>;
  phone_type?: Maybe<IOrder_By>;
  publisher?: Maybe<IPublishers_Order_By>;
  publisher_id?: Maybe<IOrder_By>;
};

/** select columns of table "publisher_phone_numbers" */
export enum IPublisher_Phone_Numbers_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DiscardedAt = 'discarded_at',
  /** column name */
  Id = 'id',
  /** column name */
  Number = 'number',
  /** column name */
  NumberNormalized = 'number_normalized',
  /** column name */
  PhoneType = 'phone_type',
  /** column name */
  PublisherId = 'publisher_id'
}

/** input type for updating data in table "publisher_phone_numbers" */
export type IPublisher_Phone_Numbers_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  number?: Maybe<Scalars['String']>;
  number_normalized?: Maybe<Scalars['String']>;
  phone_type?: Maybe<Scalars['String']>;
  publisher_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type IPublisher_Phone_Numbers_Stddev_Fields = {
  __typename?: 'publisher_phone_numbers_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  publisher_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "publisher_phone_numbers" */
export type IPublisher_Phone_Numbers_Stddev_Order_By = {
  id?: Maybe<IOrder_By>;
  publisher_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IPublisher_Phone_Numbers_Stddev_Pop_Fields = {
  __typename?: 'publisher_phone_numbers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  publisher_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "publisher_phone_numbers" */
export type IPublisher_Phone_Numbers_Stddev_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
  publisher_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IPublisher_Phone_Numbers_Stddev_Samp_Fields = {
  __typename?: 'publisher_phone_numbers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  publisher_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "publisher_phone_numbers" */
export type IPublisher_Phone_Numbers_Stddev_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
  publisher_id?: Maybe<IOrder_By>;
};

/** aggregate sum on columns */
export type IPublisher_Phone_Numbers_Sum_Fields = {
  __typename?: 'publisher_phone_numbers_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  publisher_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "publisher_phone_numbers" */
export type IPublisher_Phone_Numbers_Sum_Order_By = {
  id?: Maybe<IOrder_By>;
  publisher_id?: Maybe<IOrder_By>;
};

/** update columns of table "publisher_phone_numbers" */
export enum IPublisher_Phone_Numbers_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DiscardedAt = 'discarded_at',
  /** column name */
  Id = 'id',
  /** column name */
  Number = 'number',
  /** column name */
  NumberNormalized = 'number_normalized',
  /** column name */
  PhoneType = 'phone_type',
  /** column name */
  PublisherId = 'publisher_id'
}

/** aggregate var_pop on columns */
export type IPublisher_Phone_Numbers_Var_Pop_Fields = {
  __typename?: 'publisher_phone_numbers_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  publisher_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "publisher_phone_numbers" */
export type IPublisher_Phone_Numbers_Var_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
  publisher_id?: Maybe<IOrder_By>;
};

/** aggregate var_samp on columns */
export type IPublisher_Phone_Numbers_Var_Samp_Fields = {
  __typename?: 'publisher_phone_numbers_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  publisher_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "publisher_phone_numbers" */
export type IPublisher_Phone_Numbers_Var_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
  publisher_id?: Maybe<IOrder_By>;
};

/** aggregate variance on columns */
export type IPublisher_Phone_Numbers_Variance_Fields = {
  __typename?: 'publisher_phone_numbers_variance_fields';
  id?: Maybe<Scalars['Float']>;
  publisher_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "publisher_phone_numbers" */
export type IPublisher_Phone_Numbers_Variance_Order_By = {
  id?: Maybe<IOrder_By>;
  publisher_id?: Maybe<IOrder_By>;
};

/** columns and relationships of "publishers" */
export type IPublishers = {
  __typename?: 'publishers';
  address?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  folder?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  lat?: Maybe<Scalars['String']>;
  lng?: Maybe<Scalars['String']>;
  /** An array relationship */
  locations: Array<ILocations_Publishers>;
  /** An aggregated array relationship */
  locations_aggregate: ILocations_Publishers_Aggregate;
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  phone_numbers: Array<IPublisher_Phone_Numbers>;
  /** An aggregated array relationship */
  phone_numbers_aggregate: IPublisher_Phone_Numbers_Aggregate;
  postal_code?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  site_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  swiq_client?: Maybe<Scalars['String']>;
};


/** columns and relationships of "publishers" */
export type IPublishersLocationsArgs = {
  distinct_on?: Maybe<Array<ILocations_Publishers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILocations_Publishers_Order_By>>;
  where?: Maybe<ILocations_Publishers_Bool_Exp>;
};


/** columns and relationships of "publishers" */
export type IPublishersLocations_AggregateArgs = {
  distinct_on?: Maybe<Array<ILocations_Publishers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILocations_Publishers_Order_By>>;
  where?: Maybe<ILocations_Publishers_Bool_Exp>;
};


/** columns and relationships of "publishers" */
export type IPublishersPhone_NumbersArgs = {
  distinct_on?: Maybe<Array<IPublisher_Phone_Numbers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPublisher_Phone_Numbers_Order_By>>;
  where?: Maybe<IPublisher_Phone_Numbers_Bool_Exp>;
};


/** columns and relationships of "publishers" */
export type IPublishersPhone_Numbers_AggregateArgs = {
  distinct_on?: Maybe<Array<IPublisher_Phone_Numbers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPublisher_Phone_Numbers_Order_By>>;
  where?: Maybe<IPublisher_Phone_Numbers_Bool_Exp>;
};

/** aggregated selection of "publishers" */
export type IPublishers_Aggregate = {
  __typename?: 'publishers_aggregate';
  aggregate?: Maybe<IPublishers_Aggregate_Fields>;
  nodes: Array<IPublishers>;
};

/** aggregate fields of "publishers" */
export type IPublishers_Aggregate_Fields = {
  __typename?: 'publishers_aggregate_fields';
  avg?: Maybe<IPublishers_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<IPublishers_Max_Fields>;
  min?: Maybe<IPublishers_Min_Fields>;
  stddev?: Maybe<IPublishers_Stddev_Fields>;
  stddev_pop?: Maybe<IPublishers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IPublishers_Stddev_Samp_Fields>;
  sum?: Maybe<IPublishers_Sum_Fields>;
  var_pop?: Maybe<IPublishers_Var_Pop_Fields>;
  var_samp?: Maybe<IPublishers_Var_Samp_Fields>;
  variance?: Maybe<IPublishers_Variance_Fields>;
};


/** aggregate fields of "publishers" */
export type IPublishers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IPublishers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "publishers" */
export type IPublishers_Aggregate_Order_By = {
  avg?: Maybe<IPublishers_Avg_Order_By>;
  count?: Maybe<IOrder_By>;
  max?: Maybe<IPublishers_Max_Order_By>;
  min?: Maybe<IPublishers_Min_Order_By>;
  stddev?: Maybe<IPublishers_Stddev_Order_By>;
  stddev_pop?: Maybe<IPublishers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IPublishers_Stddev_Samp_Order_By>;
  sum?: Maybe<IPublishers_Sum_Order_By>;
  var_pop?: Maybe<IPublishers_Var_Pop_Order_By>;
  var_samp?: Maybe<IPublishers_Var_Samp_Order_By>;
  variance?: Maybe<IPublishers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "publishers" */
export type IPublishers_Arr_Rel_Insert_Input = {
  data: Array<IPublishers_Insert_Input>;
  on_conflict?: Maybe<IPublishers_On_Conflict>;
};

/** aggregate avg on columns */
export type IPublishers_Avg_Fields = {
  __typename?: 'publishers_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "publishers" */
export type IPublishers_Avg_Order_By = {
  id?: Maybe<IOrder_By>;
};

/** Boolean expression to filter rows from the table "publishers". All fields are combined with a logical 'AND'. */
export type IPublishers_Bool_Exp = {
  _and?: Maybe<Array<Maybe<IPublishers_Bool_Exp>>>;
  _not?: Maybe<IPublishers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<IPublishers_Bool_Exp>>>;
  address?: Maybe<IString_Comparison_Exp>;
  address_line_2?: Maybe<IString_Comparison_Exp>;
  city?: Maybe<IString_Comparison_Exp>;
  created_at?: Maybe<ITimestamptz_Comparison_Exp>;
  discarded_at?: Maybe<ITimestamptz_Comparison_Exp>;
  domain?: Maybe<IString_Comparison_Exp>;
  folder?: Maybe<IString_Comparison_Exp>;
  id?: Maybe<IBigint_Comparison_Exp>;
  lat?: Maybe<IString_Comparison_Exp>;
  lng?: Maybe<IString_Comparison_Exp>;
  locations?: Maybe<ILocations_Publishers_Bool_Exp>;
  name?: Maybe<IString_Comparison_Exp>;
  phone_numbers?: Maybe<IPublisher_Phone_Numbers_Bool_Exp>;
  postal_code?: Maybe<IString_Comparison_Exp>;
  region?: Maybe<IString_Comparison_Exp>;
  site_code?: Maybe<IString_Comparison_Exp>;
  state?: Maybe<IString_Comparison_Exp>;
  swiq_client?: Maybe<IString_Comparison_Exp>;
};

/** unique or primary key constraints on table "publishers" */
export enum IPublishers_Constraint {
  /** unique or primary key constraint */
  Idx_17070Primary = 'idx_17070_primary',
  /** unique or primary key constraint */
  PublishersSiteCodeSwiqClientKey = 'publishers_site_code_swiq_client_key'
}

/** input type for incrementing integer column in table "publishers" */
export type IPublishers_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "publishers" */
export type IPublishers_Insert_Input = {
  address?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  folder?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lat?: Maybe<Scalars['String']>;
  lng?: Maybe<Scalars['String']>;
  locations?: Maybe<ILocations_Publishers_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  phone_numbers?: Maybe<IPublisher_Phone_Numbers_Arr_Rel_Insert_Input>;
  postal_code?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  site_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  swiq_client?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type IPublishers_Max_Fields = {
  __typename?: 'publishers_max_fields';
  address?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  folder?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lat?: Maybe<Scalars['String']>;
  lng?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  site_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  swiq_client?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "publishers" */
export type IPublishers_Max_Order_By = {
  address?: Maybe<IOrder_By>;
  address_line_2?: Maybe<IOrder_By>;
  city?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  discarded_at?: Maybe<IOrder_By>;
  domain?: Maybe<IOrder_By>;
  folder?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  lat?: Maybe<IOrder_By>;
  lng?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  postal_code?: Maybe<IOrder_By>;
  region?: Maybe<IOrder_By>;
  site_code?: Maybe<IOrder_By>;
  state?: Maybe<IOrder_By>;
  swiq_client?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IPublishers_Min_Fields = {
  __typename?: 'publishers_min_fields';
  address?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  folder?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lat?: Maybe<Scalars['String']>;
  lng?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  site_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  swiq_client?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "publishers" */
export type IPublishers_Min_Order_By = {
  address?: Maybe<IOrder_By>;
  address_line_2?: Maybe<IOrder_By>;
  city?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  discarded_at?: Maybe<IOrder_By>;
  domain?: Maybe<IOrder_By>;
  folder?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  lat?: Maybe<IOrder_By>;
  lng?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  postal_code?: Maybe<IOrder_By>;
  region?: Maybe<IOrder_By>;
  site_code?: Maybe<IOrder_By>;
  state?: Maybe<IOrder_By>;
  swiq_client?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "publishers" */
export type IPublishers_Mutation_Response = {
  __typename?: 'publishers_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<IPublishers>;
};

/** input type for inserting object relation for remote table "publishers" */
export type IPublishers_Obj_Rel_Insert_Input = {
  data: IPublishers_Insert_Input;
  on_conflict?: Maybe<IPublishers_On_Conflict>;
};

/** on conflict condition type for table "publishers" */
export type IPublishers_On_Conflict = {
  constraint: IPublishers_Constraint;
  update_columns: Array<IPublishers_Update_Column>;
  where?: Maybe<IPublishers_Bool_Exp>;
};

/** ordering options when selecting data from "publishers" */
export type IPublishers_Order_By = {
  address?: Maybe<IOrder_By>;
  address_line_2?: Maybe<IOrder_By>;
  city?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  discarded_at?: Maybe<IOrder_By>;
  domain?: Maybe<IOrder_By>;
  folder?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  lat?: Maybe<IOrder_By>;
  lng?: Maybe<IOrder_By>;
  locations_aggregate?: Maybe<ILocations_Publishers_Aggregate_Order_By>;
  name?: Maybe<IOrder_By>;
  phone_numbers_aggregate?: Maybe<IPublisher_Phone_Numbers_Aggregate_Order_By>;
  postal_code?: Maybe<IOrder_By>;
  region?: Maybe<IOrder_By>;
  site_code?: Maybe<IOrder_By>;
  state?: Maybe<IOrder_By>;
  swiq_client?: Maybe<IOrder_By>;
};

/** select columns of table "publishers" */
export enum IPublishers_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  AddressLine_2 = 'address_line_2',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DiscardedAt = 'discarded_at',
  /** column name */
  Domain = 'domain',
  /** column name */
  Folder = 'folder',
  /** column name */
  Id = 'id',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  Name = 'name',
  /** column name */
  PostalCode = 'postal_code',
  /** column name */
  Region = 'region',
  /** column name */
  SiteCode = 'site_code',
  /** column name */
  State = 'state',
  /** column name */
  SwiqClient = 'swiq_client'
}

/** input type for updating data in table "publishers" */
export type IPublishers_Set_Input = {
  address?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  folder?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lat?: Maybe<Scalars['String']>;
  lng?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  site_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  swiq_client?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type IPublishers_Stddev_Fields = {
  __typename?: 'publishers_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "publishers" */
export type IPublishers_Stddev_Order_By = {
  id?: Maybe<IOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IPublishers_Stddev_Pop_Fields = {
  __typename?: 'publishers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "publishers" */
export type IPublishers_Stddev_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IPublishers_Stddev_Samp_Fields = {
  __typename?: 'publishers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "publishers" */
export type IPublishers_Stddev_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
};

/** aggregate sum on columns */
export type IPublishers_Sum_Fields = {
  __typename?: 'publishers_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "publishers" */
export type IPublishers_Sum_Order_By = {
  id?: Maybe<IOrder_By>;
};

/** update columns of table "publishers" */
export enum IPublishers_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  AddressLine_2 = 'address_line_2',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DiscardedAt = 'discarded_at',
  /** column name */
  Domain = 'domain',
  /** column name */
  Folder = 'folder',
  /** column name */
  Id = 'id',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  Name = 'name',
  /** column name */
  PostalCode = 'postal_code',
  /** column name */
  Region = 'region',
  /** column name */
  SiteCode = 'site_code',
  /** column name */
  State = 'state',
  /** column name */
  SwiqClient = 'swiq_client'
}

/** aggregate var_pop on columns */
export type IPublishers_Var_Pop_Fields = {
  __typename?: 'publishers_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "publishers" */
export type IPublishers_Var_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
};

/** aggregate var_samp on columns */
export type IPublishers_Var_Samp_Fields = {
  __typename?: 'publishers_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "publishers" */
export type IPublishers_Var_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
};

/** aggregate variance on columns */
export type IPublishers_Variance_Fields = {
  __typename?: 'publishers_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "publishers" */
export type IPublishers_Variance_Order_By = {
  id?: Maybe<IOrder_By>;
};

/** query root */
export type IQuery_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "archived_obituary" */
  archived_obituary: Array<IArchived_Obituary>;
  /** fetch aggregated fields from the table: "archived_obituary" */
  archived_obituary_aggregate: IArchived_Obituary_Aggregate;
  /** fetch data from the table: "archived_obituary" using primary key columns */
  archived_obituary_by_pk?: Maybe<IArchived_Obituary>;
  /** fetch data from the table: "capi_config" */
  capi_config: Array<ICapi_Config>;
  /** fetch aggregated fields from the table: "capi_config" */
  capi_config_aggregate: ICapi_Config_Aggregate;
  /** fetch data from the table: "capi_config" using primary key columns */
  capi_config_by_pk?: Maybe<ICapi_Config>;
  /** fetch data from the table: "categories" */
  categories: Array<ICategories>;
  /** fetch aggregated fields from the table: "categories" */
  categories_aggregate: ICategories_Aggregate;
  /** fetch data from the table: "categories_locations" */
  categories_locations: Array<ICategories_Locations>;
  /** fetch aggregated fields from the table: "categories_locations" */
  categories_locations_aggregate: ICategories_Locations_Aggregate;
  /** fetch data from the table: "categories_verticals" */
  categories_verticals: Array<ICategories_Verticals>;
  /** fetch aggregated fields from the table: "categories_verticals" */
  categories_verticals_aggregate: ICategories_Verticals_Aggregate;
  /** fetch data from the table: "category_image_urls" */
  category_image_urls: Array<ICategory_Image_Urls>;
  /** fetch aggregated fields from the table: "category_image_urls" */
  category_image_urls_aggregate: ICategory_Image_Urls_Aggregate;
  /** fetch data from the table: "category_keywords" */
  category_keywords: Array<ICategory_Keywords>;
  /** fetch aggregated fields from the table: "category_keywords" */
  category_keywords_aggregate: ICategory_Keywords_Aggregate;
  /** fetch data from the table: "coupon_adas" */
  coupon_adas: Array<ICoupon_Adas>;
  /** fetch aggregated fields from the table: "coupon_adas" */
  coupon_adas_aggregate: ICoupon_Adas_Aggregate;
  /** fetch data from the table: "daily_locations_counts" */
  daily_locations_counts: Array<IDaily_Locations_Counts>;
  /** fetch aggregated fields from the table: "daily_locations_counts" */
  daily_locations_counts_aggregate: IDaily_Locations_Counts_Aggregate;
  /** fetch data from the table: "display_obituary" */
  display_obituary: Array<IDisplay_Obituary>;
  /** fetch aggregated fields from the table: "display_obituary" */
  display_obituary_aggregate: IDisplay_Obituary_Aggregate;
  /** fetch data from the table: "display_obituary" using primary key columns */
  display_obituary_by_pk?: Maybe<IDisplay_Obituary>;
  /** fetch data from the table: "display_obituary_publication" */
  display_obituary_publication: Array<IDisplay_Obituary_Publication>;
  /** fetch aggregated fields from the table: "display_obituary_publication" */
  display_obituary_publication_aggregate: IDisplay_Obituary_Publication_Aggregate;
  /** fetch data from the table: "display_obituary_publication" using primary key columns */
  display_obituary_publication_by_pk?: Maybe<IDisplay_Obituary_Publication>;
  /** fetch data from the table: "display_obituary_tag" */
  display_obituary_tag: Array<IDisplay_Obituary_Tag>;
  /** fetch aggregated fields from the table: "display_obituary_tag" */
  display_obituary_tag_aggregate: IDisplay_Obituary_Tag_Aggregate;
  /** fetch data from the table: "display_obituary_tag" using primary key columns */
  display_obituary_tag_by_pk?: Maybe<IDisplay_Obituary_Tag>;
  /** fetch data from the table: "display_run_date" */
  display_run_date: Array<IDisplay_Run_Date>;
  /** fetch aggregated fields from the table: "display_run_date" */
  display_run_date_aggregate: IDisplay_Run_Date_Aggregate;
  /** fetch data from the table: "display_run_date" using primary key columns */
  display_run_date_by_pk?: Maybe<IDisplay_Run_Date>;
  dummy?: Maybe<Scalars['String']>;
  /** fetch data from the table: "duplicate_locations" */
  duplicate_locations: Array<IDuplicate_Locations>;
  /** fetch aggregated fields from the table: "duplicate_locations" */
  duplicate_locations_aggregate: IDuplicate_Locations_Aggregate;
  /** An array relationship */
  events: Array<IEvents>;
  /** An aggregate relationship */
  events_aggregate: IEvents_Aggregate;
  /** fetch data from the table: "events" using primary key columns */
  events_by_pk?: Maybe<IEvents>;
  /** fetch data from the table: "events_types" */
  events_types: Array<IEvents_Types>;
  /** fetch aggregated fields from the table: "events_types" */
  events_types_aggregate: IEvents_Types_Aggregate;
  /** fetch data from the table: "events_types" using primary key columns */
  events_types_by_pk?: Maybe<IEvents_Types>;
  /** fetch data from the table: "external_link" */
  external_link: Array<IExternal_Link>;
  /** fetch aggregated fields from the table: "external_link" */
  external_link_aggregate: IExternal_Link_Aggregate;
  /** fetch data from the table: "external_link" using primary key columns */
  external_link_by_pk?: Maybe<IExternal_Link>;
  /** fetch data from the table: "external_link_source" */
  external_link_source: Array<IExternal_Link_Source>;
  /** fetch aggregated fields from the table: "external_link_source" */
  external_link_source_aggregate: IExternal_Link_Source_Aggregate;
  /** fetch data from the table: "external_link_source" using primary key columns */
  external_link_source_by_pk?: Maybe<IExternal_Link_Source>;
  /** fetch data from the table: "external_link_type" */
  external_link_type: Array<IExternal_Link_Type>;
  /** fetch aggregated fields from the table: "external_link_type" */
  external_link_type_aggregate: IExternal_Link_Type_Aggregate;
  /** fetch data from the table: "external_link_type" using primary key columns */
  external_link_type_by_pk?: Maybe<IExternal_Link_Type>;
  /** fetch data from the table: "facebook_config" */
  facebook_config: Array<IFacebook_Config>;
  /** fetch aggregated fields from the table: "facebook_config" */
  facebook_config_aggregate: IFacebook_Config_Aggregate;
  /** fetch data from the table: "facebook_config" using primary key columns */
  facebook_config_by_pk?: Maybe<IFacebook_Config>;
  /** fetch data from the table: "from_sales_order" */
  from_sales_order: Array<IFrom_Sales_Order>;
  /** fetch aggregated fields from the table: "from_sales_order" */
  from_sales_order_aggregate: IFrom_Sales_Order_Aggregate;
  /** fetch data from the table: "from_sales_order" using primary key columns */
  from_sales_order_by_pk?: Maybe<IFrom_Sales_Order>;
  /** fetch data from the table: "from_sales_order_from_sales_publication" */
  from_sales_order_from_sales_publication: Array<IFrom_Sales_Order_From_Sales_Publication>;
  /** fetch aggregated fields from the table: "from_sales_order_from_sales_publication" */
  from_sales_order_from_sales_publication_aggregate: IFrom_Sales_Order_From_Sales_Publication_Aggregate;
  /** fetch data from the table: "from_sales_order_from_sales_publication" using primary key columns */
  from_sales_order_from_sales_publication_by_pk?: Maybe<IFrom_Sales_Order_From_Sales_Publication>;
  /** fetch data from the table: "from_sales_publication" */
  from_sales_publication: Array<IFrom_Sales_Publication>;
  /** fetch aggregated fields from the table: "from_sales_publication" */
  from_sales_publication_aggregate: IFrom_Sales_Publication_Aggregate;
  /** fetch data from the table: "from_sales_publication" using primary key columns */
  from_sales_publication_by_pk?: Maybe<IFrom_Sales_Publication>;
  /** fetch data from the table: "funeral_home" */
  funeral_home: Array<IFuneral_Home>;
  /** fetch aggregated fields from the table: "funeral_home" */
  funeral_home_aggregate: IFuneral_Home_Aggregate;
  /** fetch data from the table: "funeral_home" using primary key columns */
  funeral_home_by_pk?: Maybe<IFuneral_Home>;
  /** fetch data from the table: "funeral_home_external_id" */
  funeral_home_external_id: Array<IFuneral_Home_External_Id>;
  /** fetch aggregated fields from the table: "funeral_home_external_id" */
  funeral_home_external_id_aggregate: IFuneral_Home_External_Id_Aggregate;
  /** fetch data from the table: "funeral_home_external_id" using primary key columns */
  funeral_home_external_id_by_pk?: Maybe<IFuneral_Home_External_Id>;
  /** fetch data from the table: "funeral_home_external_user_id" */
  funeral_home_external_user_id: Array<IFuneral_Home_External_User_Id>;
  /** fetch aggregated fields from the table: "funeral_home_external_user_id" */
  funeral_home_external_user_id_aggregate: IFuneral_Home_External_User_Id_Aggregate;
  /** fetch data from the table: "funeral_home_external_user_id" using primary key columns */
  funeral_home_external_user_id_by_pk?: Maybe<IFuneral_Home_External_User_Id>;
  get_image_signed_policy_url?: Maybe<IPolicy>;
  /** should not be used */
  get_memory?: Maybe<IMemory>;
  get_signed_policy?: Maybe<IPolicy>;
  /** fetch data from the table: "historical_obituary" */
  historical_obituary: Array<IHistorical_Obituary>;
  /** fetch aggregated fields from the table: "historical_obituary" */
  historical_obituary_aggregate: IHistorical_Obituary_Aggregate;
  /** fetch data from the table: "historical_obituary" using primary key columns */
  historical_obituary_by_pk?: Maybe<IHistorical_Obituary>;
  /** fetch data from the table: "historical_obituary_event" */
  historical_obituary_event: Array<IHistorical_Obituary_Event>;
  /** fetch aggregated fields from the table: "historical_obituary_event" */
  historical_obituary_event_aggregate: IHistorical_Obituary_Event_Aggregate;
  /** fetch data from the table: "historical_obituary_event" using primary key columns */
  historical_obituary_event_by_pk?: Maybe<IHistorical_Obituary_Event>;
  /** fetch data from the table: "historical_obituary_publication" */
  historical_obituary_publication: Array<IHistorical_Obituary_Publication>;
  /** fetch aggregated fields from the table: "historical_obituary_publication" */
  historical_obituary_publication_aggregate: IHistorical_Obituary_Publication_Aggregate;
  /** fetch data from the table: "historical_obituary_publication" using primary key columns */
  historical_obituary_publication_by_pk?: Maybe<IHistorical_Obituary_Publication>;
  /** fetch data from the table: "historical_obituary_tag" */
  historical_obituary_tag: Array<IHistorical_Obituary_Tag>;
  /** fetch aggregated fields from the table: "historical_obituary_tag" */
  historical_obituary_tag_aggregate: IHistorical_Obituary_Tag_Aggregate;
  /** fetch data from the table: "historical_obituary_tag" using primary key columns */
  historical_obituary_tag_by_pk?: Maybe<IHistorical_Obituary_Tag>;
  /** fetch data from the table: "image" */
  image: Array<IImage>;
  /** fetch aggregated fields from the table: "image" */
  image_aggregate: IImage_Aggregate;
  /** fetch data from the table: "image" using primary key columns */
  image_by_pk?: Maybe<IImage>;
  /** fetch data from the table: "image_lookup" */
  image_lookup: Array<IImage_Lookup>;
  /** fetch aggregated fields from the table: "image_lookup" */
  image_lookup_aggregate: IImage_Lookup_Aggregate;
  /** fetch data from the table: "image_lookup" using primary key columns */
  image_lookup_by_pk?: Maybe<IImage_Lookup>;
  /** fetch data from the table: "image_order" */
  image_order: Array<IImage_Order>;
  /** fetch aggregated fields from the table: "image_order" */
  image_order_aggregate: IImage_Order_Aggregate;
  /** fetch data from the table: "image_order" using primary key columns */
  image_order_by_pk?: Maybe<IImage_Order>;
  /** fetch data from the table: "image_type" */
  image_type: Array<IImage_Type>;
  /** fetch aggregated fields from the table: "image_type" */
  image_type_aggregate: IImage_Type_Aggregate;
  /** fetch data from the table: "image_type" using primary key columns */
  image_type_by_pk?: Maybe<IImage_Type>;
  /** An array relationship */
  images: Array<IImages>;
  /** An aggregate relationship */
  images_aggregate: IImages_Aggregate;
  /** fetch data from the table: "images" using primary key columns */
  images_by_pk?: Maybe<IImages>;
  /** fetch data from the table: "latest_events" */
  latest_events: Array<ILatest_Events>;
  /** fetch aggregated fields from the table: "latest_events" */
  latest_events_aggregate: ILatest_Events_Aggregate;
  /** fetch data from the table: "legacycom_config" */
  legacycom_config: Array<ILegacycom_Config>;
  /** fetch aggregated fields from the table: "legacycom_config" */
  legacycom_config_aggregate: ILegacycom_Config_Aggregate;
  /** fetch data from the table: "legacycom_config" using primary key columns */
  legacycom_config_by_pk?: Maybe<ILegacycom_Config>;
  /** fetch data from the table: "life_story" */
  life_story: Array<ILife_Story>;
  /** fetch aggregated fields from the table: "life_story" */
  life_story_aggregate: ILife_Story_Aggregate;
  /** fetch data from the table: "life_story" using primary key columns */
  life_story_by_pk?: Maybe<ILife_Story>;
  /** fetch data from the table: "life_story_location" */
  life_story_location: Array<ILife_Story_Location>;
  /** fetch aggregated fields from the table: "life_story_location" */
  life_story_location_aggregate: ILife_Story_Location_Aggregate;
  /** fetch data from the table: "life_story_location" using primary key columns */
  life_story_location_by_pk?: Maybe<ILife_Story_Location>;
  /** fetch data from the table: "life_story_locations_types" */
  life_story_locations_types: Array<ILife_Story_Locations_Types>;
  /** fetch aggregated fields from the table: "life_story_locations_types" */
  life_story_locations_types_aggregate: ILife_Story_Locations_Types_Aggregate;
  /** fetch data from the table: "life_story_locations_types" using primary key columns */
  life_story_locations_types_by_pk?: Maybe<ILife_Story_Locations_Types>;
  /** fetch data from the table: "locations" */
  locations: Array<ILocations>;
  /** fetch aggregated fields from the table: "locations" */
  locations_aggregate: ILocations_Aggregate;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<ILocations>;
  /** fetch data from the table: "locations_publishers" */
  locations_publishers: Array<ILocations_Publishers>;
  /** fetch aggregated fields from the table: "locations_publishers" */
  locations_publishers_aggregate: ILocations_Publishers_Aggregate;
  /** An array relationship */
  memories: Array<IMemories>;
  /** An aggregate relationship */
  memories_aggregate: IMemories_Aggregate;
  /** fetch data from the table: "memories" using primary key columns */
  memories_by_pk?: Maybe<IMemories>;
  /** fetch data from the table: "metadata_locations" */
  metadata_locations: Array<IMetadata_Locations>;
  /** fetch aggregated fields from the table: "metadata_locations" */
  metadata_locations_aggregate: IMetadata_Locations_Aggregate;
  /** fetch data from the table: "metadata_locations" using primary key columns */
  metadata_locations_by_pk?: Maybe<IMetadata_Locations>;
  /** fetch data from the table: "obits" */
  obits: Array<IObits>;
  /** fetch aggregated fields from the table: "obits" */
  obits_aggregate: IObits_Aggregate;
  /** fetch data from the table: "obits" using primary key columns */
  obits_by_pk?: Maybe<IObits>;
  /** fetch data from the table: "obituary" */
  obituary: Array<IObituary>;
  /** fetch aggregated fields from the table: "obituary" */
  obituary_aggregate: IObituary_Aggregate;
  /** fetch data from the table: "obituary" using primary key columns */
  obituary_by_pk?: Maybe<IObituary>;
  /** fetch data from the table: "obituary_publication" */
  obituary_publication: Array<IObituary_Publication>;
  /** fetch aggregated fields from the table: "obituary_publication" */
  obituary_publication_aggregate: IObituary_Publication_Aggregate;
  /** fetch data from the table: "obituary_publication" using primary key columns */
  obituary_publication_by_pk?: Maybe<IObituary_Publication>;
  /** fetch data from the table: "obituary_status" */
  obituary_status: Array<IObituary_Status>;
  /** fetch aggregated fields from the table: "obituary_status" */
  obituary_status_aggregate: IObituary_Status_Aggregate;
  /** fetch data from the table: "obituary_status" using primary key columns */
  obituary_status_by_pk?: Maybe<IObituary_Status>;
  /** fetch data from the table: "obituary_tag" */
  obituary_tag: Array<IObituary_Tag>;
  /** fetch aggregated fields from the table: "obituary_tag" */
  obituary_tag_aggregate: IObituary_Tag_Aggregate;
  /** fetch data from the table: "obituary_tag" using primary key columns */
  obituary_tag_by_pk?: Maybe<IObituary_Tag>;
  /** fetch data from the table: "payment_methods" */
  payment_methods: Array<IPayment_Methods>;
  /** fetch aggregated fields from the table: "payment_methods" */
  payment_methods_aggregate: IPayment_Methods_Aggregate;
  /** fetch data from the table: "phone_numbers" */
  phone_numbers: Array<IPhone_Numbers>;
  /** fetch aggregated fields from the table: "phone_numbers" */
  phone_numbers_aggregate: IPhone_Numbers_Aggregate;
  /** fetch data from the table: "possible_matches" */
  possible_matches: Array<IPossible_Matches>;
  /** fetch aggregated fields from the table: "possible_matches" */
  possible_matches_aggregate: IPossible_Matches_Aggregate;
  /** fetch data from the table: "publication" */
  publication: Array<IPublication>;
  /** fetch aggregated fields from the table: "publication" */
  publication_aggregate: IPublication_Aggregate;
  /** fetch data from the table: "publication" using primary key columns */
  publication_by_pk?: Maybe<IPublication>;
  /** An array relationship */
  publication_info: Array<IPublication_Info>;
  /** An aggregate relationship */
  publication_info_aggregate: IPublication_Info_Aggregate;
  /** fetch data from the table: "publication_info" using primary key columns */
  publication_info_by_pk?: Maybe<IPublication_Info>;
  /** fetch data from the table: "publisher_phone_numbers" */
  publisher_phone_numbers: Array<IPublisher_Phone_Numbers>;
  /** fetch aggregated fields from the table: "publisher_phone_numbers" */
  publisher_phone_numbers_aggregate: IPublisher_Phone_Numbers_Aggregate;
  /** fetch data from the table: "publishers" */
  publishers: Array<IPublishers>;
  /** fetch aggregated fields from the table: "publishers" */
  publishers_aggregate: IPublishers_Aggregate;
  /** fetch data from the table: "raw_data" */
  raw_data: Array<IRaw_Data>;
  /** fetch aggregated fields from the table: "raw_data" */
  raw_data_aggregate: IRaw_Data_Aggregate;
  /** fetch data from the table: "raw_data" using primary key columns */
  raw_data_by_pk?: Maybe<IRaw_Data>;
  /** fetch data from the table: "reviews" */
  reviews: Array<IReviews>;
  /** fetch aggregated fields from the table: "reviews" */
  reviews_aggregate: IReviews_Aggregate;
  /** execute function "search_locations" which returns "locations" */
  search_locations: Array<ILocations>;
  /** execute function "search_locations" and query aggregates on result of table type "locations" */
  search_locations_aggregate: ILocations_Aggregate;
  /** fetch data from the table: "service" */
  service: Array<IService>;
  /** fetch aggregated fields from the table: "service" */
  service_aggregate: IService_Aggregate;
  /** fetch data from the table: "service" using primary key columns */
  service_by_pk?: Maybe<IService>;
  /** fetch data from the table: "service_duration" */
  service_duration: Array<IService_Duration>;
  /** fetch aggregated fields from the table: "service_duration" */
  service_duration_aggregate: IService_Duration_Aggregate;
  /** fetch data from the table: "service_duration" using primary key columns */
  service_duration_by_pk?: Maybe<IService_Duration>;
  /** fetch data from the table: "service_duration_type" */
  service_duration_type: Array<IService_Duration_Type>;
  /** fetch aggregated fields from the table: "service_duration_type" */
  service_duration_type_aggregate: IService_Duration_Type_Aggregate;
  /** fetch data from the table: "service_duration_type" using primary key columns */
  service_duration_type_by_pk?: Maybe<IService_Duration_Type>;
  /** fetch data from the table: "service_offers" */
  service_offers: Array<IService_Offers>;
  /** fetch aggregated fields from the table: "service_offers" */
  service_offers_aggregate: IService_Offers_Aggregate;
  /** fetch data from the table: "tag" */
  tag: Array<ITag>;
  /** fetch aggregated fields from the table: "tag" */
  tag_aggregate: ITag_Aggregate;
  /** fetch data from the table: "tag" using primary key columns */
  tag_by_pk?: Maybe<ITag>;
  /** fetch data from the table: "topics" */
  topics: Array<ITopics>;
  /** fetch aggregated fields from the table: "topics" */
  topics_aggregate: ITopics_Aggregate;
  /** fetch data from the table: "topics" using primary key columns */
  topics_by_pk?: Maybe<ITopics>;
  /** fetch data from the table: "urls" */
  urls: Array<IUrls>;
  /** fetch aggregated fields from the table: "urls" */
  urls_aggregate: IUrls_Aggregate;
  /** fetch data from the table: "users" */
  users: Array<IUsers>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: IUsers_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<IUsers>;
  /** fetch data from the table: "verticals" */
  verticals: Array<IVerticals>;
  /** fetch aggregated fields from the table: "verticals" */
  verticals_aggregate: IVerticals_Aggregate;
  /** fetch data from the table: "web_config" */
  web_config: Array<IWeb_Config>;
  /** fetch aggregated fields from the table: "web_config" */
  web_config_aggregate: IWeb_Config_Aggregate;
  /** fetch data from the table: "web_config" using primary key columns */
  web_config_by_pk?: Maybe<IWeb_Config>;
  /** fetch data from the table: "web_config_filter" */
  web_config_filter: Array<IWeb_Config_Filter>;
  /** fetch aggregated fields from the table: "web_config_filter" */
  web_config_filter_aggregate: IWeb_Config_Filter_Aggregate;
  /** fetch data from the table: "web_config_filter" using primary key columns */
  web_config_filter_by_pk?: Maybe<IWeb_Config_Filter>;
  /** An array relationship */
  web_config_filter_tags: Array<IWeb_Config_Filter_Tags>;
  /** An aggregate relationship */
  web_config_filter_tags_aggregate: IWeb_Config_Filter_Tags_Aggregate;
  /** fetch data from the table: "web_config_filter_tags" using primary key columns */
  web_config_filter_tags_by_pk?: Maybe<IWeb_Config_Filter_Tags>;
};


/** query root */
export type IQuery_RootArchived_ObituaryArgs = {
  distinct_on?: Maybe<Array<IArchived_Obituary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IArchived_Obituary_Order_By>>;
  where?: Maybe<IArchived_Obituary_Bool_Exp>;
};


/** query root */
export type IQuery_RootArchived_Obituary_AggregateArgs = {
  distinct_on?: Maybe<Array<IArchived_Obituary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IArchived_Obituary_Order_By>>;
  where?: Maybe<IArchived_Obituary_Bool_Exp>;
};


/** query root */
export type IQuery_RootArchived_Obituary_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootCapi_ConfigArgs = {
  distinct_on?: Maybe<Array<ICapi_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICapi_Config_Order_By>>;
  where?: Maybe<ICapi_Config_Bool_Exp>;
};


/** query root */
export type IQuery_RootCapi_Config_AggregateArgs = {
  distinct_on?: Maybe<Array<ICapi_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICapi_Config_Order_By>>;
  where?: Maybe<ICapi_Config_Bool_Exp>;
};


/** query root */
export type IQuery_RootCapi_Config_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootCategoriesArgs = {
  distinct_on?: Maybe<Array<ICategories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategories_Order_By>>;
  where?: Maybe<ICategories_Bool_Exp>;
};


/** query root */
export type IQuery_RootCategories_AggregateArgs = {
  distinct_on?: Maybe<Array<ICategories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategories_Order_By>>;
  where?: Maybe<ICategories_Bool_Exp>;
};


/** query root */
export type IQuery_RootCategories_LocationsArgs = {
  distinct_on?: Maybe<Array<ICategories_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategories_Locations_Order_By>>;
  where?: Maybe<ICategories_Locations_Bool_Exp>;
};


/** query root */
export type IQuery_RootCategories_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<ICategories_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategories_Locations_Order_By>>;
  where?: Maybe<ICategories_Locations_Bool_Exp>;
};


/** query root */
export type IQuery_RootCategories_VerticalsArgs = {
  distinct_on?: Maybe<Array<ICategories_Verticals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategories_Verticals_Order_By>>;
  where?: Maybe<ICategories_Verticals_Bool_Exp>;
};


/** query root */
export type IQuery_RootCategories_Verticals_AggregateArgs = {
  distinct_on?: Maybe<Array<ICategories_Verticals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategories_Verticals_Order_By>>;
  where?: Maybe<ICategories_Verticals_Bool_Exp>;
};


/** query root */
export type IQuery_RootCategory_Image_UrlsArgs = {
  distinct_on?: Maybe<Array<ICategory_Image_Urls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategory_Image_Urls_Order_By>>;
  where?: Maybe<ICategory_Image_Urls_Bool_Exp>;
};


/** query root */
export type IQuery_RootCategory_Image_Urls_AggregateArgs = {
  distinct_on?: Maybe<Array<ICategory_Image_Urls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategory_Image_Urls_Order_By>>;
  where?: Maybe<ICategory_Image_Urls_Bool_Exp>;
};


/** query root */
export type IQuery_RootCategory_KeywordsArgs = {
  distinct_on?: Maybe<Array<ICategory_Keywords_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategory_Keywords_Order_By>>;
  where?: Maybe<ICategory_Keywords_Bool_Exp>;
};


/** query root */
export type IQuery_RootCategory_Keywords_AggregateArgs = {
  distinct_on?: Maybe<Array<ICategory_Keywords_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategory_Keywords_Order_By>>;
  where?: Maybe<ICategory_Keywords_Bool_Exp>;
};


/** query root */
export type IQuery_RootCoupon_AdasArgs = {
  distinct_on?: Maybe<Array<ICoupon_Adas_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICoupon_Adas_Order_By>>;
  where?: Maybe<ICoupon_Adas_Bool_Exp>;
};


/** query root */
export type IQuery_RootCoupon_Adas_AggregateArgs = {
  distinct_on?: Maybe<Array<ICoupon_Adas_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICoupon_Adas_Order_By>>;
  where?: Maybe<ICoupon_Adas_Bool_Exp>;
};


/** query root */
export type IQuery_RootDaily_Locations_CountsArgs = {
  distinct_on?: Maybe<Array<IDaily_Locations_Counts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDaily_Locations_Counts_Order_By>>;
  where?: Maybe<IDaily_Locations_Counts_Bool_Exp>;
};


/** query root */
export type IQuery_RootDaily_Locations_Counts_AggregateArgs = {
  distinct_on?: Maybe<Array<IDaily_Locations_Counts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDaily_Locations_Counts_Order_By>>;
  where?: Maybe<IDaily_Locations_Counts_Bool_Exp>;
};


/** query root */
export type IQuery_RootDisplay_ObituaryArgs = {
  distinct_on?: Maybe<Array<IDisplay_Obituary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDisplay_Obituary_Order_By>>;
  where?: Maybe<IDisplay_Obituary_Bool_Exp>;
};


/** query root */
export type IQuery_RootDisplay_Obituary_AggregateArgs = {
  distinct_on?: Maybe<Array<IDisplay_Obituary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDisplay_Obituary_Order_By>>;
  where?: Maybe<IDisplay_Obituary_Bool_Exp>;
};


/** query root */
export type IQuery_RootDisplay_Obituary_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootDisplay_Obituary_PublicationArgs = {
  distinct_on?: Maybe<Array<IDisplay_Obituary_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDisplay_Obituary_Publication_Order_By>>;
  where?: Maybe<IDisplay_Obituary_Publication_Bool_Exp>;
};


/** query root */
export type IQuery_RootDisplay_Obituary_Publication_AggregateArgs = {
  distinct_on?: Maybe<Array<IDisplay_Obituary_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDisplay_Obituary_Publication_Order_By>>;
  where?: Maybe<IDisplay_Obituary_Publication_Bool_Exp>;
};


/** query root */
export type IQuery_RootDisplay_Obituary_Publication_By_PkArgs = {
  display_obituary_id: Scalars['uuid'];
  publication_id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootDisplay_Obituary_TagArgs = {
  distinct_on?: Maybe<Array<IDisplay_Obituary_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDisplay_Obituary_Tag_Order_By>>;
  where?: Maybe<IDisplay_Obituary_Tag_Bool_Exp>;
};


/** query root */
export type IQuery_RootDisplay_Obituary_Tag_AggregateArgs = {
  distinct_on?: Maybe<Array<IDisplay_Obituary_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDisplay_Obituary_Tag_Order_By>>;
  where?: Maybe<IDisplay_Obituary_Tag_Bool_Exp>;
};


/** query root */
export type IQuery_RootDisplay_Obituary_Tag_By_PkArgs = {
  display_obituary_id: Scalars['uuid'];
  tag_id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootDisplay_Run_DateArgs = {
  distinct_on?: Maybe<Array<IDisplay_Run_Date_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDisplay_Run_Date_Order_By>>;
  where?: Maybe<IDisplay_Run_Date_Bool_Exp>;
};


/** query root */
export type IQuery_RootDisplay_Run_Date_AggregateArgs = {
  distinct_on?: Maybe<Array<IDisplay_Run_Date_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDisplay_Run_Date_Order_By>>;
  where?: Maybe<IDisplay_Run_Date_Bool_Exp>;
};


/** query root */
export type IQuery_RootDisplay_Run_Date_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootDuplicate_LocationsArgs = {
  distinct_on?: Maybe<Array<IDuplicate_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDuplicate_Locations_Order_By>>;
  where?: Maybe<IDuplicate_Locations_Bool_Exp>;
};


/** query root */
export type IQuery_RootDuplicate_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<IDuplicate_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDuplicate_Locations_Order_By>>;
  where?: Maybe<IDuplicate_Locations_Bool_Exp>;
};


/** query root */
export type IQuery_RootEventsArgs = {
  distinct_on?: Maybe<Array<IEvents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IEvents_Order_By>>;
  where?: Maybe<IEvents_Bool_Exp>;
};


/** query root */
export type IQuery_RootEvents_AggregateArgs = {
  distinct_on?: Maybe<Array<IEvents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IEvents_Order_By>>;
  where?: Maybe<IEvents_Bool_Exp>;
};


/** query root */
export type IQuery_RootEvents_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootEvents_TypesArgs = {
  distinct_on?: Maybe<Array<IEvents_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IEvents_Types_Order_By>>;
  where?: Maybe<IEvents_Types_Bool_Exp>;
};


/** query root */
export type IQuery_RootEvents_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<IEvents_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IEvents_Types_Order_By>>;
  where?: Maybe<IEvents_Types_Bool_Exp>;
};


/** query root */
export type IQuery_RootEvents_Types_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type IQuery_RootExternal_LinkArgs = {
  distinct_on?: Maybe<Array<IExternal_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExternal_Link_Order_By>>;
  where?: Maybe<IExternal_Link_Bool_Exp>;
};


/** query root */
export type IQuery_RootExternal_Link_AggregateArgs = {
  distinct_on?: Maybe<Array<IExternal_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExternal_Link_Order_By>>;
  where?: Maybe<IExternal_Link_Bool_Exp>;
};


/** query root */
export type IQuery_RootExternal_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootExternal_Link_SourceArgs = {
  distinct_on?: Maybe<Array<IExternal_Link_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExternal_Link_Source_Order_By>>;
  where?: Maybe<IExternal_Link_Source_Bool_Exp>;
};


/** query root */
export type IQuery_RootExternal_Link_Source_AggregateArgs = {
  distinct_on?: Maybe<Array<IExternal_Link_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExternal_Link_Source_Order_By>>;
  where?: Maybe<IExternal_Link_Source_Bool_Exp>;
};


/** query root */
export type IQuery_RootExternal_Link_Source_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type IQuery_RootExternal_Link_TypeArgs = {
  distinct_on?: Maybe<Array<IExternal_Link_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExternal_Link_Type_Order_By>>;
  where?: Maybe<IExternal_Link_Type_Bool_Exp>;
};


/** query root */
export type IQuery_RootExternal_Link_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<IExternal_Link_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExternal_Link_Type_Order_By>>;
  where?: Maybe<IExternal_Link_Type_Bool_Exp>;
};


/** query root */
export type IQuery_RootExternal_Link_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type IQuery_RootFacebook_ConfigArgs = {
  distinct_on?: Maybe<Array<IFacebook_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFacebook_Config_Order_By>>;
  where?: Maybe<IFacebook_Config_Bool_Exp>;
};


/** query root */
export type IQuery_RootFacebook_Config_AggregateArgs = {
  distinct_on?: Maybe<Array<IFacebook_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFacebook_Config_Order_By>>;
  where?: Maybe<IFacebook_Config_Bool_Exp>;
};


/** query root */
export type IQuery_RootFacebook_Config_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootFrom_Sales_OrderArgs = {
  distinct_on?: Maybe<Array<IFrom_Sales_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFrom_Sales_Order_Order_By>>;
  where?: Maybe<IFrom_Sales_Order_Bool_Exp>;
};


/** query root */
export type IQuery_RootFrom_Sales_Order_AggregateArgs = {
  distinct_on?: Maybe<Array<IFrom_Sales_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFrom_Sales_Order_Order_By>>;
  where?: Maybe<IFrom_Sales_Order_Bool_Exp>;
};


/** query root */
export type IQuery_RootFrom_Sales_Order_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootFrom_Sales_Order_From_Sales_PublicationArgs = {
  distinct_on?: Maybe<Array<IFrom_Sales_Order_From_Sales_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFrom_Sales_Order_From_Sales_Publication_Order_By>>;
  where?: Maybe<IFrom_Sales_Order_From_Sales_Publication_Bool_Exp>;
};


/** query root */
export type IQuery_RootFrom_Sales_Order_From_Sales_Publication_AggregateArgs = {
  distinct_on?: Maybe<Array<IFrom_Sales_Order_From_Sales_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFrom_Sales_Order_From_Sales_Publication_Order_By>>;
  where?: Maybe<IFrom_Sales_Order_From_Sales_Publication_Bool_Exp>;
};


/** query root */
export type IQuery_RootFrom_Sales_Order_From_Sales_Publication_By_PkArgs = {
  order_id: Scalars['uuid'];
  publication_id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootFrom_Sales_PublicationArgs = {
  distinct_on?: Maybe<Array<IFrom_Sales_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFrom_Sales_Publication_Order_By>>;
  where?: Maybe<IFrom_Sales_Publication_Bool_Exp>;
};


/** query root */
export type IQuery_RootFrom_Sales_Publication_AggregateArgs = {
  distinct_on?: Maybe<Array<IFrom_Sales_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFrom_Sales_Publication_Order_By>>;
  where?: Maybe<IFrom_Sales_Publication_Bool_Exp>;
};


/** query root */
export type IQuery_RootFrom_Sales_Publication_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootFuneral_HomeArgs = {
  distinct_on?: Maybe<Array<IFuneral_Home_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFuneral_Home_Order_By>>;
  where?: Maybe<IFuneral_Home_Bool_Exp>;
};


/** query root */
export type IQuery_RootFuneral_Home_AggregateArgs = {
  distinct_on?: Maybe<Array<IFuneral_Home_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFuneral_Home_Order_By>>;
  where?: Maybe<IFuneral_Home_Bool_Exp>;
};


/** query root */
export type IQuery_RootFuneral_Home_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootFuneral_Home_External_IdArgs = {
  distinct_on?: Maybe<Array<IFuneral_Home_External_Id_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFuneral_Home_External_Id_Order_By>>;
  where?: Maybe<IFuneral_Home_External_Id_Bool_Exp>;
};


/** query root */
export type IQuery_RootFuneral_Home_External_Id_AggregateArgs = {
  distinct_on?: Maybe<Array<IFuneral_Home_External_Id_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFuneral_Home_External_Id_Order_By>>;
  where?: Maybe<IFuneral_Home_External_Id_Bool_Exp>;
};


/** query root */
export type IQuery_RootFuneral_Home_External_Id_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootFuneral_Home_External_User_IdArgs = {
  distinct_on?: Maybe<Array<IFuneral_Home_External_User_Id_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFuneral_Home_External_User_Id_Order_By>>;
  where?: Maybe<IFuneral_Home_External_User_Id_Bool_Exp>;
};


/** query root */
export type IQuery_RootFuneral_Home_External_User_Id_AggregateArgs = {
  distinct_on?: Maybe<Array<IFuneral_Home_External_User_Id_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFuneral_Home_External_User_Id_Order_By>>;
  where?: Maybe<IFuneral_Home_External_User_Id_Bool_Exp>;
};


/** query root */
export type IQuery_RootFuneral_Home_External_User_Id_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootGet_Image_Signed_Policy_UrlArgs = {
  containerHeight: Scalars['String'];
  containerWidth: Scalars['String'];
  contentType: Scalars['String'];
  cropHeight: Scalars['String'];
  cropWidth: Scalars['String'];
  cropX: Scalars['String'];
  cropY: Scalars['String'];
  imageType: Scalars['String'];
  imgRectX1: Scalars['String'];
  imgRectX2: Scalars['String'];
  imgRectY1: Scalars['String'];
  imgRectY2: Scalars['String'];
  name: Scalars['String'];
  naturalImageHeight: Scalars['String'];
  naturalImageWidth: Scalars['String'];
  obitId: Scalars['String'];
  rotate: Scalars['String'];
  scale: Scalars['String'];
};


/** query root */
export type IQuery_RootGet_Signed_PolicyArgs = {
  content_type: Scalars['String'];
};


/** query root */
export type IQuery_RootHistorical_ObituaryArgs = {
  distinct_on?: Maybe<Array<IHistorical_Obituary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHistorical_Obituary_Order_By>>;
  where?: Maybe<IHistorical_Obituary_Bool_Exp>;
};


/** query root */
export type IQuery_RootHistorical_Obituary_AggregateArgs = {
  distinct_on?: Maybe<Array<IHistorical_Obituary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHistorical_Obituary_Order_By>>;
  where?: Maybe<IHistorical_Obituary_Bool_Exp>;
};


/** query root */
export type IQuery_RootHistorical_Obituary_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootHistorical_Obituary_EventArgs = {
  distinct_on?: Maybe<Array<IHistorical_Obituary_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHistorical_Obituary_Event_Order_By>>;
  where?: Maybe<IHistorical_Obituary_Event_Bool_Exp>;
};


/** query root */
export type IQuery_RootHistorical_Obituary_Event_AggregateArgs = {
  distinct_on?: Maybe<Array<IHistorical_Obituary_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHistorical_Obituary_Event_Order_By>>;
  where?: Maybe<IHistorical_Obituary_Event_Bool_Exp>;
};


/** query root */
export type IQuery_RootHistorical_Obituary_Event_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootHistorical_Obituary_PublicationArgs = {
  distinct_on?: Maybe<Array<IHistorical_Obituary_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHistorical_Obituary_Publication_Order_By>>;
  where?: Maybe<IHistorical_Obituary_Publication_Bool_Exp>;
};


/** query root */
export type IQuery_RootHistorical_Obituary_Publication_AggregateArgs = {
  distinct_on?: Maybe<Array<IHistorical_Obituary_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHistorical_Obituary_Publication_Order_By>>;
  where?: Maybe<IHistorical_Obituary_Publication_Bool_Exp>;
};


/** query root */
export type IQuery_RootHistorical_Obituary_Publication_By_PkArgs = {
  historial_obituary_id: Scalars['uuid'];
  publication_id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootHistorical_Obituary_TagArgs = {
  distinct_on?: Maybe<Array<IHistorical_Obituary_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHistorical_Obituary_Tag_Order_By>>;
  where?: Maybe<IHistorical_Obituary_Tag_Bool_Exp>;
};


/** query root */
export type IQuery_RootHistorical_Obituary_Tag_AggregateArgs = {
  distinct_on?: Maybe<Array<IHistorical_Obituary_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHistorical_Obituary_Tag_Order_By>>;
  where?: Maybe<IHistorical_Obituary_Tag_Bool_Exp>;
};


/** query root */
export type IQuery_RootHistorical_Obituary_Tag_By_PkArgs = {
  historical_obituary_id: Scalars['uuid'];
  tag_id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootImageArgs = {
  distinct_on?: Maybe<Array<IImage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IImage_Order_By>>;
  where?: Maybe<IImage_Bool_Exp>;
};


/** query root */
export type IQuery_RootImage_AggregateArgs = {
  distinct_on?: Maybe<Array<IImage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IImage_Order_By>>;
  where?: Maybe<IImage_Bool_Exp>;
};


/** query root */
export type IQuery_RootImage_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootImage_LookupArgs = {
  distinct_on?: Maybe<Array<IImage_Lookup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IImage_Lookup_Order_By>>;
  where?: Maybe<IImage_Lookup_Bool_Exp>;
};


/** query root */
export type IQuery_RootImage_Lookup_AggregateArgs = {
  distinct_on?: Maybe<Array<IImage_Lookup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IImage_Lookup_Order_By>>;
  where?: Maybe<IImage_Lookup_Bool_Exp>;
};


/** query root */
export type IQuery_RootImage_Lookup_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootImage_OrderArgs = {
  distinct_on?: Maybe<Array<IImage_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IImage_Order_Order_By>>;
  where?: Maybe<IImage_Order_Bool_Exp>;
};


/** query root */
export type IQuery_RootImage_Order_AggregateArgs = {
  distinct_on?: Maybe<Array<IImage_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IImage_Order_Order_By>>;
  where?: Maybe<IImage_Order_Bool_Exp>;
};


/** query root */
export type IQuery_RootImage_Order_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootImage_TypeArgs = {
  distinct_on?: Maybe<Array<IImage_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IImage_Type_Order_By>>;
  where?: Maybe<IImage_Type_Bool_Exp>;
};


/** query root */
export type IQuery_RootImage_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<IImage_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IImage_Type_Order_By>>;
  where?: Maybe<IImage_Type_Bool_Exp>;
};


/** query root */
export type IQuery_RootImage_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type IQuery_RootImagesArgs = {
  distinct_on?: Maybe<Array<IImages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IImages_Order_By>>;
  where?: Maybe<IImages_Bool_Exp>;
};


/** query root */
export type IQuery_RootImages_AggregateArgs = {
  distinct_on?: Maybe<Array<IImages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IImages_Order_By>>;
  where?: Maybe<IImages_Bool_Exp>;
};


/** query root */
export type IQuery_RootImages_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootLatest_EventsArgs = {
  distinct_on?: Maybe<Array<ILatest_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILatest_Events_Order_By>>;
  where?: Maybe<ILatest_Events_Bool_Exp>;
};


/** query root */
export type IQuery_RootLatest_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<ILatest_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILatest_Events_Order_By>>;
  where?: Maybe<ILatest_Events_Bool_Exp>;
};


/** query root */
export type IQuery_RootLegacycom_ConfigArgs = {
  distinct_on?: Maybe<Array<ILegacycom_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILegacycom_Config_Order_By>>;
  where?: Maybe<ILegacycom_Config_Bool_Exp>;
};


/** query root */
export type IQuery_RootLegacycom_Config_AggregateArgs = {
  distinct_on?: Maybe<Array<ILegacycom_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILegacycom_Config_Order_By>>;
  where?: Maybe<ILegacycom_Config_Bool_Exp>;
};


/** query root */
export type IQuery_RootLegacycom_Config_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootLife_StoryArgs = {
  distinct_on?: Maybe<Array<ILife_Story_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILife_Story_Order_By>>;
  where?: Maybe<ILife_Story_Bool_Exp>;
};


/** query root */
export type IQuery_RootLife_Story_AggregateArgs = {
  distinct_on?: Maybe<Array<ILife_Story_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILife_Story_Order_By>>;
  where?: Maybe<ILife_Story_Bool_Exp>;
};


/** query root */
export type IQuery_RootLife_Story_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootLife_Story_LocationArgs = {
  distinct_on?: Maybe<Array<ILife_Story_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILife_Story_Location_Order_By>>;
  where?: Maybe<ILife_Story_Location_Bool_Exp>;
};


/** query root */
export type IQuery_RootLife_Story_Location_AggregateArgs = {
  distinct_on?: Maybe<Array<ILife_Story_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILife_Story_Location_Order_By>>;
  where?: Maybe<ILife_Story_Location_Bool_Exp>;
};


/** query root */
export type IQuery_RootLife_Story_Location_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootLife_Story_Locations_TypesArgs = {
  distinct_on?: Maybe<Array<ILife_Story_Locations_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILife_Story_Locations_Types_Order_By>>;
  where?: Maybe<ILife_Story_Locations_Types_Bool_Exp>;
};


/** query root */
export type IQuery_RootLife_Story_Locations_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<ILife_Story_Locations_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILife_Story_Locations_Types_Order_By>>;
  where?: Maybe<ILife_Story_Locations_Types_Bool_Exp>;
};


/** query root */
export type IQuery_RootLife_Story_Locations_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type IQuery_RootLocationsArgs = {
  distinct_on?: Maybe<Array<ILocations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILocations_Order_By>>;
  where?: Maybe<ILocations_Bool_Exp>;
};


/** query root */
export type IQuery_RootLocations_AggregateArgs = {
  distinct_on?: Maybe<Array<ILocations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILocations_Order_By>>;
  where?: Maybe<ILocations_Bool_Exp>;
};


/** query root */
export type IQuery_RootLocations_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type IQuery_RootLocations_PublishersArgs = {
  distinct_on?: Maybe<Array<ILocations_Publishers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILocations_Publishers_Order_By>>;
  where?: Maybe<ILocations_Publishers_Bool_Exp>;
};


/** query root */
export type IQuery_RootLocations_Publishers_AggregateArgs = {
  distinct_on?: Maybe<Array<ILocations_Publishers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILocations_Publishers_Order_By>>;
  where?: Maybe<ILocations_Publishers_Bool_Exp>;
};


/** query root */
export type IQuery_RootMemoriesArgs = {
  distinct_on?: Maybe<Array<IMemories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMemories_Order_By>>;
  where?: Maybe<IMemories_Bool_Exp>;
};


/** query root */
export type IQuery_RootMemories_AggregateArgs = {
  distinct_on?: Maybe<Array<IMemories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMemories_Order_By>>;
  where?: Maybe<IMemories_Bool_Exp>;
};


/** query root */
export type IQuery_RootMemories_By_PkArgs = {
  id: Scalars['uuid'];
  obit_id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootMetadata_LocationsArgs = {
  distinct_on?: Maybe<Array<IMetadata_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMetadata_Locations_Order_By>>;
  where?: Maybe<IMetadata_Locations_Bool_Exp>;
};


/** query root */
export type IQuery_RootMetadata_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<IMetadata_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMetadata_Locations_Order_By>>;
  where?: Maybe<IMetadata_Locations_Bool_Exp>;
};


/** query root */
export type IQuery_RootMetadata_Locations_By_PkArgs = {
  location_id: Scalars['bigint'];
  name: Scalars['String'];
  value: Scalars['String'];
};


/** query root */
export type IQuery_RootObitsArgs = {
  distinct_on?: Maybe<Array<IObits_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IObits_Order_By>>;
  where?: Maybe<IObits_Bool_Exp>;
};


/** query root */
export type IQuery_RootObits_AggregateArgs = {
  distinct_on?: Maybe<Array<IObits_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IObits_Order_By>>;
  where?: Maybe<IObits_Bool_Exp>;
};


/** query root */
export type IQuery_RootObits_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootObituaryArgs = {
  distinct_on?: Maybe<Array<IObituary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IObituary_Order_By>>;
  where?: Maybe<IObituary_Bool_Exp>;
};


/** query root */
export type IQuery_RootObituary_AggregateArgs = {
  distinct_on?: Maybe<Array<IObituary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IObituary_Order_By>>;
  where?: Maybe<IObituary_Bool_Exp>;
};


/** query root */
export type IQuery_RootObituary_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootObituary_PublicationArgs = {
  distinct_on?: Maybe<Array<IObituary_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IObituary_Publication_Order_By>>;
  where?: Maybe<IObituary_Publication_Bool_Exp>;
};


/** query root */
export type IQuery_RootObituary_Publication_AggregateArgs = {
  distinct_on?: Maybe<Array<IObituary_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IObituary_Publication_Order_By>>;
  where?: Maybe<IObituary_Publication_Bool_Exp>;
};


/** query root */
export type IQuery_RootObituary_Publication_By_PkArgs = {
  obituary_id: Scalars['uuid'];
  publication_id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootObituary_StatusArgs = {
  distinct_on?: Maybe<Array<IObituary_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IObituary_Status_Order_By>>;
  where?: Maybe<IObituary_Status_Bool_Exp>;
};


/** query root */
export type IQuery_RootObituary_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<IObituary_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IObituary_Status_Order_By>>;
  where?: Maybe<IObituary_Status_Bool_Exp>;
};


/** query root */
export type IQuery_RootObituary_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type IQuery_RootObituary_TagArgs = {
  distinct_on?: Maybe<Array<IObituary_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IObituary_Tag_Order_By>>;
  where?: Maybe<IObituary_Tag_Bool_Exp>;
};


/** query root */
export type IQuery_RootObituary_Tag_AggregateArgs = {
  distinct_on?: Maybe<Array<IObituary_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IObituary_Tag_Order_By>>;
  where?: Maybe<IObituary_Tag_Bool_Exp>;
};


/** query root */
export type IQuery_RootObituary_Tag_By_PkArgs = {
  obituary_id: Scalars['uuid'];
  tag_id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootPayment_MethodsArgs = {
  distinct_on?: Maybe<Array<IPayment_Methods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPayment_Methods_Order_By>>;
  where?: Maybe<IPayment_Methods_Bool_Exp>;
};


/** query root */
export type IQuery_RootPayment_Methods_AggregateArgs = {
  distinct_on?: Maybe<Array<IPayment_Methods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPayment_Methods_Order_By>>;
  where?: Maybe<IPayment_Methods_Bool_Exp>;
};


/** query root */
export type IQuery_RootPhone_NumbersArgs = {
  distinct_on?: Maybe<Array<IPhone_Numbers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPhone_Numbers_Order_By>>;
  where?: Maybe<IPhone_Numbers_Bool_Exp>;
};


/** query root */
export type IQuery_RootPhone_Numbers_AggregateArgs = {
  distinct_on?: Maybe<Array<IPhone_Numbers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPhone_Numbers_Order_By>>;
  where?: Maybe<IPhone_Numbers_Bool_Exp>;
};


/** query root */
export type IQuery_RootPossible_MatchesArgs = {
  distinct_on?: Maybe<Array<IPossible_Matches_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPossible_Matches_Order_By>>;
  where?: Maybe<IPossible_Matches_Bool_Exp>;
};


/** query root */
export type IQuery_RootPossible_Matches_AggregateArgs = {
  distinct_on?: Maybe<Array<IPossible_Matches_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPossible_Matches_Order_By>>;
  where?: Maybe<IPossible_Matches_Bool_Exp>;
};


/** query root */
export type IQuery_RootPublicationArgs = {
  distinct_on?: Maybe<Array<IPublication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPublication_Order_By>>;
  where?: Maybe<IPublication_Bool_Exp>;
};


/** query root */
export type IQuery_RootPublication_AggregateArgs = {
  distinct_on?: Maybe<Array<IPublication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPublication_Order_By>>;
  where?: Maybe<IPublication_Bool_Exp>;
};


/** query root */
export type IQuery_RootPublication_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootPublication_InfoArgs = {
  distinct_on?: Maybe<Array<IPublication_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPublication_Info_Order_By>>;
  where?: Maybe<IPublication_Info_Bool_Exp>;
};


/** query root */
export type IQuery_RootPublication_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<IPublication_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPublication_Info_Order_By>>;
  where?: Maybe<IPublication_Info_Bool_Exp>;
};


/** query root */
export type IQuery_RootPublication_Info_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootPublisher_Phone_NumbersArgs = {
  distinct_on?: Maybe<Array<IPublisher_Phone_Numbers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPublisher_Phone_Numbers_Order_By>>;
  where?: Maybe<IPublisher_Phone_Numbers_Bool_Exp>;
};


/** query root */
export type IQuery_RootPublisher_Phone_Numbers_AggregateArgs = {
  distinct_on?: Maybe<Array<IPublisher_Phone_Numbers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPublisher_Phone_Numbers_Order_By>>;
  where?: Maybe<IPublisher_Phone_Numbers_Bool_Exp>;
};


/** query root */
export type IQuery_RootPublishersArgs = {
  distinct_on?: Maybe<Array<IPublishers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPublishers_Order_By>>;
  where?: Maybe<IPublishers_Bool_Exp>;
};


/** query root */
export type IQuery_RootPublishers_AggregateArgs = {
  distinct_on?: Maybe<Array<IPublishers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPublishers_Order_By>>;
  where?: Maybe<IPublishers_Bool_Exp>;
};


/** query root */
export type IQuery_RootRaw_DataArgs = {
  distinct_on?: Maybe<Array<IRaw_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IRaw_Data_Order_By>>;
  where?: Maybe<IRaw_Data_Bool_Exp>;
};


/** query root */
export type IQuery_RootRaw_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<IRaw_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IRaw_Data_Order_By>>;
  where?: Maybe<IRaw_Data_Bool_Exp>;
};


/** query root */
export type IQuery_RootRaw_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootReviewsArgs = {
  distinct_on?: Maybe<Array<IReviews_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IReviews_Order_By>>;
  where?: Maybe<IReviews_Bool_Exp>;
};


/** query root */
export type IQuery_RootReviews_AggregateArgs = {
  distinct_on?: Maybe<Array<IReviews_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IReviews_Order_By>>;
  where?: Maybe<IReviews_Bool_Exp>;
};


/** query root */
export type IQuery_RootSearch_LocationsArgs = {
  args: ISearch_Locations_Args;
  distinct_on?: Maybe<Array<ILocations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILocations_Order_By>>;
  where?: Maybe<ILocations_Bool_Exp>;
};


/** query root */
export type IQuery_RootSearch_Locations_AggregateArgs = {
  args: ISearch_Locations_Args;
  distinct_on?: Maybe<Array<ILocations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILocations_Order_By>>;
  where?: Maybe<ILocations_Bool_Exp>;
};


/** query root */
export type IQuery_RootServiceArgs = {
  distinct_on?: Maybe<Array<IService_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IService_Order_By>>;
  where?: Maybe<IService_Bool_Exp>;
};


/** query root */
export type IQuery_RootService_AggregateArgs = {
  distinct_on?: Maybe<Array<IService_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IService_Order_By>>;
  where?: Maybe<IService_Bool_Exp>;
};


/** query root */
export type IQuery_RootService_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootService_DurationArgs = {
  distinct_on?: Maybe<Array<IService_Duration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IService_Duration_Order_By>>;
  where?: Maybe<IService_Duration_Bool_Exp>;
};


/** query root */
export type IQuery_RootService_Duration_AggregateArgs = {
  distinct_on?: Maybe<Array<IService_Duration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IService_Duration_Order_By>>;
  where?: Maybe<IService_Duration_Bool_Exp>;
};


/** query root */
export type IQuery_RootService_Duration_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootService_Duration_TypeArgs = {
  distinct_on?: Maybe<Array<IService_Duration_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IService_Duration_Type_Order_By>>;
  where?: Maybe<IService_Duration_Type_Bool_Exp>;
};


/** query root */
export type IQuery_RootService_Duration_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<IService_Duration_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IService_Duration_Type_Order_By>>;
  where?: Maybe<IService_Duration_Type_Bool_Exp>;
};


/** query root */
export type IQuery_RootService_Duration_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type IQuery_RootService_OffersArgs = {
  distinct_on?: Maybe<Array<IService_Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IService_Offers_Order_By>>;
  where?: Maybe<IService_Offers_Bool_Exp>;
};


/** query root */
export type IQuery_RootService_Offers_AggregateArgs = {
  distinct_on?: Maybe<Array<IService_Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IService_Offers_Order_By>>;
  where?: Maybe<IService_Offers_Bool_Exp>;
};


/** query root */
export type IQuery_RootTagArgs = {
  distinct_on?: Maybe<Array<ITag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ITag_Order_By>>;
  where?: Maybe<ITag_Bool_Exp>;
};


/** query root */
export type IQuery_RootTag_AggregateArgs = {
  distinct_on?: Maybe<Array<ITag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ITag_Order_By>>;
  where?: Maybe<ITag_Bool_Exp>;
};


/** query root */
export type IQuery_RootTag_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootTopicsArgs = {
  distinct_on?: Maybe<Array<ITopics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ITopics_Order_By>>;
  where?: Maybe<ITopics_Bool_Exp>;
};


/** query root */
export type IQuery_RootTopics_AggregateArgs = {
  distinct_on?: Maybe<Array<ITopics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ITopics_Order_By>>;
  where?: Maybe<ITopics_Bool_Exp>;
};


/** query root */
export type IQuery_RootTopics_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type IQuery_RootUrlsArgs = {
  distinct_on?: Maybe<Array<IUrls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IUrls_Order_By>>;
  where?: Maybe<IUrls_Bool_Exp>;
};


/** query root */
export type IQuery_RootUrls_AggregateArgs = {
  distinct_on?: Maybe<Array<IUrls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IUrls_Order_By>>;
  where?: Maybe<IUrls_Bool_Exp>;
};


/** query root */
export type IQuery_RootUsersArgs = {
  distinct_on?: Maybe<Array<IUsers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IUsers_Order_By>>;
  where?: Maybe<IUsers_Bool_Exp>;
};


/** query root */
export type IQuery_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<IUsers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IUsers_Order_By>>;
  where?: Maybe<IUsers_Bool_Exp>;
};


/** query root */
export type IQuery_RootUsers_By_PkArgs = {
  id: Scalars['String'];
};


/** query root */
export type IQuery_RootVerticalsArgs = {
  distinct_on?: Maybe<Array<IVerticals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IVerticals_Order_By>>;
  where?: Maybe<IVerticals_Bool_Exp>;
};


/** query root */
export type IQuery_RootVerticals_AggregateArgs = {
  distinct_on?: Maybe<Array<IVerticals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IVerticals_Order_By>>;
  where?: Maybe<IVerticals_Bool_Exp>;
};


/** query root */
export type IQuery_RootWeb_ConfigArgs = {
  distinct_on?: Maybe<Array<IWeb_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWeb_Config_Order_By>>;
  where?: Maybe<IWeb_Config_Bool_Exp>;
};


/** query root */
export type IQuery_RootWeb_Config_AggregateArgs = {
  distinct_on?: Maybe<Array<IWeb_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWeb_Config_Order_By>>;
  where?: Maybe<IWeb_Config_Bool_Exp>;
};


/** query root */
export type IQuery_RootWeb_Config_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootWeb_Config_FilterArgs = {
  distinct_on?: Maybe<Array<IWeb_Config_Filter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWeb_Config_Filter_Order_By>>;
  where?: Maybe<IWeb_Config_Filter_Bool_Exp>;
};


/** query root */
export type IQuery_RootWeb_Config_Filter_AggregateArgs = {
  distinct_on?: Maybe<Array<IWeb_Config_Filter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWeb_Config_Filter_Order_By>>;
  where?: Maybe<IWeb_Config_Filter_Bool_Exp>;
};


/** query root */
export type IQuery_RootWeb_Config_Filter_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type IQuery_RootWeb_Config_Filter_TagsArgs = {
  distinct_on?: Maybe<Array<IWeb_Config_Filter_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWeb_Config_Filter_Tags_Order_By>>;
  where?: Maybe<IWeb_Config_Filter_Tags_Bool_Exp>;
};


/** query root */
export type IQuery_RootWeb_Config_Filter_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<IWeb_Config_Filter_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWeb_Config_Filter_Tags_Order_By>>;
  where?: Maybe<IWeb_Config_Filter_Tags_Bool_Exp>;
};


/** query root */
export type IQuery_RootWeb_Config_Filter_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "raw_data" */
export type IRaw_Data = {
  __typename?: 'raw_data';
  created_at: Scalars['timestamp'];
  data: Scalars['jsonb'];
  data_type: Scalars['String'];
  hash: Scalars['String'];
  id: Scalars['uuid'];
  processed_at?: Maybe<Scalars['timestamp']>;
  source: Scalars['String'];
  source_id: Scalars['uuid'];
};


/** columns and relationships of "raw_data" */
export type IRaw_DataDataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "raw_data" */
export type IRaw_Data_Aggregate = {
  __typename?: 'raw_data_aggregate';
  aggregate?: Maybe<IRaw_Data_Aggregate_Fields>;
  nodes: Array<IRaw_Data>;
};

/** aggregate fields of "raw_data" */
export type IRaw_Data_Aggregate_Fields = {
  __typename?: 'raw_data_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<IRaw_Data_Max_Fields>;
  min?: Maybe<IRaw_Data_Min_Fields>;
};


/** aggregate fields of "raw_data" */
export type IRaw_Data_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IRaw_Data_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "raw_data" */
export type IRaw_Data_Aggregate_Order_By = {
  count?: Maybe<IOrder_By>;
  max?: Maybe<IRaw_Data_Max_Order_By>;
  min?: Maybe<IRaw_Data_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IRaw_Data_Append_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "raw_data" */
export type IRaw_Data_Arr_Rel_Insert_Input = {
  data: Array<IRaw_Data_Insert_Input>;
  on_conflict?: Maybe<IRaw_Data_On_Conflict>;
};

/** Boolean expression to filter rows from the table "raw_data". All fields are combined with a logical 'AND'. */
export type IRaw_Data_Bool_Exp = {
  _and?: Maybe<Array<Maybe<IRaw_Data_Bool_Exp>>>;
  _not?: Maybe<IRaw_Data_Bool_Exp>;
  _or?: Maybe<Array<Maybe<IRaw_Data_Bool_Exp>>>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  data?: Maybe<IJsonb_Comparison_Exp>;
  data_type?: Maybe<IString_Comparison_Exp>;
  hash?: Maybe<IString_Comparison_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  processed_at?: Maybe<ITimestamp_Comparison_Exp>;
  source?: Maybe<IString_Comparison_Exp>;
  source_id?: Maybe<IUuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "raw_data" */
export enum IRaw_Data_Constraint {
  /** unique or primary key constraint */
  RawDataIdSourceIdHashKey = 'raw_data_id_source_id_hash_key',
  /** unique or primary key constraint */
  RawDataPkey = 'raw_data_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IRaw_Data_Delete_At_Path_Input = {
  data?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IRaw_Data_Delete_Elem_Input = {
  data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IRaw_Data_Delete_Key_Input = {
  data?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "raw_data" */
export type IRaw_Data_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  data?: Maybe<Scalars['jsonb']>;
  data_type?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  processed_at?: Maybe<Scalars['timestamp']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type IRaw_Data_Max_Fields = {
  __typename?: 'raw_data_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  data_type?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  processed_at?: Maybe<Scalars['timestamp']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "raw_data" */
export type IRaw_Data_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  data_type?: Maybe<IOrder_By>;
  hash?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  processed_at?: Maybe<IOrder_By>;
  source?: Maybe<IOrder_By>;
  source_id?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IRaw_Data_Min_Fields = {
  __typename?: 'raw_data_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  data_type?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  processed_at?: Maybe<Scalars['timestamp']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "raw_data" */
export type IRaw_Data_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  data_type?: Maybe<IOrder_By>;
  hash?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  processed_at?: Maybe<IOrder_By>;
  source?: Maybe<IOrder_By>;
  source_id?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "raw_data" */
export type IRaw_Data_Mutation_Response = {
  __typename?: 'raw_data_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<IRaw_Data>;
};

/** input type for inserting object relation for remote table "raw_data" */
export type IRaw_Data_Obj_Rel_Insert_Input = {
  data: IRaw_Data_Insert_Input;
  on_conflict?: Maybe<IRaw_Data_On_Conflict>;
};

/** on conflict condition type for table "raw_data" */
export type IRaw_Data_On_Conflict = {
  constraint: IRaw_Data_Constraint;
  update_columns: Array<IRaw_Data_Update_Column>;
  where?: Maybe<IRaw_Data_Bool_Exp>;
};

/** ordering options when selecting data from "raw_data" */
export type IRaw_Data_Order_By = {
  created_at?: Maybe<IOrder_By>;
  data?: Maybe<IOrder_By>;
  data_type?: Maybe<IOrder_By>;
  hash?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  processed_at?: Maybe<IOrder_By>;
  source?: Maybe<IOrder_By>;
  source_id?: Maybe<IOrder_By>;
};

/** primary key columns input for table: "raw_data" */
export type IRaw_Data_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IRaw_Data_Prepend_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "raw_data" */
export enum IRaw_Data_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  DataType = 'data_type',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  ProcessedAt = 'processed_at',
  /** column name */
  Source = 'source',
  /** column name */
  SourceId = 'source_id'
}

/** input type for updating data in table "raw_data" */
export type IRaw_Data_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  data?: Maybe<Scalars['jsonb']>;
  data_type?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  processed_at?: Maybe<Scalars['timestamp']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "raw_data" */
export enum IRaw_Data_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  DataType = 'data_type',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  ProcessedAt = 'processed_at',
  /** column name */
  Source = 'source',
  /** column name */
  SourceId = 'source_id'
}

/** columns and relationships of "reviews" */
export type IReviews = {
  __typename?: 'reviews';
  created_at: Scalars['timestamptz'];
  date?: Maybe<Scalars['timestamptz']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  /** An object relationship */
  location?: Maybe<ILocations>;
  location_id?: Maybe<Scalars['bigint']>;
  lock_version: Scalars['bigint'];
  rating: Scalars['bigint'];
  reviewer_name?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  url?: Maybe<Scalars['String']>;
};

/** aggregated selection of "reviews" */
export type IReviews_Aggregate = {
  __typename?: 'reviews_aggregate';
  aggregate?: Maybe<IReviews_Aggregate_Fields>;
  nodes: Array<IReviews>;
};

/** aggregate fields of "reviews" */
export type IReviews_Aggregate_Fields = {
  __typename?: 'reviews_aggregate_fields';
  avg?: Maybe<IReviews_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<IReviews_Max_Fields>;
  min?: Maybe<IReviews_Min_Fields>;
  stddev?: Maybe<IReviews_Stddev_Fields>;
  stddev_pop?: Maybe<IReviews_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IReviews_Stddev_Samp_Fields>;
  sum?: Maybe<IReviews_Sum_Fields>;
  var_pop?: Maybe<IReviews_Var_Pop_Fields>;
  var_samp?: Maybe<IReviews_Var_Samp_Fields>;
  variance?: Maybe<IReviews_Variance_Fields>;
};


/** aggregate fields of "reviews" */
export type IReviews_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IReviews_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reviews" */
export type IReviews_Aggregate_Order_By = {
  avg?: Maybe<IReviews_Avg_Order_By>;
  count?: Maybe<IOrder_By>;
  max?: Maybe<IReviews_Max_Order_By>;
  min?: Maybe<IReviews_Min_Order_By>;
  stddev?: Maybe<IReviews_Stddev_Order_By>;
  stddev_pop?: Maybe<IReviews_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IReviews_Stddev_Samp_Order_By>;
  sum?: Maybe<IReviews_Sum_Order_By>;
  var_pop?: Maybe<IReviews_Var_Pop_Order_By>;
  var_samp?: Maybe<IReviews_Var_Samp_Order_By>;
  variance?: Maybe<IReviews_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "reviews" */
export type IReviews_Arr_Rel_Insert_Input = {
  data: Array<IReviews_Insert_Input>;
  on_conflict?: Maybe<IReviews_On_Conflict>;
};

/** aggregate avg on columns */
export type IReviews_Avg_Fields = {
  __typename?: 'reviews_avg_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  lock_version?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "reviews" */
export type IReviews_Avg_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  rating?: Maybe<IOrder_By>;
};

/** Boolean expression to filter rows from the table "reviews". All fields are combined with a logical 'AND'. */
export type IReviews_Bool_Exp = {
  _and?: Maybe<Array<Maybe<IReviews_Bool_Exp>>>;
  _not?: Maybe<IReviews_Bool_Exp>;
  _or?: Maybe<Array<Maybe<IReviews_Bool_Exp>>>;
  created_at?: Maybe<ITimestamptz_Comparison_Exp>;
  date?: Maybe<ITimestamptz_Comparison_Exp>;
  discarded_at?: Maybe<ITimestamptz_Comparison_Exp>;
  id?: Maybe<IBigint_Comparison_Exp>;
  location?: Maybe<ILocations_Bool_Exp>;
  location_id?: Maybe<IBigint_Comparison_Exp>;
  lock_version?: Maybe<IBigint_Comparison_Exp>;
  rating?: Maybe<IBigint_Comparison_Exp>;
  reviewer_name?: Maybe<IString_Comparison_Exp>;
  source?: Maybe<IString_Comparison_Exp>;
  source_id?: Maybe<IString_Comparison_Exp>;
  text?: Maybe<IString_Comparison_Exp>;
  updated_at?: Maybe<ITimestamptz_Comparison_Exp>;
  url?: Maybe<IString_Comparison_Exp>;
};

/** unique or primary key constraints on table "reviews" */
export enum IReviews_Constraint {
  /** unique or primary key constraint */
  Idx_17088Primary = 'idx_17088_primary'
}

/** input type for incrementing integer column in table "reviews" */
export type IReviews_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  lock_version?: Maybe<Scalars['bigint']>;
  rating?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "reviews" */
export type IReviews_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  location?: Maybe<ILocations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['bigint']>;
  lock_version?: Maybe<Scalars['bigint']>;
  rating?: Maybe<Scalars['bigint']>;
  reviewer_name?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type IReviews_Max_Fields = {
  __typename?: 'reviews_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  lock_version?: Maybe<Scalars['bigint']>;
  rating?: Maybe<Scalars['bigint']>;
  reviewer_name?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "reviews" */
export type IReviews_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  date?: Maybe<IOrder_By>;
  discarded_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  rating?: Maybe<IOrder_By>;
  reviewer_name?: Maybe<IOrder_By>;
  source?: Maybe<IOrder_By>;
  source_id?: Maybe<IOrder_By>;
  text?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  url?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IReviews_Min_Fields = {
  __typename?: 'reviews_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  lock_version?: Maybe<Scalars['bigint']>;
  rating?: Maybe<Scalars['bigint']>;
  reviewer_name?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "reviews" */
export type IReviews_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  date?: Maybe<IOrder_By>;
  discarded_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  rating?: Maybe<IOrder_By>;
  reviewer_name?: Maybe<IOrder_By>;
  source?: Maybe<IOrder_By>;
  source_id?: Maybe<IOrder_By>;
  text?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  url?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "reviews" */
export type IReviews_Mutation_Response = {
  __typename?: 'reviews_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<IReviews>;
};

/** input type for inserting object relation for remote table "reviews" */
export type IReviews_Obj_Rel_Insert_Input = {
  data: IReviews_Insert_Input;
  on_conflict?: Maybe<IReviews_On_Conflict>;
};

/** on conflict condition type for table "reviews" */
export type IReviews_On_Conflict = {
  constraint: IReviews_Constraint;
  update_columns: Array<IReviews_Update_Column>;
  where?: Maybe<IReviews_Bool_Exp>;
};

/** ordering options when selecting data from "reviews" */
export type IReviews_Order_By = {
  created_at?: Maybe<IOrder_By>;
  date?: Maybe<IOrder_By>;
  discarded_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location?: Maybe<ILocations_Order_By>;
  location_id?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  rating?: Maybe<IOrder_By>;
  reviewer_name?: Maybe<IOrder_By>;
  source?: Maybe<IOrder_By>;
  source_id?: Maybe<IOrder_By>;
  text?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  url?: Maybe<IOrder_By>;
};

/** select columns of table "reviews" */
export enum IReviews_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  DiscardedAt = 'discarded_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  LockVersion = 'lock_version',
  /** column name */
  Rating = 'rating',
  /** column name */
  ReviewerName = 'reviewer_name',
  /** column name */
  Source = 'source',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "reviews" */
export type IReviews_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  lock_version?: Maybe<Scalars['bigint']>;
  rating?: Maybe<Scalars['bigint']>;
  reviewer_name?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type IReviews_Stddev_Fields = {
  __typename?: 'reviews_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  lock_version?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "reviews" */
export type IReviews_Stddev_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  rating?: Maybe<IOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IReviews_Stddev_Pop_Fields = {
  __typename?: 'reviews_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  lock_version?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "reviews" */
export type IReviews_Stddev_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  rating?: Maybe<IOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IReviews_Stddev_Samp_Fields = {
  __typename?: 'reviews_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  lock_version?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "reviews" */
export type IReviews_Stddev_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  rating?: Maybe<IOrder_By>;
};

/** aggregate sum on columns */
export type IReviews_Sum_Fields = {
  __typename?: 'reviews_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  lock_version?: Maybe<Scalars['bigint']>;
  rating?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "reviews" */
export type IReviews_Sum_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  rating?: Maybe<IOrder_By>;
};

/** update columns of table "reviews" */
export enum IReviews_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  DiscardedAt = 'discarded_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  LockVersion = 'lock_version',
  /** column name */
  Rating = 'rating',
  /** column name */
  ReviewerName = 'reviewer_name',
  /** column name */
  Source = 'source',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** aggregate var_pop on columns */
export type IReviews_Var_Pop_Fields = {
  __typename?: 'reviews_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  lock_version?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "reviews" */
export type IReviews_Var_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  rating?: Maybe<IOrder_By>;
};

/** aggregate var_samp on columns */
export type IReviews_Var_Samp_Fields = {
  __typename?: 'reviews_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  lock_version?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "reviews" */
export type IReviews_Var_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  rating?: Maybe<IOrder_By>;
};

/** aggregate variance on columns */
export type IReviews_Variance_Fields = {
  __typename?: 'reviews_variance_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  lock_version?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "reviews" */
export type IReviews_Variance_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  lock_version?: Maybe<IOrder_By>;
  rating?: Maybe<IOrder_By>;
};

export type ISearch_Locations_Args = {
  keyword?: Maybe<Scalars['String']>;
};

/** columns and relationships of "service" */
export type IService = {
  __typename?: 'service';
  address1: Scalars['String'];
  address2: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  created_at?: Maybe<Scalars['timestamp']>;
  external_uri?: Maybe<Scalars['String']>;
  external_uri_description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  latitude: Scalars['numeric'];
  longitude: Scalars['numeric'];
  name: Scalars['String'];
  notes: Scalars['String'];
  /** An object relationship */
  obituary: IObituary;
  obituary_id: Scalars['uuid'];
  phonenumber: Scalars['String'];
  /** An array relationship */
  service_durations: Array<IService_Duration>;
  /** An aggregate relationship */
  service_durations_aggregate: IService_Duration_Aggregate;
  state: Scalars['String'];
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamp']>;
  zip: Scalars['String'];
};


/** columns and relationships of "service" */
export type IServiceService_DurationsArgs = {
  distinct_on?: Maybe<Array<IService_Duration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IService_Duration_Order_By>>;
  where?: Maybe<IService_Duration_Bool_Exp>;
};


/** columns and relationships of "service" */
export type IServiceService_Durations_AggregateArgs = {
  distinct_on?: Maybe<Array<IService_Duration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IService_Duration_Order_By>>;
  where?: Maybe<IService_Duration_Bool_Exp>;
};

/** aggregated selection of "service" */
export type IService_Aggregate = {
  __typename?: 'service_aggregate';
  aggregate?: Maybe<IService_Aggregate_Fields>;
  nodes: Array<IService>;
};

/** aggregate fields of "service" */
export type IService_Aggregate_Fields = {
  __typename?: 'service_aggregate_fields';
  avg?: Maybe<IService_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IService_Max_Fields>;
  min?: Maybe<IService_Min_Fields>;
  stddev?: Maybe<IService_Stddev_Fields>;
  stddev_pop?: Maybe<IService_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IService_Stddev_Samp_Fields>;
  sum?: Maybe<IService_Sum_Fields>;
  var_pop?: Maybe<IService_Var_Pop_Fields>;
  var_samp?: Maybe<IService_Var_Samp_Fields>;
  variance?: Maybe<IService_Variance_Fields>;
};


/** aggregate fields of "service" */
export type IService_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IService_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "service" */
export type IService_Aggregate_Order_By = {
  avg?: Maybe<IService_Avg_Order_By>;
  count?: Maybe<IOrder_By>;
  max?: Maybe<IService_Max_Order_By>;
  min?: Maybe<IService_Min_Order_By>;
  stddev?: Maybe<IService_Stddev_Order_By>;
  stddev_pop?: Maybe<IService_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IService_Stddev_Samp_Order_By>;
  sum?: Maybe<IService_Sum_Order_By>;
  var_pop?: Maybe<IService_Var_Pop_Order_By>;
  var_samp?: Maybe<IService_Var_Samp_Order_By>;
  variance?: Maybe<IService_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "service" */
export type IService_Arr_Rel_Insert_Input = {
  data: Array<IService_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<IService_On_Conflict>;
};

/** aggregate avg on columns */
export type IService_Avg_Fields = {
  __typename?: 'service_avg_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "service" */
export type IService_Avg_Order_By = {
  latitude?: Maybe<IOrder_By>;
  longitude?: Maybe<IOrder_By>;
};

/** Boolean expression to filter rows from the table "service". All fields are combined with a logical 'AND'. */
export type IService_Bool_Exp = {
  _and?: Maybe<Array<IService_Bool_Exp>>;
  _not?: Maybe<IService_Bool_Exp>;
  _or?: Maybe<Array<IService_Bool_Exp>>;
  address1?: Maybe<IString_Comparison_Exp>;
  address2?: Maybe<IString_Comparison_Exp>;
  city?: Maybe<IString_Comparison_Exp>;
  country?: Maybe<IString_Comparison_Exp>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  external_uri?: Maybe<IString_Comparison_Exp>;
  external_uri_description?: Maybe<IString_Comparison_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  latitude?: Maybe<INumeric_Comparison_Exp>;
  longitude?: Maybe<INumeric_Comparison_Exp>;
  name?: Maybe<IString_Comparison_Exp>;
  notes?: Maybe<IString_Comparison_Exp>;
  obituary?: Maybe<IObituary_Bool_Exp>;
  obituary_id?: Maybe<IUuid_Comparison_Exp>;
  phonenumber?: Maybe<IString_Comparison_Exp>;
  service_durations?: Maybe<IService_Duration_Bool_Exp>;
  state?: Maybe<IString_Comparison_Exp>;
  title?: Maybe<IString_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
  zip?: Maybe<IString_Comparison_Exp>;
};

/** unique or primary key constraints on table "service" */
export enum IService_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServicePkey = 'service_pkey'
}

/** columns and relationships of "service_duration" */
export type IService_Duration = {
  __typename?: 'service_duration';
  begin?: Maybe<Scalars['timestamptz']>;
  begin_offset?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  end?: Maybe<Scalars['timestamptz']>;
  end_offset?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  /** An object relationship */
  service: IService;
  service_id: Scalars['uuid'];
  type: IService_Duration_Type_Enum;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregated selection of "service_duration" */
export type IService_Duration_Aggregate = {
  __typename?: 'service_duration_aggregate';
  aggregate?: Maybe<IService_Duration_Aggregate_Fields>;
  nodes: Array<IService_Duration>;
};

/** aggregate fields of "service_duration" */
export type IService_Duration_Aggregate_Fields = {
  __typename?: 'service_duration_aggregate_fields';
  avg?: Maybe<IService_Duration_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IService_Duration_Max_Fields>;
  min?: Maybe<IService_Duration_Min_Fields>;
  stddev?: Maybe<IService_Duration_Stddev_Fields>;
  stddev_pop?: Maybe<IService_Duration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IService_Duration_Stddev_Samp_Fields>;
  sum?: Maybe<IService_Duration_Sum_Fields>;
  var_pop?: Maybe<IService_Duration_Var_Pop_Fields>;
  var_samp?: Maybe<IService_Duration_Var_Samp_Fields>;
  variance?: Maybe<IService_Duration_Variance_Fields>;
};


/** aggregate fields of "service_duration" */
export type IService_Duration_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IService_Duration_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "service_duration" */
export type IService_Duration_Aggregate_Order_By = {
  avg?: Maybe<IService_Duration_Avg_Order_By>;
  count?: Maybe<IOrder_By>;
  max?: Maybe<IService_Duration_Max_Order_By>;
  min?: Maybe<IService_Duration_Min_Order_By>;
  stddev?: Maybe<IService_Duration_Stddev_Order_By>;
  stddev_pop?: Maybe<IService_Duration_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IService_Duration_Stddev_Samp_Order_By>;
  sum?: Maybe<IService_Duration_Sum_Order_By>;
  var_pop?: Maybe<IService_Duration_Var_Pop_Order_By>;
  var_samp?: Maybe<IService_Duration_Var_Samp_Order_By>;
  variance?: Maybe<IService_Duration_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "service_duration" */
export type IService_Duration_Arr_Rel_Insert_Input = {
  data: Array<IService_Duration_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<IService_Duration_On_Conflict>;
};

/** aggregate avg on columns */
export type IService_Duration_Avg_Fields = {
  __typename?: 'service_duration_avg_fields';
  begin_offset?: Maybe<Scalars['Float']>;
  end_offset?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "service_duration" */
export type IService_Duration_Avg_Order_By = {
  begin_offset?: Maybe<IOrder_By>;
  end_offset?: Maybe<IOrder_By>;
};

/** Boolean expression to filter rows from the table "service_duration". All fields are combined with a logical 'AND'. */
export type IService_Duration_Bool_Exp = {
  _and?: Maybe<Array<IService_Duration_Bool_Exp>>;
  _not?: Maybe<IService_Duration_Bool_Exp>;
  _or?: Maybe<Array<IService_Duration_Bool_Exp>>;
  begin?: Maybe<ITimestamptz_Comparison_Exp>;
  begin_offset?: Maybe<IInt_Comparison_Exp>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  end?: Maybe<ITimestamptz_Comparison_Exp>;
  end_offset?: Maybe<IInt_Comparison_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  service?: Maybe<IService_Bool_Exp>;
  service_id?: Maybe<IUuid_Comparison_Exp>;
  type?: Maybe<IService_Duration_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_duration" */
export enum IService_Duration_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceDurationPkey = 'service_duration_pkey'
}

/** input type for incrementing numeric columns in table "service_duration" */
export type IService_Duration_Inc_Input = {
  begin_offset?: Maybe<Scalars['Int']>;
  end_offset?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "service_duration" */
export type IService_Duration_Insert_Input = {
  begin?: Maybe<Scalars['timestamptz']>;
  begin_offset?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  end?: Maybe<Scalars['timestamptz']>;
  end_offset?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  service?: Maybe<IService_Obj_Rel_Insert_Input>;
  service_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<IService_Duration_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type IService_Duration_Max_Fields = {
  __typename?: 'service_duration_max_fields';
  begin?: Maybe<Scalars['timestamptz']>;
  begin_offset?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  end?: Maybe<Scalars['timestamptz']>;
  end_offset?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  service_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "service_duration" */
export type IService_Duration_Max_Order_By = {
  begin?: Maybe<IOrder_By>;
  begin_offset?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  end?: Maybe<IOrder_By>;
  end_offset?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  service_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IService_Duration_Min_Fields = {
  __typename?: 'service_duration_min_fields';
  begin?: Maybe<Scalars['timestamptz']>;
  begin_offset?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  end?: Maybe<Scalars['timestamptz']>;
  end_offset?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  service_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "service_duration" */
export type IService_Duration_Min_Order_By = {
  begin?: Maybe<IOrder_By>;
  begin_offset?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  end?: Maybe<IOrder_By>;
  end_offset?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  service_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "service_duration" */
export type IService_Duration_Mutation_Response = {
  __typename?: 'service_duration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IService_Duration>;
};

/** on_conflict condition type for table "service_duration" */
export type IService_Duration_On_Conflict = {
  constraint: IService_Duration_Constraint;
  update_columns?: Array<IService_Duration_Update_Column>;
  where?: Maybe<IService_Duration_Bool_Exp>;
};

/** Ordering options when selecting data from "service_duration". */
export type IService_Duration_Order_By = {
  begin?: Maybe<IOrder_By>;
  begin_offset?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  end?: Maybe<IOrder_By>;
  end_offset?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  service?: Maybe<IService_Order_By>;
  service_id?: Maybe<IOrder_By>;
  type?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** primary key columns input for table: service_duration */
export type IService_Duration_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "service_duration" */
export enum IService_Duration_Select_Column {
  /** column name */
  Begin = 'begin',
  /** column name */
  BeginOffset = 'begin_offset',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  End = 'end',
  /** column name */
  EndOffset = 'end_offset',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "service_duration" */
export type IService_Duration_Set_Input = {
  begin?: Maybe<Scalars['timestamptz']>;
  begin_offset?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  end?: Maybe<Scalars['timestamptz']>;
  end_offset?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  service_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<IService_Duration_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type IService_Duration_Stddev_Fields = {
  __typename?: 'service_duration_stddev_fields';
  begin_offset?: Maybe<Scalars['Float']>;
  end_offset?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "service_duration" */
export type IService_Duration_Stddev_Order_By = {
  begin_offset?: Maybe<IOrder_By>;
  end_offset?: Maybe<IOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IService_Duration_Stddev_Pop_Fields = {
  __typename?: 'service_duration_stddev_pop_fields';
  begin_offset?: Maybe<Scalars['Float']>;
  end_offset?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "service_duration" */
export type IService_Duration_Stddev_Pop_Order_By = {
  begin_offset?: Maybe<IOrder_By>;
  end_offset?: Maybe<IOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IService_Duration_Stddev_Samp_Fields = {
  __typename?: 'service_duration_stddev_samp_fields';
  begin_offset?: Maybe<Scalars['Float']>;
  end_offset?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "service_duration" */
export type IService_Duration_Stddev_Samp_Order_By = {
  begin_offset?: Maybe<IOrder_By>;
  end_offset?: Maybe<IOrder_By>;
};

/** aggregate sum on columns */
export type IService_Duration_Sum_Fields = {
  __typename?: 'service_duration_sum_fields';
  begin_offset?: Maybe<Scalars['Int']>;
  end_offset?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "service_duration" */
export type IService_Duration_Sum_Order_By = {
  begin_offset?: Maybe<IOrder_By>;
  end_offset?: Maybe<IOrder_By>;
};

/** enum type for service_duration */
export type IService_Duration_Type = {
  __typename?: 'service_duration_type';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "service_duration_type" */
export type IService_Duration_Type_Aggregate = {
  __typename?: 'service_duration_type_aggregate';
  aggregate?: Maybe<IService_Duration_Type_Aggregate_Fields>;
  nodes: Array<IService_Duration_Type>;
};

/** aggregate fields of "service_duration_type" */
export type IService_Duration_Type_Aggregate_Fields = {
  __typename?: 'service_duration_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IService_Duration_Type_Max_Fields>;
  min?: Maybe<IService_Duration_Type_Min_Fields>;
};


/** aggregate fields of "service_duration_type" */
export type IService_Duration_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IService_Duration_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "service_duration_type". All fields are combined with a logical 'AND'. */
export type IService_Duration_Type_Bool_Exp = {
  _and?: Maybe<Array<IService_Duration_Type_Bool_Exp>>;
  _not?: Maybe<IService_Duration_Type_Bool_Exp>;
  _or?: Maybe<Array<IService_Duration_Type_Bool_Exp>>;
  comment?: Maybe<IString_Comparison_Exp>;
  value?: Maybe<IString_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_duration_type" */
export enum IService_Duration_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  ServiceDurationTypePkey = 'service_duration_type_pkey'
}

export enum IService_Duration_Type_Enum {
  /** All day should have the time set to 00:00:00 and use the date part to represent a day. */
  AllDay = 'all_day',
  /** To be interpreted as a full date plus time */
  DateTime = 'date_time'
}

/** Boolean expression to compare columns of type "service_duration_type_enum". All fields are combined with logical 'AND'. */
export type IService_Duration_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<IService_Duration_Type_Enum>;
  _in?: Maybe<Array<IService_Duration_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<IService_Duration_Type_Enum>;
  _nin?: Maybe<Array<IService_Duration_Type_Enum>>;
};

/** input type for inserting data into table "service_duration_type" */
export type IService_Duration_Type_Insert_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type IService_Duration_Type_Max_Fields = {
  __typename?: 'service_duration_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type IService_Duration_Type_Min_Fields = {
  __typename?: 'service_duration_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "service_duration_type" */
export type IService_Duration_Type_Mutation_Response = {
  __typename?: 'service_duration_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IService_Duration_Type>;
};

/** on_conflict condition type for table "service_duration_type" */
export type IService_Duration_Type_On_Conflict = {
  constraint: IService_Duration_Type_Constraint;
  update_columns?: Array<IService_Duration_Type_Update_Column>;
  where?: Maybe<IService_Duration_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "service_duration_type". */
export type IService_Duration_Type_Order_By = {
  comment?: Maybe<IOrder_By>;
  value?: Maybe<IOrder_By>;
};

/** primary key columns input for table: service_duration_type */
export type IService_Duration_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "service_duration_type" */
export enum IService_Duration_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "service_duration_type" */
export type IService_Duration_Type_Set_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "service_duration_type" */
export enum IService_Duration_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** update columns of table "service_duration" */
export enum IService_Duration_Update_Column {
  /** column name */
  Begin = 'begin',
  /** column name */
  BeginOffset = 'begin_offset',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  End = 'end',
  /** column name */
  EndOffset = 'end_offset',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type IService_Duration_Var_Pop_Fields = {
  __typename?: 'service_duration_var_pop_fields';
  begin_offset?: Maybe<Scalars['Float']>;
  end_offset?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "service_duration" */
export type IService_Duration_Var_Pop_Order_By = {
  begin_offset?: Maybe<IOrder_By>;
  end_offset?: Maybe<IOrder_By>;
};

/** aggregate var_samp on columns */
export type IService_Duration_Var_Samp_Fields = {
  __typename?: 'service_duration_var_samp_fields';
  begin_offset?: Maybe<Scalars['Float']>;
  end_offset?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "service_duration" */
export type IService_Duration_Var_Samp_Order_By = {
  begin_offset?: Maybe<IOrder_By>;
  end_offset?: Maybe<IOrder_By>;
};

/** aggregate variance on columns */
export type IService_Duration_Variance_Fields = {
  __typename?: 'service_duration_variance_fields';
  begin_offset?: Maybe<Scalars['Float']>;
  end_offset?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "service_duration" */
export type IService_Duration_Variance_Order_By = {
  begin_offset?: Maybe<IOrder_By>;
  end_offset?: Maybe<IOrder_By>;
};

/** input type for incrementing numeric columns in table "service" */
export type IService_Inc_Input = {
  latitude?: Maybe<Scalars['numeric']>;
  longitude?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "service" */
export type IService_Insert_Input = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  external_uri?: Maybe<Scalars['String']>;
  external_uri_description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['numeric']>;
  longitude?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  obituary?: Maybe<IObituary_Obj_Rel_Insert_Input>;
  obituary_id?: Maybe<Scalars['uuid']>;
  phonenumber?: Maybe<Scalars['String']>;
  service_durations?: Maybe<IService_Duration_Arr_Rel_Insert_Input>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  zip?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type IService_Max_Fields = {
  __typename?: 'service_max_fields';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  external_uri?: Maybe<Scalars['String']>;
  external_uri_description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['numeric']>;
  longitude?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  phonenumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  zip?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "service" */
export type IService_Max_Order_By = {
  address1?: Maybe<IOrder_By>;
  address2?: Maybe<IOrder_By>;
  city?: Maybe<IOrder_By>;
  country?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  external_uri?: Maybe<IOrder_By>;
  external_uri_description?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  latitude?: Maybe<IOrder_By>;
  longitude?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  notes?: Maybe<IOrder_By>;
  obituary_id?: Maybe<IOrder_By>;
  phonenumber?: Maybe<IOrder_By>;
  state?: Maybe<IOrder_By>;
  title?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  zip?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IService_Min_Fields = {
  __typename?: 'service_min_fields';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  external_uri?: Maybe<Scalars['String']>;
  external_uri_description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['numeric']>;
  longitude?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  phonenumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  zip?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "service" */
export type IService_Min_Order_By = {
  address1?: Maybe<IOrder_By>;
  address2?: Maybe<IOrder_By>;
  city?: Maybe<IOrder_By>;
  country?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  external_uri?: Maybe<IOrder_By>;
  external_uri_description?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  latitude?: Maybe<IOrder_By>;
  longitude?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  notes?: Maybe<IOrder_By>;
  obituary_id?: Maybe<IOrder_By>;
  phonenumber?: Maybe<IOrder_By>;
  state?: Maybe<IOrder_By>;
  title?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  zip?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "service" */
export type IService_Mutation_Response = {
  __typename?: 'service_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IService>;
};

/** input type for inserting object relation for remote table "service" */
export type IService_Obj_Rel_Insert_Input = {
  data: IService_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<IService_On_Conflict>;
};

/** columns and relationships of "service_offers" */
export type IService_Offers = {
  __typename?: 'service_offers';
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  location?: Maybe<ILocations>;
  location_id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "service_offers" */
export type IService_Offers_Aggregate = {
  __typename?: 'service_offers_aggregate';
  aggregate?: Maybe<IService_Offers_Aggregate_Fields>;
  nodes: Array<IService_Offers>;
};

/** aggregate fields of "service_offers" */
export type IService_Offers_Aggregate_Fields = {
  __typename?: 'service_offers_aggregate_fields';
  avg?: Maybe<IService_Offers_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<IService_Offers_Max_Fields>;
  min?: Maybe<IService_Offers_Min_Fields>;
  stddev?: Maybe<IService_Offers_Stddev_Fields>;
  stddev_pop?: Maybe<IService_Offers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IService_Offers_Stddev_Samp_Fields>;
  sum?: Maybe<IService_Offers_Sum_Fields>;
  var_pop?: Maybe<IService_Offers_Var_Pop_Fields>;
  var_samp?: Maybe<IService_Offers_Var_Samp_Fields>;
  variance?: Maybe<IService_Offers_Variance_Fields>;
};


/** aggregate fields of "service_offers" */
export type IService_Offers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IService_Offers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "service_offers" */
export type IService_Offers_Aggregate_Order_By = {
  avg?: Maybe<IService_Offers_Avg_Order_By>;
  count?: Maybe<IOrder_By>;
  max?: Maybe<IService_Offers_Max_Order_By>;
  min?: Maybe<IService_Offers_Min_Order_By>;
  stddev?: Maybe<IService_Offers_Stddev_Order_By>;
  stddev_pop?: Maybe<IService_Offers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IService_Offers_Stddev_Samp_Order_By>;
  sum?: Maybe<IService_Offers_Sum_Order_By>;
  var_pop?: Maybe<IService_Offers_Var_Pop_Order_By>;
  var_samp?: Maybe<IService_Offers_Var_Samp_Order_By>;
  variance?: Maybe<IService_Offers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "service_offers" */
export type IService_Offers_Arr_Rel_Insert_Input = {
  data: Array<IService_Offers_Insert_Input>;
  on_conflict?: Maybe<IService_Offers_On_Conflict>;
};

/** aggregate avg on columns */
export type IService_Offers_Avg_Fields = {
  __typename?: 'service_offers_avg_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "service_offers" */
export type IService_Offers_Avg_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** Boolean expression to filter rows from the table "service_offers". All fields are combined with a logical 'AND'. */
export type IService_Offers_Bool_Exp = {
  _and?: Maybe<Array<Maybe<IService_Offers_Bool_Exp>>>;
  _not?: Maybe<IService_Offers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<IService_Offers_Bool_Exp>>>;
  created_at?: Maybe<ITimestamptz_Comparison_Exp>;
  id?: Maybe<IBigint_Comparison_Exp>;
  location?: Maybe<ILocations_Bool_Exp>;
  location_id?: Maybe<IBigint_Comparison_Exp>;
  name?: Maybe<IString_Comparison_Exp>;
  updated_at?: Maybe<ITimestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_offers" */
export enum IService_Offers_Constraint {
  /** unique or primary key constraint */
  Idx_17111Primary = 'idx_17111_primary'
}

/** input type for incrementing integer column in table "service_offers" */
export type IService_Offers_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "service_offers" */
export type IService_Offers_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  location?: Maybe<ILocations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type IService_Offers_Max_Fields = {
  __typename?: 'service_offers_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "service_offers" */
export type IService_Offers_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IService_Offers_Min_Fields = {
  __typename?: 'service_offers_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "service_offers" */
export type IService_Offers_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "service_offers" */
export type IService_Offers_Mutation_Response = {
  __typename?: 'service_offers_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<IService_Offers>;
};

/** input type for inserting object relation for remote table "service_offers" */
export type IService_Offers_Obj_Rel_Insert_Input = {
  data: IService_Offers_Insert_Input;
  on_conflict?: Maybe<IService_Offers_On_Conflict>;
};

/** on conflict condition type for table "service_offers" */
export type IService_Offers_On_Conflict = {
  constraint: IService_Offers_Constraint;
  update_columns: Array<IService_Offers_Update_Column>;
  where?: Maybe<IService_Offers_Bool_Exp>;
};

/** ordering options when selecting data from "service_offers" */
export type IService_Offers_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location?: Maybe<ILocations_Order_By>;
  location_id?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** select columns of table "service_offers" */
export enum IService_Offers_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "service_offers" */
export type IService_Offers_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type IService_Offers_Stddev_Fields = {
  __typename?: 'service_offers_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "service_offers" */
export type IService_Offers_Stddev_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IService_Offers_Stddev_Pop_Fields = {
  __typename?: 'service_offers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "service_offers" */
export type IService_Offers_Stddev_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IService_Offers_Stddev_Samp_Fields = {
  __typename?: 'service_offers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "service_offers" */
export type IService_Offers_Stddev_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate sum on columns */
export type IService_Offers_Sum_Fields = {
  __typename?: 'service_offers_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "service_offers" */
export type IService_Offers_Sum_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** update columns of table "service_offers" */
export enum IService_Offers_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type IService_Offers_Var_Pop_Fields = {
  __typename?: 'service_offers_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "service_offers" */
export type IService_Offers_Var_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate var_samp on columns */
export type IService_Offers_Var_Samp_Fields = {
  __typename?: 'service_offers_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "service_offers" */
export type IService_Offers_Var_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate variance on columns */
export type IService_Offers_Variance_Fields = {
  __typename?: 'service_offers_variance_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "service_offers" */
export type IService_Offers_Variance_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** on_conflict condition type for table "service" */
export type IService_On_Conflict = {
  constraint: IService_Constraint;
  update_columns?: Array<IService_Update_Column>;
  where?: Maybe<IService_Bool_Exp>;
};

/** Ordering options when selecting data from "service". */
export type IService_Order_By = {
  address1?: Maybe<IOrder_By>;
  address2?: Maybe<IOrder_By>;
  city?: Maybe<IOrder_By>;
  country?: Maybe<IOrder_By>;
  created_at?: Maybe<IOrder_By>;
  external_uri?: Maybe<IOrder_By>;
  external_uri_description?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  latitude?: Maybe<IOrder_By>;
  longitude?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  notes?: Maybe<IOrder_By>;
  obituary?: Maybe<IObituary_Order_By>;
  obituary_id?: Maybe<IOrder_By>;
  phonenumber?: Maybe<IOrder_By>;
  service_durations_aggregate?: Maybe<IService_Duration_Aggregate_Order_By>;
  state?: Maybe<IOrder_By>;
  title?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  zip?: Maybe<IOrder_By>;
};

/** primary key columns input for table: service */
export type IService_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "service" */
export enum IService_Select_Column {
  /** column name */
  Address1 = 'address1',
  /** column name */
  Address2 = 'address2',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalUri = 'external_uri',
  /** column name */
  ExternalUriDescription = 'external_uri_description',
  /** column name */
  Id = 'id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  ObituaryId = 'obituary_id',
  /** column name */
  Phonenumber = 'phonenumber',
  /** column name */
  State = 'state',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Zip = 'zip'
}

/** input type for updating data in table "service" */
export type IService_Set_Input = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  external_uri?: Maybe<Scalars['String']>;
  external_uri_description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['numeric']>;
  longitude?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  obituary_id?: Maybe<Scalars['uuid']>;
  phonenumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  zip?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type IService_Stddev_Fields = {
  __typename?: 'service_stddev_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "service" */
export type IService_Stddev_Order_By = {
  latitude?: Maybe<IOrder_By>;
  longitude?: Maybe<IOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IService_Stddev_Pop_Fields = {
  __typename?: 'service_stddev_pop_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "service" */
export type IService_Stddev_Pop_Order_By = {
  latitude?: Maybe<IOrder_By>;
  longitude?: Maybe<IOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IService_Stddev_Samp_Fields = {
  __typename?: 'service_stddev_samp_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "service" */
export type IService_Stddev_Samp_Order_By = {
  latitude?: Maybe<IOrder_By>;
  longitude?: Maybe<IOrder_By>;
};

/** aggregate sum on columns */
export type IService_Sum_Fields = {
  __typename?: 'service_sum_fields';
  latitude?: Maybe<Scalars['numeric']>;
  longitude?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "service" */
export type IService_Sum_Order_By = {
  latitude?: Maybe<IOrder_By>;
  longitude?: Maybe<IOrder_By>;
};

/** update columns of table "service" */
export enum IService_Update_Column {
  /** column name */
  Address1 = 'address1',
  /** column name */
  Address2 = 'address2',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalUri = 'external_uri',
  /** column name */
  ExternalUriDescription = 'external_uri_description',
  /** column name */
  Id = 'id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  ObituaryId = 'obituary_id',
  /** column name */
  Phonenumber = 'phonenumber',
  /** column name */
  State = 'state',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Zip = 'zip'
}

/** aggregate var_pop on columns */
export type IService_Var_Pop_Fields = {
  __typename?: 'service_var_pop_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "service" */
export type IService_Var_Pop_Order_By = {
  latitude?: Maybe<IOrder_By>;
  longitude?: Maybe<IOrder_By>;
};

/** aggregate var_samp on columns */
export type IService_Var_Samp_Fields = {
  __typename?: 'service_var_samp_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "service" */
export type IService_Var_Samp_Order_By = {
  latitude?: Maybe<IOrder_By>;
  longitude?: Maybe<IOrder_By>;
};

/** aggregate variance on columns */
export type IService_Variance_Fields = {
  __typename?: 'service_variance_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "service" */
export type IService_Variance_Order_By = {
  latitude?: Maybe<IOrder_By>;
  longitude?: Maybe<IOrder_By>;
};

/** subscription root */
export type ISubscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "archived_obituary" */
  archived_obituary: Array<IArchived_Obituary>;
  /** fetch aggregated fields from the table: "archived_obituary" */
  archived_obituary_aggregate: IArchived_Obituary_Aggregate;
  /** fetch data from the table: "archived_obituary" using primary key columns */
  archived_obituary_by_pk?: Maybe<IArchived_Obituary>;
  /** fetch data from the table: "capi_config" */
  capi_config: Array<ICapi_Config>;
  /** fetch aggregated fields from the table: "capi_config" */
  capi_config_aggregate: ICapi_Config_Aggregate;
  /** fetch data from the table: "capi_config" using primary key columns */
  capi_config_by_pk?: Maybe<ICapi_Config>;
  /** fetch data from the table: "categories" */
  categories: Array<ICategories>;
  /** fetch aggregated fields from the table: "categories" */
  categories_aggregate: ICategories_Aggregate;
  /** fetch data from the table: "categories_locations" */
  categories_locations: Array<ICategories_Locations>;
  /** fetch aggregated fields from the table: "categories_locations" */
  categories_locations_aggregate: ICategories_Locations_Aggregate;
  /** fetch data from the table: "categories_verticals" */
  categories_verticals: Array<ICategories_Verticals>;
  /** fetch aggregated fields from the table: "categories_verticals" */
  categories_verticals_aggregate: ICategories_Verticals_Aggregate;
  /** fetch data from the table: "category_image_urls" */
  category_image_urls: Array<ICategory_Image_Urls>;
  /** fetch aggregated fields from the table: "category_image_urls" */
  category_image_urls_aggregate: ICategory_Image_Urls_Aggregate;
  /** fetch data from the table: "category_keywords" */
  category_keywords: Array<ICategory_Keywords>;
  /** fetch aggregated fields from the table: "category_keywords" */
  category_keywords_aggregate: ICategory_Keywords_Aggregate;
  /** fetch data from the table: "coupon_adas" */
  coupon_adas: Array<ICoupon_Adas>;
  /** fetch aggregated fields from the table: "coupon_adas" */
  coupon_adas_aggregate: ICoupon_Adas_Aggregate;
  /** fetch data from the table: "daily_locations_counts" */
  daily_locations_counts: Array<IDaily_Locations_Counts>;
  /** fetch aggregated fields from the table: "daily_locations_counts" */
  daily_locations_counts_aggregate: IDaily_Locations_Counts_Aggregate;
  /** fetch data from the table: "display_obituary" */
  display_obituary: Array<IDisplay_Obituary>;
  /** fetch aggregated fields from the table: "display_obituary" */
  display_obituary_aggregate: IDisplay_Obituary_Aggregate;
  /** fetch data from the table: "display_obituary" using primary key columns */
  display_obituary_by_pk?: Maybe<IDisplay_Obituary>;
  /** fetch data from the table: "display_obituary_publication" */
  display_obituary_publication: Array<IDisplay_Obituary_Publication>;
  /** fetch aggregated fields from the table: "display_obituary_publication" */
  display_obituary_publication_aggregate: IDisplay_Obituary_Publication_Aggregate;
  /** fetch data from the table: "display_obituary_publication" using primary key columns */
  display_obituary_publication_by_pk?: Maybe<IDisplay_Obituary_Publication>;
  /** fetch data from the table: "display_obituary_tag" */
  display_obituary_tag: Array<IDisplay_Obituary_Tag>;
  /** fetch aggregated fields from the table: "display_obituary_tag" */
  display_obituary_tag_aggregate: IDisplay_Obituary_Tag_Aggregate;
  /** fetch data from the table: "display_obituary_tag" using primary key columns */
  display_obituary_tag_by_pk?: Maybe<IDisplay_Obituary_Tag>;
  /** fetch data from the table: "display_run_date" */
  display_run_date: Array<IDisplay_Run_Date>;
  /** fetch aggregated fields from the table: "display_run_date" */
  display_run_date_aggregate: IDisplay_Run_Date_Aggregate;
  /** fetch data from the table: "display_run_date" using primary key columns */
  display_run_date_by_pk?: Maybe<IDisplay_Run_Date>;
  /** fetch data from the table: "duplicate_locations" */
  duplicate_locations: Array<IDuplicate_Locations>;
  /** fetch aggregated fields from the table: "duplicate_locations" */
  duplicate_locations_aggregate: IDuplicate_Locations_Aggregate;
  /** An array relationship */
  events: Array<IEvents>;
  /** An aggregate relationship */
  events_aggregate: IEvents_Aggregate;
  /** fetch data from the table: "events" using primary key columns */
  events_by_pk?: Maybe<IEvents>;
  /** fetch data from the table in a streaming manner: "events" */
  events_stream: Array<IEvents>;
  /** fetch data from the table: "events_types" */
  events_types: Array<IEvents_Types>;
  /** fetch aggregated fields from the table: "events_types" */
  events_types_aggregate: IEvents_Types_Aggregate;
  /** fetch data from the table: "events_types" using primary key columns */
  events_types_by_pk?: Maybe<IEvents_Types>;
  /** fetch data from the table in a streaming manner: "events_types" */
  events_types_stream: Array<IEvents_Types>;
  /** fetch data from the table: "external_link" */
  external_link: Array<IExternal_Link>;
  /** fetch aggregated fields from the table: "external_link" */
  external_link_aggregate: IExternal_Link_Aggregate;
  /** fetch data from the table: "external_link" using primary key columns */
  external_link_by_pk?: Maybe<IExternal_Link>;
  /** fetch data from the table: "external_link_source" */
  external_link_source: Array<IExternal_Link_Source>;
  /** fetch aggregated fields from the table: "external_link_source" */
  external_link_source_aggregate: IExternal_Link_Source_Aggregate;
  /** fetch data from the table: "external_link_source" using primary key columns */
  external_link_source_by_pk?: Maybe<IExternal_Link_Source>;
  /** fetch data from the table: "external_link_type" */
  external_link_type: Array<IExternal_Link_Type>;
  /** fetch aggregated fields from the table: "external_link_type" */
  external_link_type_aggregate: IExternal_Link_Type_Aggregate;
  /** fetch data from the table: "external_link_type" using primary key columns */
  external_link_type_by_pk?: Maybe<IExternal_Link_Type>;
  /** fetch data from the table: "facebook_config" */
  facebook_config: Array<IFacebook_Config>;
  /** fetch aggregated fields from the table: "facebook_config" */
  facebook_config_aggregate: IFacebook_Config_Aggregate;
  /** fetch data from the table: "facebook_config" using primary key columns */
  facebook_config_by_pk?: Maybe<IFacebook_Config>;
  /** fetch data from the table: "from_sales_order" */
  from_sales_order: Array<IFrom_Sales_Order>;
  /** fetch aggregated fields from the table: "from_sales_order" */
  from_sales_order_aggregate: IFrom_Sales_Order_Aggregate;
  /** fetch data from the table: "from_sales_order" using primary key columns */
  from_sales_order_by_pk?: Maybe<IFrom_Sales_Order>;
  /** fetch data from the table: "from_sales_order_from_sales_publication" */
  from_sales_order_from_sales_publication: Array<IFrom_Sales_Order_From_Sales_Publication>;
  /** fetch aggregated fields from the table: "from_sales_order_from_sales_publication" */
  from_sales_order_from_sales_publication_aggregate: IFrom_Sales_Order_From_Sales_Publication_Aggregate;
  /** fetch data from the table: "from_sales_order_from_sales_publication" using primary key columns */
  from_sales_order_from_sales_publication_by_pk?: Maybe<IFrom_Sales_Order_From_Sales_Publication>;
  /** fetch data from the table: "from_sales_publication" */
  from_sales_publication: Array<IFrom_Sales_Publication>;
  /** fetch aggregated fields from the table: "from_sales_publication" */
  from_sales_publication_aggregate: IFrom_Sales_Publication_Aggregate;
  /** fetch data from the table: "from_sales_publication" using primary key columns */
  from_sales_publication_by_pk?: Maybe<IFrom_Sales_Publication>;
  /** fetch data from the table: "funeral_home" */
  funeral_home: Array<IFuneral_Home>;
  /** fetch aggregated fields from the table: "funeral_home" */
  funeral_home_aggregate: IFuneral_Home_Aggregate;
  /** fetch data from the table: "funeral_home" using primary key columns */
  funeral_home_by_pk?: Maybe<IFuneral_Home>;
  /** fetch data from the table: "funeral_home_external_id" */
  funeral_home_external_id: Array<IFuneral_Home_External_Id>;
  /** fetch aggregated fields from the table: "funeral_home_external_id" */
  funeral_home_external_id_aggregate: IFuneral_Home_External_Id_Aggregate;
  /** fetch data from the table: "funeral_home_external_id" using primary key columns */
  funeral_home_external_id_by_pk?: Maybe<IFuneral_Home_External_Id>;
  /** fetch data from the table: "funeral_home_external_user_id" */
  funeral_home_external_user_id: Array<IFuneral_Home_External_User_Id>;
  /** fetch aggregated fields from the table: "funeral_home_external_user_id" */
  funeral_home_external_user_id_aggregate: IFuneral_Home_External_User_Id_Aggregate;
  /** fetch data from the table: "funeral_home_external_user_id" using primary key columns */
  funeral_home_external_user_id_by_pk?: Maybe<IFuneral_Home_External_User_Id>;
  /** fetch data from the table: "historical_obituary" */
  historical_obituary: Array<IHistorical_Obituary>;
  /** fetch aggregated fields from the table: "historical_obituary" */
  historical_obituary_aggregate: IHistorical_Obituary_Aggregate;
  /** fetch data from the table: "historical_obituary" using primary key columns */
  historical_obituary_by_pk?: Maybe<IHistorical_Obituary>;
  /** fetch data from the table: "historical_obituary_event" */
  historical_obituary_event: Array<IHistorical_Obituary_Event>;
  /** fetch aggregated fields from the table: "historical_obituary_event" */
  historical_obituary_event_aggregate: IHistorical_Obituary_Event_Aggregate;
  /** fetch data from the table: "historical_obituary_event" using primary key columns */
  historical_obituary_event_by_pk?: Maybe<IHistorical_Obituary_Event>;
  /** fetch data from the table: "historical_obituary_publication" */
  historical_obituary_publication: Array<IHistorical_Obituary_Publication>;
  /** fetch aggregated fields from the table: "historical_obituary_publication" */
  historical_obituary_publication_aggregate: IHistorical_Obituary_Publication_Aggregate;
  /** fetch data from the table: "historical_obituary_publication" using primary key columns */
  historical_obituary_publication_by_pk?: Maybe<IHistorical_Obituary_Publication>;
  /** fetch data from the table: "historical_obituary_tag" */
  historical_obituary_tag: Array<IHistorical_Obituary_Tag>;
  /** fetch aggregated fields from the table: "historical_obituary_tag" */
  historical_obituary_tag_aggregate: IHistorical_Obituary_Tag_Aggregate;
  /** fetch data from the table: "historical_obituary_tag" using primary key columns */
  historical_obituary_tag_by_pk?: Maybe<IHistorical_Obituary_Tag>;
  /** fetch data from the table: "image" */
  image: Array<IImage>;
  /** fetch aggregated fields from the table: "image" */
  image_aggregate: IImage_Aggregate;
  /** fetch data from the table: "image" using primary key columns */
  image_by_pk?: Maybe<IImage>;
  /** fetch data from the table: "image_lookup" */
  image_lookup: Array<IImage_Lookup>;
  /** fetch aggregated fields from the table: "image_lookup" */
  image_lookup_aggregate: IImage_Lookup_Aggregate;
  /** fetch data from the table: "image_lookup" using primary key columns */
  image_lookup_by_pk?: Maybe<IImage_Lookup>;
  /** fetch data from the table: "image_order" */
  image_order: Array<IImage_Order>;
  /** fetch aggregated fields from the table: "image_order" */
  image_order_aggregate: IImage_Order_Aggregate;
  /** fetch data from the table: "image_order" using primary key columns */
  image_order_by_pk?: Maybe<IImage_Order>;
  /** fetch data from the table: "image_type" */
  image_type: Array<IImage_Type>;
  /** fetch aggregated fields from the table: "image_type" */
  image_type_aggregate: IImage_Type_Aggregate;
  /** fetch data from the table: "image_type" using primary key columns */
  image_type_by_pk?: Maybe<IImage_Type>;
  /** An array relationship */
  images: Array<IImages>;
  /** An aggregate relationship */
  images_aggregate: IImages_Aggregate;
  /** fetch data from the table: "images" using primary key columns */
  images_by_pk?: Maybe<IImages>;
  /** fetch data from the table in a streaming manner: "images" */
  images_stream: Array<IImages>;
  /** fetch data from the table: "latest_events" */
  latest_events: Array<ILatest_Events>;
  /** fetch aggregated fields from the table: "latest_events" */
  latest_events_aggregate: ILatest_Events_Aggregate;
  /** fetch data from the table in a streaming manner: "latest_events" */
  latest_events_stream: Array<ILatest_Events>;
  /** fetch data from the table: "legacycom_config" */
  legacycom_config: Array<ILegacycom_Config>;
  /** fetch aggregated fields from the table: "legacycom_config" */
  legacycom_config_aggregate: ILegacycom_Config_Aggregate;
  /** fetch data from the table: "legacycom_config" using primary key columns */
  legacycom_config_by_pk?: Maybe<ILegacycom_Config>;
  /** fetch data from the table: "life_story" */
  life_story: Array<ILife_Story>;
  /** fetch aggregated fields from the table: "life_story" */
  life_story_aggregate: ILife_Story_Aggregate;
  /** fetch data from the table: "life_story" using primary key columns */
  life_story_by_pk?: Maybe<ILife_Story>;
  /** fetch data from the table: "life_story_location" */
  life_story_location: Array<ILife_Story_Location>;
  /** fetch aggregated fields from the table: "life_story_location" */
  life_story_location_aggregate: ILife_Story_Location_Aggregate;
  /** fetch data from the table: "life_story_location" using primary key columns */
  life_story_location_by_pk?: Maybe<ILife_Story_Location>;
  /** fetch data from the table: "life_story_locations_types" */
  life_story_locations_types: Array<ILife_Story_Locations_Types>;
  /** fetch aggregated fields from the table: "life_story_locations_types" */
  life_story_locations_types_aggregate: ILife_Story_Locations_Types_Aggregate;
  /** fetch data from the table: "life_story_locations_types" using primary key columns */
  life_story_locations_types_by_pk?: Maybe<ILife_Story_Locations_Types>;
  /** fetch data from the table: "locations" */
  locations: Array<ILocations>;
  /** fetch aggregated fields from the table: "locations" */
  locations_aggregate: ILocations_Aggregate;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<ILocations>;
  /** fetch data from the table: "locations_publishers" */
  locations_publishers: Array<ILocations_Publishers>;
  /** fetch aggregated fields from the table: "locations_publishers" */
  locations_publishers_aggregate: ILocations_Publishers_Aggregate;
  /** An array relationship */
  memories: Array<IMemories>;
  /** An aggregate relationship */
  memories_aggregate: IMemories_Aggregate;
  /** fetch data from the table: "memories" using primary key columns */
  memories_by_pk?: Maybe<IMemories>;
  /** fetch data from the table in a streaming manner: "memories" */
  memories_stream: Array<IMemories>;
  /** fetch data from the table: "metadata_locations" */
  metadata_locations: Array<IMetadata_Locations>;
  /** fetch aggregated fields from the table: "metadata_locations" */
  metadata_locations_aggregate: IMetadata_Locations_Aggregate;
  /** fetch data from the table: "metadata_locations" using primary key columns */
  metadata_locations_by_pk?: Maybe<IMetadata_Locations>;
  /** fetch data from the table: "obits" */
  obits: Array<IObits>;
  /** fetch aggregated fields from the table: "obits" */
  obits_aggregate: IObits_Aggregate;
  /** fetch data from the table: "obits" using primary key columns */
  obits_by_pk?: Maybe<IObits>;
  /** fetch data from the table in a streaming manner: "obits" */
  obits_stream: Array<IObits>;
  /** fetch data from the table: "obituary" */
  obituary: Array<IObituary>;
  /** fetch aggregated fields from the table: "obituary" */
  obituary_aggregate: IObituary_Aggregate;
  /** fetch data from the table: "obituary" using primary key columns */
  obituary_by_pk?: Maybe<IObituary>;
  /** fetch data from the table: "obituary_publication" */
  obituary_publication: Array<IObituary_Publication>;
  /** fetch aggregated fields from the table: "obituary_publication" */
  obituary_publication_aggregate: IObituary_Publication_Aggregate;
  /** fetch data from the table: "obituary_publication" using primary key columns */
  obituary_publication_by_pk?: Maybe<IObituary_Publication>;
  /** fetch data from the table: "obituary_status" */
  obituary_status: Array<IObituary_Status>;
  /** fetch aggregated fields from the table: "obituary_status" */
  obituary_status_aggregate: IObituary_Status_Aggregate;
  /** fetch data from the table: "obituary_status" using primary key columns */
  obituary_status_by_pk?: Maybe<IObituary_Status>;
  /** fetch data from the table: "obituary_tag" */
  obituary_tag: Array<IObituary_Tag>;
  /** fetch aggregated fields from the table: "obituary_tag" */
  obituary_tag_aggregate: IObituary_Tag_Aggregate;
  /** fetch data from the table: "obituary_tag" using primary key columns */
  obituary_tag_by_pk?: Maybe<IObituary_Tag>;
  /** fetch data from the table: "payment_methods" */
  payment_methods: Array<IPayment_Methods>;
  /** fetch aggregated fields from the table: "payment_methods" */
  payment_methods_aggregate: IPayment_Methods_Aggregate;
  /** fetch data from the table: "phone_numbers" */
  phone_numbers: Array<IPhone_Numbers>;
  /** fetch aggregated fields from the table: "phone_numbers" */
  phone_numbers_aggregate: IPhone_Numbers_Aggregate;
  /** fetch data from the table: "possible_matches" */
  possible_matches: Array<IPossible_Matches>;
  /** fetch aggregated fields from the table: "possible_matches" */
  possible_matches_aggregate: IPossible_Matches_Aggregate;
  /** fetch data from the table: "publication" */
  publication: Array<IPublication>;
  /** fetch aggregated fields from the table: "publication" */
  publication_aggregate: IPublication_Aggregate;
  /** fetch data from the table: "publication" using primary key columns */
  publication_by_pk?: Maybe<IPublication>;
  /** An array relationship */
  publication_info: Array<IPublication_Info>;
  /** An aggregate relationship */
  publication_info_aggregate: IPublication_Info_Aggregate;
  /** fetch data from the table: "publication_info" using primary key columns */
  publication_info_by_pk?: Maybe<IPublication_Info>;
  /** fetch data from the table: "publisher_phone_numbers" */
  publisher_phone_numbers: Array<IPublisher_Phone_Numbers>;
  /** fetch aggregated fields from the table: "publisher_phone_numbers" */
  publisher_phone_numbers_aggregate: IPublisher_Phone_Numbers_Aggregate;
  /** fetch data from the table: "publishers" */
  publishers: Array<IPublishers>;
  /** fetch aggregated fields from the table: "publishers" */
  publishers_aggregate: IPublishers_Aggregate;
  /** fetch data from the table: "raw_data" */
  raw_data: Array<IRaw_Data>;
  /** fetch aggregated fields from the table: "raw_data" */
  raw_data_aggregate: IRaw_Data_Aggregate;
  /** fetch data from the table: "raw_data" using primary key columns */
  raw_data_by_pk?: Maybe<IRaw_Data>;
  /** fetch data from the table: "reviews" */
  reviews: Array<IReviews>;
  /** fetch aggregated fields from the table: "reviews" */
  reviews_aggregate: IReviews_Aggregate;
  /** execute function "search_locations" which returns "locations" */
  search_locations: Array<ILocations>;
  /** execute function "search_locations" and query aggregates on result of table type "locations" */
  search_locations_aggregate: ILocations_Aggregate;
  /** fetch data from the table: "service" */
  service: Array<IService>;
  /** fetch aggregated fields from the table: "service" */
  service_aggregate: IService_Aggregate;
  /** fetch data from the table: "service" using primary key columns */
  service_by_pk?: Maybe<IService>;
  /** fetch data from the table: "service_duration" */
  service_duration: Array<IService_Duration>;
  /** fetch aggregated fields from the table: "service_duration" */
  service_duration_aggregate: IService_Duration_Aggregate;
  /** fetch data from the table: "service_duration" using primary key columns */
  service_duration_by_pk?: Maybe<IService_Duration>;
  /** fetch data from the table: "service_duration_type" */
  service_duration_type: Array<IService_Duration_Type>;
  /** fetch aggregated fields from the table: "service_duration_type" */
  service_duration_type_aggregate: IService_Duration_Type_Aggregate;
  /** fetch data from the table: "service_duration_type" using primary key columns */
  service_duration_type_by_pk?: Maybe<IService_Duration_Type>;
  /** fetch data from the table: "service_offers" */
  service_offers: Array<IService_Offers>;
  /** fetch aggregated fields from the table: "service_offers" */
  service_offers_aggregate: IService_Offers_Aggregate;
  /** fetch data from the table: "tag" */
  tag: Array<ITag>;
  /** fetch aggregated fields from the table: "tag" */
  tag_aggregate: ITag_Aggregate;
  /** fetch data from the table: "tag" using primary key columns */
  tag_by_pk?: Maybe<ITag>;
  /** fetch data from the table: "topics" */
  topics: Array<ITopics>;
  /** fetch aggregated fields from the table: "topics" */
  topics_aggregate: ITopics_Aggregate;
  /** fetch data from the table: "topics" using primary key columns */
  topics_by_pk?: Maybe<ITopics>;
  /** fetch data from the table in a streaming manner: "topics" */
  topics_stream: Array<ITopics>;
  /** fetch data from the table: "urls" */
  urls: Array<IUrls>;
  /** fetch aggregated fields from the table: "urls" */
  urls_aggregate: IUrls_Aggregate;
  /** fetch data from the table: "users" */
  users: Array<IUsers>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: IUsers_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<IUsers>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<IUsers>;
  /** fetch data from the table: "verticals" */
  verticals: Array<IVerticals>;
  /** fetch aggregated fields from the table: "verticals" */
  verticals_aggregate: IVerticals_Aggregate;
  /** fetch data from the table: "web_config" */
  web_config: Array<IWeb_Config>;
  /** fetch aggregated fields from the table: "web_config" */
  web_config_aggregate: IWeb_Config_Aggregate;
  /** fetch data from the table: "web_config" using primary key columns */
  web_config_by_pk?: Maybe<IWeb_Config>;
  /** fetch data from the table: "web_config_filter" */
  web_config_filter: Array<IWeb_Config_Filter>;
  /** fetch aggregated fields from the table: "web_config_filter" */
  web_config_filter_aggregate: IWeb_Config_Filter_Aggregate;
  /** fetch data from the table: "web_config_filter" using primary key columns */
  web_config_filter_by_pk?: Maybe<IWeb_Config_Filter>;
  /** An array relationship */
  web_config_filter_tags: Array<IWeb_Config_Filter_Tags>;
  /** An aggregate relationship */
  web_config_filter_tags_aggregate: IWeb_Config_Filter_Tags_Aggregate;
  /** fetch data from the table: "web_config_filter_tags" using primary key columns */
  web_config_filter_tags_by_pk?: Maybe<IWeb_Config_Filter_Tags>;
};


/** subscription root */
export type ISubscription_RootArchived_ObituaryArgs = {
  distinct_on?: Maybe<Array<IArchived_Obituary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IArchived_Obituary_Order_By>>;
  where?: Maybe<IArchived_Obituary_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootArchived_Obituary_AggregateArgs = {
  distinct_on?: Maybe<Array<IArchived_Obituary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IArchived_Obituary_Order_By>>;
  where?: Maybe<IArchived_Obituary_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootArchived_Obituary_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootCapi_ConfigArgs = {
  distinct_on?: Maybe<Array<ICapi_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICapi_Config_Order_By>>;
  where?: Maybe<ICapi_Config_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootCapi_Config_AggregateArgs = {
  distinct_on?: Maybe<Array<ICapi_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICapi_Config_Order_By>>;
  where?: Maybe<ICapi_Config_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootCapi_Config_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootCategoriesArgs = {
  distinct_on?: Maybe<Array<ICategories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategories_Order_By>>;
  where?: Maybe<ICategories_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootCategories_AggregateArgs = {
  distinct_on?: Maybe<Array<ICategories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategories_Order_By>>;
  where?: Maybe<ICategories_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootCategories_LocationsArgs = {
  distinct_on?: Maybe<Array<ICategories_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategories_Locations_Order_By>>;
  where?: Maybe<ICategories_Locations_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootCategories_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<ICategories_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategories_Locations_Order_By>>;
  where?: Maybe<ICategories_Locations_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootCategories_VerticalsArgs = {
  distinct_on?: Maybe<Array<ICategories_Verticals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategories_Verticals_Order_By>>;
  where?: Maybe<ICategories_Verticals_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootCategories_Verticals_AggregateArgs = {
  distinct_on?: Maybe<Array<ICategories_Verticals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategories_Verticals_Order_By>>;
  where?: Maybe<ICategories_Verticals_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootCategory_Image_UrlsArgs = {
  distinct_on?: Maybe<Array<ICategory_Image_Urls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategory_Image_Urls_Order_By>>;
  where?: Maybe<ICategory_Image_Urls_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootCategory_Image_Urls_AggregateArgs = {
  distinct_on?: Maybe<Array<ICategory_Image_Urls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategory_Image_Urls_Order_By>>;
  where?: Maybe<ICategory_Image_Urls_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootCategory_KeywordsArgs = {
  distinct_on?: Maybe<Array<ICategory_Keywords_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategory_Keywords_Order_By>>;
  where?: Maybe<ICategory_Keywords_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootCategory_Keywords_AggregateArgs = {
  distinct_on?: Maybe<Array<ICategory_Keywords_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategory_Keywords_Order_By>>;
  where?: Maybe<ICategory_Keywords_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootCoupon_AdasArgs = {
  distinct_on?: Maybe<Array<ICoupon_Adas_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICoupon_Adas_Order_By>>;
  where?: Maybe<ICoupon_Adas_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootCoupon_Adas_AggregateArgs = {
  distinct_on?: Maybe<Array<ICoupon_Adas_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICoupon_Adas_Order_By>>;
  where?: Maybe<ICoupon_Adas_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootDaily_Locations_CountsArgs = {
  distinct_on?: Maybe<Array<IDaily_Locations_Counts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDaily_Locations_Counts_Order_By>>;
  where?: Maybe<IDaily_Locations_Counts_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootDaily_Locations_Counts_AggregateArgs = {
  distinct_on?: Maybe<Array<IDaily_Locations_Counts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDaily_Locations_Counts_Order_By>>;
  where?: Maybe<IDaily_Locations_Counts_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootDisplay_ObituaryArgs = {
  distinct_on?: Maybe<Array<IDisplay_Obituary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDisplay_Obituary_Order_By>>;
  where?: Maybe<IDisplay_Obituary_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootDisplay_Obituary_AggregateArgs = {
  distinct_on?: Maybe<Array<IDisplay_Obituary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDisplay_Obituary_Order_By>>;
  where?: Maybe<IDisplay_Obituary_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootDisplay_Obituary_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootDisplay_Obituary_PublicationArgs = {
  distinct_on?: Maybe<Array<IDisplay_Obituary_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDisplay_Obituary_Publication_Order_By>>;
  where?: Maybe<IDisplay_Obituary_Publication_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootDisplay_Obituary_Publication_AggregateArgs = {
  distinct_on?: Maybe<Array<IDisplay_Obituary_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDisplay_Obituary_Publication_Order_By>>;
  where?: Maybe<IDisplay_Obituary_Publication_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootDisplay_Obituary_Publication_By_PkArgs = {
  display_obituary_id: Scalars['uuid'];
  publication_id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootDisplay_Obituary_TagArgs = {
  distinct_on?: Maybe<Array<IDisplay_Obituary_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDisplay_Obituary_Tag_Order_By>>;
  where?: Maybe<IDisplay_Obituary_Tag_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootDisplay_Obituary_Tag_AggregateArgs = {
  distinct_on?: Maybe<Array<IDisplay_Obituary_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDisplay_Obituary_Tag_Order_By>>;
  where?: Maybe<IDisplay_Obituary_Tag_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootDisplay_Obituary_Tag_By_PkArgs = {
  display_obituary_id: Scalars['uuid'];
  tag_id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootDisplay_Run_DateArgs = {
  distinct_on?: Maybe<Array<IDisplay_Run_Date_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDisplay_Run_Date_Order_By>>;
  where?: Maybe<IDisplay_Run_Date_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootDisplay_Run_Date_AggregateArgs = {
  distinct_on?: Maybe<Array<IDisplay_Run_Date_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDisplay_Run_Date_Order_By>>;
  where?: Maybe<IDisplay_Run_Date_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootDisplay_Run_Date_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootDuplicate_LocationsArgs = {
  distinct_on?: Maybe<Array<IDuplicate_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDuplicate_Locations_Order_By>>;
  where?: Maybe<IDuplicate_Locations_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootDuplicate_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<IDuplicate_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDuplicate_Locations_Order_By>>;
  where?: Maybe<IDuplicate_Locations_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootEventsArgs = {
  distinct_on?: Maybe<Array<IEvents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IEvents_Order_By>>;
  where?: Maybe<IEvents_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootEvents_AggregateArgs = {
  distinct_on?: Maybe<Array<IEvents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IEvents_Order_By>>;
  where?: Maybe<IEvents_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootEvents_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootEvents_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IEvents_Stream_Cursor_Input>>;
  where?: Maybe<IEvents_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootEvents_TypesArgs = {
  distinct_on?: Maybe<Array<IEvents_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IEvents_Types_Order_By>>;
  where?: Maybe<IEvents_Types_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootEvents_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<IEvents_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IEvents_Types_Order_By>>;
  where?: Maybe<IEvents_Types_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootEvents_Types_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type ISubscription_RootEvents_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IEvents_Types_Stream_Cursor_Input>>;
  where?: Maybe<IEvents_Types_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootExternal_LinkArgs = {
  distinct_on?: Maybe<Array<IExternal_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExternal_Link_Order_By>>;
  where?: Maybe<IExternal_Link_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootExternal_Link_AggregateArgs = {
  distinct_on?: Maybe<Array<IExternal_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExternal_Link_Order_By>>;
  where?: Maybe<IExternal_Link_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootExternal_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootExternal_Link_SourceArgs = {
  distinct_on?: Maybe<Array<IExternal_Link_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExternal_Link_Source_Order_By>>;
  where?: Maybe<IExternal_Link_Source_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootExternal_Link_Source_AggregateArgs = {
  distinct_on?: Maybe<Array<IExternal_Link_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExternal_Link_Source_Order_By>>;
  where?: Maybe<IExternal_Link_Source_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootExternal_Link_Source_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type ISubscription_RootExternal_Link_TypeArgs = {
  distinct_on?: Maybe<Array<IExternal_Link_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExternal_Link_Type_Order_By>>;
  where?: Maybe<IExternal_Link_Type_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootExternal_Link_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<IExternal_Link_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExternal_Link_Type_Order_By>>;
  where?: Maybe<IExternal_Link_Type_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootExternal_Link_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type ISubscription_RootFacebook_ConfigArgs = {
  distinct_on?: Maybe<Array<IFacebook_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFacebook_Config_Order_By>>;
  where?: Maybe<IFacebook_Config_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootFacebook_Config_AggregateArgs = {
  distinct_on?: Maybe<Array<IFacebook_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFacebook_Config_Order_By>>;
  where?: Maybe<IFacebook_Config_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootFacebook_Config_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootFrom_Sales_OrderArgs = {
  distinct_on?: Maybe<Array<IFrom_Sales_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFrom_Sales_Order_Order_By>>;
  where?: Maybe<IFrom_Sales_Order_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootFrom_Sales_Order_AggregateArgs = {
  distinct_on?: Maybe<Array<IFrom_Sales_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFrom_Sales_Order_Order_By>>;
  where?: Maybe<IFrom_Sales_Order_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootFrom_Sales_Order_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootFrom_Sales_Order_From_Sales_PublicationArgs = {
  distinct_on?: Maybe<Array<IFrom_Sales_Order_From_Sales_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFrom_Sales_Order_From_Sales_Publication_Order_By>>;
  where?: Maybe<IFrom_Sales_Order_From_Sales_Publication_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootFrom_Sales_Order_From_Sales_Publication_AggregateArgs = {
  distinct_on?: Maybe<Array<IFrom_Sales_Order_From_Sales_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFrom_Sales_Order_From_Sales_Publication_Order_By>>;
  where?: Maybe<IFrom_Sales_Order_From_Sales_Publication_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootFrom_Sales_Order_From_Sales_Publication_By_PkArgs = {
  order_id: Scalars['uuid'];
  publication_id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootFrom_Sales_PublicationArgs = {
  distinct_on?: Maybe<Array<IFrom_Sales_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFrom_Sales_Publication_Order_By>>;
  where?: Maybe<IFrom_Sales_Publication_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootFrom_Sales_Publication_AggregateArgs = {
  distinct_on?: Maybe<Array<IFrom_Sales_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFrom_Sales_Publication_Order_By>>;
  where?: Maybe<IFrom_Sales_Publication_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootFrom_Sales_Publication_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootFuneral_HomeArgs = {
  distinct_on?: Maybe<Array<IFuneral_Home_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFuneral_Home_Order_By>>;
  where?: Maybe<IFuneral_Home_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootFuneral_Home_AggregateArgs = {
  distinct_on?: Maybe<Array<IFuneral_Home_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFuneral_Home_Order_By>>;
  where?: Maybe<IFuneral_Home_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootFuneral_Home_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootFuneral_Home_External_IdArgs = {
  distinct_on?: Maybe<Array<IFuneral_Home_External_Id_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFuneral_Home_External_Id_Order_By>>;
  where?: Maybe<IFuneral_Home_External_Id_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootFuneral_Home_External_Id_AggregateArgs = {
  distinct_on?: Maybe<Array<IFuneral_Home_External_Id_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFuneral_Home_External_Id_Order_By>>;
  where?: Maybe<IFuneral_Home_External_Id_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootFuneral_Home_External_Id_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootFuneral_Home_External_User_IdArgs = {
  distinct_on?: Maybe<Array<IFuneral_Home_External_User_Id_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFuneral_Home_External_User_Id_Order_By>>;
  where?: Maybe<IFuneral_Home_External_User_Id_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootFuneral_Home_External_User_Id_AggregateArgs = {
  distinct_on?: Maybe<Array<IFuneral_Home_External_User_Id_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFuneral_Home_External_User_Id_Order_By>>;
  where?: Maybe<IFuneral_Home_External_User_Id_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootFuneral_Home_External_User_Id_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootHistorical_ObituaryArgs = {
  distinct_on?: Maybe<Array<IHistorical_Obituary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHistorical_Obituary_Order_By>>;
  where?: Maybe<IHistorical_Obituary_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootHistorical_Obituary_AggregateArgs = {
  distinct_on?: Maybe<Array<IHistorical_Obituary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHistorical_Obituary_Order_By>>;
  where?: Maybe<IHistorical_Obituary_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootHistorical_Obituary_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootHistorical_Obituary_EventArgs = {
  distinct_on?: Maybe<Array<IHistorical_Obituary_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHistorical_Obituary_Event_Order_By>>;
  where?: Maybe<IHistorical_Obituary_Event_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootHistorical_Obituary_Event_AggregateArgs = {
  distinct_on?: Maybe<Array<IHistorical_Obituary_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHistorical_Obituary_Event_Order_By>>;
  where?: Maybe<IHistorical_Obituary_Event_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootHistorical_Obituary_Event_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootHistorical_Obituary_PublicationArgs = {
  distinct_on?: Maybe<Array<IHistorical_Obituary_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHistorical_Obituary_Publication_Order_By>>;
  where?: Maybe<IHistorical_Obituary_Publication_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootHistorical_Obituary_Publication_AggregateArgs = {
  distinct_on?: Maybe<Array<IHistorical_Obituary_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHistorical_Obituary_Publication_Order_By>>;
  where?: Maybe<IHistorical_Obituary_Publication_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootHistorical_Obituary_Publication_By_PkArgs = {
  historial_obituary_id: Scalars['uuid'];
  publication_id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootHistorical_Obituary_TagArgs = {
  distinct_on?: Maybe<Array<IHistorical_Obituary_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHistorical_Obituary_Tag_Order_By>>;
  where?: Maybe<IHistorical_Obituary_Tag_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootHistorical_Obituary_Tag_AggregateArgs = {
  distinct_on?: Maybe<Array<IHistorical_Obituary_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHistorical_Obituary_Tag_Order_By>>;
  where?: Maybe<IHistorical_Obituary_Tag_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootHistorical_Obituary_Tag_By_PkArgs = {
  historical_obituary_id: Scalars['uuid'];
  tag_id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootImageArgs = {
  distinct_on?: Maybe<Array<IImage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IImage_Order_By>>;
  where?: Maybe<IImage_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootImage_AggregateArgs = {
  distinct_on?: Maybe<Array<IImage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IImage_Order_By>>;
  where?: Maybe<IImage_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootImage_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootImage_LookupArgs = {
  distinct_on?: Maybe<Array<IImage_Lookup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IImage_Lookup_Order_By>>;
  where?: Maybe<IImage_Lookup_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootImage_Lookup_AggregateArgs = {
  distinct_on?: Maybe<Array<IImage_Lookup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IImage_Lookup_Order_By>>;
  where?: Maybe<IImage_Lookup_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootImage_Lookup_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootImage_OrderArgs = {
  distinct_on?: Maybe<Array<IImage_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IImage_Order_Order_By>>;
  where?: Maybe<IImage_Order_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootImage_Order_AggregateArgs = {
  distinct_on?: Maybe<Array<IImage_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IImage_Order_Order_By>>;
  where?: Maybe<IImage_Order_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootImage_Order_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootImage_TypeArgs = {
  distinct_on?: Maybe<Array<IImage_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IImage_Type_Order_By>>;
  where?: Maybe<IImage_Type_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootImage_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<IImage_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IImage_Type_Order_By>>;
  where?: Maybe<IImage_Type_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootImage_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type ISubscription_RootImagesArgs = {
  distinct_on?: Maybe<Array<IImages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IImages_Order_By>>;
  where?: Maybe<IImages_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootImages_AggregateArgs = {
  distinct_on?: Maybe<Array<IImages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IImages_Order_By>>;
  where?: Maybe<IImages_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootImages_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootImages_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IImages_Stream_Cursor_Input>>;
  where?: Maybe<IImages_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootLatest_EventsArgs = {
  distinct_on?: Maybe<Array<ILatest_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILatest_Events_Order_By>>;
  where?: Maybe<ILatest_Events_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootLatest_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<ILatest_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILatest_Events_Order_By>>;
  where?: Maybe<ILatest_Events_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootLatest_Events_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ILatest_Events_Stream_Cursor_Input>>;
  where?: Maybe<ILatest_Events_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootLegacycom_ConfigArgs = {
  distinct_on?: Maybe<Array<ILegacycom_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILegacycom_Config_Order_By>>;
  where?: Maybe<ILegacycom_Config_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootLegacycom_Config_AggregateArgs = {
  distinct_on?: Maybe<Array<ILegacycom_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILegacycom_Config_Order_By>>;
  where?: Maybe<ILegacycom_Config_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootLegacycom_Config_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootLife_StoryArgs = {
  distinct_on?: Maybe<Array<ILife_Story_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILife_Story_Order_By>>;
  where?: Maybe<ILife_Story_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootLife_Story_AggregateArgs = {
  distinct_on?: Maybe<Array<ILife_Story_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILife_Story_Order_By>>;
  where?: Maybe<ILife_Story_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootLife_Story_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootLife_Story_LocationArgs = {
  distinct_on?: Maybe<Array<ILife_Story_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILife_Story_Location_Order_By>>;
  where?: Maybe<ILife_Story_Location_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootLife_Story_Location_AggregateArgs = {
  distinct_on?: Maybe<Array<ILife_Story_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILife_Story_Location_Order_By>>;
  where?: Maybe<ILife_Story_Location_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootLife_Story_Location_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootLife_Story_Locations_TypesArgs = {
  distinct_on?: Maybe<Array<ILife_Story_Locations_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILife_Story_Locations_Types_Order_By>>;
  where?: Maybe<ILife_Story_Locations_Types_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootLife_Story_Locations_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<ILife_Story_Locations_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILife_Story_Locations_Types_Order_By>>;
  where?: Maybe<ILife_Story_Locations_Types_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootLife_Story_Locations_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type ISubscription_RootLocationsArgs = {
  distinct_on?: Maybe<Array<ILocations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILocations_Order_By>>;
  where?: Maybe<ILocations_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootLocations_AggregateArgs = {
  distinct_on?: Maybe<Array<ILocations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILocations_Order_By>>;
  where?: Maybe<ILocations_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootLocations_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type ISubscription_RootLocations_PublishersArgs = {
  distinct_on?: Maybe<Array<ILocations_Publishers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILocations_Publishers_Order_By>>;
  where?: Maybe<ILocations_Publishers_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootLocations_Publishers_AggregateArgs = {
  distinct_on?: Maybe<Array<ILocations_Publishers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILocations_Publishers_Order_By>>;
  where?: Maybe<ILocations_Publishers_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootMemoriesArgs = {
  distinct_on?: Maybe<Array<IMemories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMemories_Order_By>>;
  where?: Maybe<IMemories_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootMemories_AggregateArgs = {
  distinct_on?: Maybe<Array<IMemories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMemories_Order_By>>;
  where?: Maybe<IMemories_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootMemories_By_PkArgs = {
  id: Scalars['uuid'];
  obit_id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootMemories_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IMemories_Stream_Cursor_Input>>;
  where?: Maybe<IMemories_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootMetadata_LocationsArgs = {
  distinct_on?: Maybe<Array<IMetadata_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMetadata_Locations_Order_By>>;
  where?: Maybe<IMetadata_Locations_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootMetadata_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<IMetadata_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMetadata_Locations_Order_By>>;
  where?: Maybe<IMetadata_Locations_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootMetadata_Locations_By_PkArgs = {
  location_id: Scalars['bigint'];
  name: Scalars['String'];
  value: Scalars['String'];
};


/** subscription root */
export type ISubscription_RootObitsArgs = {
  distinct_on?: Maybe<Array<IObits_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IObits_Order_By>>;
  where?: Maybe<IObits_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootObits_AggregateArgs = {
  distinct_on?: Maybe<Array<IObits_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IObits_Order_By>>;
  where?: Maybe<IObits_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootObits_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootObits_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IObits_Stream_Cursor_Input>>;
  where?: Maybe<IObits_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootObituaryArgs = {
  distinct_on?: Maybe<Array<IObituary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IObituary_Order_By>>;
  where?: Maybe<IObituary_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootObituary_AggregateArgs = {
  distinct_on?: Maybe<Array<IObituary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IObituary_Order_By>>;
  where?: Maybe<IObituary_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootObituary_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootObituary_PublicationArgs = {
  distinct_on?: Maybe<Array<IObituary_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IObituary_Publication_Order_By>>;
  where?: Maybe<IObituary_Publication_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootObituary_Publication_AggregateArgs = {
  distinct_on?: Maybe<Array<IObituary_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IObituary_Publication_Order_By>>;
  where?: Maybe<IObituary_Publication_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootObituary_Publication_By_PkArgs = {
  obituary_id: Scalars['uuid'];
  publication_id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootObituary_StatusArgs = {
  distinct_on?: Maybe<Array<IObituary_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IObituary_Status_Order_By>>;
  where?: Maybe<IObituary_Status_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootObituary_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<IObituary_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IObituary_Status_Order_By>>;
  where?: Maybe<IObituary_Status_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootObituary_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type ISubscription_RootObituary_TagArgs = {
  distinct_on?: Maybe<Array<IObituary_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IObituary_Tag_Order_By>>;
  where?: Maybe<IObituary_Tag_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootObituary_Tag_AggregateArgs = {
  distinct_on?: Maybe<Array<IObituary_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IObituary_Tag_Order_By>>;
  where?: Maybe<IObituary_Tag_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootObituary_Tag_By_PkArgs = {
  obituary_id: Scalars['uuid'];
  tag_id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootPayment_MethodsArgs = {
  distinct_on?: Maybe<Array<IPayment_Methods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPayment_Methods_Order_By>>;
  where?: Maybe<IPayment_Methods_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootPayment_Methods_AggregateArgs = {
  distinct_on?: Maybe<Array<IPayment_Methods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPayment_Methods_Order_By>>;
  where?: Maybe<IPayment_Methods_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootPhone_NumbersArgs = {
  distinct_on?: Maybe<Array<IPhone_Numbers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPhone_Numbers_Order_By>>;
  where?: Maybe<IPhone_Numbers_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootPhone_Numbers_AggregateArgs = {
  distinct_on?: Maybe<Array<IPhone_Numbers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPhone_Numbers_Order_By>>;
  where?: Maybe<IPhone_Numbers_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootPossible_MatchesArgs = {
  distinct_on?: Maybe<Array<IPossible_Matches_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPossible_Matches_Order_By>>;
  where?: Maybe<IPossible_Matches_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootPossible_Matches_AggregateArgs = {
  distinct_on?: Maybe<Array<IPossible_Matches_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPossible_Matches_Order_By>>;
  where?: Maybe<IPossible_Matches_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootPublicationArgs = {
  distinct_on?: Maybe<Array<IPublication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPublication_Order_By>>;
  where?: Maybe<IPublication_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootPublication_AggregateArgs = {
  distinct_on?: Maybe<Array<IPublication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPublication_Order_By>>;
  where?: Maybe<IPublication_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootPublication_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootPublication_InfoArgs = {
  distinct_on?: Maybe<Array<IPublication_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPublication_Info_Order_By>>;
  where?: Maybe<IPublication_Info_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootPublication_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<IPublication_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPublication_Info_Order_By>>;
  where?: Maybe<IPublication_Info_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootPublication_Info_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootPublisher_Phone_NumbersArgs = {
  distinct_on?: Maybe<Array<IPublisher_Phone_Numbers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPublisher_Phone_Numbers_Order_By>>;
  where?: Maybe<IPublisher_Phone_Numbers_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootPublisher_Phone_Numbers_AggregateArgs = {
  distinct_on?: Maybe<Array<IPublisher_Phone_Numbers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPublisher_Phone_Numbers_Order_By>>;
  where?: Maybe<IPublisher_Phone_Numbers_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootPublishersArgs = {
  distinct_on?: Maybe<Array<IPublishers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPublishers_Order_By>>;
  where?: Maybe<IPublishers_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootPublishers_AggregateArgs = {
  distinct_on?: Maybe<Array<IPublishers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IPublishers_Order_By>>;
  where?: Maybe<IPublishers_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootRaw_DataArgs = {
  distinct_on?: Maybe<Array<IRaw_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IRaw_Data_Order_By>>;
  where?: Maybe<IRaw_Data_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootRaw_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<IRaw_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IRaw_Data_Order_By>>;
  where?: Maybe<IRaw_Data_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootRaw_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootReviewsArgs = {
  distinct_on?: Maybe<Array<IReviews_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IReviews_Order_By>>;
  where?: Maybe<IReviews_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootReviews_AggregateArgs = {
  distinct_on?: Maybe<Array<IReviews_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IReviews_Order_By>>;
  where?: Maybe<IReviews_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootSearch_LocationsArgs = {
  args: ISearch_Locations_Args;
  distinct_on?: Maybe<Array<ILocations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILocations_Order_By>>;
  where?: Maybe<ILocations_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootSearch_Locations_AggregateArgs = {
  args: ISearch_Locations_Args;
  distinct_on?: Maybe<Array<ILocations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ILocations_Order_By>>;
  where?: Maybe<ILocations_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootServiceArgs = {
  distinct_on?: Maybe<Array<IService_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IService_Order_By>>;
  where?: Maybe<IService_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootService_AggregateArgs = {
  distinct_on?: Maybe<Array<IService_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IService_Order_By>>;
  where?: Maybe<IService_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootService_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootService_DurationArgs = {
  distinct_on?: Maybe<Array<IService_Duration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IService_Duration_Order_By>>;
  where?: Maybe<IService_Duration_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootService_Duration_AggregateArgs = {
  distinct_on?: Maybe<Array<IService_Duration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IService_Duration_Order_By>>;
  where?: Maybe<IService_Duration_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootService_Duration_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootService_Duration_TypeArgs = {
  distinct_on?: Maybe<Array<IService_Duration_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IService_Duration_Type_Order_By>>;
  where?: Maybe<IService_Duration_Type_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootService_Duration_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<IService_Duration_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IService_Duration_Type_Order_By>>;
  where?: Maybe<IService_Duration_Type_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootService_Duration_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type ISubscription_RootService_OffersArgs = {
  distinct_on?: Maybe<Array<IService_Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IService_Offers_Order_By>>;
  where?: Maybe<IService_Offers_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootService_Offers_AggregateArgs = {
  distinct_on?: Maybe<Array<IService_Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IService_Offers_Order_By>>;
  where?: Maybe<IService_Offers_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootTagArgs = {
  distinct_on?: Maybe<Array<ITag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ITag_Order_By>>;
  where?: Maybe<ITag_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootTag_AggregateArgs = {
  distinct_on?: Maybe<Array<ITag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ITag_Order_By>>;
  where?: Maybe<ITag_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootTag_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootTopicsArgs = {
  distinct_on?: Maybe<Array<ITopics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ITopics_Order_By>>;
  where?: Maybe<ITopics_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootTopics_AggregateArgs = {
  distinct_on?: Maybe<Array<ITopics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ITopics_Order_By>>;
  where?: Maybe<ITopics_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootTopics_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type ISubscription_RootTopics_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ITopics_Stream_Cursor_Input>>;
  where?: Maybe<ITopics_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootUrlsArgs = {
  distinct_on?: Maybe<Array<IUrls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IUrls_Order_By>>;
  where?: Maybe<IUrls_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootUrls_AggregateArgs = {
  distinct_on?: Maybe<Array<IUrls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IUrls_Order_By>>;
  where?: Maybe<IUrls_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<IUsers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IUsers_Order_By>>;
  where?: Maybe<IUsers_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<IUsers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IUsers_Order_By>>;
  where?: Maybe<IUsers_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootUsers_By_PkArgs = {
  id: Scalars['String'];
};


/** subscription root */
export type ISubscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IUsers_Stream_Cursor_Input>>;
  where?: Maybe<IUsers_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootVerticalsArgs = {
  distinct_on?: Maybe<Array<IVerticals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IVerticals_Order_By>>;
  where?: Maybe<IVerticals_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootVerticals_AggregateArgs = {
  distinct_on?: Maybe<Array<IVerticals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IVerticals_Order_By>>;
  where?: Maybe<IVerticals_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootWeb_ConfigArgs = {
  distinct_on?: Maybe<Array<IWeb_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWeb_Config_Order_By>>;
  where?: Maybe<IWeb_Config_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootWeb_Config_AggregateArgs = {
  distinct_on?: Maybe<Array<IWeb_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWeb_Config_Order_By>>;
  where?: Maybe<IWeb_Config_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootWeb_Config_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootWeb_Config_FilterArgs = {
  distinct_on?: Maybe<Array<IWeb_Config_Filter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWeb_Config_Filter_Order_By>>;
  where?: Maybe<IWeb_Config_Filter_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootWeb_Config_Filter_AggregateArgs = {
  distinct_on?: Maybe<Array<IWeb_Config_Filter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWeb_Config_Filter_Order_By>>;
  where?: Maybe<IWeb_Config_Filter_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootWeb_Config_Filter_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type ISubscription_RootWeb_Config_Filter_TagsArgs = {
  distinct_on?: Maybe<Array<IWeb_Config_Filter_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWeb_Config_Filter_Tags_Order_By>>;
  where?: Maybe<IWeb_Config_Filter_Tags_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootWeb_Config_Filter_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<IWeb_Config_Filter_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWeb_Config_Filter_Tags_Order_By>>;
  where?: Maybe<IWeb_Config_Filter_Tags_Bool_Exp>;
};


/** subscription root */
export type ISubscription_RootWeb_Config_Filter_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "tag" */
export type ITag = {
  __typename?: 'tag';
  created_at: Scalars['timestamp'];
  /** An array relationship */
  display_obituary_tags: Array<IDisplay_Obituary_Tag>;
  /** An aggregate relationship */
  display_obituary_tags_aggregate: IDisplay_Obituary_Tag_Aggregate;
  /** An array relationship */
  historical_obituary_tags: Array<IHistorical_Obituary_Tag>;
  /** An aggregate relationship */
  historical_obituary_tags_aggregate: IHistorical_Obituary_Tag_Aggregate;
  id: Scalars['uuid'];
  updated_at: Scalars['timestamp'];
  value: Scalars['String'];
  /** An array relationship */
  web_config_filter_tags: Array<IWeb_Config_Filter_Tags>;
  /** An aggregate relationship */
  web_config_filter_tags_aggregate: IWeb_Config_Filter_Tags_Aggregate;
};


/** columns and relationships of "tag" */
export type ITagDisplay_Obituary_TagsArgs = {
  distinct_on?: Maybe<Array<IDisplay_Obituary_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDisplay_Obituary_Tag_Order_By>>;
  where?: Maybe<IDisplay_Obituary_Tag_Bool_Exp>;
};


/** columns and relationships of "tag" */
export type ITagDisplay_Obituary_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<IDisplay_Obituary_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDisplay_Obituary_Tag_Order_By>>;
  where?: Maybe<IDisplay_Obituary_Tag_Bool_Exp>;
};


/** columns and relationships of "tag" */
export type ITagHistorical_Obituary_TagsArgs = {
  distinct_on?: Maybe<Array<IHistorical_Obituary_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHistorical_Obituary_Tag_Order_By>>;
  where?: Maybe<IHistorical_Obituary_Tag_Bool_Exp>;
};


/** columns and relationships of "tag" */
export type ITagHistorical_Obituary_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<IHistorical_Obituary_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHistorical_Obituary_Tag_Order_By>>;
  where?: Maybe<IHistorical_Obituary_Tag_Bool_Exp>;
};


/** columns and relationships of "tag" */
export type ITagWeb_Config_Filter_TagsArgs = {
  distinct_on?: Maybe<Array<IWeb_Config_Filter_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWeb_Config_Filter_Tags_Order_By>>;
  where?: Maybe<IWeb_Config_Filter_Tags_Bool_Exp>;
};


/** columns and relationships of "tag" */
export type ITagWeb_Config_Filter_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<IWeb_Config_Filter_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWeb_Config_Filter_Tags_Order_By>>;
  where?: Maybe<IWeb_Config_Filter_Tags_Bool_Exp>;
};

/** aggregated selection of "tag" */
export type ITag_Aggregate = {
  __typename?: 'tag_aggregate';
  aggregate?: Maybe<ITag_Aggregate_Fields>;
  nodes: Array<ITag>;
};

/** aggregate fields of "tag" */
export type ITag_Aggregate_Fields = {
  __typename?: 'tag_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ITag_Max_Fields>;
  min?: Maybe<ITag_Min_Fields>;
};


/** aggregate fields of "tag" */
export type ITag_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ITag_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "tag". All fields are combined with a logical 'AND'. */
export type ITag_Bool_Exp = {
  _and?: Maybe<Array<ITag_Bool_Exp>>;
  _not?: Maybe<ITag_Bool_Exp>;
  _or?: Maybe<Array<ITag_Bool_Exp>>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  display_obituary_tags?: Maybe<IDisplay_Obituary_Tag_Bool_Exp>;
  historical_obituary_tags?: Maybe<IHistorical_Obituary_Tag_Bool_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
  value?: Maybe<IString_Comparison_Exp>;
  web_config_filter_tags?: Maybe<IWeb_Config_Filter_Tags_Bool_Exp>;
};

/** unique or primary key constraints on table "tag" */
export enum ITag_Constraint {
  /** unique or primary key constraint on columns "id" */
  TagPkey = 'tag_pkey',
  /** unique or primary key constraint on columns "value" */
  TagValueKey = 'tag_value_key'
}

/** input type for inserting data into table "tag" */
export type ITag_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  display_obituary_tags?: Maybe<IDisplay_Obituary_Tag_Arr_Rel_Insert_Input>;
  historical_obituary_tags?: Maybe<IHistorical_Obituary_Tag_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  value?: Maybe<Scalars['String']>;
  web_config_filter_tags?: Maybe<IWeb_Config_Filter_Tags_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type ITag_Max_Fields = {
  __typename?: 'tag_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ITag_Min_Fields = {
  __typename?: 'tag_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "tag" */
export type ITag_Mutation_Response = {
  __typename?: 'tag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ITag>;
};

/** input type for inserting object relation for remote table "tag" */
export type ITag_Obj_Rel_Insert_Input = {
  data: ITag_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<ITag_On_Conflict>;
};

/** on_conflict condition type for table "tag" */
export type ITag_On_Conflict = {
  constraint: ITag_Constraint;
  update_columns?: Array<ITag_Update_Column>;
  where?: Maybe<ITag_Bool_Exp>;
};

/** Ordering options when selecting data from "tag". */
export type ITag_Order_By = {
  created_at?: Maybe<IOrder_By>;
  display_obituary_tags_aggregate?: Maybe<IDisplay_Obituary_Tag_Aggregate_Order_By>;
  historical_obituary_tags_aggregate?: Maybe<IHistorical_Obituary_Tag_Aggregate_Order_By>;
  id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  value?: Maybe<IOrder_By>;
  web_config_filter_tags_aggregate?: Maybe<IWeb_Config_Filter_Tags_Aggregate_Order_By>;
};

/** primary key columns input for table: tag */
export type ITag_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tag" */
export enum ITag_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "tag" */
export type ITag_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "tag" */
export enum ITag_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}


/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type ITimestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type ITimestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "topics" */
export type ITopics = {
  __typename?: 'topics';
  id: Scalars['Int'];
  value: Scalars['String'];
};

/** aggregated selection of "topics" */
export type ITopics_Aggregate = {
  __typename?: 'topics_aggregate';
  aggregate?: Maybe<ITopics_Aggregate_Fields>;
  nodes: Array<ITopics>;
};

/** aggregate fields of "topics" */
export type ITopics_Aggregate_Fields = {
  __typename?: 'topics_aggregate_fields';
  avg?: Maybe<ITopics_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ITopics_Max_Fields>;
  min?: Maybe<ITopics_Min_Fields>;
  stddev?: Maybe<ITopics_Stddev_Fields>;
  stddev_pop?: Maybe<ITopics_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ITopics_Stddev_Samp_Fields>;
  sum?: Maybe<ITopics_Sum_Fields>;
  var_pop?: Maybe<ITopics_Var_Pop_Fields>;
  var_samp?: Maybe<ITopics_Var_Samp_Fields>;
  variance?: Maybe<ITopics_Variance_Fields>;
};


/** aggregate fields of "topics" */
export type ITopics_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ITopics_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ITopics_Avg_Fields = {
  __typename?: 'topics_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "topics". All fields are combined with a logical 'AND'. */
export type ITopics_Bool_Exp = {
  _and?: Maybe<Array<ITopics_Bool_Exp>>;
  _not?: Maybe<ITopics_Bool_Exp>;
  _or?: Maybe<Array<ITopics_Bool_Exp>>;
  id?: Maybe<IInt_Comparison_Exp>;
  value?: Maybe<IString_Comparison_Exp>;
};

/** unique or primary key constraints on table "topics" */
export enum ITopics_Constraint {
  /** unique or primary key constraint on columns "id" */
  TopicsPkey = 'topics_pkey'
}

/** input type for incrementing numeric columns in table "topics" */
export type ITopics_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "topics" */
export type ITopics_Insert_Input = {
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ITopics_Max_Fields = {
  __typename?: 'topics_max_fields';
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ITopics_Min_Fields = {
  __typename?: 'topics_min_fields';
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "topics" */
export type ITopics_Mutation_Response = {
  __typename?: 'topics_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ITopics>;
};

/** input type for inserting object relation for remote table "topics" */
export type ITopics_Obj_Rel_Insert_Input = {
  data: ITopics_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<ITopics_On_Conflict>;
};

/** on_conflict condition type for table "topics" */
export type ITopics_On_Conflict = {
  constraint: ITopics_Constraint;
  update_columns?: Array<ITopics_Update_Column>;
  where?: Maybe<ITopics_Bool_Exp>;
};

/** Ordering options when selecting data from "topics". */
export type ITopics_Order_By = {
  id?: Maybe<IOrder_By>;
  value?: Maybe<IOrder_By>;
};

/** primary key columns input for table: topics */
export type ITopics_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "topics" */
export enum ITopics_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "topics" */
export type ITopics_Set_Input = {
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ITopics_Stddev_Fields = {
  __typename?: 'topics_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ITopics_Stddev_Pop_Fields = {
  __typename?: 'topics_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ITopics_Stddev_Samp_Fields = {
  __typename?: 'topics_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "topics" */
export type ITopics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ITopics_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<ICursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ITopics_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ITopics_Sum_Fields = {
  __typename?: 'topics_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "topics" */
export enum ITopics_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

export type ITopics_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ITopics_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ITopics_Set_Input>;
  /** filter the rows which have to be updated */
  where: ITopics_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ITopics_Var_Pop_Fields = {
  __typename?: 'topics_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ITopics_Var_Samp_Fields = {
  __typename?: 'topics_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ITopics_Variance_Fields = {
  __typename?: 'topics_variance_fields';
  id?: Maybe<Scalars['Float']>;
};


/** expression to compare columns of type tsvector. All fields are combined with logical 'AND'. */
export type ITsvector_Comparison_Exp = {
  _eq?: Maybe<Scalars['tsvector']>;
  _gt?: Maybe<Scalars['tsvector']>;
  _gte?: Maybe<Scalars['tsvector']>;
  _in?: Maybe<Array<Scalars['tsvector']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['tsvector']>;
  _lte?: Maybe<Scalars['tsvector']>;
  _neq?: Maybe<Scalars['tsvector']>;
  _nin?: Maybe<Array<Scalars['tsvector']>>;
};

/** columns and relationships of "urls" */
export type IUrls = {
  __typename?: 'urls';
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  location?: Maybe<ILocations>;
  location_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  url?: Maybe<Scalars['String']>;
  url_type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "urls" */
export type IUrls_Aggregate = {
  __typename?: 'urls_aggregate';
  aggregate?: Maybe<IUrls_Aggregate_Fields>;
  nodes: Array<IUrls>;
};

/** aggregate fields of "urls" */
export type IUrls_Aggregate_Fields = {
  __typename?: 'urls_aggregate_fields';
  avg?: Maybe<IUrls_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<IUrls_Max_Fields>;
  min?: Maybe<IUrls_Min_Fields>;
  stddev?: Maybe<IUrls_Stddev_Fields>;
  stddev_pop?: Maybe<IUrls_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IUrls_Stddev_Samp_Fields>;
  sum?: Maybe<IUrls_Sum_Fields>;
  var_pop?: Maybe<IUrls_Var_Pop_Fields>;
  var_samp?: Maybe<IUrls_Var_Samp_Fields>;
  variance?: Maybe<IUrls_Variance_Fields>;
};


/** aggregate fields of "urls" */
export type IUrls_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IUrls_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "urls" */
export type IUrls_Aggregate_Order_By = {
  avg?: Maybe<IUrls_Avg_Order_By>;
  count?: Maybe<IOrder_By>;
  max?: Maybe<IUrls_Max_Order_By>;
  min?: Maybe<IUrls_Min_Order_By>;
  stddev?: Maybe<IUrls_Stddev_Order_By>;
  stddev_pop?: Maybe<IUrls_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IUrls_Stddev_Samp_Order_By>;
  sum?: Maybe<IUrls_Sum_Order_By>;
  var_pop?: Maybe<IUrls_Var_Pop_Order_By>;
  var_samp?: Maybe<IUrls_Var_Samp_Order_By>;
  variance?: Maybe<IUrls_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "urls" */
export type IUrls_Arr_Rel_Insert_Input = {
  data: Array<IUrls_Insert_Input>;
  on_conflict?: Maybe<IUrls_On_Conflict>;
};

/** aggregate avg on columns */
export type IUrls_Avg_Fields = {
  __typename?: 'urls_avg_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "urls" */
export type IUrls_Avg_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** Boolean expression to filter rows from the table "urls". All fields are combined with a logical 'AND'. */
export type IUrls_Bool_Exp = {
  _and?: Maybe<Array<Maybe<IUrls_Bool_Exp>>>;
  _not?: Maybe<IUrls_Bool_Exp>;
  _or?: Maybe<Array<Maybe<IUrls_Bool_Exp>>>;
  created_at?: Maybe<ITimestamptz_Comparison_Exp>;
  id?: Maybe<IBigint_Comparison_Exp>;
  location?: Maybe<ILocations_Bool_Exp>;
  location_id?: Maybe<IBigint_Comparison_Exp>;
  type?: Maybe<IString_Comparison_Exp>;
  updated_at?: Maybe<ITimestamptz_Comparison_Exp>;
  url?: Maybe<IString_Comparison_Exp>;
  url_type?: Maybe<IString_Comparison_Exp>;
};

/** unique or primary key constraints on table "urls" */
export enum IUrls_Constraint {
  /** unique or primary key constraint */
  Idx_17117Primary = 'idx_17117_primary'
}

/** input type for incrementing integer column in table "urls" */
export type IUrls_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "urls" */
export type IUrls_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  location?: Maybe<ILocations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  url_type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type IUrls_Max_Fields = {
  __typename?: 'urls_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  url_type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "urls" */
export type IUrls_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  type?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  url?: Maybe<IOrder_By>;
  url_type?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IUrls_Min_Fields = {
  __typename?: 'urls_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  url_type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "urls" */
export type IUrls_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
  type?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  url?: Maybe<IOrder_By>;
  url_type?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "urls" */
export type IUrls_Mutation_Response = {
  __typename?: 'urls_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<IUrls>;
};

/** input type for inserting object relation for remote table "urls" */
export type IUrls_Obj_Rel_Insert_Input = {
  data: IUrls_Insert_Input;
  on_conflict?: Maybe<IUrls_On_Conflict>;
};

/** on conflict condition type for table "urls" */
export type IUrls_On_Conflict = {
  constraint: IUrls_Constraint;
  update_columns: Array<IUrls_Update_Column>;
  where?: Maybe<IUrls_Bool_Exp>;
};

/** ordering options when selecting data from "urls" */
export type IUrls_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  location?: Maybe<ILocations_Order_By>;
  location_id?: Maybe<IOrder_By>;
  type?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  url?: Maybe<IOrder_By>;
  url_type?: Maybe<IOrder_By>;
};

/** select columns of table "urls" */
export enum IUrls_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  UrlType = 'url_type'
}

/** input type for updating data in table "urls" */
export type IUrls_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  url_type?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type IUrls_Stddev_Fields = {
  __typename?: 'urls_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "urls" */
export type IUrls_Stddev_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IUrls_Stddev_Pop_Fields = {
  __typename?: 'urls_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "urls" */
export type IUrls_Stddev_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IUrls_Stddev_Samp_Fields = {
  __typename?: 'urls_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "urls" */
export type IUrls_Stddev_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate sum on columns */
export type IUrls_Sum_Fields = {
  __typename?: 'urls_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "urls" */
export type IUrls_Sum_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** update columns of table "urls" */
export enum IUrls_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  UrlType = 'url_type'
}

/** aggregate var_pop on columns */
export type IUrls_Var_Pop_Fields = {
  __typename?: 'urls_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "urls" */
export type IUrls_Var_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate var_samp on columns */
export type IUrls_Var_Samp_Fields = {
  __typename?: 'urls_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "urls" */
export type IUrls_Var_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** aggregate variance on columns */
export type IUrls_Variance_Fields = {
  __typename?: 'urls_variance_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "urls" */
export type IUrls_Variance_Order_By = {
  id?: Maybe<IOrder_By>;
  location_id?: Maybe<IOrder_By>;
};

/** columns and relationships of "users" */
export type IUsers = {
  __typename?: 'users';
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "users" */
export type IUsers_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<IUsers_Aggregate_Fields>;
  nodes: Array<IUsers>;
};

/** aggregate fields of "users" */
export type IUsers_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IUsers_Max_Fields>;
  min?: Maybe<IUsers_Min_Fields>;
};


/** aggregate fields of "users" */
export type IUsers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IUsers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type IUsers_Bool_Exp = {
  _and?: Maybe<Array<IUsers_Bool_Exp>>;
  _not?: Maybe<IUsers_Bool_Exp>;
  _or?: Maybe<Array<IUsers_Bool_Exp>>;
  email?: Maybe<IString_Comparison_Exp>;
  id?: Maybe<IString_Comparison_Exp>;
  name?: Maybe<IString_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum IUsers_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** input type for inserting data into table "users" */
export type IUsers_Insert_Input = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type IUsers_Max_Fields = {
  __typename?: 'users_max_fields';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type IUsers_Min_Fields = {
  __typename?: 'users_min_fields';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "users" */
export type IUsers_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IUsers>;
};

/** input type for inserting object relation for remote table "users" */
export type IUsers_Obj_Rel_Insert_Input = {
  data: IUsers_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<IUsers_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type IUsers_On_Conflict = {
  constraint: IUsers_Constraint;
  update_columns?: Array<IUsers_Update_Column>;
  where?: Maybe<IUsers_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type IUsers_Order_By = {
  email?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
};

/** primary key columns input for table: users */
export type IUsers_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "users" */
export enum IUsers_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "users" */
export type IUsers_Set_Input = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "users" */
export type IUsers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IUsers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<ICursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IUsers_Stream_Cursor_Value_Input = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "users" */
export enum IUsers_Update_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type IUsers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<IUsers_Set_Input>;
  /** filter the rows which have to be updated */
  where: IUsers_Bool_Exp;
};


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type IUuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "verticals" */
export type IVerticals = {
  __typename?: 'verticals';
  /** An array relationship */
  categories: Array<ICategories_Verticals>;
  /** An aggregated array relationship */
  categories_aggregate: ICategories_Verticals_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  name?: Maybe<Scalars['String']>;
  rl_category_id?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "verticals" */
export type IVerticalsCategoriesArgs = {
  distinct_on?: Maybe<Array<ICategories_Verticals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategories_Verticals_Order_By>>;
  where?: Maybe<ICategories_Verticals_Bool_Exp>;
};


/** columns and relationships of "verticals" */
export type IVerticalsCategories_AggregateArgs = {
  distinct_on?: Maybe<Array<ICategories_Verticals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICategories_Verticals_Order_By>>;
  where?: Maybe<ICategories_Verticals_Bool_Exp>;
};

/** aggregated selection of "verticals" */
export type IVerticals_Aggregate = {
  __typename?: 'verticals_aggregate';
  aggregate?: Maybe<IVerticals_Aggregate_Fields>;
  nodes: Array<IVerticals>;
};

/** aggregate fields of "verticals" */
export type IVerticals_Aggregate_Fields = {
  __typename?: 'verticals_aggregate_fields';
  avg?: Maybe<IVerticals_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<IVerticals_Max_Fields>;
  min?: Maybe<IVerticals_Min_Fields>;
  stddev?: Maybe<IVerticals_Stddev_Fields>;
  stddev_pop?: Maybe<IVerticals_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IVerticals_Stddev_Samp_Fields>;
  sum?: Maybe<IVerticals_Sum_Fields>;
  var_pop?: Maybe<IVerticals_Var_Pop_Fields>;
  var_samp?: Maybe<IVerticals_Var_Samp_Fields>;
  variance?: Maybe<IVerticals_Variance_Fields>;
};


/** aggregate fields of "verticals" */
export type IVerticals_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IVerticals_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "verticals" */
export type IVerticals_Aggregate_Order_By = {
  avg?: Maybe<IVerticals_Avg_Order_By>;
  count?: Maybe<IOrder_By>;
  max?: Maybe<IVerticals_Max_Order_By>;
  min?: Maybe<IVerticals_Min_Order_By>;
  stddev?: Maybe<IVerticals_Stddev_Order_By>;
  stddev_pop?: Maybe<IVerticals_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IVerticals_Stddev_Samp_Order_By>;
  sum?: Maybe<IVerticals_Sum_Order_By>;
  var_pop?: Maybe<IVerticals_Var_Pop_Order_By>;
  var_samp?: Maybe<IVerticals_Var_Samp_Order_By>;
  variance?: Maybe<IVerticals_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "verticals" */
export type IVerticals_Arr_Rel_Insert_Input = {
  data: Array<IVerticals_Insert_Input>;
  on_conflict?: Maybe<IVerticals_On_Conflict>;
};

/** aggregate avg on columns */
export type IVerticals_Avg_Fields = {
  __typename?: 'verticals_avg_fields';
  id?: Maybe<Scalars['Float']>;
  rl_category_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "verticals" */
export type IVerticals_Avg_Order_By = {
  id?: Maybe<IOrder_By>;
  rl_category_id?: Maybe<IOrder_By>;
};

/** Boolean expression to filter rows from the table "verticals". All fields are combined with a logical 'AND'. */
export type IVerticals_Bool_Exp = {
  _and?: Maybe<Array<Maybe<IVerticals_Bool_Exp>>>;
  _not?: Maybe<IVerticals_Bool_Exp>;
  _or?: Maybe<Array<Maybe<IVerticals_Bool_Exp>>>;
  categories?: Maybe<ICategories_Verticals_Bool_Exp>;
  created_at?: Maybe<ITimestamptz_Comparison_Exp>;
  discarded_at?: Maybe<ITimestamptz_Comparison_Exp>;
  id?: Maybe<IBigint_Comparison_Exp>;
  name?: Maybe<IString_Comparison_Exp>;
  rl_category_id?: Maybe<IBigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "verticals" */
export enum IVerticals_Constraint {
  /** unique or primary key constraint */
  Idx_17144Primary = 'idx_17144_primary'
}

/** input type for incrementing integer column in table "verticals" */
export type IVerticals_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  rl_category_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "verticals" */
export type IVerticals_Insert_Input = {
  categories?: Maybe<ICategories_Verticals_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  rl_category_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type IVerticals_Max_Fields = {
  __typename?: 'verticals_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  rl_category_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "verticals" */
export type IVerticals_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  discarded_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  rl_category_id?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IVerticals_Min_Fields = {
  __typename?: 'verticals_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  rl_category_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "verticals" */
export type IVerticals_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  discarded_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  rl_category_id?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "verticals" */
export type IVerticals_Mutation_Response = {
  __typename?: 'verticals_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<IVerticals>;
};

/** input type for inserting object relation for remote table "verticals" */
export type IVerticals_Obj_Rel_Insert_Input = {
  data: IVerticals_Insert_Input;
  on_conflict?: Maybe<IVerticals_On_Conflict>;
};

/** on conflict condition type for table "verticals" */
export type IVerticals_On_Conflict = {
  constraint: IVerticals_Constraint;
  update_columns: Array<IVerticals_Update_Column>;
  where?: Maybe<IVerticals_Bool_Exp>;
};

/** ordering options when selecting data from "verticals" */
export type IVerticals_Order_By = {
  categories_aggregate?: Maybe<ICategories_Verticals_Aggregate_Order_By>;
  created_at?: Maybe<IOrder_By>;
  discarded_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  rl_category_id?: Maybe<IOrder_By>;
};

/** select columns of table "verticals" */
export enum IVerticals_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DiscardedAt = 'discarded_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RlCategoryId = 'rl_category_id'
}

/** input type for updating data in table "verticals" */
export type IVerticals_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  discarded_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  rl_category_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type IVerticals_Stddev_Fields = {
  __typename?: 'verticals_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  rl_category_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "verticals" */
export type IVerticals_Stddev_Order_By = {
  id?: Maybe<IOrder_By>;
  rl_category_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IVerticals_Stddev_Pop_Fields = {
  __typename?: 'verticals_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  rl_category_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "verticals" */
export type IVerticals_Stddev_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
  rl_category_id?: Maybe<IOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IVerticals_Stddev_Samp_Fields = {
  __typename?: 'verticals_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  rl_category_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "verticals" */
export type IVerticals_Stddev_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
  rl_category_id?: Maybe<IOrder_By>;
};

/** aggregate sum on columns */
export type IVerticals_Sum_Fields = {
  __typename?: 'verticals_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  rl_category_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "verticals" */
export type IVerticals_Sum_Order_By = {
  id?: Maybe<IOrder_By>;
  rl_category_id?: Maybe<IOrder_By>;
};

/** update columns of table "verticals" */
export enum IVerticals_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DiscardedAt = 'discarded_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RlCategoryId = 'rl_category_id'
}

/** aggregate var_pop on columns */
export type IVerticals_Var_Pop_Fields = {
  __typename?: 'verticals_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  rl_category_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "verticals" */
export type IVerticals_Var_Pop_Order_By = {
  id?: Maybe<IOrder_By>;
  rl_category_id?: Maybe<IOrder_By>;
};

/** aggregate var_samp on columns */
export type IVerticals_Var_Samp_Fields = {
  __typename?: 'verticals_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  rl_category_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "verticals" */
export type IVerticals_Var_Samp_Order_By = {
  id?: Maybe<IOrder_By>;
  rl_category_id?: Maybe<IOrder_By>;
};

/** aggregate variance on columns */
export type IVerticals_Variance_Fields = {
  __typename?: 'verticals_variance_fields';
  id?: Maybe<Scalars['Float']>;
  rl_category_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "verticals" */
export type IVerticals_Variance_Order_By = {
  id?: Maybe<IOrder_By>;
  rl_category_id?: Maybe<IOrder_By>;
};

/** columns and relationships of "web_config" */
export type IWeb_Config = {
  __typename?: 'web_config';
  created_at: Scalars['timestamp'];
  desktop_ad_unit?: Maybe<Scalars['String']>;
  domain: Scalars['String'];
  external_links: Scalars['_text'];
  footer: Scalars['String'];
  header: Scalars['String'];
  id: Scalars['uuid'];
  mobile_ad_unit?: Maybe<Scalars['String']>;
  /** An object relationship */
  publication: IPublication;
  publication_id: Scalars['uuid'];
  /** An object relationship */
  tag?: Maybe<ITag>;
  tag_id?: Maybe<Scalars['uuid']>;
  title: Scalars['String'];
  updated_at: Scalars['timestamp'];
  /** An array relationship */
  web_config_filters: Array<IWeb_Config_Filter>;
  /** An aggregate relationship */
  web_config_filters_aggregate: IWeb_Config_Filter_Aggregate;
};


/** columns and relationships of "web_config" */
export type IWeb_ConfigWeb_Config_FiltersArgs = {
  distinct_on?: Maybe<Array<IWeb_Config_Filter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWeb_Config_Filter_Order_By>>;
  where?: Maybe<IWeb_Config_Filter_Bool_Exp>;
};


/** columns and relationships of "web_config" */
export type IWeb_ConfigWeb_Config_Filters_AggregateArgs = {
  distinct_on?: Maybe<Array<IWeb_Config_Filter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWeb_Config_Filter_Order_By>>;
  where?: Maybe<IWeb_Config_Filter_Bool_Exp>;
};

/** aggregated selection of "web_config" */
export type IWeb_Config_Aggregate = {
  __typename?: 'web_config_aggregate';
  aggregate?: Maybe<IWeb_Config_Aggregate_Fields>;
  nodes: Array<IWeb_Config>;
};

/** aggregate fields of "web_config" */
export type IWeb_Config_Aggregate_Fields = {
  __typename?: 'web_config_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IWeb_Config_Max_Fields>;
  min?: Maybe<IWeb_Config_Min_Fields>;
};


/** aggregate fields of "web_config" */
export type IWeb_Config_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IWeb_Config_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "web_config" */
export type IWeb_Config_Aggregate_Order_By = {
  count?: Maybe<IOrder_By>;
  max?: Maybe<IWeb_Config_Max_Order_By>;
  min?: Maybe<IWeb_Config_Min_Order_By>;
};

/** input type for inserting array relation for remote table "web_config" */
export type IWeb_Config_Arr_Rel_Insert_Input = {
  data: Array<IWeb_Config_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<IWeb_Config_On_Conflict>;
};

/** Boolean expression to filter rows from the table "web_config". All fields are combined with a logical 'AND'. */
export type IWeb_Config_Bool_Exp = {
  _and?: Maybe<Array<IWeb_Config_Bool_Exp>>;
  _not?: Maybe<IWeb_Config_Bool_Exp>;
  _or?: Maybe<Array<IWeb_Config_Bool_Exp>>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  desktop_ad_unit?: Maybe<IString_Comparison_Exp>;
  domain?: Maybe<IString_Comparison_Exp>;
  external_links?: Maybe<I_Text_Comparison_Exp>;
  footer?: Maybe<IString_Comparison_Exp>;
  header?: Maybe<IString_Comparison_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  mobile_ad_unit?: Maybe<IString_Comparison_Exp>;
  publication?: Maybe<IPublication_Bool_Exp>;
  publication_id?: Maybe<IUuid_Comparison_Exp>;
  tag?: Maybe<ITag_Bool_Exp>;
  tag_id?: Maybe<IUuid_Comparison_Exp>;
  title?: Maybe<IString_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
  web_config_filters?: Maybe<IWeb_Config_Filter_Bool_Exp>;
};

/** unique or primary key constraints on table "web_config" */
export enum IWeb_Config_Constraint {
  /** unique or primary key constraint on columns "id" */
  WebConfigPkey = 'web_config_pkey'
}

/** columns and relationships of "web_config_filter" */
export type IWeb_Config_Filter = {
  __typename?: 'web_config_filter';
  created_at: Scalars['timestamp'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  updated_at: Scalars['timestamp'];
  /** An object relationship */
  web_config: IWeb_Config;
  /** An array relationship */
  web_config_filter_tags: Array<IWeb_Config_Filter_Tags>;
  /** An aggregate relationship */
  web_config_filter_tags_aggregate: IWeb_Config_Filter_Tags_Aggregate;
  web_config_id: Scalars['uuid'];
};


/** columns and relationships of "web_config_filter" */
export type IWeb_Config_FilterWeb_Config_Filter_TagsArgs = {
  distinct_on?: Maybe<Array<IWeb_Config_Filter_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWeb_Config_Filter_Tags_Order_By>>;
  where?: Maybe<IWeb_Config_Filter_Tags_Bool_Exp>;
};


/** columns and relationships of "web_config_filter" */
export type IWeb_Config_FilterWeb_Config_Filter_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<IWeb_Config_Filter_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWeb_Config_Filter_Tags_Order_By>>;
  where?: Maybe<IWeb_Config_Filter_Tags_Bool_Exp>;
};

/** aggregated selection of "web_config_filter" */
export type IWeb_Config_Filter_Aggregate = {
  __typename?: 'web_config_filter_aggregate';
  aggregate?: Maybe<IWeb_Config_Filter_Aggregate_Fields>;
  nodes: Array<IWeb_Config_Filter>;
};

/** aggregate fields of "web_config_filter" */
export type IWeb_Config_Filter_Aggregate_Fields = {
  __typename?: 'web_config_filter_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IWeb_Config_Filter_Max_Fields>;
  min?: Maybe<IWeb_Config_Filter_Min_Fields>;
};


/** aggregate fields of "web_config_filter" */
export type IWeb_Config_Filter_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IWeb_Config_Filter_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "web_config_filter" */
export type IWeb_Config_Filter_Aggregate_Order_By = {
  count?: Maybe<IOrder_By>;
  max?: Maybe<IWeb_Config_Filter_Max_Order_By>;
  min?: Maybe<IWeb_Config_Filter_Min_Order_By>;
};

/** input type for inserting array relation for remote table "web_config_filter" */
export type IWeb_Config_Filter_Arr_Rel_Insert_Input = {
  data: Array<IWeb_Config_Filter_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<IWeb_Config_Filter_On_Conflict>;
};

/** Boolean expression to filter rows from the table "web_config_filter". All fields are combined with a logical 'AND'. */
export type IWeb_Config_Filter_Bool_Exp = {
  _and?: Maybe<Array<IWeb_Config_Filter_Bool_Exp>>;
  _not?: Maybe<IWeb_Config_Filter_Bool_Exp>;
  _or?: Maybe<Array<IWeb_Config_Filter_Bool_Exp>>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  name?: Maybe<IString_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
  web_config?: Maybe<IWeb_Config_Bool_Exp>;
  web_config_filter_tags?: Maybe<IWeb_Config_Filter_Tags_Bool_Exp>;
  web_config_id?: Maybe<IUuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "web_config_filter" */
export enum IWeb_Config_Filter_Constraint {
  /** unique or primary key constraint on columns "id" */
  WebConfigFilterPkey = 'web_config_filter_pkey'
}

/** input type for inserting data into table "web_config_filter" */
export type IWeb_Config_Filter_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  web_config?: Maybe<IWeb_Config_Obj_Rel_Insert_Input>;
  web_config_filter_tags?: Maybe<IWeb_Config_Filter_Tags_Arr_Rel_Insert_Input>;
  web_config_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type IWeb_Config_Filter_Max_Fields = {
  __typename?: 'web_config_filter_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  web_config_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "web_config_filter" */
export type IWeb_Config_Filter_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  web_config_id?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IWeb_Config_Filter_Min_Fields = {
  __typename?: 'web_config_filter_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  web_config_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "web_config_filter" */
export type IWeb_Config_Filter_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  web_config_id?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "web_config_filter" */
export type IWeb_Config_Filter_Mutation_Response = {
  __typename?: 'web_config_filter_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IWeb_Config_Filter>;
};

/** input type for inserting object relation for remote table "web_config_filter" */
export type IWeb_Config_Filter_Obj_Rel_Insert_Input = {
  data: IWeb_Config_Filter_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<IWeb_Config_Filter_On_Conflict>;
};

/** on_conflict condition type for table "web_config_filter" */
export type IWeb_Config_Filter_On_Conflict = {
  constraint: IWeb_Config_Filter_Constraint;
  update_columns?: Array<IWeb_Config_Filter_Update_Column>;
  where?: Maybe<IWeb_Config_Filter_Bool_Exp>;
};

/** Ordering options when selecting data from "web_config_filter". */
export type IWeb_Config_Filter_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  name?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  web_config?: Maybe<IWeb_Config_Order_By>;
  web_config_filter_tags_aggregate?: Maybe<IWeb_Config_Filter_Tags_Aggregate_Order_By>;
  web_config_id?: Maybe<IOrder_By>;
};

/** primary key columns input for table: web_config_filter */
export type IWeb_Config_Filter_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "web_config_filter" */
export enum IWeb_Config_Filter_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WebConfigId = 'web_config_id'
}

/** input type for updating data in table "web_config_filter" */
export type IWeb_Config_Filter_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  web_config_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "web_config_filter_tags" */
export type IWeb_Config_Filter_Tags = {
  __typename?: 'web_config_filter_tags';
  created_at: Scalars['timestamp'];
  id: Scalars['uuid'];
  /** An object relationship */
  tag: ITag;
  tag_id: Scalars['uuid'];
  updated_at: Scalars['timestamp'];
  /** An object relationship */
  web_config_filter: IWeb_Config_Filter;
  web_config_filter_id: Scalars['uuid'];
};

/** aggregated selection of "web_config_filter_tags" */
export type IWeb_Config_Filter_Tags_Aggregate = {
  __typename?: 'web_config_filter_tags_aggregate';
  aggregate?: Maybe<IWeb_Config_Filter_Tags_Aggregate_Fields>;
  nodes: Array<IWeb_Config_Filter_Tags>;
};

/** aggregate fields of "web_config_filter_tags" */
export type IWeb_Config_Filter_Tags_Aggregate_Fields = {
  __typename?: 'web_config_filter_tags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IWeb_Config_Filter_Tags_Max_Fields>;
  min?: Maybe<IWeb_Config_Filter_Tags_Min_Fields>;
};


/** aggregate fields of "web_config_filter_tags" */
export type IWeb_Config_Filter_Tags_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IWeb_Config_Filter_Tags_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "web_config_filter_tags" */
export type IWeb_Config_Filter_Tags_Aggregate_Order_By = {
  count?: Maybe<IOrder_By>;
  max?: Maybe<IWeb_Config_Filter_Tags_Max_Order_By>;
  min?: Maybe<IWeb_Config_Filter_Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "web_config_filter_tags" */
export type IWeb_Config_Filter_Tags_Arr_Rel_Insert_Input = {
  data: Array<IWeb_Config_Filter_Tags_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<IWeb_Config_Filter_Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "web_config_filter_tags". All fields are combined with a logical 'AND'. */
export type IWeb_Config_Filter_Tags_Bool_Exp = {
  _and?: Maybe<Array<IWeb_Config_Filter_Tags_Bool_Exp>>;
  _not?: Maybe<IWeb_Config_Filter_Tags_Bool_Exp>;
  _or?: Maybe<Array<IWeb_Config_Filter_Tags_Bool_Exp>>;
  created_at?: Maybe<ITimestamp_Comparison_Exp>;
  id?: Maybe<IUuid_Comparison_Exp>;
  tag?: Maybe<ITag_Bool_Exp>;
  tag_id?: Maybe<IUuid_Comparison_Exp>;
  updated_at?: Maybe<ITimestamp_Comparison_Exp>;
  web_config_filter?: Maybe<IWeb_Config_Filter_Bool_Exp>;
  web_config_filter_id?: Maybe<IUuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "web_config_filter_tags" */
export enum IWeb_Config_Filter_Tags_Constraint {
  /** unique or primary key constraint on columns "id" */
  WebConfigFilterTagsPkey = 'web_config_filter_tags_pkey'
}

/** input type for inserting data into table "web_config_filter_tags" */
export type IWeb_Config_Filter_Tags_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  tag?: Maybe<ITag_Obj_Rel_Insert_Input>;
  tag_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  web_config_filter?: Maybe<IWeb_Config_Filter_Obj_Rel_Insert_Input>;
  web_config_filter_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type IWeb_Config_Filter_Tags_Max_Fields = {
  __typename?: 'web_config_filter_tags_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  web_config_filter_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "web_config_filter_tags" */
export type IWeb_Config_Filter_Tags_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  tag_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  web_config_filter_id?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IWeb_Config_Filter_Tags_Min_Fields = {
  __typename?: 'web_config_filter_tags_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  web_config_filter_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "web_config_filter_tags" */
export type IWeb_Config_Filter_Tags_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  tag_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  web_config_filter_id?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "web_config_filter_tags" */
export type IWeb_Config_Filter_Tags_Mutation_Response = {
  __typename?: 'web_config_filter_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IWeb_Config_Filter_Tags>;
};

/** on_conflict condition type for table "web_config_filter_tags" */
export type IWeb_Config_Filter_Tags_On_Conflict = {
  constraint: IWeb_Config_Filter_Tags_Constraint;
  update_columns?: Array<IWeb_Config_Filter_Tags_Update_Column>;
  where?: Maybe<IWeb_Config_Filter_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "web_config_filter_tags". */
export type IWeb_Config_Filter_Tags_Order_By = {
  created_at?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  tag?: Maybe<ITag_Order_By>;
  tag_id?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  web_config_filter?: Maybe<IWeb_Config_Filter_Order_By>;
  web_config_filter_id?: Maybe<IOrder_By>;
};

/** primary key columns input for table: web_config_filter_tags */
export type IWeb_Config_Filter_Tags_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "web_config_filter_tags" */
export enum IWeb_Config_Filter_Tags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WebConfigFilterId = 'web_config_filter_id'
}

/** input type for updating data in table "web_config_filter_tags" */
export type IWeb_Config_Filter_Tags_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  web_config_filter_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "web_config_filter_tags" */
export enum IWeb_Config_Filter_Tags_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WebConfigFilterId = 'web_config_filter_id'
}

/** update columns of table "web_config_filter" */
export enum IWeb_Config_Filter_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WebConfigId = 'web_config_id'
}

/** input type for inserting data into table "web_config" */
export type IWeb_Config_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  desktop_ad_unit?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  external_links?: Maybe<Scalars['_text']>;
  footer?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mobile_ad_unit?: Maybe<Scalars['String']>;
  publication?: Maybe<IPublication_Obj_Rel_Insert_Input>;
  publication_id?: Maybe<Scalars['uuid']>;
  tag?: Maybe<ITag_Obj_Rel_Insert_Input>;
  tag_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  web_config_filters?: Maybe<IWeb_Config_Filter_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type IWeb_Config_Max_Fields = {
  __typename?: 'web_config_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  desktop_ad_unit?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mobile_ad_unit?: Maybe<Scalars['String']>;
  publication_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "web_config" */
export type IWeb_Config_Max_Order_By = {
  created_at?: Maybe<IOrder_By>;
  desktop_ad_unit?: Maybe<IOrder_By>;
  domain?: Maybe<IOrder_By>;
  footer?: Maybe<IOrder_By>;
  header?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  mobile_ad_unit?: Maybe<IOrder_By>;
  publication_id?: Maybe<IOrder_By>;
  tag_id?: Maybe<IOrder_By>;
  title?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** aggregate min on columns */
export type IWeb_Config_Min_Fields = {
  __typename?: 'web_config_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  desktop_ad_unit?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mobile_ad_unit?: Maybe<Scalars['String']>;
  publication_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "web_config" */
export type IWeb_Config_Min_Order_By = {
  created_at?: Maybe<IOrder_By>;
  desktop_ad_unit?: Maybe<IOrder_By>;
  domain?: Maybe<IOrder_By>;
  footer?: Maybe<IOrder_By>;
  header?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  mobile_ad_unit?: Maybe<IOrder_By>;
  publication_id?: Maybe<IOrder_By>;
  tag_id?: Maybe<IOrder_By>;
  title?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
};

/** response of any mutation on the table "web_config" */
export type IWeb_Config_Mutation_Response = {
  __typename?: 'web_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IWeb_Config>;
};

/** input type for inserting object relation for remote table "web_config" */
export type IWeb_Config_Obj_Rel_Insert_Input = {
  data: IWeb_Config_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<IWeb_Config_On_Conflict>;
};

/** on_conflict condition type for table "web_config" */
export type IWeb_Config_On_Conflict = {
  constraint: IWeb_Config_Constraint;
  update_columns?: Array<IWeb_Config_Update_Column>;
  where?: Maybe<IWeb_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "web_config". */
export type IWeb_Config_Order_By = {
  created_at?: Maybe<IOrder_By>;
  desktop_ad_unit?: Maybe<IOrder_By>;
  domain?: Maybe<IOrder_By>;
  external_links?: Maybe<IOrder_By>;
  footer?: Maybe<IOrder_By>;
  header?: Maybe<IOrder_By>;
  id?: Maybe<IOrder_By>;
  mobile_ad_unit?: Maybe<IOrder_By>;
  publication?: Maybe<IPublication_Order_By>;
  publication_id?: Maybe<IOrder_By>;
  tag?: Maybe<ITag_Order_By>;
  tag_id?: Maybe<IOrder_By>;
  title?: Maybe<IOrder_By>;
  updated_at?: Maybe<IOrder_By>;
  web_config_filters_aggregate?: Maybe<IWeb_Config_Filter_Aggregate_Order_By>;
};

/** primary key columns input for table: web_config */
export type IWeb_Config_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "web_config" */
export enum IWeb_Config_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DesktopAdUnit = 'desktop_ad_unit',
  /** column name */
  Domain = 'domain',
  /** column name */
  ExternalLinks = 'external_links',
  /** column name */
  Footer = 'footer',
  /** column name */
  Header = 'header',
  /** column name */
  Id = 'id',
  /** column name */
  MobileAdUnit = 'mobile_ad_unit',
  /** column name */
  PublicationId = 'publication_id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "web_config" */
export type IWeb_Config_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  desktop_ad_unit?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  external_links?: Maybe<Scalars['_text']>;
  footer?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mobile_ad_unit?: Maybe<Scalars['String']>;
  publication_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "web_config" */
export enum IWeb_Config_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DesktopAdUnit = 'desktop_ad_unit',
  /** column name */
  Domain = 'domain',
  /** column name */
  ExternalLinks = 'external_links',
  /** column name */
  Footer = 'footer',
  /** column name */
  Header = 'header',
  /** column name */
  Id = 'id',
  /** column name */
  MobileAdUnit = 'mobile_ad_unit',
  /** column name */
  PublicationId = 'publication_id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}
