// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IGetImageSignedPolicyUrlQueryVariables = Types.Exact<{
  containerHeight: Types.Scalars['String'];
  containerWidth: Types.Scalars['String'];
  contentType: Types.Scalars['String'];
  cropHeight: Types.Scalars['String'];
  cropWidth: Types.Scalars['String'];
  cropX: Types.Scalars['String'];
  cropY: Types.Scalars['String'];
  imageType: Types.Scalars['String'];
  imgRectX1: Types.Scalars['String'];
  imgRectX2: Types.Scalars['String'];
  imgRectY1: Types.Scalars['String'];
  imgRectY2: Types.Scalars['String'];
  name: Types.Scalars['String'];
  naturalImageHeight: Types.Scalars['String'];
  naturalImageWidth: Types.Scalars['String'];
  obitId: Types.Scalars['String'];
  rotate: Types.Scalars['String'];
  scale: Types.Scalars['String'];
}>;


export type IGetImageSignedPolicyUrlQuery = (
  { __typename?: 'query_root' }
  & { get_image_signed_policy_url?: Types.Maybe<(
    { __typename?: 'Policy' }
    & Pick<Types.IPolicy, 'name' | 'url'>
    & { fields?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'Field' }
      & Pick<Types.IField, 'key' | 'value'>
    )>>> }
  )> }
);


export const GetImageSignedPolicyUrlDocument = gql`
    query GetImageSignedPolicyURL($containerHeight: String!, $containerWidth: String!, $contentType: String!, $cropHeight: String!, $cropWidth: String!, $cropX: String!, $cropY: String!, $imageType: String!, $imgRectX1: String!, $imgRectX2: String!, $imgRectY1: String!, $imgRectY2: String!, $name: String!, $naturalImageHeight: String!, $naturalImageWidth: String!, $obitId: String!, $rotate: String!, $scale: String!) {
  get_image_signed_policy_url(
    containerHeight: $containerHeight
    containerWidth: $containerWidth
    contentType: $contentType
    cropHeight: $cropHeight
    cropWidth: $cropWidth
    cropX: $cropX
    cropY: $cropY
    imageType: $imageType
    imgRectX1: $imgRectX1
    imgRectX2: $imgRectX2
    imgRectY1: $imgRectY1
    imgRectY2: $imgRectY2
    name: $name
    naturalImageHeight: $naturalImageHeight
    naturalImageWidth: $naturalImageWidth
    obitId: $obitId
    rotate: $rotate
    scale: $scale
  ) {
    fields {
      key
      value
    }
    name
    url
  }
}
    `;

/**
 * __useGetImageSignedPolicyUrlQuery__
 *
 * To run a query within a React component, call `useGetImageSignedPolicyUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImageSignedPolicyUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImageSignedPolicyUrlQuery({
 *   variables: {
 *      containerHeight: // value for 'containerHeight'
 *      containerWidth: // value for 'containerWidth'
 *      contentType: // value for 'contentType'
 *      cropHeight: // value for 'cropHeight'
 *      cropWidth: // value for 'cropWidth'
 *      cropX: // value for 'cropX'
 *      cropY: // value for 'cropY'
 *      imageType: // value for 'imageType'
 *      imgRectX1: // value for 'imgRectX1'
 *      imgRectX2: // value for 'imgRectX2'
 *      imgRectY1: // value for 'imgRectY1'
 *      imgRectY2: // value for 'imgRectY2'
 *      name: // value for 'name'
 *      naturalImageHeight: // value for 'naturalImageHeight'
 *      naturalImageWidth: // value for 'naturalImageWidth'
 *      obitId: // value for 'obitId'
 *      rotate: // value for 'rotate'
 *      scale: // value for 'scale'
 *   },
 * });
 */
export function useGetImageSignedPolicyUrlQuery(baseOptions: Apollo.QueryHookOptions<IGetImageSignedPolicyUrlQuery, IGetImageSignedPolicyUrlQueryVariables>) {
        return Apollo.useQuery<IGetImageSignedPolicyUrlQuery, IGetImageSignedPolicyUrlQueryVariables>(GetImageSignedPolicyUrlDocument, baseOptions);
      }
export function useGetImageSignedPolicyUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetImageSignedPolicyUrlQuery, IGetImageSignedPolicyUrlQueryVariables>) {
          return Apollo.useLazyQuery<IGetImageSignedPolicyUrlQuery, IGetImageSignedPolicyUrlQueryVariables>(GetImageSignedPolicyUrlDocument, baseOptions);
        }
export type GetImageSignedPolicyUrlQueryHookResult = ReturnType<typeof useGetImageSignedPolicyUrlQuery>;
export type GetImageSignedPolicyUrlLazyQueryHookResult = ReturnType<typeof useGetImageSignedPolicyUrlLazyQuery>;
export type GetImageSignedPolicyUrlQueryResult = Apollo.QueryResult<IGetImageSignedPolicyUrlQuery, IGetImageSignedPolicyUrlQueryVariables>;